import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { FormTextField } from '../../../../../../component/form/FormTextField';
import { useTranslation } from 'react-i18next';
import React, { FormEvent, useEffect, useState } from 'react';
import { OptionItem, SubsidyRecipientView } from '../../../../../../generated/ApiClient';
import { Control, useForm, useWatch } from 'react-hook-form';
import {
  FinancesDrawerFormValues,
  salesAidSchema,
  SalesAidValues,
  SubsidyValues,
} from '../../RightDrawerSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { EnhancedTable } from '../../../../../../common/table/EnhancedTable';
import { HeadCell } from '../../../../../../common/table/EnhancedTableHead';
import { SubsidyForm } from './SubsidyForm';
import i18n from '../../../../../../common/i18n/i18n';
import { Delete } from '@mui/icons-material';
import { usePermissions } from '../../../../../../common/auth/PermissionsContext';
import { FormCheckbox } from '../../../../../../component/form/FormCheckbox';
import { FormNumberInput } from '../../../../../../component/form/FormNumberInput';

interface Props {
  salesAid: SalesAidValues | undefined;
  onClose: () => void;
  onSubmit: (values: SalesAidValues) => void;
  givers: OptionItem[];
  recipients: SubsidyRecipientView[];
  recipientUnits: { [index: number]: string };
  financingControl: Control<FinancesDrawerFormValues>;
  onRelevantChange: (name: string, value: unknown) => void;
}

interface SubsidyRow extends SubsidyValues {
  id: string;
  subsidyGiver: string;
  subsidyRecipient: string;
  icon?: JSX.Element;
  isFixValueIcon: JSX.Element;
  fixumText: string;
}

export const SalesAidDialog = ({
  onClose,
  salesAid,
  givers,
  recipients,
  recipientUnits,
  onSubmit,
  financingControl,
  onRelevantChange,
}: Props) => {
  const { t } = useTranslation();

  const { control, reset, handleSubmit } = useForm<SalesAidValues>({
    resolver: zodResolver(salesAidSchema),
    mode: 'onChange',
    defaultValues: salesAid,
  });
  const { hasPermission } = usePermissions();
  const canViewLocked = hasPermission('LockSalesAid', 'Execute');
  const [subsidies, setSubsidies] = useState<SubsidyValues[]>([]);

  useEffect(() => {
    reset({ ...salesAid, subsidies: [] });
    setSubsidies(
      salesAid?.subsidies.map((s, i) => ({
        ...s,
        id: i.toString(),
        icon: delButton(i),
      })) ?? []
    );
    // eslint-disable-next-line
  }, [salesAid]);

  const delButton = (i: number) => (
    <IconButton onClick={() => handleRemoveSubsidy(i)}>
      <Delete />
    </IconButton>
  );

  const handleRemoveSubsidy = (index: number) => {
    setSubsidies(subsidies.filter((s, i) => i !== index));
  };

  const handleNewSubsidy = (values: SubsidyValues) => {
    setSubsidies([...subsidies, values]);
  };

  const onHandleSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit((values) => {
      onSubmit({ ...values, subsidies });
      reset({});
      setSubsidies([]);
      onClose();
    }, console.error)(event);
  };

  const columns: readonly HeadCell<SubsidyRow>[] = [
    {
      id: 'subsidyGiver',
      disablePadding: false,
      align: 'left',
      label: t('deals.new.financesConfiguration.calculationDrawer.salesAids.subsidyGiver'),
    },
    {
      id: 'subsidyRecipient',
      disablePadding: false,
      align: 'left',
      label: t('deals.new.financesConfiguration.calculationDrawer.salesAids.subsidyRecipient'),
    },
    {
      id: 'percentage',
      disablePadding: false,
      align: 'left',
      label: t('deals.new.financesConfiguration.calculationDrawer.salesAids.percentage'),
    },
    {
      id: 'fixumText',
      disablePadding: false,
      align: 'left',
      label: t('deals.new.financesConfiguration.calculationDrawer.salesAids.fixum'),
    },
    {
      id: 'isFixValueIcon',
      disablePadding: false,
      align: 'left',
      label: t('deals.new.financesConfiguration.calculationDrawer.salesAids.isFixValue'),
    },
    {
      id: 'icon',
      align: 'right',
      disablePadding: false,
      label: '',
    },
  ];

  const roracUrl = useWatch({ control: financingControl, name: 'credibilityCheck.roracUrl' });

  return (
    <Dialog
      sx={{
        '.MuiDialog-paper': {
          width: { xl: '60vw', lg: '70vw', md: '90vw', sm: '100vw' },
          maxWidth: '900px',
        },
      }}
      open={Boolean(salesAid)}
      onClose={onClose}
      scroll="paper"
    >
      <DialogTitle>
        {t('deals.new.financesConfiguration.calculationDrawer.salesAids.title')}
      </DialogTitle>
      <DialogContent>
        <form id="sales-aid" onSubmit={onHandleSubmit}>
          {canViewLocked && (
            <Stack alignItems="flex-end" position="relative">
              <FormCheckbox
                control={control}
                variant="switch"
                label={t('deals.new.financesConfiguration.calculationDrawer.salesAids.isLocked')}
                name="isLocked"
              />
            </Stack>
          )}

          <FormTextField
            control={control}
            label={t('deals.new.financesConfiguration.calculationDrawer.salesAids.name')}
            name="name"
            sx={{ mb: 1 }}
            required
          />
          <FormTextField
            control={control}
            label={t('deals.new.financesConfiguration.calculationDrawer.salesAids.notice')}
            name="notice"
            multiline
            sx={{ mb: 1 }}
          />
          <FormTextField
            control={control}
            label={t('deals.new.financesConfiguration.calculationDrawer.salesAids.description')}
            name="description"
            multiline
            sx={{ mb: 1 }}
          />

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <EnhancedTable
              rows={subsidies
                .filter((s) => s.isVisible)
                .map((s, index) => ({
                  id: `${index}`,
                  ...s,
                  subsidyGiver: givers.find((g) => g.value === s.subsidyGiverId)?.label ?? '-',
                  subsidyRecipient: i18n.t([
                    `deals.new.financesConfiguration.calculationDrawer.subsidyValues.${s.subsidyRecipientId}`,
                    '',
                  ]),
                  isFixValueIcon: <Checkbox checked={s.isFixValue} />,
                  fixumText:
                    s.fixum != null
                      ? `${s.isFixValue ? s.fixum * -1 : s.fixum} ${
                          recipientUnits[s.subsidyRecipientId]
                        }`
                      : '',
                  percentage: s.isFixValue ? undefined : s.percentage,
                  icon: delButton(index),
                }))}
              variant="default"
              headCells={columns}
              hideCheckbox={true}
              minWidth={300}
            />
          </Box>
        </form>
        <SubsidyForm
          onSubmit={handleNewSubsidy}
          givers={givers}
          recipients={recipients}
          subsidies={subsidies}
        />
        <Stack alignItems="center" justifyContent="center">
          <FormNumberInput
            control={financingControl}
            name="credibilityCheck.ksvRating"
            label={t('deals.new.financesConfiguration.calculationDrawer.ksvRating')}
            onBlur={() => onRelevantChange('credibilityCheck.ksvRating', undefined)}
          />
          <Stack
            flexDirection="row"
            width="100%"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <FormTextField
              control={financingControl}
              name="credibilityCheck.roracUrl"
              label={t('deals.new.financesConfiguration.calculationDrawer.roracUrl')}
              onBlur={() => onRelevantChange('credibilityCheck.roracUrl', undefined)}
            />
            <Button
              disabled={!roracUrl}
              href={roracUrl ?? ''}
              target="_blank"
              sx={{ whiteSpace: 'nowrap', mt: 1 }}
            >
              {t('deals.new.financesConfiguration.calculationDrawer.openRorac')}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ height: '36px', width: '94px', border: 1, borderColor: 'black', color: 'black' }}
          onClick={onClose}
        >
          {t('deals.new.financesConfiguration.calculationDrawer.salesAids.cancel')}
        </Button>
        <Button variant="contained" color="primary" type="submit" form="sales-aid">
          {t('deals.new.financesConfiguration.calculationDrawer.salesAids.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { zodResolver } from '@hookform/resolvers/zod';
import { Button, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormCheckbox } from '../../../../component/form/FormCheckbox';
import { FormTextField } from '../../../../component/form/FormTextField';
import { EnhancedDialog } from '../../../../component/EnhancedDialog';

const schema = z.object({
  subject: z.string(),
  text: z.string(),
  sendAlsoToSalesman: z.boolean().optional(),
});

type SendDocumentsFormValues = z.infer<typeof schema>;

interface Props {
  onSend: (subject: string, text: string, sendAlsoToSaltesman: boolean) => Promise<void>;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const SendDocumentsDialog = ({ onSend, isOpen, setOpen }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const { control, handleSubmit, setValue } = useForm<SendDocumentsFormValues>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    setValue('text', t('deals.edit.sendDocuments.defaultValue.text'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(
      async (values) => {
        setLoading(true);
        try {
          await onSend(values.subject, values.text, values.sendAlsoToSalesman ?? false);
        } finally {
          setLoading(false);
        }
      },
      (err) => console.error(err)
    )(event);
  };

  return (
    <EnhancedDialog isDialogOpened={isOpen} setIsDialogOpened={setOpen}>
      <DialogTitle>
        {t('deals.edit.sendDocuments.sendDocumentByMail')}
      </DialogTitle>
      <DialogContent dividers>
        <form id="send-documents-form" onSubmit={onFormSubmit} noValidate>
          <span>{t('deals.edit.sendDocuments.remark')}</span>
          <Grid container spacing={4} sx={{ paddingY: 3 }}>
            <Grid item xs={12}>
              <FormTextField
                control={control}
                name="subject"
                label={t('deals.edit.sendDocuments.subject')}
                disabled={loading}
                shrink
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                control={control}
                name="text"
                label={t('deals.edit.sendDocuments.text')}
                disabled={loading}
                shrink
                multiline
                rows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <FormCheckbox
                control={control}
                name="sendAlsoToSalesman"
                label={t('deals.edit.sendDocuments.sendAlsoToSalesman')}
                disabled={loading}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Box sx={{ textAlign: 'right' }}>
          <Button
            onClick={handleClose}
            sx={{ border: 1, borderColor: 'black', color: 'black', marginRight: '4px' }} disabled={loading}
          >
            {t('cancel')}
          </Button>
          <Button type="submit" form="send-documents-form" variant="contained" disabled={loading}>
            {t('send')}
          </Button>
        </Box>
      </DialogActions>
    </EnhancedDialog>
  );
};

import { Grid, Skeleton } from '@mui/material';
import React from 'react';

export function DealInfoSkeleton() {
  const inputCol = {
    sm: 12,
    md: 3,
    lg: 3,
    sx: {
      display: 'flex',
      flexDirection: 'column',
    },
  };

  const textFieldProps = {
    sx: {height: "48px",
    margin: "8px"}
  };

  return (
    <Grid
      container
      item
      spacing="24px"
      sx={{
        '& .MuiTextField-root, .price-input': { m: 1, width: 'unset', flexGrow: 1 },
      }}
    >
      <Grid item {...inputCol}>
        <Skeleton {...textFieldProps} />
        <Skeleton {...textFieldProps} />
      </Grid>
      <Grid item {...inputCol}>
        <Skeleton {...textFieldProps} />
        <Skeleton {...textFieldProps} />
      </Grid>
      <Grid item {...inputCol}>
        <Skeleton {...textFieldProps} />
        <Skeleton {...textFieldProps} />
      </Grid>
      <Grid item {...inputCol}>
        <Skeleton {...textFieldProps} />
        <Skeleton {...textFieldProps} />
      </Grid>
    </Grid>
  );
}

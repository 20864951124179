import { grey, blue, red, green, yellow } from '@mercedes-benz/mui5-theme/';

export function DashboardStatusIndicator({ color }: { color: string }) {
  return (
    <div>
      <span className="dashboard-indicator" style={{ background: color }} />
      {/*// @ts-ignore*/}
      {/*<span>{state?.value ? t(`dashboard.applications.states.${state.value}`) : '-'}</span>*/}
    </div>
  );
}

export function DefaultDashboardStatusIndicator({state}: {state: number}) {
  return <DashboardStatusIndicator color={mapDefaultDashboardStatus(state)} />
}

export const mapDefaultDashboardStatus = (c?: number) => {
  if (c === 0 || c === 2 || c === 5) {
    return blue[70];
  }
  if (c === 4) {
    return red[70];
  }
  if (c === 3) {
    return grey[65];
  }
  if (c === 1 || c === 7) {
    return green[70];
  }
  if (c === 6) {
    return yellow[60];
  }
  return grey[25];
};

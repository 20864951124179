import { Dispatch, FormEvent, SetStateAction, useContext, useEffect, useState } from 'react';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { CustomerContext } from '../data/CustomerContext';
import RestHttpClient from '../../../common/RestHttpClient';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Grid } from '@mui/material';
import { FormTextField } from '../../../component/form/FormTextField';
import { FormDropdown } from '../../../component/form/FormDropdown';
import { FormCheckbox } from '../../../component/form/FormCheckbox';
import { OptionItem } from '../../../generated/ApiClient';

interface Props {
  toggleBasicSearch: Dispatch<SetStateAction<boolean>>;
  customerTypeId?: number;
  includePotentialBuyer?: boolean;
  excludeCustomerIds?: number[];
}

const schema = z.object({
  addressCountryId: z.number().nonnegative().optional(),
  addressPostalCode: z.string().optional(),
  addressCity: z.string().optional(),
  addressStreet: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  isPotentialBuyer: z.boolean().optional(),
});

type CustomerAdvancedSearchFormValues = z.infer<typeof schema>;

export function CustomerSearchAdvanced({
  toggleBasicSearch,
  customerTypeId,
  includePotentialBuyer,
  excludeCustomerIds,
}: Props) {
  const { t } = useTranslation();
  const { customers, setCustomers, page, setPage, setLoading, orderSelectors } =
    useContext(CustomerContext);
  const [countries, setCountries] = useState<OptionItem[]>([]);
  const [searchValues, setSearchValues] = useState<CustomerAdvancedSearchFormValues>({
    isPotentialBuyer: includePotentialBuyer,
  } as CustomerAdvancedSearchFormValues);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CustomerAdvancedSearchFormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      isPotentialBuyer: includePotentialBuyer,
    },
  });

  const fetchCustomers = (sv = searchValues, p = page, os: string[] = orderSelectors) => {
    setLoading(true);

    //Backend unfortunately accepts country name only...
    var country;
    if (sv.addressCountryId)
      country = countries.filter((c) => c.value === sv.addressCountryId)[0].label;
    RestHttpClient.searchCustomers(
      sv.firstName,
      sv.lastName,
      country,
      sv.addressCity,
      sv.addressStreet,
      sv.addressPostalCode,
      customerTypeId,
      !sv.isPotentialBuyer,
      excludeCustomerIds,
      p,
      25,
      os
    )
      .then((res) => {
        const newCustomers = res.data;

        if (p === 0 && newCustomers.items.length) {
          setCustomers(newCustomers.items);
        } else if (newCustomers.items.length) {
          setCustomers(customers.concat(newCustomers.items));
        }
      })
      .catch((_) => {
        setCustomers([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (page > 0) {
      fetchCustomers();
    }
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    RestHttpClient.getCountries().then((res) => {
      setCountries(res.data);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPage(0);
    setCustomers([]);
    fetchCustomers(searchValues, 0, orderSelectors);
    // eslint-disable-next-line
  }, [orderSelectors, excludeCustomerIds]);

  const onSubmit = (event: FormEvent) => {
    handleSubmit(async (values) => {
      setCustomers([]);
      setPage(0);
      setSearchValues(values);
      fetchCustomers(values, 0);
    })(event);
  };

  const handleToggle = () => {
    setPage(0);
    toggleBasicSearch(true);
  };

  return (
    <form onSubmit={onSubmit} noValidate>
      <Box className="search-panel search-header">
        <Grid spacing={2} container>
          <Grid item container spacing={4} md={11}>
            <Grid item md={4}>
              <FormDropdown
                control={control}
                label={t('customers.search.addressCountry')}
                name="addressCountryId"
                options={countries}
              />
              <FormTextField
                control={control}
                label={t('customers.search.addressCity')}
                name="addressCity"
                withIcon
              />
            </Grid>

            <Grid item md={4}>
              <FormTextField
                control={control}
                label={t('customers.search.addressStreet')}
                name="addressStreet"
                withIcon
              />
              <FormTextField
                control={control}
                label={t('customers.search.addressPostalCode')}
                name="addressPostalCode"
                withIcon
              />
            </Grid>

            <Grid item md={4}>
              <FormTextField
                control={control}
                label={t('customers.search.lastName')}
                name="lastName"
                withIcon
              />
              <FormTextField
                control={control}
                label={t('customers.search.firstName')}
                name="firstName"
                withIcon
              />

              {includePotentialBuyer && (
                <FormCheckbox
                  control={control}
                  name="isPotentialBuyer"
                  label={t('customers.search.potentialBuyer')}
                ></FormCheckbox>
              )}
            </Grid>
          </Grid>
          <Grid item md={1}>
            <Button variant="contained" fullWidth type="submit" disabled={isSubmitting}>
              {t('search.start')}
            </Button>
            <Button variant="text" className="toggle-search" onClick={handleToggle} fullWidth>
              {t('search.basic')}
            </Button>
            <Button className="reset" variant="text" onClick={() => reset()} fullWidth>
              {t('reset')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

import { Grid, Typography } from '@mui/material';
import { IDisolveDetail, IReportEntry } from '../../../generated/ApiClient';
import { useTranslation } from 'react-i18next';
import { formatToEuro } from '../../deals/new/finances/FinancesCalculationList';
import { DateTimeFormatter, dateFormat } from '../../../common/formatter/DateTimeFormatter';

const surchargeRelative = 0.025;
const relevantCode = 'UCARSPKW';
const relevantGarant = 'Mercedes-Benz Österreich GmbH';

interface Props {
  dissolve?: IDisolveDetail;
  report?: IReportEntry;
}

export function OverlaySurcharge({ report, dissolve }: Props) {
  const { t } = useTranslation();

  const listPrice = report?.listPrice ?? 0;
  const surcharge = listPrice * surchargeRelative;
  const dissolveValue = dissolve?.dissolveValue ?? 0;
  const showSurcharge =
    report?.listPrice && report?.rkv?.includes(relevantCode) && report.rkv.includes(relevantGarant);
  const showHint = !!dissolve?.dissolveValue;

  if (!showSurcharge) return null;

  return (
    <Grid item container xs={12} mb={12} width="100%" rowSpacing={2}>
      <Grid item xs={3} className="value-column">
        <div>{t('dashboard.contractsOverlay.dissolveValues.surcharge')}</div>
      </Grid>
      <Grid item xs={3} className="value-column">
        <div>{formatToEuro(surcharge)}</div>
      </Grid>
      <Grid item xs={12}>
        <Typography>{t('dashboard.contractsOverlay.dissolveValues.surchargeHint')}</Typography>
      </Grid>
      {showHint && (
        <Grid item xs={12}>
          <Typography>
            {t('dashboard.contractsOverlay.dissolveValues.surchargeExample', {
              date: DateTimeFormatter.format(dissolve?.dueDate, { format: dateFormat }),
              surcharge: formatToEuro(surcharge),
              dissolveValue: formatToEuro(dissolveValue),
              sum: formatToEuro(dissolveValue + surcharge),
            })}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

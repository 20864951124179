import {
  CoOptIntermFile,
  CredibilityCheckValues,
  FinancesCalculationContextValues,
  FinancesDrawerFormValues,
  RecalculateParams,
  SalesAidValues,
} from '../../common/financeOverlay/RightDrawerSchema';
import { Dispatch, SetStateAction, useState } from 'react';
import RestHttpClient from '../../../../common/RestHttpClient';
import {
  AgentOption,
  ApiconServiceRequestBundle,
  GetCalculatedProductForExistingDealRequest,
  GetCalculationResponse,
  IVinAssignment,
  LimitViolationVoting,
  ObjectConfigurationRequest,
  ServiceOption,
  VoteOnSalesAidLimitViolationRequest,
} from '../../../../generated/ApiClient';
import {
  createCommonServiceRequest,
  mapCalculationFormToSaveDto,
  mapConvOptionValuesToView,
  mapSalesAidValuesToView,
} from '../../new/state/stateToDtoMapper';
import { FinancesRightDrawer } from '../../common/financeOverlay/FinancesRightDrawer';

interface Props {
  isDrawerOpened: boolean;
  setIsDrawerOpened: Dispatch<SetStateAction<boolean>>;
  isIncludingVat: boolean;
  dealId: number;
  calculationId?: number;
  setCalculationId?: Dispatch<SetStateAction<number | undefined>>;
  readonly: boolean;
  reload?: () => void;
  vinAssignments: IVinAssignment[];
  credibilityCheck: CredibilityCheckValues;
  hasSubmittedCalculation: boolean;
}

export const DealDetailFinanceDrawer = ({
  isDrawerOpened,
  setIsDrawerOpened,
  isIncludingVat,
  dealId,
  calculationId,
  setCalculationId,
  readonly,
  reload,
  vinAssignments,
  credibilityCheck,
  hasSubmittedCalculation,
}: Props) => {
  const [newSalesAids, setNewSalesAids] = useState<SalesAidValues[]>([]);

  const fetchDefault = async (
    calcId: number | undefined = calculationId
  ): Promise<GetCalculationResponse> => {
    if (calcId) {
      const { data } = await RestHttpClient.getCalculationById(dealId, calcId);

      return data;
    } else {
      const { data } = await RestHttpClient.getDefaultCalculationByDealId(dealId);
      return data;
    }
  };

  const fetchCalculate = async (
    recalcParams: RecalculateParams
  ): Promise<GetCalculationResponse> => {
    const { data } = await RestHttpClient.calculateExistingDeal(
      new GetCalculatedProductForExistingDealRequest({
        dealId,
        calculationId,
        financingTypeId: recalcParams.fundingType,
        duration: recalcParams.duration,
        mileageId: recalcParams.mileageId,
        businessRelationshipId: recalcParams.businessRelationshipId,
        interestRateCalculationMethodId: recalcParams.interestCalculationMethodId,
        rateCategoryId: recalcParams.tariffGroup,
        downpayment: recalcParams.downPayment,
        downPaymentPercent: recalcParams.downPaymentPercent,
        deposit: recalcParams.deposit,
        depositPercent: recalcParams.depositPercent,
        residualValueContractId: recalcParams.residualValueId,
        sourceOfFoundsId: recalcParams.sourceOfFoundsId,
        accountOwner: recalcParams.accountOwner,
        ksvRating: recalcParams.ksvRating,
        residualValueConversionOptions: recalcParams.residualValueConversionOptions,
        residualValueVehicleAbsolute: recalcParams.residualValueVehicleAbsolute,
        residualValuePercentage: recalcParams.residualValuePercentage,
        objectConfig: new ObjectConfigurationRequest({
          listPrice: recalcParams.objectConfiguration_ListPrice,
          extrasPrice: recalcParams.objectConfiguration_ExtrasPrice,
          listPriceIncludingExtras: recalcParams.objectConfiguration_ListPriceIncludingExtras,
          totalPrice: recalcParams.objectConfiguration_TotalPrice,
          coFinancedExtras: recalcParams.objectConfiguration_CoFinancedExtras,
          purchasePrice: recalcParams.objectConfiguration_PurchasePrice,
          calculationBase: recalcParams.objectConfiguration_CalculationBase,
          discountPercent: recalcParams.objectConfiguration_DiscountPercent,
          discountInEuro: recalcParams.objectConfiguration_DiscountInEuro,
          registrationDate: recalcParams.objectConfiguration_RegistrationDate,
          initialMileage: recalcParams.objectConfiguration_InitialMileage,
          commissionNumber: recalcParams.objectConfiguration_CommissionNumber,
        }),
        referencesCalculationId: recalcParams.referencesCalculationId,
        referencesDealId: recalcParams.referencesDealId,
        apiconServiceRequestBundle: new ApiconServiceRequestBundle(
          createCommonServiceRequest(
            recalcParams.service,
            dealId,
            calculationId,
            recalcParams.fundingType
          )
        ),
        serviceVariantId: recalcParams.serviceVariantId,
        cacheGuid: recalcParams.cacheGuid,
        agentOptions: recalcParams.agentOptions
          .filter((ao) => ao.price > 0 && ao.title)
          .map(
            (ao) =>
              new AgentOption({
                id: Number.isInteger(ao.id) ? ao.id : 0,
                title: ao.title,
                dealerId: ao.dealerId,
                price: ao.price,
              })
          ),
        conversionOptions: recalcParams.conversionOptions
          .filter((co) => co.price > 0 && co.title)
          .map(mapConvOptionValuesToView),
        purchaseContractDate: recalcParams.purchaseContractDate,
        specialOfferId: recalcParams.specialOfferId,
        salesAid: recalcParams.salesAid
          ? mapSalesAidValuesToView(recalcParams.salesAid)
          : undefined,
        selectedFuelServiceProductId: recalcParams.selectedFuelServiceProductId,
      })
    );
    return data;
  };

  const handleSubmit = async (
    values: FinancesDrawerFormValues | FinancesCalculationContextValues,
    isServiceVariantSaveRequest: boolean,
    technicalOptions: ServiceOption[],
    intermFiles?: CoOptIntermFile[],
    isReload?: boolean
  ) => {
    try {
      const saveDto = mapCalculationFormToSaveDto(
        dealId,
        values,
        [...(values as FinancesCalculationContextValues).salesAids],
        isServiceVariantSaveRequest,
        technicalOptions,
        calculationId
      );

      const {
        data: { calculationId: newCalcId },
      } = await RestHttpClient.saveCalculation(saveDto);
      if (intermFiles && intermFiles.length > 0 && newCalcId) {
        const {
          data: { conversionOptions },
        } = await RestHttpClient.getCalculationById(dealId, newCalcId);

        await Promise.all(
          intermFiles.map((interFile) => {
            return RestHttpClient.uploadSupplierInvoice(
              dealId,
              newCalcId,
              conversionOptions[interFile.index].id,
              {
                fileName: interFile.file.name,
                data: interFile.file,
              }
            );
          })
        );
      }

      setNewSalesAids([]);
      if (
        (values.removeReconsiderationReference || values.overtakenCustomerId || isReload) &&
        reload
      ) {
        reload();
      }

      return newCalcId;
    } catch (err) {}
  };

  const handleAddSalesAid = (salesAid: SalesAidValues) => {
    setNewSalesAids([...newSalesAids, salesAid]);
  };

  const handleEditSalesAid = (salesAid: SalesAidValues, index: number) => {
    const newAids = [...newSalesAids];
    newAids[index] = salesAid;
    setNewSalesAids(newAids);
  };

  const fetchSubmittedDeals = async (
    page?: number,
    id?: string,
    salesPersonName?: string,
    creationDate?: Date,
    customerName?: string,
    orderSelectors?: string[]
  ) => {
    const { data } = await RestHttpClient.getSubmittedDealsForExistingDeal(
      dealId,
      calculationId,
      id,
      salesPersonName,
      creationDate,
      customerName,
      page ?? 0,
      10,
      orderSelectors
    );
    return data;
  };

  const fetchReconsideredFundingProduct = async (
    referenceDealId: number,
    overtakeCustomer: boolean
  ) => {
    const { data } = await RestHttpClient.getReconsideredFundingProductForExistingDeal(
      dealId,
      calculationId,
      referenceDealId,
      overtakeCustomer
    );
    return data;
  };

  const defaultValues = {
    vinConfigs: vinAssignments,
    credibilityCheck: credibilityCheck,
  };

  const onVote = (vote: LimitViolationVoting) => {
    if (calculationId) {
      const req = new VoteOnSalesAidLimitViolationRequest({
        financingCalculationId: calculationId,
        voting: vote,
      });
      RestHttpClient.voteOnSalesAidLimitViolation(req).then((_) => {
        setIsDrawerOpened(false);
      });
    }
  };

  return (
    <FinancesRightDrawer
      defaultValues={defaultValues}
      dealId={dealId}
      calculationId={calculationId}
      setCalculationId={setCalculationId}
      dealSalesAids={newSalesAids}
      onAddSalesAid={handleAddSalesAid}
      onEditSalesAid={handleEditSalesAid}
      isDrawerOpened={isDrawerOpened}
      setIsDrawerOpened={setIsDrawerOpened}
      isIncludingVat={isIncludingVat}
      fetchCalculate={fetchCalculate}
      fetchDefault={fetchDefault}
      onSubmit={handleSubmit}
      readonly={readonly}
      fetchSubmittedDeals={fetchSubmittedDeals}
      fetchReconsideredFundingProduct={fetchReconsideredFundingProduct}
      onVote={onVote}
      hasSubmittedCalculation={hasSubmittedCalculation}
    />
  );
};

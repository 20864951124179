import { useState } from 'react';
import { CustomerHeader } from './CustomerHeader';
import { CustomerList } from './CustomerList';
import { CustomerProvider } from './data/CustomerContext';
import { CustomerSearch } from './search/CustomerSearch';
import { CustomerSearchAdvanced } from './search/CustomerSearchAdvanced';
import { SearchResult } from './search/SearchResult';
import { useNotifications } from '../../common/global/useNotifications';
import { NotificationLocation } from '../../generated/ApiClient';

interface Props {
  customerTypeId?: number;
}

export function Customers({ customerTypeId }: Props) {
  const [basicSearch, setBasicSearch] = useState(true);

  useNotifications(NotificationLocation.Customers);

  return (
    <CustomerProvider>
      <div className="customers">
        <CustomerHeader />
        {basicSearch ? (
          <CustomerSearch
            customerTypeId={customerTypeId}
            toggleBasicSearch={setBasicSearch}
            includePotentialBuyer={true}
          />
        ) : (
          <CustomerSearchAdvanced
            customerTypeId={customerTypeId}
            toggleBasicSearch={setBasicSearch}
            includePotentialBuyer={true}
          />
        )}
        <SearchResult />
        <CustomerList />
      </div>
    </CustomerProvider>
  );
}

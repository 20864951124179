import { alpha, Box, BoxProps, Button, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { Control, useWatch } from 'react-hook-form';
import { NumberFormatter } from '../../../../common/formatter/NumberFormatter';
import { FinancesDrawerFormValues } from './RightDrawerSchema';
import { LimitViolationVoting } from '../../../../generated/ApiClient';

interface FinancesCalculationFooterProps {
  control: Control<FinancesDrawerFormValues>;
  onClose: () => void;
  vatFree: boolean;
  loading: boolean;
  width: string | object;
  // isSubmitting: any;
  readonly?: boolean;
  disableSave: boolean;
  isVote: boolean;
  onVote?: (vote: LimitViolationVoting) => void;
}

type Props = FinancesCalculationFooterProps & BoxProps;
export const FinancesCalculationFooter = ({
  control,
  onClose,
  vatFree,
  loading,
  width,
  readonly,
  disableSave,
  isVote,
  onVote,
  ...others
}: Props) => {
  const { t } = useTranslation();

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  const hasServices = useWatch({ control, name: 'hasServices' });
  const regularInstalmentTotalGross = useWatch({ control, name: 'regularInstalmentTotalGross' });
  const regularInstalmentTotalNet = useWatch({ control, name: 'regularInstalmentTotalNet' });
  const regularInstalmentTotalVat = useWatch({ control, name: 'regularInstalmentTotalVat' });
  const rateIncludingVAT = useWatch({ control, name: 'rateIncludingVAT' });
  const rateExcludingVAT = useWatch({ control, name: 'rateExcludingVAT' });
  const VAT = useWatch({ control, name: 'VAT' });

  const renderField = (value: number | undefined, label: string, small?: boolean) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {value ? (
        <div className="footer-value">{NumberFormatter.format(value)} €</div>
      ) : (
        <div className="footer-value">-</div>
      )}
      <div className="footer-label" style={{ fontSize: small ? '14px' : undefined }}>
        {label}
      </div>
    </Box>
  );

  return (
    <Box
      {...others}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: `0px -2px 12px ${alpha('#000000', 0.2)}`,
        pr: 2,
        position: 'fixed',
        bottom: 0,
        background: 'white',
        width: width,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          gap: 1,
          flexShrink: 0,
          justifyContent: 'space-around',
          input: {
            fontFamily: 'MB Corpo A Title Cond WEB',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '24px',
            lineHeight: '32px',
          },
        }}
      >
        <Box>
          <Button
            onClick={handleClose}
            sx={{ height: '28px', width: '94px', border: 1, borderColor: 'black', color: 'black' }}
          >
            {t('deals.new.financesConfiguration.calculationDrawer.rate.abort')}
          </Button>
        </Box>

        {vatFree && (
          <Box>
            {renderField(
              rateIncludingVAT,
              t('deals.new.financesConfiguration.calculationDrawer.rate.rate')
            )}
          </Box>
        )}
        {!vatFree && (
          <>
            <Box>
              {renderField(
                rateIncludingVAT,
                t('deals.new.financesConfiguration.calculationDrawer.rate.rateIncludingVAT')
              )}
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box>
              {renderField(
                rateExcludingVAT,
                t('deals.new.financesConfiguration.calculationDrawer.rate.rateExcludingVAT')
              )}
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box>
              {renderField(
                VAT ?? undefined,
                t('deals.new.financesConfiguration.calculationDrawer.rate.VAT')
              )}
            </Box>
          </>
        )}
        <Box>
          {isVote && onVote ? (
            <Stack gap={1}>
              <Button
                variant="contained"
                color='success'
                disabled={loading}
                onClick={() => onVote(LimitViolationVoting.Accept)}
                sx={{ height: '28px', width: '94px' }}
              >
                {t('deals.new.financesConfiguration.calculationDrawer.rate.accept')}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                color='error'
                onClick={() => onVote(LimitViolationVoting.Reject)}
                sx={{ height: '28px', width: '94px' }}
              >
                {t('deals.new.financesConfiguration.calculationDrawer.rate.reject')}
              </Button>
            </Stack>
          ) : !readonly ? (
            <Button
              type="submit"
              form="finances-calculation"
              variant="contained"
              disabled={loading || disableSave}
              sx={{ height: '28px', width: '94px' }}
            >
              {t('deals.new.financesConfiguration.calculationDrawer.rate.save')}
            </Button>
          ) : null}
        </Box>
      </Box>

      {hasServices && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            gap: 1,
            flexShrink: 0,
            justifyContent: 'space-around',
          }}
        >
          <Box sx={{ width: '94px' }} />
          <Box>
            {renderField(
              regularInstalmentTotalGross,
              t(
                'deals.new.financesConfiguration.calculationDrawer.rate.regularInstalmentTotalGross'
              ),
              true
            )}
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box>
            {renderField(
              regularInstalmentTotalNet,
              t('deals.new.financesConfiguration.calculationDrawer.rate.regularInstalmentTotalNet'),
              true
            )}
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box>
            {renderField(
              regularInstalmentTotalVat,
              t('deals.new.financesConfiguration.calculationDrawer.rate.regularInstalmentTotalVat'),
              true
            )}
          </Box>
          <Box sx={{ width: '94px' }} />
        </Box>
      )}
    </Box>
  );
};

import { Box, Button } from '@mui/material';
import { Ref, useState } from 'react';
import {
  Control,
  FieldValues,
  Path,
  UseFormClearErrors,
  UseFormSetError,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { confirmHandler } from '../../../common/ConfirmModal';
import { EnhancedTable } from '../../../common/table/EnhancedTable';
import { HeadCell } from '../../../common/table/EnhancedTableHead';
import { pushNotificationHandler } from '../../../common/PushNotification';

interface KsvSearchProps<
  TFormValues extends FieldValues | { ksvId: string | null | undefined },
  TResult,
  TRow extends { id: string }
> {
  getFormValues: () => TFormValues;
  setError: UseFormSetError<TFormValues>;
  clearErrors: UseFormClearErrors<TFormValues>;
  headCells: readonly HeadCell<TRow>[];
  search: (values: TFormValues) => Promise<TResult[]>;
  requiredFields: (keyof TFormValues)[];
  resultSelected: (value: TResult, alternative?: boolean) => void;
  enableOverwrite?: boolean;
  map: (value: TResult) => TRow;
  control: Control<TFormValues>;
  onReset: () => void;
  ref?: Ref<HTMLButtonElement>;
}

export function KsvSearch<
  TFormValues extends FieldValues | { ksvId: string | null | undefined; ksvSearchDone: boolean },
  TResult extends { ksvId: string },
  TRow extends { id: string }
>(props: KsvSearchProps<TFormValues, TResult, TRow>) {
  const {
    getFormValues,
    setError,
    clearErrors,
    headCells,
    search,
    requiredFields,
    resultSelected,
    enableOverwrite,
    map,
    control,
    onReset,
    ref,
  } = props;

  const { t } = useTranslation();

  const [isSearching, setSearching] = useState<boolean>(false);
  const [searchedAtLeastOnce, setSearchedAtLeastOnce] = useState<boolean>();

  const [ksvResult, setKsvResult] = useState<TResult[]>([]);
  const [rows, setGridRows] = useState<TRow[]>([]);

  async function Submit(): Promise<void> {
    try {
      var values = getFormValues();
      if (!validate(values)) return;

      setSearching(true);
      var result = await search(values);
      setKsvResult(result);
      if (result.length === 0) {
        pushNotificationHandler.publish(t('customers.edit.ksvSearch.nothingFound'), 'warning');
      } else {
        pushNotificationHandler.publish(t('customers.edit.ksvSearch.found'), 'info');
      }
      setGridRows(result.map((val) => map(val)));
      setSearchedAtLeastOnce(true);
    } finally {
      setSearching(false);
    }
  }

  function validate(values: TFormValues): boolean {
    let isValid = true;
    for (const requiredField of requiredFields) {
      isValid = validateField(requiredField) && isValid;
    }
    return isValid;

    function validateField(field: keyof TFormValues): boolean {
      if (!values[field]) {
        console.log(field);
        const validationErrorMessage = t('customers.ksv.requiredForKsvSearch');
        setError(field as Path<TFormValues>, { type: 'required', message: validationErrorMessage });
        return false;
      }

      clearErrors(field as Path<TFormValues>);
      return true;
    }
  }

  function onResultSelected(_: React.MouseEvent<unknown>, id: string): void {
    const title = t('customers.ksv.result.acceptTitle');
    const message = t('customers.ksv.result.accept');
    const overwriteMessage = t('customers.ksv.result.overwriteMessage');
    const noOverwriteMessage = t('customers.ksv.result.noOverwriteMessage');

    if (enableOverwrite)
      confirmHandler.confirm(
        title,
        () => resultConfirmed(false),
        () => resultConfirmed(true),
        message,
        noOverwriteMessage,
        overwriteMessage
      );
    else confirmHandler.confirm(title, resultConfirmed, undefined, message);

    function resultConfirmed(overwrite?: boolean) {
      const result = ksvResult.find((val) => val.ksvId === id);
      if (!result) return;
      resultSelected(result, overwrite);
      setSearchedAtLeastOnce(false);
    }
  }

  const ksvId = useWatch({ control, name: 'ksvId' as Path<TFormValues> });
  return (
    <div>
      <h2>{t('customers.ksv.title')}</h2>
      <Box className="view-header">
        <Box className="button-container">
          {ksvId && (
            <Button color="secondary" variant="outlined" onClick={onReset} disabled={isSearching}>
              {t('customers.ksv.reset')}
            </Button>
          )}
          <Button
            ref={ref}
            variant="contained"
            style={{ display: 'none' }}
            color="primary"
            onClick={Submit}
            disabled={isSearching}
          >
            {t('customers.ksv.submitSearch')}
          </Button>
        </Box>
      </Box>
      {searchedAtLeastOnce && rows.length === 0 && (
        <h2>{t('customers.edit.ksvSearch.nothingFound')}</h2>
      )}
      {searchedAtLeastOnce && rows.length !== 0 && (
        <Box className="ksv-result" sx={{ mb: 2 }}>
          <EnhancedTable
            rows={rows}
            headCells={headCells}
            hideCheckbox={true}
            variant="default"
            onRowClick={onResultSelected}
          ></EnhancedTable>
        </Box>
      )}
    </div>
  );
}

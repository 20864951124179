import { Box, CircularProgress, Tooltip, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import RestHttpClient from '../../common/RestHttpClient';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import { useTranslation } from 'react-i18next';

interface ModelImageProps {
  modelId: number | undefined;
}

export function ModelImage({ modelId }: ModelImageProps) {
  const [modelImageSrc, setModelImageSrc] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (!modelId) {
      setLoadingError(true);
      return;
    }

    setLoadingError(false);
    freeImage();

    setLoading(true);
    RestHttpClient.getModelImage(modelId)
      .then((res) => {
          const url = URL.createObjectURL(res.data);
          setModelImageSrc(url);

          setLoadingError(res.fileName === 'noPicturePathFound.jpg');
        
      })
      .finally(() => {
        setLoading(false);
      });

    return () => freeImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);

  function freeImage() {
    if (modelImageSrc) URL.revokeObjectURL(modelImageSrc);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!loading && loadingError && (
        <Tooltip title={t('model.image.errorWhileLoading')}>
          <TimeToLeaveIcon htmlColor={theme.palette.error.main} fontSize={'large'} />
        </Tooltip>
      )}
      {!loading && !loadingError && (
        <Box component="img" alt="Model image" width="100%" src={modelImageSrc} />
      )}
      {loading && (
        <CircularProgress
          classes={{
            root: 'circular',
          }}
        />
      )}
    </Box>
  );
}

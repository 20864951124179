import { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useActor } from '@xstate/react';
import { StateMachineContext } from '../state/StateMachineContextProvider';
import { BACK, CONFIRM_APPLICANT } from '../state/orderStateMachine';
import { PrivateCustomerFormValues } from '../../../customers/data/PrivateCustomerFormValues';
import { CommercialCustomerFormValues } from '../../../customers/data/CommercialCustomerFormValues';
import { CustomerType } from '../../../../generated/ApiClient';
import { SplitPanel } from '../../../../component/SplitPanel';
import { CustomerSelection } from '../customer/CustomerSelection';
import { ApplicantConfiguration } from './ApplicantConfiguration';
import { confirmHandler } from '../../../../common/ConfirmModal';
import RestHttpClient from '../../../../common/RestHttpClient';

export interface Applicant {
  customerId?: string;
  customer: PrivateCustomerFormValues | CommercialCustomerFormValues;
  type?: CustomerType;
  applyChangesToMainCustomer: boolean;
}

export function ApplicantForm() {
  const { t } = useTranslation();
  const stateMachine = useContext(StateMachineContext);
  const [state, send] = useActor(stateMachine);

  const [customerId, setCustomerId] = useState<string>();
  const [customerType, setCustomerType] = useState<CustomerType>(
    state.context.finances?.businessType === 1 ? CustomerType.Private : CustomerType.Commercial
  );
  const [open, setOpen] = useState(!state.context.applicants);

  const handleCustomerSelect = async (id: string, keepType?: boolean) => {
    const numId = Number(id);
    if (keepType) {
      setCustomerId(id);
      return;
    }
    if (Number.isNaN(numId)) {
      // eslint-disable-next-line eqeqeq
      const customer = (state.context.applicants || []).find((a) => a.customerId == id);
      if (customer && customer.type) {
        setCustomerType(customer.type);
        setCustomerId(id);
        setOpen(false);
      }
    } else {
      const { data } = await RestHttpClient.getCustomerType(Number(id));
      setCustomerType(data);
      setCustomerId(id);
      setOpen(false);
    }
  };

  const onSubmit = () => {
    const sendCallback = () =>
      send({
        type: CONFIRM_APPLICANT,
      });
    if (!state.context.applicants || state.context?.applicants?.length === 0) {
      confirmHandler.confirm(t('deals.new.noApplicantConfirm'), sendCallback);
    } else {
      sendCallback();
    }
  };

  const handleAddMore = () => {
    setOpen(true);
    setCustomerId(undefined);
  };

  const handleTriggerCreate = () => {
    setCustomerId('');
    setOpen(false);
  };

  const dealCustomerId = state.context.customer?.customerId;

  return (
    <>
      <div className="stepper-footer">
        <Button className="white" onClick={() => send(BACK)}>
          {t('back')}
        </Button>
        <h2 className="current-step">{t('deals.new.stepApplicantSubtitle')}</h2>
        <Button variant="contained" onClick={onSubmit} form="applicant-form">
          {t('continue')}
        </Button>
      </div>
      <Divider />
      <div className="content">
        <div className="form">
          <SplitPanel
            open={open}
            setOpen={setOpen}
            left={
              <CustomerSelection
                onTriggerCreate={handleTriggerCreate}
                onRowClick={handleCustomerSelect}
                excludeCustomerIds={
                  dealCustomerId
                    ? [
                        Number(dealCustomerId),
                        ...(state.context.applicants ?? [])
                          .filter(
                            (value) => value.customerId && !Number.isNaN(Number(value.customerId))
                          )
                          .map((value) => Number(value.customerId)),
                      ]
                    : undefined
                }
                includePotentialBuyer
              />
            }
            right={
              <ApplicantConfiguration
                onSelectCustomer={handleCustomerSelect}
                onAddMore={handleAddMore}
                customerId={customerId}
                customerType={customerType}
                setCustomerType={setCustomerType}
              />
            }
          />
        </div>
      </div>
    </>
  );
}

import { Box } from '@mui/material';
import { Control } from 'react-hook-form';
import { ModelConfigurationGeneral } from './ModelConfigurationGeneral';
import { ModelConfigurationPriceDetails } from './ModelConfigurationPriceDetails';
import { ModelConfigurationVehicleDetails } from './ModelConfigurationVehicleDetails';
import { ModelFormValues } from './ModelForm';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form/dist/types/form';

interface ModelConfigurationBodyProps {
  control: Control<ModelFormValues>;
  pricesIncludingVAT: boolean;
  setValue: UseFormSetValue<ModelFormValues>;
  getValues: UseFormGetValues<ModelFormValues>;
  isDistributionChannelEditable: boolean;
}

export function ModelConfigurationBody({
  control,
  pricesIncludingVAT,
  setValue,
  getValues,
  isDistributionChannelEditable,
}: ModelConfigurationBodyProps) {
  return (
    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
      <ModelConfigurationGeneral
        control={control}
        isDistributionChannelEditable={isDistributionChannelEditable}
      />
      <ModelConfigurationVehicleDetails
        control={control}
        setValue={setValue}
        getValues={getValues}
      />
      <ModelConfigurationPriceDetails
        control={control}
        pricesIncludingVAT={pricesIncludingVAT}
        setValue={setValue}
        getValues={getValues}
      />
    </Box>
  );
}

import { Dispatch, SetStateAction, useState } from 'react';
import { DealAdvancedSearchFormValues, DealSearchAdvanced } from './DealSearchAdvanced';
import RestHttpClient from '../../../common/RestHttpClient';
import { DealOverviewIPagedList } from '../../../generated/ApiClient';
import { useInfiniteQuery } from 'react-query';
import { DealList } from '../DealList';

interface Props {
  toggleBasicSearch: Dispatch<SetStateAction<boolean>>;
}

export function DealAdvancedSearchList({ toggleBasicSearch }: Props) {
  const [searchValues, setSearchValues] = useState<DealAdvancedSearchFormValues>(
    {} as DealAdvancedSearchFormValues
  );
  const [orderSelectors, setOrderSelectors] = useState<string[]>(['id|desc']);

  const { data, fetchNextPage, isFetching } = useInfiniteQuery({
    queryKey: ['deals', orderSelectors, {searchValues}],
    queryFn: ({ pageParam }) => fetchDeals(searchValues, pageParam, orderSelectors),
    getNextPageParam: (lastPage: DealOverviewIPagedList) => {
      return lastPage.index + 1;
    },
    refetchOnWindowFocus: false,
  });

  const fetchDeals = async (
    sv: DealAdvancedSearchFormValues,
    p = 0,
    os: string[] = orderSelectors
  ) => {
    const res = await RestHttpClient.dealAdvancedSearch(
      sv.id,
      sv.salesPerson,
      sv.trademark,
      sv.model,
      sv.dealFrom ?? undefined,
      sv.created ?? undefined,
      sv.customer,
      sv.hasFinancing,
      sv.hasInsurance,
      sv.hasSubmitted,
      p,
      10,
      os
    );

    return res.data;
  };

  return (
    <>
      <DealSearchAdvanced toggleBasicSearch={toggleBasicSearch} setSearchValues={setSearchValues} />
      <DealList
        deals={data?.pages.flatMap((x) => x.items) ?? []}
        loadNewPage={fetchNextPage}
        loading={isFetching}
        setOrderSelectors={setOrderSelectors}
        total={data?.pages[0].total ?? 0}
      />
    </>
  );
}

import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { EnhancedDialog } from '../../../../component/EnhancedDialog';
import { useTranslation } from 'react-i18next';
import { UploadInput } from '../../../../component/UploadInput';
import RestHttpClient from '../../../../common/RestHttpClient';
import { pushNotificationHandler } from '../../../../common/PushNotification';
import { StateMachineContext } from '../state/StateMachineContextProvider';
import { useActor } from '@xstate/react';
import { IMPORT_CONFIGURATION } from '../state/orderStateMachine';
import { DialogContent, DialogTitle } from '@mui/material';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onImportSuccess: (modelId: number) => void;
}

const ModelImportDialog = ({ open, setOpen, onImportSuccess }: Props) => {
  const stateMachine = useContext(StateMachineContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, send] = useActor(stateMachine);

  const { t } = useTranslation();
  const [file, setFile] = useState<File>();

  const handleFileChange = async (file?: File) => {
    if (file) {
      setFile(file);
      const { data } = await RestHttpClient.importModelConfiguration({
        fileName: file.name,
        data: file,
      });

      if (data.validationErrors) {
        data.validationErrors.forEach((valError) => {
          pushNotificationHandler.publish(valError, 'error');
        });
      }

      if (data.success) {
        send({
          type: IMPORT_CONFIGURATION,
          info: {
            customer: data.result.customer,
            deal: data.result.deal,
            vinAssignment: data.result.vinAssignment,
          },
        });
        setOpen(false);
        onImportSuccess(data.result.deal.modelId);
      }
    }
  };

  useEffect(() => {
    if (open) {
      setFile(undefined);
    }
  }, [open]);

  return (
    <EnhancedDialog isDialogOpened={open} setIsDialogOpened={setOpen}>
      <DialogTitle>{t('deals.new.import')}</DialogTitle>
      <DialogContent>
        <UploadInput
          label={t('deals.new.importUpload')}
          onFileChange={handleFileChange}
          file={file}
          accept=".xml"
        />
      </DialogContent>
    </EnhancedDialog>
  );
};

export default ModelImportDialog;

import { User, WebStorageStateStore } from 'oidc-client-ts';
import { history } from '../../views/App';

export const OidcConfig = (window: Window) => {
  if (process.env.NODE_ENV === 'development')
    return {
      authority: 'http://localhost:15030/',
      client_id: 'dekato.app',
      redirect_uri: 'http://localhost:15035/',
      automaticSilentRenew: true,
      scope: 'dekato.api',
      userStore: new WebStorageStateStore(),
    };

  return {
    //e.g. https://future-dev.dekato.at/identity/
    authority: joinPath(window.location.origin, 'identity/'),

    client_id: 'dekato.app',

    //e.g. https://future-dev.dekato.at/
    redirect_uri: ensureTrailingSlash(window.location.origin),

    automaticSilentRenew: true,
    scope: 'dekato.api openid profile',
    userStore: new WebStorageStateStore(),
  };
};

function joinPath(left: string, right: string): string {
  left = ensureTrailingSlash(left);
  return left + right;
}

function ensureTrailingSlash(str: string): string {
  if (str[str.length - 1] === '/') return str;

  return str + '/';
}

export const onSigninCallback = (user: User | void): void => {
  // history.push(window.location.pathname);
  window.history.replaceState({}, document.title, window.location.pathname);
  console.log('#onSigninCallback window.location.pathname', window.location.pathname);
  if (user !== undefined && user.state !== undefined) {
    const state = user.state as SignInState;
    console.log('#onSigninCallback state', state);
    console.log('#onSigninCallback history', history);
    setTimeout(() => {
      window.location.pathname = state.redirectUri ?? '/';
    }, 500);
  }
};

export const getUser = () => {
  const storageKey = getStorageKey();
  const oidcStorage = localStorage.getItem(storageKey);
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};

function getStorageKey(): string {
  //Pattern: oidc.user:<your authority>:<your client id>`
  const config = OidcConfig(window);
  return `oidc.user:${config.authority}:${config.client_id}`;
}

export class SignInState {
  redirectUri: string;

  constructor(redirectUri: string) {
    this.redirectUri = redirectUri;
  }
}

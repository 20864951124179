import * as React from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { HeadCell } from './EnhancedTableHead';
import Box from '@mui/material/Box';

export interface EndlessTableBodyProps<T extends { id: string, className?: string }> {
  rows: T[];
  total?: number;
  headCells: readonly HeadCell<T>[];
  hideCheckbox: boolean;
  loadNewPage: () => void;
  handleClick: (ev: React.MouseEvent<unknown>, name: string) => void;
  isSelectable?: (row: T) => boolean;
  isSelected: (id: string) => boolean;
  rowsUntilLoad: number;
}

export function EndlessTableBody<T extends { id: string, className?: string }>({
  headCells,
  rows,
  total,
  loadNewPage,
  handleClick,
  hideCheckbox,
  isSelected,
  isSelectable,
  rowsUntilLoad,
}: EndlessTableBodyProps<T>) {
  const loadTriggerRow = React.useRef(null);
  const loadAtIndex = rows.length - rowsUntilLoad;

  const callback: IntersectionObserverCallback = (entries, refObserver) => {
    let reload = false;

    entries.forEach((element) => {
      if (element.isIntersecting) {
        reload = true;

        refObserver.unobserve(element.target);
      }
    });

    // eslint-disable-next-line eqeqeq
    if (reload && loadNewPage && (total == undefined || rows.length < total)) {
      loadNewPage();
    }
  };

  const observer = new IntersectionObserver(callback);

  React.useEffect(() => {
    if (loadTriggerRow.current) {
      observer.observe(loadTriggerRow.current);
    }
    // eslint-disable-next-line
  }, [rows.length]);

  return (
    <TableBody>
      {rows.map((row: T, index: number) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            onClick={
              !isSelectable || isSelectable(row) ? (event) => handleClick(event, row.id) : () => {}
            }
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            ref={loadAtIndex === index ? loadTriggerRow : undefined}
            key={row.id + index}
            selected={isItemSelected}
            className={row.className}
          >
            {hideCheckbox ? (
              <Box />
            ) : (
              <TableCell padding="checkbox">
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  color="primary"
                  checked={isItemSelected}
                  disabled={isSelectable && !isSelectable(row)}
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </TableCell>
            )}
            {headCells.map((x: HeadCell<T>) => (
              <TableCell align={x.align ?? 'left'} key={x.id as string}>
                {row[x.id]}
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </TableBody>
  );
}

import { DefaultDashboardStatusIndicator } from '../common/DashboardStatusIndicator';
import { useTranslation } from 'react-i18next';
import { HeadCell } from '../../../common/table/EnhancedTableHead';
import {
  Box,
  FormControlLabel,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Switch,
} from '@mui/material';
import { EnhancedTable } from '../../../common/table/EnhancedTable';
import React, { useEffect, useState } from 'react';
import RestHttpClient from '../../../common/RestHttpClient';
import { ApplicationModel } from '../../../generated/ApiClient';
import { DateTimeFormatter, dateFormat } from '../../../common/formatter/DateTimeFormatter';
import { usePermissions } from '../../../common/auth/PermissionsContext';
import { DashboardApplicationOverlay } from '../applications/DashboardApplicationOverlay';
import { PreliminaryDashboardStatusIndicator } from './DashboardContractPreliminaryIndicator';
import { dashboardContentGrid, dashboardSideGrid } from '../Dashboard';

interface DashboardRow {
  id: string;
  contractNumber: string;
  salesPerson: string;
  customer: string;
  modelName: string;
  type: string;
  creationDate: string;
  expiryDate?: string;
  status: JSX.Element;
  endDate?: string;
  activationDate?: string;
}

const mapApplicationModelToRow = (am: ApplicationModel, showHandover: boolean): DashboardRow => ({
  id: am.contractId.toString(),
  contractNumber: am.contractId?.toString() ?? '',
  customer: am.customerName ?? '',
  modelName: am.objectName ?? '',
  type: am.financingTypeDisplayName ?? '',
  expiryDate: DateTimeFormatter.format(am.expirationDate, { format: dateFormat }),
  creationDate: DateTimeFormatter.format(showHandover ? am.handOverDate : am.creationDate, { format: dateFormat }),
  salesPerson: am.salesPerson ?? '',
  status: <PreliminaryDashboardStatusIndicator date={am.handOverDate} showHandover={showHandover} state={am.state?.value ?? -1} />,
  activationDate: DateTimeFormatter.format(am.activationDate, {format: dateFormat}),
  endDate: DateTimeFormatter.format(am.endDate, {format: dateFormat})
});

interface Props {
  defaultFilter?: number;
}

export function DashboardContractPreliminary({ defaultFilter }: Props) {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  const [openDoc, setOpenDoc] = useState<string | undefined>();

  const [selState, setSelState] = useState<number | undefined>(defaultFilter);
  const [showAll, setShowAll] = useState(false);
  const [rows, setRows] = useState<DashboardRow[]>([]);
  const [loading, setLoading] = useState(false);


  const showHandover = selState === 5;

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const { data } = await RestHttpClient.getPriliminaryContracts(showAll, selState);
      setRows(data.map((am) => mapApplicationModelToRow(am, showHandover)));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setRows([]);
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selState, showAll]);

  const headCells: readonly HeadCell<DashboardRow>[] = [
    {
      id: 'status',
      disablePadding: true,
      label: '',
      width: '45px;',
    },
    {
      id: 'contractNumber',
      disablePadding: false,
      label: t('dashboard.applications.contractNumber'),
      allowSorting: true,
      columnName: 'contractNumber',
      allowFiltering: true,
      width: '220px',
    },
    {
      id: 'salesPerson',
      disablePadding: false,
      label: t('dashboard.applications.salesPerson'),
      allowSorting: true,
      columnName: 'salesPerson',
      allowFiltering: true,
      width: '280px',
    },
    {
      id: 'customer',
      disablePadding: false,
      label: t('dashboard.applications.customer'),
      allowSorting: true,
      columnName: 'customer',
      allowFiltering: true,
      width: '280px',
    },
    {
      id: 'modelName',
      disablePadding: false,
      label: t('dashboard.applications.modelName'),
      allowSorting: true,
      columnName: 'modelName',
      allowFiltering: true,
    },
    {
      id: 'type',
      disablePadding: false,
      label: t('dashboard.applications.type'),
      allowSorting: true,
      columnName: 'type',
      allowFiltering: true,
      width: '280px',
    },
    {
      id: 'creationDate',
      disablePadding: false,
      label: showHandover ? t('dashboard.applications.handOverDate') : t('dashboard.applications.creationDate'),
      allowSorting: true,
      columnName: 'creationDate',
      allowFiltering: true,
      width: '180px',
      sortTransform: (value) => DateTimeFormatter.parse(value, { format: dateFormat }),
    },
    {
      id: 'expiryDate',
      disablePadding: false,
      label: t('dashboard.applications.expiryDate'),
      allowSorting: true,
      columnName: 'expiryDate',
      allowFiltering: true,
      width: '180px',
      sortTransform: (value) => DateTimeFormatter.parse(value, { format: dateFormat }),
    },
    {
      id: 'activationDate',
      disablePadding: false,
      label: t('dashboard.applications.activationDate'),
      allowSorting: true,
      columnName: 'activationDate',
      allowFiltering: true,
      width: '180px',
      sortTransform: (value) => DateTimeFormatter.parse(value, { format: dateFormat }),
    },
    {
      id: 'endDate',
      disablePadding: false,
      label: t('dashboard.applications.endDate'),
      allowSorting: true,
      columnName: 'endDate',
      allowFiltering: true,
      width: '180px',
      sortTransform: (value) => DateTimeFormatter.parse(value, { format: dateFormat }),
    },
  ];

  const getButtonProps = (state?: number) => ({
    selected: selState === state,
    onClick: () => setSelState(state),
    disabled: loading,
  });

  const handleRowClick = (e: React.MouseEvent<unknown>, id: string) => {
    e.stopPropagation();
    setOpenDoc(id);
  };

  return (
    <Grid container className="dashboard-table">
      <DashboardApplicationOverlay
        isDrawerOpen={Boolean(openDoc)}
        onClose={() => setOpenDoc(undefined)}
        cmsId={openDoc}
      />
      <Grid item {...dashboardSideGrid} height="100%">
        <Box className="dashboard-table-sidebar">
          <List>
            <ListItemButton {...getButtonProps(undefined)}>
              <ListItemText primary={t('dashboard.all')} />
            </ListItemButton>
            {[5, 7].map((i) => (
              <ListItemButton {...getButtonProps(i)}>
                <ListItemText
                  primary={
                    // @ts-ignore
                    t(`dashboard.applications.states.${i}`, '')
                  }
                  sx={{ mr: 2 }}
                />
                <DefaultDashboardStatusIndicator state={i} />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Grid>
      <Grid item {...dashboardContentGrid}>
        {!hasPermission('Dashboard.AllUsersContracts', 'View') &&
          hasPermission('Dashboard.EmployeeContracts', 'View') && (
            <Box sx={{ ml: 1 }}>
              <FormControlLabel
                onChange={() => setShowAll(!showAll)}
                control={<Switch checked={showAll} />}
                label={t('dashboard.applications.showAll')}
              />
            </Box>
          )}
        <EnhancedTable
          rows={rows}
          total={rows.length}
          loading={loading}
          headCells={headCells}
          hideCheckbox={true}
          variant="default"
          onRowClick={handleRowClick}
          defaultOrderBy="contractNumber"
          defaultOrder="desc"
          enableXlsxExport={true}
        />
      </Grid>
    </Grid>
  );
}

import { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useActor } from '@xstate/react';
import { StateMachineContext } from '../state/StateMachineContextProvider';
import { BACK, CONFIRM_CUSTOMER, RESET_CUSTOMER, SKIP_APPLICANT } from '../state/orderStateMachine';
import { SplitPanel } from '../../../../component/SplitPanel';
import { CustomerSelection } from './CustomerSelection';
import { CustomerConfiguration } from './CustomerConfiguration';
import { CustomerType } from '../../../../generated/ApiClient';
import { PrivateCustomerFormValues } from '../../../customers/data/PrivateCustomerFormValues';
import { CommercialCustomerFormValues } from '../../../customers/data/CommercialCustomerFormValues';
import { confirmHandler } from '../../../../common/ConfirmModal';

export interface CustomerContextValues {
  customerId?: string;
  customer?: PrivateCustomerFormValues | CommercialCustomerFormValues;
  type?: CustomerType;
  applyChangesToMainCustomer: boolean;
}

export function CustomerForm() {
  const { t } = useTranslation();
  const stateMachine = useContext(StateMachineContext);
  const [state, send] = useActor(stateMachine);
  const [open, setOpen] = useState(!state.context.customer);
  const [customerId, setCustomerId] = useState<string>(state.context.customer?.customerId ?? '');
  const [submitable, setSubmitable] = useState<boolean>(
    Boolean(state.context.customer?.customerId || state.context.customer?.customer)
  );

  const submitDealCustomer = (
    values: PrivateCustomerFormValues | CommercialCustomerFormValues,
    type: CustomerType,
    applyMainCustomer: boolean,
    submitType?: string
  ) => {
    send({
      type: submitType === 'skip' ? SKIP_APPLICANT : CONFIRM_CUSTOMER,
      info: {
        customerId: customerId,
        customer: values,
        type: type,
        applyChangesToMainCustomer: applyMainCustomer,
      },
    });
  };

  const resetCustomer = () => {
    send({ type: RESET_CUSTOMER });
  };

  const onSubmit = (
    values: PrivateCustomerFormValues | CommercialCustomerFormValues,
    type: CustomerType,
    changesMade: boolean,
    submitType?: string
  ) => {
    if (changesMade && customerId !== '') {
      confirmHandler.confirm(
        t('customers.confirmSaveMasterRecordTitle'),
        () => submitDealCustomer(values, type, true, submitType),
        () => submitDealCustomer(values, type, false, submitType),
        t('customers.confirmSaveMasterRecord')
      );
    } else {
      send({
        type: submitType === 'skip' ? SKIP_APPLICANT : CONFIRM_CUSTOMER,
        info: {
          customerId: customerId,
          customer: values,
          type: type,
          applyChangesToMainCustomer: false,
        },
      });
    }
  };

  const handleCustomerSelect = (id: string) => {
    resetCustomer();
    setCustomerId(id);
    setOpen(false);
    setSubmitable(true);
  };

  const handleTriggerCreate = () => {
    resetCustomer();
    setCustomerId('');
    setOpen(false);
    setSubmitable(true);
  };

  const customerType =
    state.context.finances?.businessType === 1 ? CustomerType.Private : CustomerType.Commercial;

  const customer = state.context.customer?.customer;

  return (
    <>
      <div className="stepper-footer">
        <Button className="white" onClick={() => send(BACK)}>
          {t('back')}
        </Button>
        <h2 className="current-step">{t('deals.new.stepCustomerSubtitle')}</h2>
        <Box display="flex" flexDirection="column">
          <Button
            sx={{ mb: 1 }}
            variant="contained"
            type="submit"
            form="edit-customer-form"
            name="submit"
            disabled={!submitable}
          >
            {t('deals.new.continueToApplicants')}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            type="submit"
            form="edit-customer-form"
            name="skip"
            disabled={!submitable}
          >
            {t('deals.new.createDeal')}
          </Button>
        </Box>
      </div>
      <Divider />
      <div className="content">
        <div className="form">
          <SplitPanel
            open={open}
            setOpen={setOpen}
            left={
              <CustomerSelection
                onTriggerCreate={handleTriggerCreate}
                customerTypeId={state.context.finances?.businessType === 1 ? 1 : 2}
                onRowClick={handleCustomerSelect}
                excludeCustomerIds={(state.context.applicants || [])
                  .filter((v) => v.customerId)
                  .map((v) => Number(v.customerId))}
                includePotentialBuyer={true}
              />
            }
            right={
              <CustomerConfiguration
                customerId={customerId}
                customerType={customerType}
                customer={customer}
                onSubmit={onSubmit}
              />
            }
          />
        </div>
      </div>
    </>
  );
}

import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { EnhancedTableHead, HeadCell } from './EnhancedTableHead';
import { getComparator, Order } from './TableComparator';
import { EndlessTableBody } from './EndlessTableBody';
import { PaginatedTableBody } from './PaginatedTableBody';
import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import { Icons } from '../../component/icons/Icons';
import { stableSort } from './TableSorter';
import { EnhancedTableToolbarButton } from './EnhancedTableToolbar';

export interface EnhancedTableProps<T> {
  rows: T[];
  total?: number;
  headCells: readonly HeadCell<T>[];
  hideCheckbox: boolean;
  toolbarButtons?: EnhancedTableToolbarButton[];
  variant?: 'pagination' | 'endless' | 'default';
  enableSelection?: boolean;
  enableSingleSelection?: boolean;
  defaultSelected?: string[];
  isSelectable?: (row: T) => boolean;
  onRowClick?: (ev: React.MouseEvent<unknown>, name: string) => void;
  onSelection?: (ev: React.SyntheticEvent<unknown>, names: readonly string[]) => void;
  loadNewPage?: () => void;
  onSortRequest?: (orderSelectors: string[]) => void;
  rowsUntilLoad?: number;
  minWidth?: number;
  loading?: boolean;
  defaultOrder?: Order;
  defaultOrderBy?: string;
  enableXlsxExport?: boolean;
}

export function EnhancedTable<T extends { id: string; [key: string]: any }>({
  rows,
  total,
  headCells,
  hideCheckbox,
  enableSelection,
  enableSingleSelection,
  defaultSelected,
  variant,
  onRowClick,
  onSelection,
  isSelectable,
  loadNewPage,
  onSortRequest,
  rowsUntilLoad,
  minWidth,
  loading,
  defaultOrder,
  defaultOrderBy,
  enableXlsxExport,
}: EnhancedTableProps<T>) {
  const [order, setOrder] = React.useState<Order>(defaultOrder ?? 'asc');
  const [orderBy, setOrderBy] = React.useState<string>(defaultOrderBy ?? 'id');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { t } = useTranslation();
  const [filters, setFilters] = React.useState<
    {
      id: keyof T;
      filterValue: string;
    }[]
  >(
    headCells.map((cellDefinition) => ({
      id: cellDefinition.id,
      filterValue: '',
    }))
  );
  const changeFilterValue = (id: keyof T, value: string) => {
    let newFilter = filters.map((filterElement) => {
      if (filterElement.id === id) {
        return {
          id: filterElement.id,
          filterValue: value,
        };
      }

      return filterElement;
    });

    setFilters(newFilter);
  };

  const withPagination = variant === 'pagination';
  const withEndless = loadNewPage && variant === 'endless';

  useEffect(() => {
    if (defaultSelected && defaultSelected.length > 0) {
      setSelected([...defaultSelected] || []);
    }
    // eslint-disable-next-line
  }, [defaultSelected]);

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: string) => {
    if (loading) return;
    const isAsc = orderBy === property && order === 'asc';
    const nextOrder = isAsc ? 'desc' : 'asc';
    setOrder(nextOrder);
    setOrderBy(property);
    if (onSortRequest) onSortRequest([property + '|' + nextOrder]);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (enableSelection) {
      if (event.target.checked) {
        const newSelecteds = rows.map((row) => row.id);
        setSelected(newSelecteds);
        if (onSelection) {
          onSelection(event, newSelecteds);
        }
        return;
      }
      setSelected([]);
    }
  };

  const handleClick = (ev: React.MouseEvent<unknown>, name: string) => {
    if (onRowClick) {
      onRowClick(ev, name);
    }

    if (enableSelection) {
      const selectedIndex = selected.indexOf(name);
      let newSelected: readonly string[] = [];
      if (enableSingleSelection) {
        newSelected = newSelected.concat(name);
      } else if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
      if (onSelection) {
        onSelection(ev, newSelected);
      }
    }
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const headCell = React.useMemo(() => headCells.find((cell) => cell.id === orderBy), [orderBy, headCells]);

  const sortedAndFilteredRows = stableSort<T>(
    rows.filter((row) => {
      let included = true;

      for (const key of Object.keys(row)) {
        if (typeof row[key] === 'string') {
          let filterValue = filters.find((filter) => filter.id === key)?.filterValue;

          included &&= (row[key] || '').toUpperCase().includes((filterValue || '').toUpperCase());
        }
      }

      return included;
    }),
    getComparator<keyof T | string>(order, orderBy, headCell?.sortTransform)
  );

  const exportXlsx = React.useCallback(() => {
    // Map only the properties that have a columnName defined
    let headersToExport = headCells.filter((head) => {
      // The best way to find out whether to render a column or not is to look at the actual row data...
      if (sortedAndFilteredRows.length > 0) {
        return (
          typeof sortedAndFilteredRows[0][head.id] === 'string' ||
          typeof sortedAndFilteredRows[0][head.id] === 'number'
        );
      }

      // As a fallback, use the columnName
      return head.columnName;
    });
    let dataToExport = sortedAndFilteredRows.map((row) => {
      const propertiesToExport = headersToExport.map((header) => header.id);

      return propertiesToExport.reduce((all, key) => {
        return {
          ...all,
          [key]: row[key],
        };
      }, {} as T);
    });

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    XLSX.utils.sheet_add_aoa(worksheet, [headersToExport.map((head) => head.label)], {
      origin: 'A1',
    });

    // Calculate and define the maxWidth for each column
    worksheet['!cols'] = headersToExport.map((header) => {
      const maxWidth = dataToExport.reduce((maxWidth, row) => {
        return Math.max(maxWidth, header.label.length, row[header.id].toString().length);
      }, 10);

      return {
        wch: maxWidth,
      };
    });

    XLSX.writeFile(workbook, 'Export.xlsx');
  }, [headCells, sortedAndFilteredRows]);

  return (
    <Box className="enhanced-table">
      {enableXlsxExport && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            color="secondary"
            variant="outlined"
            sx={{ mt: 1 }}
            startIcon={Icons.download()}
            onClick={exportXlsx}
            disabled={loading}
          >
            {`${t('exportList')}`}
          </Button>
        </Box>
      )}
      <TableContainer>
        <Table sx={{ minWidth: minWidth ?? 750 }} aria-labelledby="tableTitle" size={'medium'}>
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            hideCheckbox={hideCheckbox || (enableSingleSelection ?? false)}
          />
          {withEndless ? (
            <EndlessTableBody
              rows={rows}
              total={total}
              headCells={headCells}
              hideCheckbox={hideCheckbox}
              loadNewPage={loadNewPage}
              handleClick={handleClick}
              isSelectable={isSelectable}
              isSelected={isSelected}
              rowsUntilLoad={rowsUntilLoad ?? 5}
            />
          ) : (
            <PaginatedTableBody
              rows={sortedAndFilteredRows}
              headCells={headCells}
              hideCheckbox={hideCheckbox}
              handleClick={handleClick}
              isSelected={isSelected}
              isSelectable={isSelectable}
              withPagination={withPagination}
              rowsPerPage={rowsPerPage}
              emptyRows={emptyRows}
              page={page}
              filters={filters}
              onFilterChanged={changeFilterValue}
            />
          )}
        </Table>
      </TableContainer>
      {withPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {loading && (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', my: 2 }}>
          <CircularProgress
            classes={{
              root: 'circular',
            }}
          />
        </Box>
      )}
    </Box>
  );
}

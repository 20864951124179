import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { isMobile } from 'react-device-detect';
import { t } from 'i18next';
import { ISignFinancingCalculationDocumentsResponse } from '../../../../generated/ApiClient';
import QRCode from 'qrcode.react';
import { Dispatch, SetStateAction } from 'react';
import { EnhancedDialog } from '../../../../component/EnhancedDialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  signDocuments: ISignFinancingCalculationDocumentsResponse[];
  onClose?: (isCancel: boolean) => void;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const SignDocumentsDialog = ({ signDocuments, onClose, isOpen, setOpen }: Props) => {
  const handleClose = (isCancel: boolean) => {
    if (onClose != null) onClose(isCancel);
  };

  return (
    <EnhancedDialog isDialogOpened={isOpen} setIsDialogOpened={setOpen}>
      <DialogTitle>{t('deals.edit.signDocuments.signDocumentsTitle')}</DialogTitle>

      <DialogContent dividers>
        <span>{t('deals.edit.signDocuments.remark')}</span>
        <Box sx={{ mt: 2 }}>
          {signDocuments.map((signDocument, index) => (
            <Accordion key={`${index}-${signDocument.documentName}`}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{signDocument.documentName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  {isMobile ? (
                    <a href={signDocument.signDocumentUrl} target="_blank" rel="noreferrer">
                      <QRCode size={300} value={signDocument.signDocumentUrl} />
                    </a>
                  ) : (
                    <QRCode size={300} value={signDocument.signDocumentUrl} />
                  )}
                </Box>
                {signDocument.documentPin && (
                  <Box>
                    {t('deals.edit.signDocuments.pin')}: {signDocument.documentPin}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Box sx={{ textAlign: 'right' }}>
          <Button
            onClick={() => handleClose(true)}
            sx={{ border: 1, borderColor: 'black', color: 'black', marginRight: '4px' }}
          >
            {t('cancel')}
          </Button>
          <Button onClick={() => handleClose(false)} variant="contained">
            {t('close')}
          </Button>
        </Box>
      </DialogActions>
    </EnhancedDialog>
  );
};

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CommercialCustomerFormValues,
  commercialCustomerRequestSchema,
} from '../data/CommercialCustomerFormValues';
import { useNavigate } from 'react-router-dom';
import { FormEvent, useRef, useState } from 'react';
import RestHttpClient, { renderValidationErrors } from '../../../common/RestHttpClient';
import { ApiException, CreateCommercialCustomerRequest } from '../../../generated/ApiClient';
import { CommercialCustomerForm, errorHandler } from '../form/CommercialCustomerForm';
import { pushNotificationHandler } from '../../../common/PushNotification';
import { useTranslation } from 'react-i18next';
import { CommercialCustomerKsvSearch } from '../ksv/CommercialCustomerKsvSearch';
import { Button, Divider } from '@mui/material';
import { austriaCountryId, noPepId } from '../data/CustomerConstants';

export function NewCommercialCustomerForm() {
  const { t } = useTranslation();
  const [tabErrors, setTabErrors] = useState<number[]>([]);
  const ksvSubmitRef = useRef<HTMLButtonElement>(null);

  const { control, handleSubmit, setError, clearErrors, getValues, setValue } =
    useForm<CommercialCustomerFormValues>({
      resolver: zodResolver(commercialCustomerRequestSchema),
      mode: 'onChange',
      defaultValues: {
        isGeneralPartner: false,
        addressCountryId: austriaCountryId,
        pepSpecificationId: noPepId,
        isPotentialBuyer: false,
      },
    });

  const navigate = useNavigate();

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    setTabErrors([]);
    handleSubmit(
      (values) => {
        const req = new CreateCommercialCustomerRequest();
        req.init(values);

        RestHttpClient.createCommercialCustomer(req)
          .then((_) => {
            pushNotificationHandler.publish(t('customers.saved'), 'success');
            navigate(`/customers`);
          })
          .catch((error: ApiException) => {
            renderValidationErrors(error, setError);
          });
      },
      (err) => {
        errorHandler(err, setTabErrors);
      }
    )(event);
  };

  return (
    <form id="new-customer-form" onSubmit={onSubmit} noValidate>
      <CommercialCustomerForm
        control={control}
        isNew
        readonly={false}
        tabErrors={tabErrors}
        actions={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              ksvSubmitRef.current?.click();
              ksvSubmitRef.current?.scrollIntoView();
            }}
          >
            {t('customers.ksv.submitSearch')}
          </Button>
        }
      />
      <Divider />
      <CommercialCustomerKsvSearch
        getFormValues={getValues}
        setError={setError}
        clearErrors={clearErrors}
        setValue={setValue}
        control={control}
        ref={ksvSubmitRef}
      />
    </form>
  );
}

import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import RestHttpClient, { renderValidationErrors } from '../../../../common/RestHttpClient';
import { useTranslation } from 'react-i18next';
import { InsuranceFormValues, schema } from './InsuranceSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  CalculateInsuranceRequest,
  GetInsuranceResponse,
  GetInsuranceSpecialOffersResponse,
  InsuranceCalculationLimit,
  OptionItem,
  SaveInsuranceRequest,
} from '../../../../generated/ApiClient';
import { Box, Divider, Drawer, LinearProgress } from '@mui/material';
import { InsuranceBMIObjectInfo } from './drawerComponents/InsuranceBMIObjectInfo';
import { InsuranceCompanies } from './drawerComponents/InsuranceCompanies';
import { DealInsuranceDrawerFooter } from './drawerComponents/DealInsuranceDrawerFooter';
import { MoreInfo } from './drawerComponents/MoreInfo';
import { InsurancePromotions } from './drawerComponents/InsurancePromotions';
import { InsuranceDiscount } from './drawerComponents/InsuranceDiscount';
import { pushNotificationHandler } from '../../../../common/PushNotification';

interface Props {
  isDrawerOpened: boolean;
  setIsDrawerOpened: Dispatch<SetStateAction<boolean>>;
  dealId: number;
  insuranceId?: number;
  isPrivateCustomer: boolean;
  onSubmit: Function;
  readonly: boolean;
}

export const DealDetailInsuranceDrawer = ({
  isDrawerOpened,
  setIsDrawerOpened,
  dealId,
  insuranceId,
  isPrivateCustomer,
  onSubmit,
  readonly,
}: Props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [limits, setLimits] = useState<InsuranceCalculationLimit>();

  const [bmLevels, setBmLevels] = useState<OptionItem[]>([]);
  const [usageTypes, setUsageTypes] = useState<OptionItem[]>([]);
  const [paymentPeriods, setPaymentPeriods] = useState<OptionItem[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<OptionItem[]>([]);
  const [liabilityCompanies, setLiabilityCompanies] = useState<OptionItem[]>([]);
  const [liabilityInsurances, setLiabilityInsurances] = useState<OptionItem[]>([]);
  const [kaskoCompanies, setKaskoCompanies] = useState<OptionItem[]>([]);
  const [kaskoInsurances, setKaskoInsurances] = useState<OptionItem[]>([]);
  const [kaskoAdditionalProducts, setKaskoAdditionalProducts] = useState<OptionItem[]>([]);
  const [specialOffers, setSpecialOffers] = useState<GetInsuranceSpecialOffersResponse[]>([]);
  const [licenseHolders, setLicenseHolders] = useState<OptionItem[]>([]);

  const { control, reset, setValue, handleSubmit, trigger, setError, clearErrors, getValues } =
    useForm<InsuranceFormValues>({
      resolver: zodResolver(schema),
      defaultValues: {
        kaskoAdditionalProductIds: [],
      },
    });

  const [isPromotionsOpened, setPromotionsOpened] = useState(!!getValues().specialOfferId);

  const fetchDefault = async (): Promise<GetInsuranceResponse> => {
    try {
      setLoading(true);
      if (insuranceId) {
        const { data } = await RestHttpClient.getInsurance(insuranceId);
        setLicenseHolders(data.licenseHolders);
        return data;
      } else {
        const { data } = await RestHttpClient.getDefaultInsurance(dealId);
        setLicenseHolders(data.licenseHolders);
        return data;
      }
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const recalculate = async (shouldValidate: boolean = false) => {
    try {
      const valid = await trigger();
      if (!shouldValidate) clearErrors();
      if (!valid) return;
      const values = getValues();
      setLoading(true);
      const req = new CalculateInsuranceRequest({
        dealId,
        enableLiability: values.enableLiability,
        enableKasko: values.enableKasko,
        enableLegalProtection: values.enableLegalProtection,
        enableAccident: values.enableAccident,
        kaskoInsuranceId: values.kaskoInsuranceId ?? undefined,
        kaskoInsuranceCompanyId: values.kaskoInsuranceCompanyId ?? undefined,
        liabilityInsuranceId: values.liabilityInsuranceId ?? undefined,
        liabilityInsuranceCompanyId: values.liabilityInsuranceCompanyId ?? undefined,
        applicantId: values.applicantId,
        additionalApplicantId: values.additionalApplicantId ?? undefined,
        paymentPeriodId: values.paymentPeriodId,
        paymentTypeId: values.paymentTypeId,
        bmLevel: values.bmLevel,
        liability_BonusMalus_Internal_Id: values.liability_BonusMalus_Internal_Id ?? undefined,
        kaskoPremiumOverride: undefined,
        kaskoPremiumDiscount: values.kaskoPremiumDiscount ?? undefined,
        liabilityPremiumOverride: undefined,
        liabilityPremiumDiscount: values.liabilityPremiumDiscount ?? undefined,
        inputTaxDeduction: values.inputTaxDeduction,
        kaskoAdditionalProductIds: values.kaskoAdditionalProductIds,
        specialOfferId: values.specialOfferId || undefined,
        kw: values.kw,
        co2: values.co2 ?? 0,
        usageTypeId: values.usageTypeId ?? 0,
        weight: values.weight ?? undefined,
        maxTotalWeight: values.maxTotalWeight ?? undefined,
        numberOfSeats: values.numberOfSeats ?? undefined,
        removeCommission: values.removeCommission ?? undefined
      });
      await RestHttpClient.calculateInsurance(req).then(({ data }) => {
        setValue('engineTax', data.engineTax);
        setValue('liabilityPrice', data.liabilityPrice);
        setValue('kaskoPrice', data.kaskoPrice);
        setValue('accidentPremium', data.accidentPremium);
        setValue('legalProtectionPremium', data.legalProtectionPremium);
        setValue('totalPrice', data.totalPrice);
        setValue('commissionTotal', data.commissionTotal);
        setValue('additionalProductsPremium', data.additionalProductsTotal);
      });
    } catch (err) {
      renderValidationErrors(err, setError);
    } finally {
      setLoading(false);
    }
  };

  const fetchKasko = (override = {}) => {
    const { specialOfferId, usageTypeId, kaskoInsuranceCompanyId, kaskoInsuranceId } =
      Object.assign(getValues(), override);
    console.log(kaskoInsuranceId);
    RestHttpClient.getKaskoInsuranceCompanies(dealId).then((res) => setKaskoCompanies(res.data));
    if (kaskoInsuranceCompanyId !== undefined && kaskoInsuranceCompanyId !== null) {
      RestHttpClient.getKaskoInsurances(
        dealId,
        kaskoInsuranceCompanyId,
        specialOfferId ?? undefined,
        kaskoInsuranceId ?? undefined,
        usageTypeId ?? undefined
      ).then((res) => setKaskoInsurances(res.data));
    }
    RestHttpClient.getKaskoAdditionalProducts(
      dealId,
      kaskoInsuranceCompanyId ?? undefined,
      kaskoInsuranceId ?? undefined,
      usageTypeId ?? undefined,
      specialOfferId ?? undefined
    ).then((res) => setKaskoAdditionalProducts(res.data));
  };

  const fetchLiability = (override = {}) => {
    const { liabilityInsuranceId, liabilityInsuranceCompanyId, specialOfferId, usageTypeId } =
      Object.assign(getValues(), override);
    const restrictedLiabilityInsuranceId =
      insuranceId !== undefined && insuranceId > 0 ? liabilityInsuranceId ?? undefined : undefined;
    RestHttpClient.getLiabilityInsuranceCompanies(dealId, restrictedLiabilityInsuranceId).then(
      (res) => {
        setLiabilityCompanies(res.data);
      }
    );
    RestHttpClient.getLiabilityInsurances(
      dealId,
      liabilityInsuranceCompanyId ?? 0,
      specialOfferId ?? undefined,
      liabilityInsuranceId ?? undefined,
      usageTypeId ?? undefined
    ).then((res) => setLiabilityInsurances(res.data));
  };

  const fetchBmOptions = () => {
    RestHttpClient.getBonusMalusLevels(dealId, insuranceId).then((res) => setBmLevels(res.data));
    if (limits?.vehicleTypeId) {
      RestHttpClient.getUsageTypes(limits?.vehicleTypeId).then((res) => setUsageTypes(res.data));
    }
    RestHttpClient.getPaymentPeriods().then((res) => setPaymentPeriods(res.data));
    RestHttpClient.getPaymentTypes().then((res) => setPaymentTypes(res.data));
  };

  const fetchSpecialOffers = (override = {}) => {
    const { usageTypeId, bmLevel } = Object.assign(getValues(), override);
    RestHttpClient.getInsuranceSpecialOffers(
      dealId,
      bmLevel ?? undefined,
      usageTypeId ?? undefined
    ).then((res) => setSpecialOffers(res.data));
  };

  const fetchOptions = () => {
    fetchLiability();
    fetchKasko();
    fetchBmOptions();
    fetchSpecialOffers();
  };

  useEffect(() => {
    if (isDrawerOpened) {
      reset();
      fetchDefault().then((data) => {
        reset(data);
        setLimits(data.limits);
        fetchOptions();
      });
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [isDrawerOpened]);

  useEffect(() => {
    fetchBmOptions();
    // eslint-disable-next-line
  }, [limits]);

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(
      (values) => {
        console.log('insurance submit values', values);
        if (values.inputTaxDeduction && isPrivateCustomer) {
          setError('inputTaxDeduction', {
            type: 'error',
            message: t('deals.edit.insurance.fields.inputTaxDeductionOnlyCommercialMessage'),
          });
          pushNotificationHandler.publish(t('deals.edit.insurance.notSaved'), 'error');
          return;
        }
        setLoading(true);
        RestHttpClient.saveInsurance(
          new SaveInsuranceRequest({
            dealId,
            id: insuranceId,
            bmLevel: values.bmLevel,
            liability_BonusMalus_Internal_Id: values.liability_BonusMalus_Internal_Id || undefined,
            usageTypeId: values.usageTypeId ?? 0,
            kw: values.kw ?? 0,
            co2: values.co2 ?? 0,
            weight: values.weight ?? 0,
            numberOfSeats: values.numberOfSeats ?? 0,
            maxTotalWeight: values.maxTotalWeight ?? 0,
            applicantId: values.applicantId,
            additionalApplicantId: values.additionalApplicantId ?? undefined,
            paymentPeriodId: values.paymentPeriodId,
            paymentTypeId: values.paymentTypeId,
            specialOfferId: values.specialOfferId || undefined,
            enableLiability: values.enableLiability,
            liabilityInsuranceCompanyId: values.liabilityInsuranceCompanyId ?? 0,
            liabilityInsuranceId: values.liabilityInsuranceId ?? 0,
            enableLegalProtection: values.enableLegalProtection,
            enableAccident: values.enableAccident,
            enableKasko: values.enableKasko,
            kaskoInsuranceCompanyId: values.kaskoInsuranceCompanyId ?? 0,
            kaskoInsuranceId: values.kaskoInsuranceId ?? undefined,
            kaskoAdditionalProductIds: values.kaskoAdditionalProductIds ?? 0,
            inputTaxDeduction: values.inputTaxDeduction,
            liabilityPremiumDiscount: values.liabilityPremiumDiscount ?? 0,
            kaskoPremiumDiscount: values.kaskoPremiumDiscount ?? 0,
            removeCommission: values.removeCommission ?? undefined
          })
        )
          .then(() => {
            pushNotificationHandler.publish(t('deals.edit.insurance.saved'), 'success');
            onSubmit();
            handleClose();
          })
          .catch((err) => {
            renderValidationErrors(err, setError);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      (err) => {
        console.error(err);
      }
    )(event);
  };

  const handleClose = () => {
    reset({});
    setIsDrawerOpened(false);
  };

  const handleRelevantChange = (name: string, value: unknown) => {
    if (name === 'kaskoInsuranceCompanyId' || name === 'kaskoInsuranceId') {
      fetchKasko({
        [name]: value,
      });
    } else if (name === 'liabilityInsuranceId' || name === 'liabilityInsuranceCompanyId') {
      fetchLiability({
        [name]: value,
      });
    } else if (name === 'usageTypeId' || name === 'specialOfferId') {
      fetchKasko({
        [name]: value,
      });
      fetchLiability({
        [name]: value,
      });
      fetchSpecialOffers({
        [name]: value,
      });
    } else if (name === 'bmLevel') {
      fetchSpecialOffers({
        [name]: value,
      });
    } else if (name === 'specialOfferEnabled') {
      if (!value) {
        setValue('specialOfferId', null);
        setValue('liabilityPremiumDiscount', null);
        setValue('kaskoPremiumDiscount', null);
        fetchLiability();
        fetchKasko();
      } else {
        return;
      }
    } 
      
    recalculate();
  };

  const width = { xl: '70vw', lg: '90vw', sm: '100vw' };
  return (
    <Drawer
      PaperProps={{ sx: { height: '100vh', width: width, maxWidth: width, position: 'fixed', bottom: 0, right: 0 } }}
      anchor="right"
      open={isDrawerOpened}
      onClose={handleClose}
    >
      <Box display="flex" height="100%" className="insurance-drawer" mr={2}>
        <LinearProgress />
        <form id="insurance-form" onSubmit={onFormSubmit} noValidate style={{flexGrow: "1"}}>
          <Box sx={{ padding: 2, paddingBottom: '150px' }}>
            <h2 style={{ marginBottom: 16 }}>
              {t('deals.edit.insurance.drawerTitle', { dealId })}
            </h2>

            <InsuranceBMIObjectInfo
              control={control}
              onRelevantChange={handleRelevantChange}
              loading={loading}
              bmLevels={bmLevels}
              usageTypes={usageTypes}
              paymentPeriods={paymentPeriods}
              paymentTypes={paymentTypes}
              readonly={readonly}
              licenseHolders={licenseHolders}
            />
            <Divider sx={{ mt: 2 }} orientation="horizontal" flexItem />

            <InsurancePromotions
              control={control}
              setValue={setValue}
              specialOffers={specialOffers}
              onRelevantChange={handleRelevantChange}
              isPromotionsOpened={isPromotionsOpened}
              setPromotionsOpened={setPromotionsOpened}
              readonly={readonly}
            />

            <Divider sx={{ mt: 2 }} orientation="horizontal" flexItem />
            <InsuranceCompanies
              control={control}
              onRelevantChange={handleRelevantChange}
              loading={loading}
              liabilityCompanies={liabilityCompanies}
              liabilityInsurances={liabilityInsurances}
              kaskoCompanies={kaskoCompanies}
              kaskoInsurances={kaskoInsurances}
              kaskoAdditionalProducts={kaskoAdditionalProducts}
              readonly={readonly}
            />

            <Divider sx={{ mt: 2 }} orientation="horizontal" flexItem />
            <MoreInfo
              control={control}
              loading={loading}
              readonly={readonly}
            />
            <Divider sx={{ mt: 2 }} orientation="horizontal" flexItem />
            <InsuranceDiscount
              control={control}
              loading={loading}
              readonly={readonly}
              onRelevantChange={handleRelevantChange}
            />
          </Box>
        </form>

        <DealInsuranceDrawerFooter
          control={control}
          width={width}
          loading={loading}
          onClose={handleClose}
          onCalculate={() => recalculate(true)}
          readonly={readonly}
        />
      </Box>
    </Drawer>
  );
};

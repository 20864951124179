import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { FinancesDrawerFormValues } from '../RightDrawerSchema';
import { OptionItem } from '../../../../../generated/ApiClient';
import { Box, Button, debounce, Grid, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import { Icons } from '../../../../../component/icons/Icons';
import { FormTextField } from '../../../../../component/form/FormTextField';
import { FormDropdown } from '../../../../../component/form/FormDropdown';
import { useTranslation } from 'react-i18next';
import { FormPriceInput } from '../../../../../component/form/FormPriceInput';
import { useEffect, useState } from 'react';
import RestHttpClient from '../../../../../common/RestHttpClient';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';

interface Props {
  control: Control<FinancesDrawerFormValues>;
  dealers: OptionItem[];
  loading: boolean;
  onRelevantChange: (name: string, value: unknown) => void;
  displayGross: boolean;
  readonly?: boolean;
}

export const FinancesAgentOptions = ({
  control,
  dealers,
  displayGross,
  loading,
  onRelevantChange,
  readonly,
}: Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit =
    (hasPermission('FinancingProducts', 'Edit') || hasPermission('LeasingProducts', 'Edit')) &&
    !readonly;

  const [defaultDealerId, setDefaultDealerId] = useState<number | undefined>(undefined);

  useEffect(() => {
    RestHttpClient.getPreselectedDealerId().then((res) => {
      setDefaultDealerId(res.data.preselectedDealerId);
    });
  }, []);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'agentOptions',
  });

  const agentWatcher = useWatch({ control, name: 'agentOptions' }) || [];

  const handleAdd = () => {
    if (!defaultDealerId) return;
    append({
      id: Math.random(),
      price: 0,
      title: '',
      dealerId: defaultDealerId,
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
    onRelevantChange('agentOptions', agentWatcher);
  };
  // due to nested form logic, debounde and use of form values is needed
  const triggerRecalc = debounce(() => {
    onRelevantChange('agentOptions', agentWatcher);
  }, 500);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ pt: 2 }}>
          {t('deals.new.financesConfiguration.calculationDrawer.agentOption.agent')}
        </Typography>

        {canEdit && (
          <Button
            onClick={handleAdd}
            disabled={loading}
            sx={{ height: '28px', width: '94px', border: 1, borderColor: 'black', color: 'black' }}
          >
            {t('deals.new.financesConfiguration.calculationDrawer.agentOption.add')}
          </Button>
        )}
      </Box>

      {fields.map((field, index) => (
        <Grid key={`${index}-${field.id}`} container sx={{ mt: 1 }}>
          <Grid item xs={10}>
            <FormTextField
              control={control}
              label={t('deals.new.financesConfiguration.calculationDrawer.agentOption.title')}
              name={`agentOptions.${index}.title`}
              required
              sx={{ m: 1 }}
              disabled={loading || !canEdit}
            />

            <FormDropdown
              control={control}
              options={dealers}
              label={t('deals.new.financesConfiguration.calculationDrawer.agentOption.dealer')}
              name={`agentOptions.${index}.dealerId`}
              sx={{ m: 1 }}
              disabled={loading || !canEdit}
            />

            <FormPriceInput
              control={control}
              label={t('deals.new.financesConfiguration.calculationDrawer.agentOption.price')}
              name={`agentOptions.${index}.price`}
              onBlur={triggerRecalc}
              required
              boxProps={{
                sx: { m: 1 },
              }}
              pricesIncludingVAT={displayGross}
              disabled={loading || !canEdit}
            />
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <div>
              {canEdit && (
                <IconButton disabled={loading} onClick={() => handleRemove(index)}>
                  {Icons.trash()}
                </IconButton>
              )}
            </div>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

import { useForm } from 'react-hook-form';
import {
  PrivateCustomerFormValues,
  privateCustomerRequestSchema,
  privatePotentialBuyerSchema,
} from '../data/PrivateCustomerFormValues';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, FormEvent, SetStateAction, useEffect, useRef, useState } from 'react';
import {
  errorHandler,
  PrivateCustomerForm,
  privateFinanceFields,
  privateIncomeFields,
  privatePersonalFields,
} from '../form/PrivateCustomerForm';
import RestHttpClient, { renderValidationErrors } from '../../../common/RestHttpClient';
import { LoadingModal } from '../../../component/LoadingModal';
import { Box, Button, Divider } from '@mui/material';
import { PrivateCustomerKsvSearch } from '../ksv/PrivateCustomerKsvSearch';
import { LocalApiMessage, PrivateCustomerViewApiResult } from '../../../generated/ApiClient';
import { FormCheckbox } from '../../../component/form/FormCheckbox';
import { useTranslation } from 'react-i18next';
import { PotentialPrivateCustomerForm } from '../potential/PotentialPrivateCustomerForm';
import { removeNullValues } from '../../../common/validation/Validations';
import { austriaCountryId, noPepId } from '../data/CustomerConstants';

interface Props {
  customerId?: number;
  onSubmit: (
    values: PrivateCustomerFormValues,
    changesMade: boolean,
    submitType?: string,
    callback?: Function,
    hasRelevantChanges?: boolean
  ) => unknown;
  overrideValues?: any;
  actions?: JSX.Element;
  customerPromise?: () => Promise<PrivateCustomerViewApiResult>;
  errors?: LocalApiMessage[];
  isNew?: boolean;
  noPotential?: boolean;
  readonly: boolean;
  setUnsavedChanges?: Dispatch<SetStateAction<boolean>>;
  isCoApplicant?: boolean;
  showSelfDisclosure?: boolean;
}

const defaultValues = {
  addressCountryId: austriaCountryId,
  isPotentialBuyer: false,
  pepSpecificationId: noPepId,
  dataInformationSheetTransferred: false,
};

export function EditPrivateCustomerForm({
  customerId,
  onSubmit,
  overrideValues,
  actions,
  customerPromise,
  errors,
  isNew,
  noPotential,
  readonly,
  setUnsavedChanges,
  isCoApplicant,
  showSelfDisclosure,
}: Props) {
  const { t } = useTranslation();

  const ksvSubmitRef = useRef<HTMLButtonElement>(null);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [tabErrors, setTabErrors] = useState<number[]>([]);
  const [hasRelevantChanges, setHasRelevantChanges] = useState<boolean>(false);

  const [initialPotential, setInitialPotential] = useState(false);

  const {
    control,
    formState,
    handleSubmit,
    trigger,
    reset,
    getValues,
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm<PrivateCustomerFormValues>({
    resolver: async (data, context, options) => {
      // before submitting we call trigger() which leads to the problem that all fields are touched in the options field
      // workaround: only validate selfdisclosure if there are less than 2 touched fields
      if (options.names && options.names.length === 1) {
        if (!options.fields['selfDisclosureAgreed'] && !options.fields['ksvSearchDone']) {
          setValue('selfDisclosureAgreed', false);
        }

        if (!options.fields['ksvSearchDone']) {
          setHasRelevantChanges(true);
        }
      }

      if (data.isPotentialBuyer) {
        return zodResolver(privatePotentialBuyerSchema)(removeNullValues(data), context, options);
      }
      return zodResolver(privateCustomerRequestSchema)(removeNullValues(data), context, options);
    },
    mode: 'onChange',
    defaultValues: { ...defaultValues, isCoApplicant },
  });

  const handleSetInitialized = () => setTimeout(() => setInitialized(true), 500);

  const checkShowAllForPotential = (d: any) => {
    if (!privatePotentialBuyerSchema.safeParse(removeNullValues(d)).success) {
      setShowAll(true);
      setValue('isPotentialBuyer', true);
    } else {
      setShowAll(false);
      setValue('isPotentialBuyer', false);
    }
    setInitialPotential(d.isPotentialBuyer);
  };

  const setErrors = () => {
    const includesFields = (fields: Array<keyof PrivateCustomerFormValues>) => {
      if (!errors) {
        return false;
      }
      for (const e of errors) {
        // @ts-ignore
        if (fields.includes(e.target)) {
          return true;
        }
      }
      return false;
    };

    clearErrors();
    setTabErrors([]);
    if (errors && errors.length) {
      errors.forEach((e) => {
        // @ts-ignore
        setError(e.target, { type: 'custom', message: t('deals.edit.creditCheck.required') });
      });

      if (includesFields(privatePersonalFields)) {
        setTabErrors((prev) => [...prev, 0]);
      }
      if (includesFields(privateIncomeFields)) {
        setTabErrors((prev) => [...prev, 1]);
      }
      if (includesFields(privateFinanceFields)) {
        setTabErrors((prev) => [...prev, 2]);
      }
    }
  };

  useEffect(() => {
    setErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    let customer: Promise<PrivateCustomerViewApiResult> | undefined;

    setInitialized(false);
    if (customerPromise) {
      customer = customerPromise();
    } else if (customerId && !isNaN(customerId)) {
      customer = RestHttpClient.getPrivateCustomer(customerId);
    }

    if (customer) {
      setLoading(true);
      customer
        .then((res) => {
          if (overrideValues) {
            const resetValues = { ...res.data, ...overrideValues, isCoApplicant };
            checkShowAllForPotential(resetValues);
            reset(resetValues);
          } else {
            checkShowAllForPotential(res.data);
            reset({ ...res.data, isCoApplicant });
          }
          setErrors();
        })
        .finally(() => {
          setLoading(false);
          handleSetInitialized();
        });
    } else {
      if (overrideValues) {
        checkShowAllForPotential(overrideValues);
        reset({ ...overrideValues, isCoApplicant });
      } else {
        reset({ ...defaultValues, isCoApplicant });
      }
      setErrors();
      handleSetInitialized();
    }
    // eslint-disable-next-line
  }, [customerId]);

  useEffect(() => {
    if (isNew && (!overrideValues || overrideValues === {})) {
      reset({ ...defaultValues, isCoApplicant });
      setErrors();
    }
    // eslint-disable-next-line
  }, [isNew]);

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const valid = await trigger();
    if (!valid) return;

    const changesMade = Object.keys(formState.dirtyFields).length > 0;
    // @ts-ignore
    const submitType = event.nativeEvent.submitter.name;
    setTabErrors([]);
    await handleSubmit(
      async (values) => {
        try {
          await onSubmit(
            values,
            changesMade,
            submitType,
            () => {
              checkShowAllForPotential(values);
              if (setUnsavedChanges) setUnsavedChanges(false);
            },
            hasRelevantChanges
          );
          setHasRelevantChanges(false);
          if (changesMade) {
            reset({ ...defaultValues, isCoApplicant }, { keepValues: true });
          }
        } catch (error: any) {
          renderValidationErrors(error, setError);
        }
      },
      (err) => {
        console.error(err);
        errorHandler(err, setTabErrors);
      }
    )(event);
  };

  const isPotential = watch('isPotentialBuyer');

  const ksvDoneError = Boolean(formState.errors.ksvSearchDone?.message);

  useEffect(() => {
    const subscription = watch(() => {
      if (setUnsavedChanges && initialized) {
        setUnsavedChanges(true);
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, initialized]);

  return (
    <form id="edit-customer-form" onSubmit={handleFormSubmit} noValidate>
      <LoadingModal loading={loading} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1,
        }}
      >
        <Box>
          {((isNew && !noPotential) || initialPotential) && (
            <FormCheckbox
              variant="switch"
              control={control}
              label={t('customers.isPotentialBuyer')}
              name="isPotentialBuyer"
              inverse
              disabled={readonly}
            />
          )}
        </Box>

        {!isPotential && !readonly && (
          <Button
            variant="contained"
            color={ksvDoneError ? 'error' : 'primary'}
            onClick={() => {
              ksvSubmitRef.current?.click();
              ksvSubmitRef.current?.scrollIntoView();
            }}
          >
            {t('customers.ksv.submitSearch')}
          </Button>
        )}
      </Box>

      {isPotential && !showAll ? (
        <PotentialPrivateCustomerForm
          control={control}
          actions={actions}
          isNew={isNew}
          readonly={readonly}
        />
      ) : (
        <PrivateCustomerForm
          control={control}
          actions={actions}
          isNew={isNew}
          readonly={readonly}
          tabErrors={tabErrors}
          setValue={setValue}
          showSelfDisclosure={showSelfDisclosure}
        />
      )}

      {isPotential && !readonly && (
        <Box>
          {showAll ? (
            <Button onClick={() => setShowAll(false)}>{t('customers.showMinimal')}</Button>
          ) : (
            <Button onClick={() => setShowAll(true)}>{t('customers.showAll')}</Button>
          )}
        </Box>
      )}

      {!isPotential && !readonly && (
        <div>
          <Divider />
          <PrivateCustomerKsvSearch
            getFormValues={getValues}
            setError={setError}
            clearErrors={clearErrors}
            setValue={setValue}
            control={control}
            ref={ksvSubmitRef}
          />
        </div>
      )}
    </form>
  );
}

import React, { useEffect, useState } from 'react';
import RestHttpClient from '../../../common/RestHttpClient';
import { Box, CircularProgress, Grid } from '@mui/material';
import { OverviewListEntry } from './OverviewListEntry';
import { useTranslation } from 'react-i18next';
import { GlobalTextIdentifiers, useGlobalTitles } from '../../../common/global/useGlobalTitles';

interface Props {
  showAll: boolean;
  onFilterChange: (filter?: number) => void;
}

const DashboardApplicationWidget = ({ showAll, onFilterChange }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{ [key: string]: number }>();
  const getTitle = useGlobalTitles();

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await RestHttpClient.getApplicationWidget(showAll);
      setData(data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAll]);

  return (
    <Grid item xs={6} lg={3}>
      <Box className="card">
        <h2 style={{ cursor: 'pointer' }} onClick={() => onFilterChange(undefined)}>
          {getTitle(GlobalTextIdentifiers.DashboardApplications)}
        </h2>
        <span className="line" />

        {data && !loading && (
          <>
            <OverviewListEntry
              title={t('dashboard.applications.inProcess')}
              value={data[2]}
              color="blue"
              onClick={() => onFilterChange(2)}
            />
            <OverviewListEntry
              title={t('dashboard.applications.missingDocs')}
              value={data[4]}
              color="red"
              onClick={() => onFilterChange(4)}
            />
            <OverviewListEntry
              title={t('dashboard.applications.captured')}
              value={data[3]}
              color="grey"
              onClick={() => onFilterChange(3)}
            />
            <OverviewListEntry
              title={t('dashboard.applications.permitted')}
              value={data[1]}
              color="green"
              onClick={() => onFilterChange(1)}
            />
            <OverviewListEntry
              title={t('dashboard.applications.renounced')}
              value={data[6]}
              color="yellow"
              onClick={() => onFilterChange(6)}
            />
            <OverviewListEntry
              title={t('dashboard.applications.declined')}
              value={data[8]}
              onClick={() => onFilterChange(8)}
            />
          </>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {loading && <CircularProgress />}
        </Box>
      </Box>
    </Grid>
  );
};

export default DashboardApplicationWidget;

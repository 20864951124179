import { z } from 'zod';
import { Control } from 'react-hook-form';
import { FormEvent } from 'react';
import { DocumentDefinition } from '../../../../generated/ApiClient';
import { Box, Grid, Typography } from '@mui/material';
import {
  FormCheckboxGroup,
  OptionWithChildren,
  ReadonlyOptionItem,
} from '../../../../component/form/FormCheckboxGroup';
import { FormTextField } from '../../../../component/form/FormTextField';
import { FormDropdown } from '../../../../component/form/FormDropdown';
import { FormCheckbox } from '../../../../component/form/FormCheckbox';
import { useTranslation } from 'react-i18next';
import { OptionItem } from '../../../../component/form/OptionItem';

export const insurancePrintSchema = z.object({
  insuranceDocuments: z.array(z.number()),
  customerDocuments: z.array(z.string()),
  offerSupplement: z.string().nullable().optional(),
  previousInsuranceInfoRequired: z.boolean(),
  previousInsuranceCompanyName: z.string().nullable().optional(),
  previousTerminateContract: z.boolean().nullable().optional(),
  previousContractNumber: z.string().nullable().optional(),
  previousLicensePlate: z.string().nullable().optional(),
  previousContractTypeId: z.number().nullable().optional(),
  waiverOfBonus: z.boolean().nullable().optional(),
  hasCustomerEmail: z.boolean(),
});
export type InsurancePrintFormType = z.infer<typeof insurancePrintSchema>;

interface Props {
  control: Control<InsurancePrintFormType>;
  insuranceDocuments: DocumentDefinition[];
  customerDocuments?: DocumentDefinition[];
  onSubmit: (event: FormEvent) => void;
  loading: boolean;
  previousInsurers: OptionItem[];
  contractTypes: OptionItem[];
}

const InsurancePrintForm = ({
  control,
  insuranceDocuments,
  customerDocuments,
  onSubmit,
  loading,
  previousInsurers,
  contractTypes,
}: Props) => {
  const { t } = useTranslation();

  // @ts-ignore
  const tPrint = (key: string): string => t(`deals.edit.insurance.printDialog.${key}`);

  const documentToOption = (doc: DocumentDefinition) => {
    const opt = new OptionWithChildren({
      label: doc.name ?? '-',
      value: doc.customerId > 0 ? doc.id + '|' + doc.customerId : doc.id,
    });

    opt.readonly = doc.readonly;
    opt.children = doc.subDocuments?.map((subDoc) => {
      const o = new ReadonlyOptionItem();
      o.label = subDoc.name ?? '';
      o.value = subDoc.id;
      o.readonly = subDoc.readonly;
      return o;
    });
    return opt;
  };

  return (
    <form
      className="insurance-print-dialog"
      id="insurance-print-dialog"
      onSubmit={onSubmit}
      style={{ maxWidth: 1000 }}
    >
      <Box sx={{ mb: 1 }}>
        <Grid container sx={{ mb: 4 }}>
          <Grid item xs={6}>
            <h3>{tPrint('documentSelection')}</h3>

            <Box sx={{ ml: 1 }}>
              <Typography variant="h4">{tPrint('insuranceCategory')}</Typography>
              <FormCheckboxGroup
                disabled={loading}
                control={control}
                label={''}
                name="insuranceDocuments"
                options={insuranceDocuments.map(documentToOption)}
                selectChildsOnChange={false}
              />

              {customerDocuments && (
                <>
                  <Typography variant="h4">{tPrint('customerCategory')}</Typography>
                  <FormCheckboxGroup
                    disabled={loading}
                    control={control}
                    label={''}
                    name="customerDocuments"
                    options={customerDocuments.map(documentToOption)}
                    selectChildsOnChange={false}
                  />
                </>
              )}
            </Box>
          </Grid>

          <Grid item xs={6}>
            <h3>{tPrint('customerAdditionalInfo')}</h3>

            <FormTextField
              disabled={loading}
              sx={{ mt: 2 }}
              control={control}
              label={tPrint('offerSupplement')}
              name="offerSupplement"
            />
            <FormDropdown
              disabled={loading}
              sx={{ mt: 2 }}
              control={control}
              options={previousInsurers}
              label={tPrint('previousInsuranceCompanyName')}
              name="previousInsuranceCompanyName"
              emptyText='-'
              emptyValue={''}
            />
            <Box sx={{ mt: 2 }}>
              <FormCheckbox
                disabled={loading}
                control={control}
                name="previousTerminateContract"
                label={tPrint('previousTerminateContract')}
              />
            </Box>
            <FormTextField
              disabled={loading}
              sx={{ mt: 1 }}
              control={control}
              label={tPrint('previousContractNumber')}
              name="previousContractNumber"
            />
            <FormTextField
              disabled={loading}
              sx={{ mt: 2 }}
              control={control}
              label={tPrint('previousLicensePlate')}
              name="previousLicensePlate"
            />
            <Box sx={{ mt: 2 }}>
              <FormDropdown
                disabled={loading}
                control={control}
                name="previousContractTypeId"
                label={tPrint('previousContractTypeId')}
                options={contractTypes}
              />
            </Box>
            <FormCheckbox
              control={control}
              name="waiverOfBonus"
              label={tPrint('waiverOfBonus')}
              disabled={loading}
            />
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default InsurancePrintForm;

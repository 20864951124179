import { t } from 'i18next';
import { z } from 'zod';
import {
  optionalDate,
  radioButton,
  integerGreaterZero,
  numberGreaterZero,
  checkBoxMustBeTrue,
  getDateBefore,
  dateLessOrEqualToday,
  swiftCode,
  iban,
  phoneNumber,
  integerString,
  requiredString,
  emptyStringToUndefined,
  houseNumberLength,
} from '../../../common/validation/Validations';

export const privateCustomerBaseSchema = z.object({
  firstName: requiredString,
  lastName: requiredString,
  salutationId: z.number(),
  titleId: z.number().optional().nullable(),
  titleInternationalId: z.number().optional().nullable(),
  birthDate: z.date().max(getDateBefore(18)).optional().nullable(),
  maritalStatusId: z.number().refine((n) => n > 0, t('errors.invalid_type', { ns: 'zod' })),
  citizenshipId: z.number().refine((n) => n > 0, t('errors.invalid_type', { ns: 'zod' })),
  addressStreet: requiredString,
  addressHouseNumber: requiredString.max(houseNumberLength),
  addressPostalCode: requiredString,
  addressCity: requiredString,
  addressCountryId: z.number(),
  phoneNumber: phoneNumber.or(emptyStringToUndefined).optional().nullable(),
  additionalPhoneNumber: phoneNumber.or(emptyStringToUndefined).optional().nullable(),
  emailAddress: z.string().email().max(50),
  numberOfSupportedChildren: integerGreaterZero,
  numberOfChildrenUnderEighteen: integerGreaterZero,
  residencePermitValidSince: optionalDate,
  residencePermitValidTill: optionalDate,
  inAustriaSince: dateLessOrEqualToday.optional().nullable(),
  workPermitValidSince: optionalDate,
  workPermitValidTill: optionalDate,
  bankAccountIBAN: iban.optional().nullable(),
  bankAccountBIC: swiftCode.optional().nullable(),
  pepSpecificationId: z.number(),
  dataInformationSheetTransferred: checkBoxMustBeTrue,
  phoneMarketingAggreed: radioButton,
  emailMarketingAggreed: radioButton,
  postalMarketingAggreed: radioButton,
  verifiableMonthlyIncome: numberGreaterZero,
  verifiableMonthlyAddIncome: numberGreaterZero,
  verifiableMonthlyAddIncomeType: z.string().optional().nullable(),
  jobTitleId: z.number().optional().nullable(),
  employedSince: dateLessOrEqualToday.optional().nullable(),
  currentEmployerName: z.string().optional().nullable(),
  currentEmployerAddress: z.string().optional().nullable(),
  residenceTypeId: z.number().optional().nullable(),
  monthlyRentInclAddCharges: numberGreaterZero,
  monthlyLoanRepayment: numberGreaterZero,
  currentBankLoansAndOverdrafts: numberGreaterZero,
  maintenanceObligations: numberGreaterZero,
  currentHousehold: numberGreaterZero,
  assetSize: integerString,
  assetCity: z.string().optional().nullable(),
  assetTypeId: z.number().optional().nullable(),
  otherAssets: numberGreaterZero,
  otherAssetTypeId: z.number().optional().nullable(),
  ksvId: z.string().optional().nullable(),
  ksvSearchDone: z.boolean().optional().nullable(),
  isPotentialBuyer: radioButton,
  isCoApplicant: z.boolean().optional().nullable(),
  isApplicantSpouse: z.boolean().optional().nullable(),
  selfDisclosureAgreed: z.boolean().optional().nullable(),
  selfDisclosureAgreedAt: z.date().optional().nullable(),
  politicalExposedPersonRelated: z.boolean().optional().nullable(),
});

const refinedPrivateCustomerSchema = z
  .object({
    additionalPhoneNumber: phoneNumber.or(emptyStringToUndefined).optional().nullable(),
    phoneNumber: phoneNumber.or(emptyStringToUndefined).optional().nullable(),
    birthDate: z.date().max(getDateBefore(18)).optional().nullable(),
    workPermitValidSince: optionalDate,
    workPermitValidTill: optionalDate,
    residencePermitValidSince: optionalDate,
    residencePermitValidTill: optionalDate,
    isCoApplicant: z.boolean().optional().nullable(),
    isApplicantSpouse: z.boolean().optional().nullable(),
  })
  .refine(
    (args) => {
      const hasPhoneNumber =
        args.phoneNumber !== undefined && args.phoneNumber != null && args.phoneNumber.length > 0;
      const hasAdditionalPhoneNumber =
        args.additionalPhoneNumber !== undefined &&
        args.additionalPhoneNumber != null &&
        args.additionalPhoneNumber.length > 0;

      return hasPhoneNumber || hasAdditionalPhoneNumber;
    },
    {
      message: t('errors.invalid_type', { ns: 'zod' }),
      path: ['phoneNumber'],
    }
  )
  .refine(
    (args) =>
      args.residencePermitValidSince === undefined ||
      args.residencePermitValidTill === undefined ||
      args.residencePermitValidSince === null ||
      args.residencePermitValidTill === null ||
      args.residencePermitValidSince < args.residencePermitValidTill,
    {
      message: t('customers.edit.personalData.residencePermitValidSinceValidationError'),
      path: ['residencePermitValidSince'],
    }
  )
  .refine(
    (args) =>
      args.workPermitValidSince === undefined ||
      args.workPermitValidTill === undefined ||
      args.workPermitValidSince === null ||
      args.workPermitValidTill === null ||
      args.workPermitValidSince < args.workPermitValidTill,
    {
      message: t('customers.edit.personalData.workPermitValidSinceValidationError'),
      path: ['workPermitValidSince'],
    }
  )
  .refine((args) => args.birthDate, {
    message: t('errors.invalid_type', { ns: 'zod' }),
    path: ['birthDate'],
  })
  .refine((args) => !args.isCoApplicant || args.isApplicantSpouse != null, {
    message: t('errors.invalid_type', { ns: 'zod' }),
    path: ['isApplicantSpouse'],
  });

export const privatePotentialBuyerSchema = privateCustomerBaseSchema
  .partial()
  .refine((args) => args.lastName, {
    message: t('errors.invalid_type', { ns: 'zod' }),
    path: ['lastName'],
  })
  .refine((args) => args.firstName, {
    message: t('errors.invalid_type', { ns: 'zod' }),
    path: ['firstName'],
  })
  .refine((args) => !args.isCoApplicant || args.isApplicantSpouse != null, {
    message: t('errors.invalid_type', { ns: 'zod' }),
    path: ['isApplicantSpouse'],
  });

export const privateCustomerRequestSchema = z.intersection(
  privateCustomerBaseSchema,
  refinedPrivateCustomerSchema
);

export type PrivateCustomerFormValues = z.infer<typeof privateCustomerRequestSchema>;

import { HorizontalLinearStepper } from '../../../component/HorizontalLinearStepper';
import i18n from 'i18next';
import { useContext } from 'react';
import { ModelForm } from './model/ModelForm';
import { FinancesForm } from './finances/FinancesForm';
import { CustomerForm } from './customer/CustomerForm';
import { ApplicantForm } from './applicant/ApplicantForm';
import { useActor } from '@xstate/react';
import {
  StateMachineContext,
  StateMachineContextProvider,
} from './state/StateMachineContextProvider';
import { LoadingModal } from '../../../component/LoadingModal';

const steps = [
  {
    title: i18n.t('deals.new.stepObjectTitle'),
    state: 'model',
    component: <ModelForm />,
  },
  {
    title: i18n.t('deals.new.stepFinancesTitle'),
    optional: true,
    state: 'finances',
    component: <FinancesForm />,
  },
  {
    title: i18n.t('deals.new.stepCustomerTitle'),
    state: 'customer',
    component: <CustomerForm />,
  },
  {
    title: i18n.t('deals.new.stepApplicantTitle'),
    optional: true,
    state: 'applicant',
    component: <ApplicantForm />,
  },
];

function NewDealFormRenderer() {
  const stateMachine = useContext(StateMachineContext);
  const [state] = useActor(stateMachine);

  let activeStep = steps.length - 1;
  let activeComponent = null;
  for (let i = 0; i < steps.length; i++) {
    if (state.matches(steps[i].state)) {
      activeStep = i;
      activeComponent = steps[i].component;
      break;
    }
  }

  return (
    <div className="new-deal">
      <LoadingModal loading={Boolean(state.context.loading)} />
      <HorizontalLinearStepper
        steps={steps}
        activeStep={activeStep}
        showNavigationButtons={false}
      />

      {activeComponent || <div>Final values: {JSON.stringify(state.context)}</div>}
    </div>
  );
}

export function NewDeal() {
  return (
    <StateMachineContextProvider>
      <NewDealFormRenderer />
    </StateMachineContextProvider>
  );
}

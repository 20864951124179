import { z } from 'zod';
import { optionalDate } from '../../../../common/validation/Validations';

export const schema = z.object({
  financialCalculationId: z.number().nonnegative(),
  dealId: z.number().nonnegative(),
  termsheet: z.string().optional().nullable(),
  expectedDispatchDate: optionalDate,
  providerId: z.number().nonnegative(),
  selectedEuriborDateId: z.number().nonnegative(),
  handoverDealerIdEnabled: z.boolean(),
  handoverDealerId: z.number().nonnegative().nullable(),
  commissionDealerId: z.number().nonnegative().nullable().optional(),
});

export type CreditCheckFormValues = z.infer<typeof schema>;

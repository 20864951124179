import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { EnhancedTable } from '../../../common/table/EnhancedTable';
import { HeadCell } from '../../../common/table/EnhancedTableHead';
import { CustomerMergeContext } from './CustomerMergeContext';
import { mapSimilarCustomer, SimilarCustomerRow } from './SimilarCustomerRow';

interface Props {
  headCells: readonly HeadCell<SimilarCustomerRow>[];
  isPoseidon?: boolean;
}

export function CustomerMergeSuggestion({ headCells, isPoseidon }: Props) {
  const {
    suggestion: { selected, setSelected, setPage, page, customers, loading },
    search,
  } = useContext(CustomerMergeContext);

  const rows: SimilarCustomerRow[] = customers.map((c) => mapSimilarCustomer(c));

  const onSelection = (ev: React.SyntheticEvent<unknown>, names: readonly string[]) => {
    setSelected(names.slice());

    if (isPoseidon) {
      search.setSelected([]);
    }
  };

  const loadNewPage = () => {
    setPage(page + 1);
  };

  return (
    <Box>
      <EnhancedTable
        rows={rows}
        headCells={headCells}
        hideCheckbox={false}
        variant="endless"
        loadNewPage={loadNewPage}
        loading={loading}
        enableSelection
        enableSingleSelection={isPoseidon}
        onSelection={onSelection}
        defaultSelected={selected}
      />
    </Box>
  );
}

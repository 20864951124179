import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export interface StepperProps {
  showNavigationButtons?: boolean;
  activeStep: number;
  onBack?: () => void;
  onNext?: () => void;
  steps: {
    title: string;
    subtitle?: string;
    optional?: boolean;
  }[];
  finishText?: string;
  disabled?: boolean;
}

export function HorizontalLinearStepper({
  activeStep,
  onBack,
  onNext,
  steps,
  finishText,
  disabled,
  showNavigationButtons = true,
}: StepperProps) {
  const { t } = useTranslation();

  const handleNext = () => {
    onNext?.();
  };

  const handleBack = () => {
    onBack?.();
  };

  return (
    <div className="stepper-container">
      <Stepper activeStep={activeStep} className="stepper">
        {steps.map((step, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (steps[index].optional) {
            labelProps.optional = <Typography variant="caption">{t('optional')}</Typography>;
          }
          // if (isStepSkipped(index)) {
          //   stepProps.completed = false;
          // }
          return (
            <Step key={step.title} {...stepProps}>
              <StepLabel {...labelProps}>{step.title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {showNavigationButtons && (
        <div className="stepper-footer">
          <Button disabled={activeStep === 0 || disabled} onClick={handleBack} className="white">
            {t('back')}
          </Button>
          <h2 className="current-step">{steps[activeStep].subtitle || steps[activeStep].title}</h2>
          <Button onClick={handleNext} variant="contained" disabled={disabled} type="submit">
            {activeStep === steps.length - 1 && finishText ? finishText : t('continue')}
          </Button>
        </div>
      )}
    </div>
  );
}

import { Box, BoxProps, Button, Typography } from '@mui/material';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';
import { EnhancedTable } from '../../../../../common/table/EnhancedTable';
import { HeadCell } from '../../../../../common/table/EnhancedTableHead';
import { FinancesDrawerFormValues } from '../RightDrawerSchema';
import React from 'react';
import { FinancesServicesSpecialCasePrices } from './FinancesServicesSpecialCasePrices';

export interface ServiceVariant {
  id: string;
  variantNumber: number;
  serviceProduct: string;
  productIndustry: string;
  productUsage: string;
  productOptions: string;
  rate: string;
  comment: string;
  phone: string;
  email: string;
  answer: string;
}

interface Props extends BoxProps {
  control: Control<FinancesDrawerFormValues>;
  getValues: UseFormGetValues<FinancesDrawerFormValues>;
  setValue: UseFormSetValue<FinancesDrawerFormValues>;
  loading: boolean;
  serviceVariantsLoading: boolean;
  readonly?: boolean;
  dealId?: number;
  serviceVariants: ServiceVariant[];
  onCreateProposal: () => void;
}

export const FinancesServiceVariant = ({
  control,
  getValues,
  setValue,
  loading,
  serviceVariantsLoading,
  readonly,
  dealId,
  serviceVariants,
  onCreateProposal,
  ...others
}: Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit = hasPermission('Services', 'Edit') && !readonly;

  const headCells: readonly HeadCell<ServiceVariant>[] = [
    {
      id: 'variantNumber',
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'serviceProduct',
      align: 'left',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.options.serviceProduct'),
    },
    {
      id: 'productIndustry',
      align: 'left',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.options.industry'),
    },
    {
      id: 'productUsage',
      align: 'left',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.options.typeOfUse'),
    },
    {
      id: 'productOptions',
      align: 'left',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.options.productOptions'),
    },
    {
      id: 'rate',
      align: 'left',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.options.rate'),
    },
  ];

  const onServiceVariantSelection = (
    ev: React.SyntheticEvent<unknown>,
    names: readonly string[]
  ) => {
    setValue('service.serviceVariantId', names.length > 0 ? +names[0] : null);
  };

  return (
    <Box
      {...others}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Box sx={{ p: 2, pr: 6, mb: 10 }}>
        <FinancesServicesSpecialCasePrices
          control={control}
          getValues={getValues}
          serviceVariantsLoading={serviceVariantsLoading}
          serviceVariants={serviceVariants}
        />
        <h3>{t('deals.new.financesConfiguration.calculationDrawer.options.variant')}</h3>
        {serviceVariants.length > 0 || serviceVariantsLoading ? (
          <EnhancedTable
            headCells={headCells}
            rows={serviceVariants}
            hideCheckbox={false}
            loading={serviceVariantsLoading}
            enableSelection={true}
            enableSingleSelection={true}
            onSelection={onServiceVariantSelection}
            defaultOrder="desc"
            defaultSelected={[getValues().service.serviceVariantId?.toString() ?? '']}
          />
        ) : (
          <Typography>
            {t('deals.new.financesConfiguration.calculationDrawer.options.variantsEmpty')}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '30px',
            flexDirection: 'column',
          }}
        >
          {canEdit && (
            <Button
              onClick={onCreateProposal}
              disabled={
                loading ||
                getValues().service.apiconProposalForm?.proposalCreationDate != null ||
                getValues().service.serviceVariantId === undefined ||
                dealId === undefined
              }
              variant="contained"
              type="button"
              sx={{ height: '28px', width: '169px', alignSelf: 'flex-end' }}
            >
              {t('deals.new.financesConfiguration.calculationDrawer.options.createProposal')}
            </Button>
          )}
          {getValues().service.apiconProposalForm?.proposalId != null &&
            getValues().service.apiconProposalForm?.proposalId !== undefined && (
              <>
                <Box sx={{ alignSelf: 'flex-end' }}>
                  {t(
                    'deals.new.financesConfiguration.calculationDrawer.options.proposalCreationDate',
                    {
                      proposalCreationDate:
                        getValues().service.apiconProposalForm?.proposalCreationDate?.toLocaleString(
                          'de-DE'
                        ),
                    }
                  )}
                </Box>
                <Box sx={{ alignSelf: 'flex-end' }}>
                  {t(
                    'deals.new.financesConfiguration.calculationDrawer.options.proposalValidUntil',
                    {
                      proposalValidUntil:
                        getValues().service.apiconProposalForm?.proposalValidUntil?.toLocaleDateString(),
                    }
                  )}
                </Box>
                <Box sx={{ alignSelf: 'flex-end' }}>
                  {t('deals.new.financesConfiguration.calculationDrawer.options.proposalId', {
                    proposalId: getValues().service.apiconProposalForm?.proposalId,
                  })}
                </Box>
              </>
            )}
        </Box>
      </Box>
    </Box>
  );
};

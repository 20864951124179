import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormTextField } from '../../../component/form/FormTextField';
import { useTranslation } from 'react-i18next';
import { FormDropdown } from '../../../component/form/FormDropdown';
import { GetTypeCertificateInfoResponse } from '../../../generated/ApiClient';
import { FormCheckbox } from '../../../component/form/FormCheckbox';
import { useState } from 'react';
import { radioButton } from '../../../common/validation/Validations';
import { Send } from '@mui/icons-material';
import { TypeCertificateDialog } from './TypeCertificateDialog';

const schema = z.object({
  recipient: z.string().nonempty(),
  additionalInfo: z.string().optional().nullable(),
  street: z.string().nonempty(),
  postalCode: z.string().nonempty(),
  city: z.string().nonempty(),
  country: z.string().nonempty(),
  reasonId: z.number(),
  priority: z.boolean(),
  sendToDealer: radioButton,
});

export type TypeCertificateType = z.infer<typeof schema>;

const staticValues = {
  priority: true,
};

const defaultValues = {
  sendToDealer: true,
};

export function TypeCertificateForm({
  contractId,
  dealId,
  data,
}: {
  contractId: number;
  dealId: number;
  data: GetTypeCertificateInfoResponse;
}) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const dealerData = {
    recipient: data.recipient,
    additionalInfo: data.additionalInfo,
    street: data.street,
    postalCode: data.postalCode,
    city: data.city,
    country: data.country,
  };

  const { control, handleSubmit, watch, getValues } = useForm<TypeCertificateType>({
    resolver: zodResolver(schema),
    defaultValues: { ...defaultValues, ...dealerData, ...staticValues },
  });

  const onSubmit = () => {
    handleSubmit(() => {
      setIsOpen(true);
    })();
  };

  const isSendToDealer = watch('sendToDealer');

  const values = getValues();

  return (
    <Box mt={3}>
      <Typography variant="h3">{t('dashboard.contractsOverlay.typeCertificate.label')}</Typography>
      <Stack flexDirection="row" mt={2} gap={2}>
        <Stack gap={2} flexBasis="50%">
          <FormTextField
            control={control}
            name="recipient"
            label={t('dashboard.contractsOverlay.typeCertificate.recipient')}
            disabled={isSendToDealer}
          />
          <FormTextField
            control={control}
            name="additionalInfo"
            label={t('dashboard.contractsOverlay.typeCertificate.additionalInfo')}
            disabled={isSendToDealer}
          />
          <FormTextField
            control={control}
            name="street"
            label={t('dashboard.contractsOverlay.typeCertificate.street')}
            disabled={isSendToDealer}
          />
          <FormTextField
            control={control}
            name="city"
            label={t('dashboard.contractsOverlay.typeCertificate.city')}
            disabled={isSendToDealer}
          />
          <FormTextField
            control={control}
            name="postalCode"
            label={t('dashboard.contractsOverlay.typeCertificate.postalCode')}
            disabled={isSendToDealer}
          />
          <FormTextField
            control={control}
            name="country"
            disabled
            label={t('dashboard.contractsOverlay.typeCertificate.country')}
          />
        </Stack>
        <Stack justifyContent="space-between" flexBasis="50%">
          <Stack gap={1}>
            <FormDropdown
              control={control}
              name="reasonId"
              options={data.reasons.map((r) => ({ value: Number(r.id), label: r.name }))}
              label={t('dashboard.contractsOverlay.typeCertificate.reasonId')}
            />
            <FormCheckbox
              control={control}
              name="priority"
              label={t('dashboard.contractsOverlay.typeCertificate.priority')}
              disabled
            />
          </Stack>

          <Stack alignItems="flex-end">
            <Button onClick={onSubmit} variant="contained" endIcon={<Send />}>
              {t('dashboard.contractsOverlay.typeCertificate.send')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <TypeCertificateDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={values}
        contractId={contractId}
        dealId={dealId}
        reasons={data.reasons}
      />
    </Box>
  );
}

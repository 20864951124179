import { Box, BoxProps } from '@mui/material';
import { Control, UseFormGetValues, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EnhancedTable } from '../../../../../common/table/EnhancedTable';
import { HeadCell } from '../../../../../common/table/EnhancedTableHead';
import { FinancesDrawerFormValues } from '../RightDrawerSchema';
import { ServiceVariant } from './FinancesServiceVariant';

interface Props extends BoxProps {
  control: Control<FinancesDrawerFormValues>;
  getValues: UseFormGetValues<FinancesDrawerFormValues>;
  serviceVariantsLoading: boolean;
  serviceVariants: ServiceVariant[];
}

export const FinancesServicesSpecialCasePrices = ({
  control,
  getValues,
  serviceVariantsLoading,
  serviceVariants,
  ...others
}: Props) => {
  const { t } = useTranslation();
  const serviceVariantId = useWatch({ control, name: 'service.serviceVariantId' });

  const headCells: readonly HeadCell<ServiceVariant>[] = [
    {
      id: 'comment',
      align: 'left',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.comment'),
    },
    {
      id: 'phone',
      align: 'left',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.phone'),
    },
    {
      id: 'email',
      align: 'left',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.email'),
    },
    {
      id: 'answer',
      align: 'left',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.answer'),
    },
  ];

  return (
    <Box
      {...others}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Box>
        <h3>{t('deals.new.financesConfiguration.calculationDrawer.options.specialPriceRequest')}</h3>
        {(serviceVariants.length > 0 || serviceVariantsLoading) && (
          <EnhancedTable
            headCells={headCells}
            rows={serviceVariants.filter(x => (+x.id) === (serviceVariantId ?? -1))}
            hideCheckbox={true}
            loading={serviceVariantsLoading}
            defaultOrder="desc"
          />
        )}
      </Box>
    </Box>
  );
};

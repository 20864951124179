import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { IMergeCustomerView } from '../../../generated/ApiClient';
import { CommercialCustomerMergeFormValues } from './CommercialCustomerMergeSearch';
import { PrivateCustomerMergeFormValues } from './PrivateCustomerMergeSearch';

type CustomerState = {
  suggestion: {
    selected: string[];
    setSelected: Dispatch<SetStateAction<string[]>>;
    customers: IMergeCustomerView[];
    setCustomers: Dispatch<SetStateAction<IMergeCustomerView[]>>;
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
  };

  search: {
    selected: string[];
    setSelected: Dispatch<SetStateAction<string[]>>;
    customers: IMergeCustomerView[];
    setCustomers: Dispatch<SetStateAction<IMergeCustomerView[]>>;
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;

    private: {
      searchValues: PrivateCustomerMergeFormValues;
      setSearchValues: Dispatch<SetStateAction<PrivateCustomerMergeFormValues>>;
    };

    commercial: {
      searchValues: CommercialCustomerMergeFormValues;
      setSearchValues: Dispatch<SetStateAction<CommercialCustomerMergeFormValues>>;
    };
  };
};

export const CustomerMergeContext = createContext<CustomerState>({} as CustomerState);

export function CustomerMergeProvider({ children }: { children: React.ReactNode }) {
  const [selectedSuggestion, setSelectedSuggestion] = useState<string[]>([]);
  const [suggestedCustomers, setSuggestedCustomers] = useState<IMergeCustomerView[]>([]);
  const [suggestedPage, setSuggestedPage] = useState(0);
  const [suggestedLoading, setSuggestedLoading] = useState(false);

  const [selectedSearch, setSelectedSearch] = useState<string[]>([]);
  const [searchCustomers, setSearchCustomers] = useState<IMergeCustomerView[]>([]);
  const [searchPage, setSearchPage] = useState(0);
  const [searchLoading, setSearchLoading] = useState(false);

  const [privateSearchValues, setPrivateSearchValues] = useState<PrivateCustomerMergeFormValues>(
    {}
  );
  const [commercialSearchValues, setcommercialSearchValues] =
    useState<CommercialCustomerMergeFormValues>({});

  return (
    <CustomerMergeContext.Provider
      value={{
        suggestion: {
          selected: selectedSuggestion,
          setSelected: setSelectedSuggestion,
          customers: suggestedCustomers,
          setCustomers: setSuggestedCustomers,
          page: suggestedPage,
          setPage: setSuggestedPage,
          loading: suggestedLoading,
          setLoading: setSuggestedLoading,
        },
        search: {
          selected: selectedSearch,
          setSelected: setSelectedSearch,
          customers: searchCustomers,
          setCustomers: setSearchCustomers,
          page: searchPage,
          setPage: setSearchPage,
          loading: searchLoading,
          setLoading: setSearchLoading,
          private: {
            searchValues: privateSearchValues,
            setSearchValues: setPrivateSearchValues,
          },
          commercial: {
            searchValues: commercialSearchValues,
            setSearchValues: setcommercialSearchValues,
          },
        },
      }}
    >
      {children}
    </CustomerMergeContext.Provider>
  );
}

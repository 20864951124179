import { Box, Checkbox, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Icons } from '../../component/icons/Icons';
import { EnhancedTable } from '../../common/table/EnhancedTable';
import { HeadCell } from '../../common/table/EnhancedTableHead';
import { DealOverview } from '../../generated/ApiClient';
import { Dispatch, SetStateAction } from 'react';

interface DealRow {
  id: string;
  dealer: string;
  dealId: number;
  createdAt: string;
  customer: string;
  trademark: string;
  model: string;
  hasFinancing: JSX.Element;
  hasInsurance: JSX.Element;
  icon: JSX.Element;
}

function MapDeal(deal: DealOverview): DealRow {
  return {
    id: deal.id.toFixed(),
    dealId: deal.dId,
    dealer: deal.salesPersonName,
    createdAt: new Date(deal.creationDate).toLocaleDateString('de-DE'),
    customer: deal.customerName,
    trademark: deal.trademarkName,
    model: deal.modelName,
    hasFinancing: <Checkbox defaultChecked={deal.hasFinancingCalculation} disabled />,
    hasInsurance: <Checkbox defaultChecked={deal.hasInsuranceCalculation} disabled />,
    icon: Icons.pencil(),
  };
}

interface Props 
{
  deals: DealOverview[],
  setOrderSelectors: Dispatch<SetStateAction<string[]>>,
  loading: boolean,
  loadNewPage: () => void,
  total: number
}

export function DealList({deals, setOrderSelectors, loadNewPage, loading, total}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const headCells: readonly HeadCell<DealRow>[] = [
    {
      id: 'dealer',
      disablePadding: false,
      label: t('deals.list.dealer'),
    },
    {
      id: 'dealId',
      disablePadding: false,
      label: t('deals.list.dealId'),
      allowSorting: true,
      columnName: 'Id',
    },
    {
      id: 'createdAt',
      disablePadding: false,
      label: t('deals.list.createdAt'),
      allowSorting: true,
      columnName: 'CreationDate',
    },
    {
      id: 'customer',
      disablePadding: false,
      label: t('deals.list.customer'),
      allowSorting: true,
      columnName: 'Customer',
    },
    {
      id: 'trademark',
      disablePadding: false,
      label: t('deals.list.trademark'),
      allowSorting: true,
      columnName: 'TrademarkName',
    },
    {
      id: 'model',
      disablePadding: false,
      label: t('deals.list.model'),
      allowSorting: true,
      columnName: 'ModelName',
    },
    {
      id: 'hasFinancing',
      disablePadding: false,
      align: 'center',
      label: t('deals.list.hasFinancing'),
    },
    {
      id: 'hasInsurance',
      disablePadding: false,
      align: 'center',
      label: t('deals.list.hasInsurance'),
    },
    {
      id: 'icon',
      align: 'right',
      disablePadding: false,
      label: '',
    },
  ];

  const rows: DealRow[] = deals.map(MapDeal);

  const onClick = (_: React.MouseEvent<unknown>, id: string) => {
    navigate(id);
  };

  const onSortRequest = (orderSelectors: string[]) => {
    setOrderSelectors(orderSelectors);
  };

  return (
    <Box className="deal-list">
        <EnhancedTable
          rows={rows}
          total={total}
          headCells={headCells}
          hideCheckbox={true}
          onRowClick={onClick}
          variant="endless"
          loadNewPage={loadNewPage}
          loading={loading}
          onSortRequest={onSortRequest}
        />
    </Box>
  );
}

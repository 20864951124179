import { Dispatch, SetStateAction } from 'react';
import { EnhancedDialog } from '../../../../component/EnhancedDialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleConfirm: () => void;
  handleCancel: () => void;
}

export function CustomerOrderAmendDialog({
  isOpen,
  setIsOpen,
  handleConfirm,
  handleCancel,
}: Props) {
  const { t } = useTranslation();

  return (
    <EnhancedDialog
      isDialogOpened={isOpen}
      setIsDialogOpened={setIsOpen}
      handleCancel={handleCancel}
    >
      <DialogTitle>
        <Typography variant="h6">{t('deals.amendment.dialogTitle')}</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Stack gap={3}>
          <Stack>
            <Typography variant="h5">{t('deals.amendment.newCustomer')}</Typography>
            <Typography>{t('deals.amendment.newCustomerDesc')}</Typography>
          </Stack>
          <Stack>
            <Typography variant="h5">{t('deals.amendment.existingCustomer')}</Typography>
            <Typography>{t('deals.amendment.existingCustomerDesc')}</Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" gap={2}>
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            {t('deals.amendment.newCustomer')}
          </Button>
          <Button variant="contained" onClick={() => handleConfirm()}>
            {t('deals.amendment.existingCustomer')}
          </Button>
        </Stack>
      </DialogActions>
    </EnhancedDialog>
  );
}

import { useState } from 'react';
import { CustomerProvider } from '../../../customers/data/CustomerContext';
import { CustomerSearch } from '../../../customers/search/CustomerSearch';
import { CustomerSearchAdvanced } from '../../../customers/search/CustomerSearchAdvanced';
import { CustomerList } from '../../../customers/CustomerList';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  onRowClick: (id: string) => void;
  customerTypeId?: number;
  onTriggerCreate?: () => void;
  includePotentialBuyer: boolean;
  excludeCustomerIds?: number[];
}

export function CustomerSelection({
  onRowClick,
  customerTypeId,
  onTriggerCreate,
  includePotentialBuyer,
  excludeCustomerIds,
}: Props) {
  const { t } = useTranslation();

  const [basicSearch, setBasicSearch] = useState(true);

  return (
    <CustomerProvider>
      <div className="customers">
        <Box className="view-header">
          <h2>{t('customers.header')}</h2>
          <Box className="button-container">
            {onTriggerCreate && (
              <Button variant="outlined" color="secondary" onClick={() => onTriggerCreate()}>
                {t('customers.newCustomer')}
              </Button>
            )}
          </Box>
        </Box>
        {basicSearch ? (
          <CustomerSearch
            customerTypeId={customerTypeId}
            toggleBasicSearch={setBasicSearch}
            excludeCustomerIds={excludeCustomerIds}
            includePotentialBuyer={includePotentialBuyer}
          />
        ) : (
          <CustomerSearchAdvanced
            customerTypeId={customerTypeId}
            toggleBasicSearch={setBasicSearch}
            excludeCustomerIds={excludeCustomerIds}
            includePotentialBuyer={includePotentialBuyer}
          />
        )}
        <CustomerList onRowClick={onRowClick} />
      </div>
    </CustomerProvider>
  );
}

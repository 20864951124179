import { Icons } from './icons/Icons';
import { TreeItem, TreeView } from '@mui/lab';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

export type TreeData = {
  [key: string]: any;
  id: number;
  identifier: string;
  label: string;
  parentId: number;
  hasChildrenLoaded: boolean;
  expanded: boolean;
  children?: TreeData[];
};

interface Props {
  data: TreeData[];
  onNodeSelected: (node: TreeData | null, expandedIds: string[]) => void;
}

/**
 * Performs a depth-first search on the data. This does NOT modify the original tree unless makeCopy is set to 'false' - instead it returns a
 * copy. If the callback returns 'true', the search will stop and the callback will no longer be called
 * @param tree The entire tree
 * @param callback A callback that gets called for every traversed node. Return 'true' to break the loop
 * @param makeCopy If false, the tree-structure will be modified directly. If true, a new tree with potential changes will be returned instead. Be
 * very careful with setting this property to 'false', since the original data will be lost!
 * @returns {{id: string, children}} The new dataSource, should the old one be modified.
 * Note that the original datasource will stay the same.
 */
function iterateTree(
  tree: TreeData[],
  callback: (node: TreeData, level: number) => boolean | void,
  makeCopy = true
): TreeData[] {
  let data = {
    id: '#',
    children: makeCopy ? JSON.parse(JSON.stringify(tree)) : tree,
  };

  let breakLoop = false;

  helper(data as any, -1);

  function helper(node: TreeData, level: number) {
    if (node.children !== undefined) {
      node.children.forEach((child) => {
        if (!breakLoop) {
          helper(child, level + 1);
        }
      });
    }

    if (!breakLoop) {
      breakLoop = breakLoop || !!callback(node, level);
    }
  }
  return data.children;
}

export function TreeViewComponent({ data, onNodeSelected }: Props) {
  const [internalData, setInternalData] = useState<TreeData[]>([]);
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  useEffect(() => {
    let copy = JSON.parse(JSON.stringify(data));
    let expandedIds = new Array<string>();

    iterateTree(
      copy,
      (node) => {
        if (node.expanded) {
          expandedIds.push(node.identifier);
        }
      },
      false
    );
    setInternalData(copy);
    setExpandedNodes(expandedIds);
  }, [data]);

  const renderTreeItem = (data: TreeData[]) => {
    if (!data || data.length === 0) {
      return null;
    }
    return data.map((element) => (
      <TreeItem
        key={element.identifier}
        nodeId={element.identifier}
        label={<div className="label">{element.label}</div>}
        className="tree-item"
      >
        {renderTreeItem(element.children || [])}
      </TreeItem>
    ));
  };

  return (
    <Box className="tree-view-panel">
      <TreeView
        className="tree-view"
        defaultCollapseIcon={Icons.chevronDown()}
        defaultExpandIcon={Icons.chevronRight()}
        expanded={expandedNodes}
        onNodeSelect={(event: React.SyntheticEvent, nodeId: string) => {
          let foundNode: TreeData | null = null;

          iterateTree(internalData, (node) => {
            if (node.identifier === nodeId) {
              foundNode = node;
              return true;
            }
          });

          let fn = foundNode!;

          let expandedIds = [...expandedNodes];
          const index = expandedIds.findIndex(function (ni) {
            return ni === fn.identifier;
          });

          if (index > -1) {
            expandedIds.splice(index, 1);
          } else {
            expandedIds.push(nodeId);
          }
          onNodeSelected(foundNode, expandedIds);
        }}
      >
        {renderTreeItem(internalData)}
      </TreeView>
    </Box>
  );
}

import { t } from "i18next";
import { DateTimeFormatter, dateFormat } from "../../../common/formatter/DateTimeFormatter";

export const getDissolveText = (date: Date | undefined, next: number = 0) => {
    if (!date) return '-';

    const newDate = new Date(date.getFullYear(), date.getMonth() + next + 1, 0);

    const formattedDate = DateTimeFormatter.format(newDate, { format: dateFormat });
    return t('dashboard.contractsOverlay.dissolveValues.due', { date: formattedDate });
  };
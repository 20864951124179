import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormDropdown, OptionItem } from '../../../../component/form/FormDropdown';
import { FormTextField } from '../../../../component/form/FormTextField';
import RestHttpClient from '../../../../common/RestHttpClient';
import { Control, useWatch } from 'react-hook-form';
import { FormDatePicker } from '../../../../component/form/FormDatePicker';
import { getDateBefore } from '../../../../common/validation/Validations';
import { FormNumberInput } from '../../../../component/form/FormNumberInput';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { ModelFormValues } from './ModelForm';
import { VinAssignmentField } from '../../common/financeOverlay/finances-object/VinField';
import { VinConfigValues } from '../../common/financeOverlay/RightDrawerSchema';

interface ModelConfigurationVehicleDetailsProps {
  control: Control<ModelFormValues>;
  setValue: UseFormSetValue<ModelFormValues>;
  getValues: UseFormGetValues<ModelFormValues>;
}

export function ModelConfigurationVehicleDetails({
  control,
  setValue,
  getValues,
}: ModelConfigurationVehicleDetailsProps) {
  const { t } = useTranslation();
  const condition = useWatch({ control, name: 'vehicleCondition' });

  const [vehicleConditionsFetched, setVehicleConditionsFetched] = useState<boolean>(false);
  const [vehicleConditions, setVehicleConditions] = useState<OptionItem[]>([]);

  async function FetchVehicleConditions() {
    const { data } = await RestHttpClient.getVehicleConditionTypes();
    setVehicleConditions(data);
  }

  useEffect(() => {
    if (!vehicleConditionsFetched) {
      FetchVehicleConditions().then(() => {
        setVehicleConditionsFetched(true);
      });
    }
  });

  const handleConditionChange = (event: React.ChangeEvent) => {
    // @ts-ignore
    if (event.target.value !== 1) {
      setValue('distributionChannel', -1);
    } else {
      setValue('distributionChannel', 11);
      setValue('mileage', undefined);
    }
  };

  return (
    <Box sx={{ width: 1 / 3, p: 1, m: 1 }}>
      <h1>{t('deals.new.modelConfiguration.body.vehicleDetails.vehicleDetails')}</h1>
      <FormTextField
        control={control}
        name="modelAddition"
        label={t('deals.new.modelConfiguration.body.vehicleDetails.modelAddition')}
        sx={{ width: '100%', margin: 1, paddingRight: 2 }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          width: '100%',
        }}
      >
        <FormDropdown
          isRequired={false}
          control={control}
          name="vehicleCondition"
          label={t('deals.new.modelConfiguration.body.vehicleDetails.vehicleCondition')}
          shrink
          options={vehicleConditions}
          onChange={handleConditionChange}
          sx={{ m: 1 }}
          required={true}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          width: '100%',
        }}
      ></Box>
      {condition !== 1 && (
        <>
          <Box>
            <FormNumberInput
              control={control}
              name="mileage"
              label={t('deals.new.modelConfiguration.body.vehicleDetails.mileage')}
              variant="standard"
              readOnly={condition === 1}
              sx={{ width: '100%', margin: 1 }}
            />
          </Box>
          <Box sx={{ margin: 1 }}>
            <FormDatePicker
              control={control}
              label={t('deals.new.modelConfiguration.body.vehicleDetails.registrationDate')}
              name="registrationDate"
              maxDate={getDateBefore(0)}
            />
          </Box>
        </>
      )}
      <VinAssignmentField
        control={control as unknown as Control<{ vinConfigs: VinConfigValues[] }>}
        setValue={setValue as unknown as UseFormSetValue<{ vinConfigs: VinConfigValues[] }>}
      />
    </Box>
  );
}

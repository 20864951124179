import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Drawer,
  Grid,
  LinearProgress,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import { OverlayContract } from './OverlayContract';
import { OverlayDissolveValues } from './OverlayDissolveValues';
import { OverlayVehicle } from './OverlayVehicle';
import { OverlayDocuments } from './OverlayDocuments';
import RestHttpClient from '../../../common/RestHttpClient';
import { GetDetailResponse, ReportEntry } from '../../../generated/ApiClient';
import { formatToEuro } from '../../deals/new/finances/FinancesCalculationList';
import { Icons } from '../../../component/icons/Icons';
import { pushNotificationHandler } from '../../../common/PushNotification';
import { TypeCertificateForm } from '../typeCertificate/TypeCertificateForm';
import { useQuery } from 'react-query';

interface RightDrawerProps {
  isDrawerOpen: boolean;
  onClose: () => void;
  cmsId?: string;
  showExtend?: boolean;
}

const isCredit = (detail?: GetDetailResponse) => {
  return detail?.contract.financingType?.toLowerCase().includes('kredit');
};

export function DashboardApplicationOverlay({
  isDrawerOpen,
  onClose,
  cmsId,
  showExtend,
}: RightDrawerProps) {
  const { t } = useTranslation();

  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState<GetDetailResponse | undefined>(undefined);
  const [reportData, setReportData] = useState<ReportEntry | undefined>(undefined);

  const fetchDetails = async () => {
    if (!cmsId) return;
    try {
      setLoading(true);
      const { data } = await RestHttpClient.getDetail(cmsId);
      setDetailData(data);

      if (!isCredit(data)) {
        const reportResponse = await RestHttpClient.getReport(cmsId);
        setReportData(reportResponse.data);
      } else {
        setReportData(undefined);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchTypeCertificate = async () => {
    if (cmsId && detailData?.dealId) {
      return await RestHttpClient.getTypeCertificate(detailData.dealId);
    }

    return undefined;
  };

  const { data: typeCertificateData } = useQuery({
    queryKey: ['typeCertificate', cmsId, detailData?.dealId],
    queryFn: () => fetchTypeCertificate(),
  });

  useEffect(() => {
    if (isDrawerOpen && cmsId) {
      setDetailData(undefined);
      setActive(0);
      fetchDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpen, cmsId]);

  const handleSidebarClick = (i: number) => () => {
    setActive(i);
  };

  const handleExtendClick = async () => {
    if (detailData?.dealId) {
      RestHttpClient.extend(detailData.contractId, detailData?.dealId).then((_) => {
        pushNotificationHandler.publish(t('dashboard.contractsOverlay.extendedSuccess'), 'success');
      });
    }
  };

  const invalidDealId = !detailData?.dealId;

  return (
    <Drawer
      PaperProps={{
        sx: { height: '100vh', width: '65vw', position: 'fixed', bottom: 0, right: 0, top: 0 },
      }}
      anchor="right"
      open={isDrawerOpen}
      onClose={onClose}
    >
      <div className="dashboard-overlay">
        {loading && <LinearProgress />}
        <Grid container height="100%">
          <Grid item xs={2}>
            <Box className="sidebar">
              <List>
                <ListItemButton selected={active === 0} onClick={handleSidebarClick(0)}>
                  <ListItemText primary={t('dashboard.contractsOverlay.contract.label')} />
                </ListItemButton>
                {!isCredit(detailData) && (
                  <ListItemButton selected={active === 1} onClick={handleSidebarClick(1)}>
                    <ListItemText primary={t('dashboard.contractsOverlay.dissolveValues.label')} />
                  </ListItemButton>
                )}
                <ListItemButton selected={active === 2} onClick={handleSidebarClick(2)}>
                  <ListItemText primary={t('dashboard.contractsOverlay.vehicle.label')} />
                </ListItemButton>

                <ListItemButton selected={active === 3} onClick={handleSidebarClick(3)}>
                  <ListItemText primary={t('dashboard.contractsOverlay.documents.label')} />
                </ListItemButton>

                {typeCertificateData && (
                  <ListItemButton selected={active === 4} onClick={handleSidebarClick(4)}>
                    <ListItemText primary={t('dashboard.contractsOverlay.typeCertificate.label')} />
                  </ListItemButton>
                )}
              </List>
            </Box>
          </Grid>

          <Grid item xs={10}>
            <Box className="contracts-content">
              {!invalidDealId && (
                <h2>
                  #{cmsId}/{detailData?.dealId} - {detailData?.contract.customerName}{' '}
                </h2>
              )}
              {invalidDealId && (
                <h2>
                  #{cmsId} - {detailData?.contract.customerName}{' '}
                </h2>
              )}
              <div className="line" />

              {active === 0 && <OverlayContract cmsId={cmsId} contract={detailData?.contract} />}
              {active === 1 && (
                <OverlayDissolveValues
                  dissolve={detailData?.disolve}
                  vehicle={detailData?.vehicle}
                  report={reportData}
                />
              )}
              {active === 2 && <OverlayVehicle vehicle={detailData?.vehicle} />}
              {active === 3 && (
                <OverlayDocuments
                  dealId={detailData?.dealId}
                  documentDetail={detailData?.document}
                />
              )}
              {active === 4 && detailData?.dealId && typeCertificateData && (
                <TypeCertificateForm
                  dealId={detailData.dealId}
                  contractId={detailData.contractId}
                  data={typeCertificateData.data}
                />
              )}
            </Box>
          </Grid>
        </Grid>

        <div className="footer">
          <Button color="secondary" variant="outlined" onClick={onClose}>
            {t('dashboard.contractsOverlay.cancel')}
          </Button>

          <div className="kpi-container">
            <h2>{formatToEuro(detailData?.contract.prepayment)}</h2>
            <span>{t('dashboard.contractsOverlay.contract.downPayment')}</span>
          </div>

          <div className="kpi-container">
            <h2>
              {t('dashboard.contractsOverlay.contract.months', {
                amount: detailData?.contract.duration,
              })}
            </h2>
            <span>{t('dashboard.contractsOverlay.contract.term')}</span>
          </div>

          <div className="kpi-container">
            <h2>
              {`${
                detailData?.contract.mileage
                  ? detailData?.contract.mileage.toLocaleString('de-DE')
                  : '-'
              } km`}
            </h2>
            <span>{t('dashboard.contractsOverlay.contract.mileage')}</span>
          </div>

          <div className="kpi-container">
            <h2>{formatToEuro(detailData?.contract.rate)}</h2>
            <span>
              {detailData?.contract.isNet
                ? `${t('dashboard.contractsOverlay.rate')} ${t('price.exclVat')}`
                : t('dashboard.contractsOverlay.rate')}
            </span>
          </div>
          <Stack flexDirection="row" gap={2}>
            {showExtend && detailData?.contract.allowExtend && (
              <Button onClick={handleExtendClick} color="secondary" variant="outlined">
                {t('dashboard.contractsOverlay.extend')}
              </Button>
            )}

            {invalidDealId ? (
              <Tooltip
                disableFocusListener
                title={`${t('dashboard.contractsOverlay.invalidDealId')}: "${
                  detailData?.dealReference ?? ''
                }"`}
              >
                {Icons.alert()}
              </Tooltip>
            ) : (
              <Button
                onClick={() => {
                  window.open(`${window.location.origin}/deals/${detailData?.dealId}`, '_blank');
                }}
                color="primary"
                variant="contained"
              >
                {t('dashboard.contractsOverlay.toDeal')}
              </Button>
            )}
          </Stack>
        </div>
      </div>
    </Drawer>
  );
}

import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { pushNotificationHandler } from '../../../../common/PushNotification';
import RestHttpClient from '../../../../common/RestHttpClient';
import { CompareResult } from '../../../../generated/ApiClient';
import { EnhancedDialog } from '../../../../component/EnhancedDialog';

interface Props {
  dealId: number;
  compareResults: CompareResult[];
  onClose?: (isCanceled: boolean) => void;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const CompareDealWithMainCustomerDialog = ({
  dealId,
  compareResults,
  onClose,
  isOpen,
  setOpen,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const handleClose = (isCanceled: boolean) => {
    if (onClose != null) onClose(isCanceled);
  };

  const handleOverwrite = (updateDealCustomerWithMainCustomer: boolean) => {
    if (!updateDealCustomerWithMainCustomer) {
      handleClose(false);
      return;
    }

    setLoading(true);
    RestHttpClient.updateDealCustomerWithMainCustomer(dealId)
      .then((updateDealCustomerWithMainCustomerResponse) => {
        if (updateDealCustomerWithMainCustomerResponse.data.success) {
          pushNotificationHandler.publish(
            t('deals.edit.compareDealWithMainCustomer.dealCustomerDataUpdated'),
            'success'
          );
        } else {
          pushNotificationHandler.publish(
            t('deals.edit.compareDealWithMainCustomer.dealCustomerDataUpdateError'),
            'error'
          );
        }
        handleClose(!updateDealCustomerWithMainCustomerResponse.data.success);
      })
      .catch((err) => {
        pushNotificationHandler.publish(
          t('deals.edit.compareDealWithMainCustomer.dealCustomerDataUpdateError'),
          'error'
        );
        handleClose(true);
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <EnhancedDialog width="lg" isDialogOpened={isOpen} setIsDialogOpened={setOpen}>
      <DialogTitle>
        {t('deals.edit.compareDealWithMainCustomer.overwriteDealCustomerData')}
      </DialogTitle>

      <DialogContent dividers>
        <Alert severity="warning">{t('deals.edit.compareDealWithMainCustomer.remark')}</Alert>
        <TableContainer sx={{ mt: 2, mb: 2, maxHeight: 'calc(90vh - 300px)' }} component={Paper}>
          <Table sx={{ minWidth: 650, height: 'max-content' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('deals.edit.compareDealWithMainCustomer.property')}</TableCell>
                <TableCell>
                  {t('deals.edit.compareDealWithMainCustomer.businessCustomersData')}
                </TableCell>
                <TableCell>
                  {t('deals.edit.compareDealWithMainCustomer.customerMasterData')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {compareResults.map((compareResult, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {t(
                      'deals.edit.compareDealWithMainCustomer.props.' + compareResult.propertyName
                    )}
                  </TableCell>
                  <TableCell>{compareResult.dealCustomerValue}</TableCell>
                  <TableCell>{compareResult.mainCustomerValue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Box sx={{ textAlign: 'right' }}>
          <Button
            onClick={() => handleClose(true)}
            sx={{ border: 1, borderColor: 'black', color: 'black', marginRight: '4px' }}
          >
            {t('back')}
          </Button>
          <Button
            onClick={() => handleOverwrite(true)}
            variant="contained"
            sx={{ marginRight: '4px' }}
            disabled={loading}
          >
            {t('deals.edit.compareDealWithMainCustomer.overwrite')}
          </Button>
          <Button onClick={() => handleOverwrite(false)} variant="contained" disabled={loading}>
            {t('deals.edit.compareDealWithMainCustomer.notOverwrite')}
          </Button>
        </Box>
      </DialogActions>
    </EnhancedDialog>
  );
};

import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export function NewCustomerHeader() {
  const { t } = useTranslation();

  return (
    <Box className="view-header">
      <h2>{t('customers.newCustomer')}</h2>
      <Box className="button-container">
        <Button component={Link} to="/customers" color="secondary" variant="outlined">
          {t('cancel')}
        </Button>
        <Button type="submit" variant="contained" form="new-customer-form">
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
}

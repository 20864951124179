import { Fee } from '../../../../../../generated/ApiClient';
import { useTranslation } from 'react-i18next';
import { HeadCell } from '../../../../../../common/table/EnhancedTableHead';
import { EnhancedTable } from '../../../../../../common/table/EnhancedTable';
import { NumberFormatter } from '../../../../../../common/formatter/NumberFormatter';

interface Props {
  fees: Fee[];
}

interface FeeRow {
  id: string;
  feeType: string;
  calculatedFeeAbsolute: string;
}

const ExtendedConfigurationFees = ({ fees }: Props) => {
  const { t } = useTranslation();

  const columns: readonly HeadCell<FeeRow>[] = [
    {
      id: 'feeType',
      disablePadding: false,
      align: 'left',
      label: t(
        'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.feeTypeColumn'
      ),
    },
    {
      id: 'calculatedFeeAbsolute',
      disablePadding: false,
      align: 'left',
      label: t(
        'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.feeValueColumn'
      ),
    },
  ];

  const mapFeeToRow = (fee: Fee, index: number): FeeRow => {
    const getFeeTypeString = (feeType: number): string => {
      switch (feeType) {
        case 1:
          return t(
            'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.processingFee'
          );
        case 2:
          return t(
            'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.legalTransactionFee'
          );
        default:
          return '-';
      }
    };
    return {
      id: `${index}`,
      feeType: getFeeTypeString(fee.feeTypeId),
      calculatedFeeAbsolute: NumberFormatter.format(fee.calculatedFeeAbsolute) + ' €',
    };
  };

  return (
    <EnhancedTable
      rows={fees.map(mapFeeToRow)}
      variant="default"
      headCells={columns}
      hideCheckbox={true}
      minWidth={200}
    />
  );
};

export default ExtendedConfigurationFees;

import { Controller } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { FormComponentProps } from './FormComponentProps';
import { FieldValues } from 'react-hook-form/dist/types';
import { Icons } from '../icons/Icons';

interface Props<TFieldValues> extends FormComponentProps<TFieldValues> {
  withIcon?: boolean;
  endAdornment?: JSX.Element;
  shrink?: boolean;
  transform?: (input: string) => string | null;
  readOnly?: boolean;
}

export const FormTextField = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  placeholder,
  className,
  withIcon,
  type,
  shrink,
  endAdornment,
  transform,
  readOnly,
  onChange,
  ...others
}: Props<TFieldValues> & TextFieldProps) => {
  let ea: JSX.Element;
  if (withIcon) {
    ea = Icons.search();
  } else if (endAdornment) {
    ea = endAdornment;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          label={label}
          error={!!error}
          placeholder={placeholder}
          className={className}
          fullWidth={true}
          variant="standard"
          type={type}
          sx={
            others.required && !error && !others.disabled
              ? {
                  '& .MuiInput-underline:not(.Mui-focused):before': {
                    borderBottom: '2px solid black',
                  },
                  '& .MuiInput-underline:not(.Mui-focused):after': {
                    borderBottom: '2px solid black',
                  },
                }
              : undefined
          }
          InputProps={{
            endAdornment: ea,
            readOnly,
          }}
          helperText={error ? error.message : ''}
          InputLabelProps={{ shrink: shrink }}
          {...field}
          value={field.value || ''}
          onChange={(event) => {
            if (transform) {
              // @ts-ignore
              event.target.value = transform(event.target.value);
            }
            field.onChange(event);
            if (onChange) {
              onChange(event);
            }
          }}
          {...others}
        />
      )}
    />
  );
};

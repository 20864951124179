import React, { useEffect } from 'react';
import '../themes/app.scss';
import { SignInState } from '../common/auth/OidcConfig';
import { Header } from '../common/header/Header';
import { Box } from '@mui/material';
import { RouteConfig } from './RouteConfig';
import { createBrowserHistory } from 'history';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { usePermissions } from '../common/auth/PermissionsContext';
import { enqueueNotification, getRelevantNotifcations, isShowLoginMessage, updateLastLoginMessage, useNotificationsBase } from '../common/global/useNotifications';
import { NotificationLocation } from '../generated/ApiClient';

export const history = createBrowserHistory({ window });

function App() {
  const auth = useAuth();
  const permissions = usePermissions();

  const {notifications, onClose} = useNotificationsBase();

  React.useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      process.env.NODE_ENV !== 'development'
    ) {
      const signInState: SignInState = {
        redirectUri: window.location.pathname,
      };
      auth.signinRedirect({
        state: signInState,
      });
    }
    if (auth.isAuthenticated || process.env.NODE_ENV === 'development') {
      permissions.load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if (notifications.length && auth.isAuthenticated && isShowLoginMessage()) {
      const relevantNotifications = getRelevantNotifcations(notifications, NotificationLocation.Login);
      relevantNotifications.forEach(x => enqueueNotification(x, onClose));

      updateLastLoginMessage();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications.length, auth]);


  React.useEffect(() => {
    auth.events.addSilentRenewError(() => {
      auth.signoutRedirect();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (process.env.NODE_ENV !== 'development' && !auth.isAuthenticated) {
    return <></>;
  }
  return (
    // @ts-ignore

    <Box height="100vh" display="flex" flexDirection="column" className="App">
      <Header />
      <main>
        <RouteConfig />
      </main>
    </Box>
  );
}

export default App;

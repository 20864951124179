/* eslint-disable eqeqeq */
import { Box, FormLabel, Grid } from '@mui/material';
import { t } from 'i18next';
import { Control, UseFormSetValue } from 'react-hook-form';
import { FormCheckbox } from '../../../component/form/FormCheckbox';
import { FormDatePicker } from '../../../component/form/FormDatePicker';
import { FormDropdown, OptionItem } from '../../../component/form/FormDropdown';
import { FormRadioButton } from '../../../component/form/FormRadioButton';
import { FormTextField } from '../../../component/form/FormTextField';
import { getDateBefore } from '../../../common/validation/Validations';
import { PrivateCustomerFormValues } from '../data/PrivateCustomerFormValues';
import { useWatch } from 'react-hook-form';
import { FormNumberInput } from '../../../component/form/FormNumberInput';
import { useEffect } from 'react';

interface Props {
  control: Control<PrivateCustomerFormValues>;
  setValue: UseFormSetValue<PrivateCustomerFormValues>;
  dropdownValues: { [name: string]: OptionItem[] };
  isNew?: boolean;
  readonly: boolean;
}

export function PersonalData({ control, dropdownValues, isNew, readonly, setValue }: Props) {
  const gridCol = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 3,
    xl: 3,
  };

  const ksvId = useWatch({ control, name: 'ksvId' });
  const birthDate = useWatch({ control, name: 'birthDate' });
  const additionalPhoneNumber = useWatch({ control, name: 'additionalPhoneNumber' });
  const numberOfSupportedChildren = useWatch({ control, name: 'numberOfSupportedChildren' });
  const numberOfChildrenUnderEighteen = useWatch({
    control,
    name: 'numberOfChildrenUnderEighteen',
  });
  const dataInformationSheetTransferred = useWatch({
    control,
    name: 'dataInformationSheetTransferred',
  });
  const isCoApplicant = useWatch({
    control,
    name: 'isCoApplicant',
    defaultValue: false,
  });

  useEffect(() => {
    if (!numberOfSupportedChildren) {
      setValue('numberOfChildrenUnderEighteen', 0);
      setValue('numberOfChildrenUnderEighteen', undefined);
    } else if (
      numberOfChildrenUnderEighteen &&
      numberOfSupportedChildren < numberOfChildrenUnderEighteen
    ) {
      setValue('numberOfChildrenUnderEighteen', numberOfSupportedChildren);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfSupportedChildren, numberOfChildrenUnderEighteen]);

  return (
    <Grid container spacing={4} className="personal-data">
      <Grid item {...gridCol}>
        <FormDropdown
          control={control}
          name="salutationId"
          label={t('customers.edit.personalData.salutation')}
          options={dropdownValues.salutations}
          required
          disabled={readonly}
        />

        <FormDropdown
          control={control}
          name="titleId"
          label={t('customers.edit.personalData.title')}
          options={dropdownValues.titles}
          emptyText="-"
          disabled={readonly}
        />

        <FormDropdown
          control={control}
          name="titleInternationalId"
          label={t('customers.edit.personalData.titleInternational')}
          options={dropdownValues.titlesInternational}
          emptyText="-"
          disabled={readonly}
        />

        <FormTextField
          control={control}
          name="firstName"
          label={t('customers.edit.personalData.firstName') + ' *'}
          required
          disabled={ksvId != undefined || readonly}
        />

        <FormTextField
          control={control}
          name="lastName"
          label={t('customers.edit.personalData.lastName') + ' *'}
          required
          disabled={ksvId != undefined || readonly}
        />

        <FormDatePicker
          control={control}
          maxDate={getDateBefore(18)}
          name="birthDate"
          label={t('customers.edit.personalData.birthDate') + ' *'}
          required
          disabled={(ksvId != undefined && birthDate != undefined) || readonly}
        />

        <FormDropdown
          control={control}
          name="maritalStatusId"
          label={t('customers.edit.personalData.maritalStatus')}
          options={dropdownValues.maritalStatus}
          emptyText="-"
          required
          disabled={readonly}
        />

        <FormDropdown
          control={control}
          name="citizenshipId"
          label={t('customers.edit.personalData.citizenship')}
          options={dropdownValues.countries}
          emptyText="-"
          required
          disabled={readonly}
        />

        {ksvId && (
          <FormTextField
            control={control}
            name="ksvId"
            label={t('customers.edit.personalData.ksvId')}
            disabled={true}
          />
        )}
      </Grid>
      <Grid item {...gridCol}>
        <FormTextField
          control={control}
          name="addressStreet"
          label={t('customers.edit.personalData.addressStreet')}
          disabled={readonly}
          required
        />

        <FormTextField
          control={control}
          name="addressHouseNumber"
          label={t('customers.edit.personalData.addressHouseNumber')}
          disabled={readonly}
          required
        />

        <FormTextField
          control={control}
          name="addressPostalCode"
          transform={(s) => s.replace(/\D/g, '')}
          label={t('customers.edit.personalData.addressPostalCode')}
          disabled={readonly}
          required
        />

        <FormTextField
          control={control}
          name="addressCity"
          label={t('customers.edit.personalData.addressCity')}
          disabled={readonly}
          required
        />

        <FormDropdown
          control={control}
          name="addressCountryId"
          label={t('customers.edit.personalData.addressCountry')}
          options={dropdownValues.countries}
          required
          disabled={readonly}
        />

        <FormTextField
          control={control}
          name="phoneNumber"
          required={
            additionalPhoneNumber === null ||
            additionalPhoneNumber === undefined ||
            additionalPhoneNumber === ''
          }
          label={t('customers.edit.personalData.phoneNumber')}
          disabled={readonly}
        />

        <FormTextField
          control={control}
          name="additionalPhoneNumber"
          label={t('customers.edit.personalData.additionalPhoneNumber')}
          disabled={readonly}
        />

        <FormTextField
          control={control}
          name="emailAddress"
          label={t('customers.edit.personalData.email')}
          required
          disabled={readonly}
        />
      </Grid>
      <Grid item {...gridCol}>
        <FormNumberInput
          control={control}
          name="numberOfSupportedChildren"
          label={t('customers.edit.personalData.numberOfSupportedChildren')}
          required
          disabled={readonly}
        />

        <FormNumberInput
          control={control}
          name="numberOfChildrenUnderEighteen"
          label={t('customers.edit.personalData.numberOfChildrenUnderEighteen')}
          disabled={
            readonly ||
            numberOfSupportedChildren === null ||
            numberOfSupportedChildren === undefined ||
            numberOfSupportedChildren == 0
          }
        />

        <FormDatePicker
          maxDate={getDateBefore(0)}
          control={control}
          name="residencePermitValidSince"
          label={t('customers.edit.personalData.residencePermitValidSince')}
          disabled={readonly}
        />

        <FormDatePicker
          control={control}
          name="residencePermitValidTill"
          label={t('customers.edit.personalData.residencePermitValidTill')}
          disabled={readonly}
        />

        <FormDatePicker
          maxDate={getDateBefore(0)}
          control={control}
          name="inAustriaSince"
          label={t('customers.edit.personalData.inAustriaSince')}
          disabled={readonly}
        />

        <FormDatePicker
          maxDate={getDateBefore(0)}
          control={control}
          name="workPermitValidSince"
          label={t('customers.edit.personalData.workPermitValidSince')}
          disabled={readonly}
        />

        <FormDatePicker
          control={control}
          name="workPermitValidTill"
          label={t('customers.edit.personalData.workPermitValidTill')}
          disabled={readonly}
        />
      </Grid>
      <Grid item {...gridCol} className="split-column">
        <Box>
          <FormTextField
            control={control}
            name="bankAccountIBAN"
            label={t('customers.edit.personalData.bankAccountIBAN')}
            disabled={readonly}
          />

          <FormTextField
            control={control}
            name="bankAccountBIC"
            label={t('customers.edit.personalData.bankAccountBIC')}
            disabled={readonly}
          />
          <FormDropdown
            control={control}
            name="pepSpecificationId"
            label={t('customers.edit.personalData.pepSpecification')}
            options={dropdownValues.pepSpecifications}
            disabled={readonly}
            required
          />

          {isCoApplicant && (
            <FormRadioButton
              required
              control={control}
              name="isApplicantSpouse"
              label={t('customers.edit.personalData.isApplicantSpouse')}
              disabled={readonly}
            />
          )}
        </Box>

        <Box className="consent-form">
          <Box>
            <FormLabel>{t('customers.edit.personalData.marketingConsent')}</FormLabel>
            <FormRadioButton
              required
              control={control}
              name="emailMarketingAggreed"
              label={t('customers.edit.personalData.emailMarketingAggreed')}
              disabled={readonly}
            />
            <FormRadioButton
              required
              control={control}
              name="phoneMarketingAggreed"
              label={t('customers.edit.personalData.phoneMarketingAggreed')}
              disabled={readonly}
            />
            <FormRadioButton
              required
              control={control}
              name="postalMarketingAggreed"
              label={t('customers.edit.personalData.postalMarketingAggreed')}
              disabled={readonly}
            />
          </Box>

          {(isNew || !dataInformationSheetTransferred) && (
            <FormCheckbox
              control={control}
              name="dataInformationSheetTransferred"
              label={t('customers.edit.personalData.dataInformationSheetTransferred') + '*'}
              disabled={readonly}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

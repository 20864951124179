import { Box, BoxProps } from '@mui/material';
import { FormEvent, useEffect, useState } from 'react';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import RestHttpClient from '../../../../../common/RestHttpClient';
import { EnhancedDialog } from '../../../../../component/EnhancedDialog';
import { OptionItem } from '../../../../../component/form/FormDropdown';
import {
  ApiconPriceFormUiState,
  ApiconSpecialCasePriceFormState,
  ServiceOption,
} from '../../../../../generated/ApiClient';
import { FinancesDrawerFormValues, SpecialCaseValues } from '../RightDrawerSchema';
import { FinancesServiceCalculation } from './FinancesServiceCalculation';
import { FinancesServiceFuel } from './FinancesServiceFuel';
import { FinancesServiceParameter } from './FinancesServiceParameter';
import { FinancesServicesOptions } from './FinancesServicesOptions';
import { FinancesServiceVariant, ServiceVariant } from './FinancesServiceVariant';
import { FinancesSpecialCasePrice } from './FinancesSpecialCasePrice';

interface Props extends BoxProps {
  control: Control<FinancesDrawerFormValues>;
  getValues: UseFormGetValues<FinancesDrawerFormValues>;
  setValue: UseFormSetValue<FinancesDrawerFormValues>;
  loading: boolean;
  serviceVariantsLoading: boolean;
  readonly?: boolean;
  isMileageReadonly: boolean;
  isDurationReadonly: boolean;
  dealId?: number;
  calculationId?: number;
  fuelServiceProducts: OptionItem[];
  serviceProducts: OptionItem[];
  productIndusties: OptionItem[];
  productUsages: OptionItem[];
  campaigns: OptionItem[];
  productOptions: ServiceOption[];
  technicalOptions: ServiceOption[];
  serviceVariants: ServiceVariant[];
  serviceVariantId: number | undefined;
  onRelevantChange: (name: string, value: unknown) => void;
  onRequestServices: () => void;
  onCalculateServicePrice: () => void;
  onSaveServiceVariant: (event: FormEvent, isServiceVariantSaveRequest: boolean) => void;
  onCreateProposal: () => void;
  onRequestSpecialCasePrice: (values: SpecialCaseValues) => Promise<void>;
  onCompleteSpecialCasePrice: () => void;
}

export const FinancesServices = ({
  control,
  getValues,
  setValue,
  loading,
  serviceVariantsLoading,
  readonly,
  isDurationReadonly,
  isMileageReadonly,
  dealId,
  calculationId,
  fuelServiceProducts,
  serviceProducts,
  productUsages,
  productIndusties,
  campaigns,
  productOptions,
  technicalOptions,
  serviceVariants,
  serviceVariantId,
  onRelevantChange,
  onRequestServices,
  onCalculateServicePrice,
  onSaveServiceVariant,
  onCreateProposal,
  onRequestSpecialCasePrice,
  onCompleteSpecialCasePrice,
  ...others
}: Props) => {
  const [mileages, setMileages] = useState<OptionItem[]>([]);
  const [isFinancesSpecialCasePriceOpened, setIsFinancesSpecialCasePriceOpened] = useState(false);

  useEffect(() => {
    const milageId = getValues().service.mileageId;
    if (milageId && milageId > 0)
      RestHttpClient.getMileages(milageId).then((res) => setMileages(res.data));
    // eslint-disable-next-line
  }, []);

  const handleFinancesSpecialCasePriceClosed = () => setIsFinancesSpecialCasePriceOpened(false);
  const onSpecialCasePriceButtonClicked = () => setIsFinancesSpecialCasePriceOpened(true);
  const handleOnRequestSpecialCasePrice = async (values: SpecialCaseValues) => {
    await onRequestSpecialCasePrice(values);
    handleFinancesSpecialCasePriceClosed();
  };

  return (
    <Box {...others}>
      <FinancesServiceParameter
        control={control}
        getValues={getValues}
        loading={loading}
        readonly={readonly}
        isDurationReadonly={isDurationReadonly}
        isMileageReadonly={isMileageReadonly}
        mileages={mileages.map((value) => ({
          value: value.value,
          label: Number(value.label).toLocaleString('de-DE'),
        }))}
        onRequestServices={onRequestServices}
        sx={{ m: 2 }}
      />
      <FinancesServiceFuel
        control={control}
        loading={loading}
        readonly={readonly}
        fuelServiceProducts={fuelServiceProducts}
        onRelevantChange={onRelevantChange}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pl: 2, pr: 3 }}>
        <FinancesServicesOptions
          control={control}
          getValues={getValues}
          setValue={setValue}
          loading={loading}
          readonly={readonly}
          serviceProducts={serviceProducts}
          productIndusties={productIndusties}
          productUsages={productUsages}
          campaigns={campaigns}
          productOptions={productOptions}
          technicalOptions={technicalOptions}
        />
        <FinancesServiceCalculation
          control={control}
          getValues={getValues}
          loading={loading}
          readonly={readonly}
          dealId={dealId}
          calculationId={calculationId}
          serviceVariants={serviceVariants}
          serviceVariantId={serviceVariantId}
          specialCasePriceFormState={
            getValues().service.apiconSpecialCase?.priceFormState ??
            ApiconSpecialCasePriceFormState.SpecialCaseRequestFinished
          }
          onCalculateServicePrice={onCalculateServicePrice}
          onSaveServiceVariant={onSaveServiceVariant}
          onSpecialCasePriceButtonClicked={onSpecialCasePriceButtonClicked}
          onCompleteSpecialCasePrice={onCompleteSpecialCasePrice}
        />
      </Box>
      <FinancesServiceVariant
        control={control}
        getValues={getValues}
        setValue={setValue}
        loading={loading}
        serviceVariantsLoading={serviceVariantsLoading}
        readonly={readonly}
        dealId={dealId}
        serviceVariants={serviceVariants}
        onCreateProposal={onCreateProposal}
      />
      <EnhancedDialog
        maxWidth="md"
        setIsDialogOpened={setIsFinancesSpecialCasePriceOpened}
        isDialogOpened={isFinancesSpecialCasePriceOpened}
      >
        <FinancesSpecialCasePrice
          isFinancesSpecialCasePriceOpened={isFinancesSpecialCasePriceOpened}
          loading={loading}
          readonly={readonly}
          serviceVariantId={getValues().service.serviceVariantId ?? undefined}
          specialCaseValues={{
            priceFormState:
              getValues().service.apiconSpecialCase?.priceFormState ??
              ApiconSpecialCasePriceFormState.Initial,
            priceFormUiState:
              getValues().service.apiconSpecialCase?.priceFormUiState ??
              ApiconPriceFormUiState.Initial,
            priceNet: getValues().service.apiconSpecialCase?.priceNet ?? null,
            priceGross: getValues().service.apiconSpecialCase?.priceGross ?? null,
            vatAsValue: getValues().service.apiconSpecialCase?.vatAsValue ?? null,
            comment: getValues().service.apiconSpecialCase?.comment ?? null,
            phone: getValues().service.apiconSpecialCase?.phone ?? null,
            email: getValues().service.apiconSpecialCase?.email ?? null,
            answer: getValues().service.apiconSpecialCase?.answer ?? null,
            offerId: getValues().service.apiconSpecialCase?.offerId ?? null,
            specialCaseId: getValues().service.apiconSpecialCase?.specialCaseId ?? null,
          }}
          onClose={handleFinancesSpecialCasePriceClosed}
          onRequestSpecialCasePrice={handleOnRequestSpecialCasePrice}
        />
      </EnhancedDialog>
    </Box>
  );
};

import { useState } from 'react';
import { CustomerType } from '../../../generated/ApiClient';
import { NewCommercialCustomerForm } from './NewCommercialCustomerForm';
import { NewCustomerHeader } from './NewCustomerHeader';
import { NewCustomerTypeDropdown } from './NewCustomerTypeDropdown';
import { NewPrivateCustomerForm } from './NewPrivateCustomerForm';

export function NewCustomer() {
  const [selectedType, setSelectedType] = useState<CustomerType>(CustomerType.Private);

  return (
    <div className="new-customer">
      <NewCustomerHeader />
      <NewCustomerTypeDropdown onChange={setSelectedType} value={selectedType} />
      {selectedType === CustomerType.Private && <NewPrivateCustomerForm />}
      {selectedType === CustomerType.Commercial && <NewCommercialCustomerForm />}
    </div>
  );
}

import React, { Dispatch, SetStateAction, useState } from 'react';
import { CustomerSelection } from '../../new/customer/CustomerSelection';
import { Box, CircularProgress, Dialog, Stack } from '@mui/material';

export function ChangeCustomerDialog({
  customerTypeId,
  customerId,
  isOpen,
  setIsOpen,
  handleSelect,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleSelect: (id: string) => Promise<void>;
  customerTypeId: number;
  customerId?: number;
}) {

  return (
    <Dialog open={isOpen} maxWidth="lg" onClose={() => setIsOpen(false)}>
      <Box sx={{ paddingX: 2 }}>
          <CustomerSelection
            customerTypeId={customerTypeId}
            onRowClick={handleSelect}
            excludeCustomerIds={customerId ? [customerId] : []}
            includePotentialBuyer
          />
      </Box>
    </Dialog>
  );
}

import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import { usePermissions } from '../../common/auth/PermissionsContext';

interface Props {
  setHandoverOpen: Dispatch<SetStateAction<boolean>>;
}

export function DealHeader({ setHandoverOpen }: Props) {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canCreate = hasPermission('Deals', 'Create');
  const hasHandoverDealsPermission =
    hasPermission('HandoverDeals', 'View') || hasPermission('Unrestricted', 'Impersonate');

  return (
    <Box className="view-header">
      <h2>{t('deals.header')}</h2>
      <Box className="button-container">
        {hasHandoverDealsPermission && (
          <Button color="secondary" variant="outlined" onClick={() => setHandoverOpen(true)}>
            {t('deals.acquire')}
          </Button>
        )}
        {canCreate && (
          <Button component={Link} to="new" variant="contained">
            {t('deals.newDeal')}
          </Button>
        )}
      </Box>
    </Box>
  );
}

import { Box, Divider, FormLabel } from '@mui/material';
import { Control, useWatch } from 'react-hook-form';
import { CommercialCustomerFormValues } from '../data/CommercialCustomerFormValues';
import { FormTextField } from '../../../component/form/FormTextField';
import { FormCheckbox } from '../../../component/form/FormCheckbox';
import { t } from 'i18next';

interface Props {
  control: Control<CommercialCustomerFormValues>;
  isNew?: boolean;
  actions?: JSX.Element;
}
export const PotentialCommercialCustomerForm = ({ control, isNew, actions }: Props) => {
  const dataInformationSheetTransferred = useWatch({
    control,
    name: 'dataInformationSheetTransferred',
  });

  return (
    <Box
      className="potential-customer-form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: 'unset' },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        {actions}
      </Box>
      <FormTextField
        control={control}
        label={t('customers.edit.entryContact.companyName')}
        name="companyName"
        required
      />
      <FormTextField
        control={control}
        label={t('customers.edit.entryContact.addressPostalCode') + ' **'}
        name="addressPostalCode"
        transform={(s) => s.replace(/\D/g, '')}
      />
      {(isNew || !dataInformationSheetTransferred) && (
        <FormCheckbox
          control={control}
          name="dataInformationSheetTransferred"
          label={t('customers.edit.entryContact.dataInformationSheetTransferred') + '*'}
        />
      )}

      <Divider />
      <Box sx={{ flexDirection: 'column', display: 'flex', marginTop: '20px' }}>
        <FormLabel>{t('customers.edit.legend.mandatory')}</FormLabel>
        <FormLabel>{t('customers.edit.legend.insurance')}</FormLabel>
      </Box>
    </Box>
  );
};

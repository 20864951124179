import { Box, Grid, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormNumberInput } from '../../../../../../component/form/FormNumberInput';
import { Control } from 'react-hook-form';
import { FinancesDrawerFormValues } from '../../RightDrawerSchema';
import React from 'react';
import { FormPriceInput } from '../../../../../../component/form/FormPriceInput';
import { usePermissions } from '../../../../../../common/auth/PermissionsContext';

interface Props {
  control: Control<FinancesDrawerFormValues>;
  isIncludeVat: boolean;
  vatFree: boolean;
}

const ExtendedConfigurationRate = ({ control, isIncludeVat, vatFree }: Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  return (
    <Grid container spacing={3} sx={{ '& .MuiFormControl-root': { my: 1 } }}>
      <Grid item xs={4}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FormNumberInput
            label={t(
              'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.fundingLifetimeMin'
            )}
            name="fundingLifetimeMin"
            control={control}
            disabled
          />
          <FormNumberInput
            label={t(
              'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.fundingLifetimeMax'
            )}
            name="fundingLifetimeMax"
            control={control}
            disabled
          />
          <FormNumberInput
            label={t(
              'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.prepaymentMin'
            )}
            name="prepaymentMin"
            fixedDecimalScale
            control={control}
            disabled
          />
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ my: 1 }}>
            <FormPriceInput
              pricesIncludingVAT={isIncludeVat}
              vatFree={vatFree}
              label={t(
                'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.totalAmountPayable'
              )}
              name="totalAmountPayable"
              control={control}
              disabled
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              gap: 1,
            }}
          >
            {hasPermission('Commissions', 'View') && (
              <>
                <FormNumberInput
                  control={control}
                  name="commissionInPercent"
                  label={t(
                    'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.commissionInPercent'
                  )}
                  sx={{ width: '30%', mr: 0 }}
                  endAdornment={<span>%</span>}
                  fixedDecimalScale
                  shrink
                  disabled
                />

                <FormPriceInput
                  pricesIncludingVAT={isIncludeVat}
                  vatFree={vatFree}
                  control={control}
                  name="commissionInNumbers"
                  label={t(
                    'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.commissionInNumbers'
                  )}
                  sx={{ width: '70%', mr: 0 }}
                  shrink
                  disabled
                />
              </>
            )}
          </Box>
          {hasPermission('ProcessingFees', 'View') && (
            <Box sx={{ my: 1 }}>
              <FormPriceInput
                pricesIncludingVAT={isIncludeVat}
                vatFree={vatFree}
                control={control}
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.processingFee'
                )}
                name="processingFee"
                shrink
                disabled
              />
            </Box>
          )}
          {hasPermission('LegalTransactionFee', 'View') && (
            <FormNumberInput
              label={t(
                'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.legalTransactionFee'
              )}
              name="legalTransactionFee"
              startAdornment={<InputAdornment position="start">€</InputAdornment>}
              control={control}
              disabled
            />
          )}
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FormNumberInput
            control={control}
            name="effectiveInterestRate"
            label={t(
              'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.effectiveInterestRate'
            )}
            endAdornment={<span>%</span>}
            fixedDecimalScale
            scale={3}
            shrink
            disabled
          />
          {hasPermission('Conditions', 'View') && (
            <>
              <FormNumberInput
                control={control}
                name="nominalInterestRate"
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.nominalInterestRate'
                )}
                endAdornment={<span>%</span>}
                fixedDecimalScale
                scale={3}
                shrink
                disabled
              />
              <FormNumberInput
                control={control}
                name="fundingInterestRate"
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.fundingInterestRate'
                )}
                endAdornment={<span>%</span>}
                fixedDecimalScale
                scale={3}
                shrink
                disabled
              />
              <FormNumberInput
                control={control}
                name="euriborRate"
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.euriborRate'
                )}
                endAdornment={<span>%</span>}
                fixedDecimalScale
                scale={3}
                shrink
                disabled
              />
              <FormNumberInput
                control={control}
                name="depositInterestRate"
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.depositInterestRate'
                )}
                endAdornment={<span>%</span>}
                fixedDecimalScale
                scale={3}
                shrink
                disabled
              />
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ExtendedConfigurationRate;

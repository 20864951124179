import { t } from 'i18next';
import { AllowedSettingsReading, UploadDocumentResponseApiResult } from '../../generated/ApiClient';
import { pushNotificationHandler } from '../PushNotification';
import RestHttpClient from '../RestHttpClient';
import { confirmHandler } from '../ConfirmModal';
import { useQuery } from 'react-query';

export const tryUploadFiles = async (
  documentId: number,
  force: boolean,
  dealId: number,
  resetUpload: () => Promise<void>,
  removeChars: (name: string) => string,
  file: File
) => {
  let res: UploadDocumentResponseApiResult | undefined = undefined;

  try {
    if (force) {
      res = await RestHttpClient.forceUploadDocument(dealId, documentId, [
        {
          fileName: removeChars(file.name) ?? '',
          data: file,
        },
      ]);
      await resetUpload();
    } else {
      res = await RestHttpClient.uploadDocument(dealId, documentId, [
        {
          fileName: removeChars(file.name) ?? '',
          data: file,
        },
      ]);

      if (!res.data.isValid) {
        if (res.data.isRequired) {
          pushNotificationHandler.publish(t('upload.verification.required'), 'error');
          await resetUpload();
        } else {
          confirmHandler.confirm(
            t('upload.verification.title'),
            () => tryUploadFiles(documentId, true, dealId, resetUpload, removeChars, file),
            resetUpload,
            t('upload.verification.text'),
            t('upload.verification.skip'),
            t('cancel')
          );
        }
      } else {
        await resetUpload();
      }
    }
  } catch {
    if (res && res.messages !== null) {
      if (res.messages?.local?.length) {
        res.messages.local.forEach((message) => {
          pushNotificationHandler.publish(message.message, 'error');
        });
      }
      if (res.messages?.global?.length) {
        res.messages.global.forEach((message) => {
          pushNotificationHandler.publish(message.message, 'error');
        });
      }
    } else {
      pushNotificationHandler.publish(t('upload.uploadError'), 'error');
    }
    await resetUpload();
  }
};

function removeExtraDots(filename: string): string {
  const lastDotIndex = filename.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return filename;
  }
  const filenameWithoutExtension = filename.substring(0, lastDotIndex);
  const extension = filename.substring(lastDotIndex);
  const filenameWithoutExtraDots = filenameWithoutExtension.replace(/\./g, '');
  return filenameWithoutExtraDots + extension;
}
function replaceMultipleUnderscores(str: string): string {
  while (str.includes('__')) {
    str = str.replace('__', '_');
  }
  return str;
}

const fetchRequiredDocs = async (dealId?: number) => {
    if (dealId) {
        const res = await RestHttpClient.getRequiredDocuments(dealId);
        return res.data;
    }

    return [];
}

export const useDocumentUpload = (dealId?: number) => {

  const { data: requiredDocsRes = [] } = useQuery({
    queryKey: ['requiredDocs', dealId],
    queryFn: () => fetchRequiredDocs(dealId)
  });

  const { data: allowedExtensionsRes } = useQuery({
    queryKey: ['allowedExtensions'],
    queryFn: () =>
      RestHttpClient.getConfigurationValues(
        AllowedSettingsReading.FileUpload_UserUploadAllowedFileExtensions
      ),
      staleTime: Infinity
  });
  const { data: charsAllowedPatternRes } = useQuery({
    queryKey: ['charsAllowedPattern'],
    queryFn: () =>
      RestHttpClient.getConfigurationValues(
        AllowedSettingsReading.FileUpload_UserUploadFilenameAllowedCharsRegexPattern
      ),
      staleTime: Infinity
  });
  const { data: acceptedSizeRes } = useQuery({
    queryKey: ['acceptedSize'],
    queryFn: () =>
      RestHttpClient.getConfigurationValues(
        AllowedSettingsReading.FileUpload_UserUploadMaxFileSizeMB
      ),
      staleTime: Infinity
  });

  const charsAllowedPattern = charsAllowedPatternRes?.data.value;
  const acceptedSize = acceptedSizeRes?.data.value;
  const allowedExtensions = allowedExtensionsRes?.data.value.replace(';', ',');

  function removeChars(inputString: string) {
    if (charsAllowedPattern) {
      let replaced = inputString.replace(/"/g, '_');
      replaced = replaced.replace(new RegExp(charsAllowedPattern, 'g'), '_');
      replaced = replaceMultipleUnderscores(removeExtraDots(replaced));
      console.log(
        'Input: ' +
          inputString +
          ' | Sanitized: ' +
          replaced +
          ' with pattern: ' +
          charsAllowedPattern
      );
      return replaced;
    } else {
      return inputString;
    }
  }

  function validateFile(file: File) {
    if (file?.size != null && file?.size > parseInt(acceptedSize ?? '0') * 1024 * 1024) {
      console.log(
        'Filesize is ' + file?.size + ' allowed is ' + parseInt(acceptedSize ?? '0') * 1024 * 1024
      );
      pushNotificationHandler.publish(t('upload.sizeError'), 'error');
      return false;
    }
    const fileExtension = file?.name.substring(file?.name.lastIndexOf('.'));
    const allowedExtensionsArray = allowedExtensions?.split(',');
    if (
      allowedExtensionsArray &&
      fileExtension &&
      !allowedExtensionsArray.includes(fileExtension)
    ) {
      pushNotificationHandler.publish(t('upload.uploadExtensionError'), 'error');
      return false;
    }
    return true;
  }

  return {
    requiredDocs: requiredDocsRes.map((x) => ({ label: x.name, value: x.id })),
    allowedExtensions: allowedExtensions,
    charsAllowedPattern: charsAllowedPattern,
    acceptedSize: acceptedSize,
    removeChars: removeChars,
    validateFile: validateFile,
  };
};

import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RestHttpClient from '../../../../common/RestHttpClient';
import { OptionItem } from '../../../../component/form/FormDropdown';
import { TreeData, TreeViewComponent } from '../../../../component/TreeViewComponent';
import { AsyncAutocomplete, Option } from '../../../../component/AsyncAutocomplete';
import { ModelOverview } from '../../../../generated/ApiClient';
import * as React from 'react';
import { usePermissions } from '../../../../common/auth/PermissionsContext';
import ModelImportDialog from './ModelImportDialog';
import { SalesPersonSelection } from './SalesPersonSelection';

interface Props {
  onModelSelected: (nodeId: number | null) => void;
  sellerId: number | undefined;
  onSellerIdChanged: (sellerId: number | null) => void;
  onImportSuccess: (modelId: number) => void;
}

export function ModelSelection({
  onModelSelected,
  onSellerIdChanged,
  sellerId,
  onImportSuccess,
}: Props) {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const [treeData, setTreeData] = useState<TreeData[]>([]);
  const [trademarksFetched, setTrademarksFetched] = useState<boolean>(false);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState<number | undefined>(sellerId);

  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getModels = async (searchTerm?: string): Promise<ModelOverview[]> => {
    if (searchTerm !== undefined) {
      const { data } = await RestHttpClient.findModelsByName(searchTerm, 0, 40, []);
      return data.items;
    } else {
      return [];
    }
  };

  const handleModelSelected = (modelId: number) => {
    onModelSelected(modelId);
  };

  useEffect(() => {
    if (!trademarksFetched)
      fetchTrademarks().then(() => {
        setTrademarksFetched(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trademarksFetched]);

  async function fetchTrademarks() {
    let copiedTree: TreeData[] = [];
    RestHttpClient.getTrademarks(selectedSalesPerson)
      .then((res) => {
        res.data.forEach(async function (trademark) {
          const node = createTreeDataNode(trademark.id, 0, trademark.name!, -1, false);
          node.children?.push(dummyNode(trademark.id, 1, trademark.id));
          copiedTree.push(node);
        });
      })
      .then(() => {
        setTreeData(copiedTree);
      });
  }

  const createTreeDataNode = (
    id: number,
    level: number,
    name: string,
    parentId: number,
    hasChildrenLoaded: boolean
  ): TreeData => {
    return {
      id: id,
      level: level,
      label: name,
      parentId: parentId,
      children: [],
      hasChildrenLoaded: hasChildrenLoaded,
      expanded: false,
      identifier: parentId + '_' + id,
    };
  };

  const dummyNode = (id: number, level: number, parentId: number): TreeData => {
    return {
      id: id,
      level: level,
      label: '',
      parentId: parentId,
      children: [],
      hasChildrenLoaded: false,
      expanded: false,
      identifier: '',
    };
  };

  async function loadChilds(level: number, id: number, parentId: number, expandedIds: string[]) {
    let copiedTree = JSON.parse(JSON.stringify(treeData));
    const node = searchNode(copiedTree, level, id, parentId);
    if (node) {
      let treeNode = node!;
      treeNode.children?.pop();
      switch (level) {
        case 0: {
          RestHttpClient.getVehicleTypes(id)
            .then((res) => {
              if (res.data !== null) {
                res.data.forEach(function (value) {
                  var childNode = createTreeDataNode(value.id, 1, value.name!, id, false);
                  childNode.children?.push(dummyNode(id, 2, id));
                  treeNode.children?.push(childNode);
                });
              }
            })
            .then(() => {
              treeNode.hasChildrenLoaded = true;
              setExpandedNodes(copiedTree, expandedIds);
              setTreeData(copiedTree);
            });
          break;
        }
        case 1: {
          RestHttpClient.getModelCategories(treeNode.parentId, treeNode.id)
            .then((res) => {
              if (res.data != null) {
                res.data.forEach(function (value) {
                  var childNode = createTreeDataNode(value.id, 2, value.name, id, false);
                  childNode.children?.push(dummyNode(id, 3, id));
                  treeNode.children?.push(childNode);
                });
              }
            })
            .then(() => {
              treeNode.hasChildrenLoaded = true;
              setExpandedNodes(copiedTree, expandedIds);
              setTreeData(copiedTree);
            });
          break;
        }
        case 2: {
          RestHttpClient.getEngineTypes(treeNode.id)
            .then((res) => {
              if (res.data != null) {
                res.data.forEach(function (value) {
                  var childNode = createTreeDataNode(value.id, 3, value.name, id, false);
                  childNode.children?.push(dummyNode(id, 4, id));
                  treeNode.children?.push(childNode);
                });
              }
            })
            .then(() => {
              treeNode.hasChildrenLoaded = true;
              setExpandedNodes(copiedTree, expandedIds);
              setTreeData(copiedTree);
            });
          break;
        }
        case 3: {
          RestHttpClient.getModels(treeNode.parentId, treeNode.id)
            .then((res) => {
              if (res.data != null) {
                res.data.forEach(function (value) {
                  var childNode = createTreeDataNode(value.id, 4, value.name, id, false);
                  treeNode.children?.push(childNode);
                });
              }
            })
            .then(() => {
              treeNode.hasChildrenLoaded = true;
              setExpandedNodes(copiedTree, expandedIds);
              setTreeData(copiedTree);
            });
          break;
        }
        default:
          break;
      }
    }
  }

  function getAllExpandedChildrenNodes(
    node: TreeData,
    subNodes: TreeData[]
  ): TreeData[] | undefined {
    // eslint-disable-next-line no-self-compare
    if (node.children && (node.children?.length ?? 0 > 0)) {
      const length = node.children?.length;
      for (let i = 0; i < length; i++) {
        if (node.children[i].expanded) {
          subNodes.push(node.children[i]);
          getAllExpandedChildrenNodes(node.children[i], subNodes);
        }
      }
    }
    return subNodes;
  }

  const setExpandedIds = (node: TreeData, expandedIds: string[]): string[] => {
    const index = expandedIds.findIndex(function (ni) {
      return ni === node.identifier;
    });

    if (index === -1) {
      const children = getAllExpandedChildrenNodes(node, new Array<TreeData>());
      if (children) {
        children.forEach(function (expandedNode) {
          const i = expandedIds.findIndex(function (id) {
            return id === expandedNode.identifier;
          });
          if (i > -1) {
            expandedIds.splice(i, 1);
          }
        });
      }
    }
    return expandedIds;
  };

  function setExpandedNodes(copiedTree: TreeData[], expandedIds: string[]) {
    const nodes = copiedTree.filter(function (node) {
      return node.level === 0;
    });

    nodes.forEach(function (treeNode) {
      const index = expandedIds.findIndex(function (nodeId) {
        return nodeId === treeNode.identifier;
      });
      treeNode.expanded = index > -1;
      setAllSubNodes(treeNode, expandedIds);
    });
  }

  function setAllSubNodes(node: TreeData, expandedIds: string[]) {
    // eslint-disable-next-line no-self-compare
    if (node.children && (node.children?.length ?? 0 > 0)) {
      const length = node.children?.length;
      for (let i = 0; i < length; i++) {
        if (node.children[i]) {
          let identifier = node.children[i].identifier;
          const index = expandedIds.findIndex(function (nodeId) {
            return nodeId === identifier;
          });
          node.children[i].expanded = index > -1 && node.children[i].level < 4;
        }
        setAllSubNodes(node.children[i], expandedIds);
      }
    }
  }

  function searchNode(
    copiedTree: TreeData[],
    level: number,
    id: number,
    parentId: number
  ): TreeData | null {
    const nodes = copiedTree.filter(function (node) {
      return node.level === 0;
    });

    for (let i = 0; i < nodes.length; i++) {
      const foundNode = searchTreeNode(copiedTree, level, id, parentId, i);
      if (foundNode !== null) return foundNode;
    }
    return null;
  }

  function searchTreeNode(
    copiedTree: TreeData[],
    level: number,
    id: number,
    parentId: number,
    index: number,
    reverse: boolean = false
  ) {
    const stack = [copiedTree[index]];
    while (stack.length) {
      const node = stack[reverse ? 'pop' : 'shift']();
      if (node?.id === id && node?.level === level && node?.parentId === parentId) return node;
      node?.children && stack.push(...node?.children);
    }
    return null;
  }

  const onElementSelected = (node: TreeData | null, expandedNodeIds: string[]) => {
    if (node) {
      if (node.level === 4) {
        onModelSelected(node.id);
        scrollUp();
      } else {
        let expandedIds = setExpandedIds(node, expandedNodeIds);
        if (!node?.hasChildrenLoaded) {
          loadChilds(node.level, node.id, node.parentId, expandedIds);
        } else {
          let copiedTree = JSON.parse(JSON.stringify(treeData));
          setExpandedNodes(copiedTree, expandedIds);
          setTreeData(copiedTree);
        }
        onModelSelected(null);
      }
    }
  };

  const modelOverviewToAutocompleteOption = (mo: ModelOverview): Option =>
    ({ label: mo.name, value: mo.id } as Option);

  function handleSellerChanged(event: React.SyntheticEvent, value: any) {
    if (value == null) {
      setSelectedSalesPerson(undefined);
      setTrademarksFetched(false);
      onSellerIdChanged(null);
    }
    if (value && 'value' in value) {
      const numVal = Number(value.value);
      setSelectedSalesPerson(numVal);
      setTrademarksFetched(false);
      onSellerIdChanged(numVal);
    }
  }

  return (
    <div className="model">
      {hasPermission('DealerSelection', 'Execute') && (
        <SalesPersonSelection handleChanged={handleSellerChanged} selected={selectedSalesPerson} />
      )}
      {(hasPermission('ImportMBKS', 'Execute') || hasPermission('ImportAS400', 'Execute')) && (
        <>
          <div className="import-section">
            <h2>{t('deals.new.model')}</h2>
            <Button onClick={() => setImportDialogOpen(true)} className="white">
              {t('deals.new.import')}
            </Button>
          </div>

          <ModelImportDialog
            open={importDialogOpen}
            setOpen={setImportDialogOpen}
            onImportSuccess={onImportSuccess}
          />
        </>
      )}

      <Box className="search-model">
        <AsyncAutocomplete
          toOptionsMapper={modelOverviewToAutocompleteOption}
          getOptions={getModels}
          label={t('deals.new.searchModel')}
          onChange={handleModelSelected}
        />
      </Box>

      <Box className="model-tree">
        <TreeViewComponent data={treeData} onNodeSelected={onElementSelected} />
      </Box>
    </div>
  );
}

import { Box, BoxProps } from '@mui/material';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';
import {
    FormCheckboxGroup,
    OptionWithChildren
} from '../../../../../component/form/FormCheckboxGroup';
import { FormDropdown, OptionItem } from '../../../../../component/form/FormDropdown';
import { ServiceOption } from '../../../../../generated/ApiClient';
import { FinancesDrawerFormValues } from '../RightDrawerSchema';

type Props = {
  control: Control<FinancesDrawerFormValues>;
  getValues: UseFormGetValues<FinancesDrawerFormValues>;
  setValue: UseFormSetValue<FinancesDrawerFormValues>;
  loading: boolean;
  readonly?: boolean;
  serviceProducts: OptionItem[];
  productIndusties: OptionItem[];
  productUsages: OptionItem[];
  campaigns: OptionItem[];
  productOptions: ServiceOption[];
  technicalOptions: ServiceOption[];
} & BoxProps;
export const FinancesServicesOptions = ({
  control,
  getValues,
  setValue,
  loading,
  readonly,
  serviceProducts,
  productIndusties,
  productUsages,
  campaigns,
  productOptions,
  technicalOptions,
  ...others
}: Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit = hasPermission('Services', 'Edit') && !readonly;

  const mapServiceOptionToCheckbox = (serviceOption: ServiceOption): OptionWithChildren => {
    const opt = new OptionWithChildren({
      label: serviceOption.text ?? '-',
      value: serviceOption.id != null ? serviceOption.id : '',
    });
    opt.readonly = serviceOption.isReadOnly;
    return opt;
  };

  const handleChangeEvent = () => {
    setValue('service.requestSourceApiconServiceBundleResetCalculation', true);
  };

  return (
    <Box sx={{ width: 1 / 2 }} {...others}>
      <h3>{t('deals.new.financesConfiguration.calculationDrawer.options.service')}</h3>
      <FormDropdown
        control={control}
        name="service.apiconServiceProduct.selectedServiceProductId"
        label={t('deals.new.financesConfiguration.calculationDrawer.options.serviceProduct')}
        options={serviceProducts}
        onChange={handleChangeEvent}
        required={getValues().financingTypeId === 53 || getValues().financingTypeId === 31}
        disabled={!canEdit || loading}
        emptyText={t('pleaseChoose')}
        sx={{ m: 1 }}
        shrink
      />
      <FormDropdown
        control={control}
        name="service.apiconServiceProduct.selectedProductIndustryId"
        label={t('deals.new.financesConfiguration.calculationDrawer.options.industry')}
        options={productIndusties}
        emptyText={t('pleaseChoose')}
        emptyValue={undefined}
        onChange={handleChangeEvent}
        required={getValues().financingTypeId === 53 || getValues().financingTypeId === 31}
        disabled={!canEdit || loading}
        sx={{ m: 1 }}
        shrink
      />
      <FormDropdown
        control={control}
        name="service.apiconServiceProduct.selectedProductUsageId"
        label={t('deals.new.financesConfiguration.calculationDrawer.options.typeOfUse')}
        options={productUsages}
        emptyText={t('pleaseChoose')}
        emptyValue={undefined}
        onChange={handleChangeEvent}
        required={getValues().financingTypeId == 53 || getValues().financingTypeId == 31}
        disabled={!canEdit || loading}
        sx={{ m: 1 }}
        shrink
      />
      <FormDropdown
        control={control}
        name="service.apiconServiceProduct.selectedCampaignId"
        label={t('deals.new.financesConfiguration.calculationDrawer.options.campaign')}
        options={campaigns}
        emptyText={t('pleaseChoose')}
        emptyValue={undefined}
        onChange={handleChangeEvent}
        required={false}
        disabled={!canEdit || loading || campaigns.length === 0}
        sx={{ m: 1 }}
        shrink
      />
      <Box sx={{ m: 1 }}>
        <FormCheckboxGroup
          control={control}
          name="service.apiconServiceProduct.selectedProductOptions"
          label={t('deals.new.financesConfiguration.calculationDrawer.options.productOptions')}
          options={productOptions.map(mapServiceOptionToCheckbox)}
          onChange={handleChangeEvent}
          disabled={!canEdit || loading}
          shrink
        />
      </Box>
      <Box sx={{ m: 1 }}>
        <FormCheckboxGroup
          control={control}
          name="service.apiconServiceProduct.selectedTechnicalOptions"
          label={t('deals.new.financesConfiguration.calculationDrawer.options.technicalOptions')}
          options={technicalOptions.map(mapServiceOptionToCheckbox)}
          onChange={handleChangeEvent}
          disabled={!canEdit || loading}
          shrink
        />
      </Box>
    </Box>
  );
};

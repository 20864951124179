import { useCallback, useContext } from 'react';
import { GlobalContext } from './GlobalContext';

export enum GlobalTextIdentifiers {
  DashboardOverview = 'DashboardOverview',
  DashboardApplications = 'DashboardApplications',
  DashboardPreliminary = 'DashboardPreliminary',
  DashboardContracts = 'DashboardContracts',
  DashboardExpired = 'DashboardExpired',
  DashboardSpecialOffer = 'DashboardSpecialOffer',
  DashboardDocuments = 'DashboardDocuments',
  DashboardIncentives = 'DashboardIncentives',
  DashboardIncentiveName = 'DashboardIncentiveName'
}

export const useGlobalTitles = () => {
  const { textItems } = useContext(GlobalContext);

  const getTitle = useCallback((id: GlobalTextIdentifiers) => {
    return textItems.find(x => x.id === id)?.text ?? '';
  }, [textItems]);

  return getTitle;
};

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerContext } from '../data/CustomerContext';

export function SearchResult() {
  const { t } = useTranslation();
  const { total } = useContext(CustomerContext);

  return (
    <label className="searchinfo-panel">
      {t('customers.search.recordsFound')}:&nbsp;{total}
    </label>
  );
}

import { CommercialCustomerFormValues } from '../data/CommercialCustomerFormValues';
import { Control } from 'react-hook-form';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { OptionItem } from '../../../generated/ApiClient';
import RestHttpClient from '../../../common/RestHttpClient';
import { Box, Divider, Tab, Tabs } from '@mui/material';
import { t } from 'i18next';
import { a11yProps, TabPanel } from '../../../component/TabPanel';
import { EntryContact } from './commercial/EntryContact';
import { Partner } from './commercial/Partner';
import { customErrorHandler } from '../../../common/validation/CustomErrorHandler';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

export interface Props {
  control: Control<CommercialCustomerFormValues>;
  isNew?: boolean;
  readonly: boolean;
  actions?: JSX.Element;
  tabErrors: number[];
  showSelfDisclosure?: boolean;
}

export const commercialEntryFields: Array<keyof CommercialCustomerFormValues> = [
  'legalFormId',
  'companyName',
  'ksvId',
  'addressStreet',
  'addressHouseNumber',
  'addressPostalCode',
  'addressCity',
  'addressCountryId',
  'phoneNumber',
  'additionalPhoneNumber',
  'emailAddress',
  'foundationDate',
  'commercialRegisterNumber',
  'uidNumber',
  'branchId',
  'companyBusiness',
  'bankAccountIBAN',
  'bankAccountBIC',
  'emailMarketingAggreed',
  'phoneMarketingAggreed',
  'postalMarketingAggreed',
  'dataInformationSheetTransferred',
  'selfDisclosureAgreed',
  'pepSpecificationId'
];

export const commercialPartnerFields: Array<keyof CommercialCustomerFormValues> = [];

export const errorHandler = (
  err: FieldErrors<CommercialCustomerFormValues>,
  setTabErrors: Dispatch<SetStateAction<number[]>>
) =>
  customErrorHandler<CommercialCustomerFormValues>(err, [
    {
      fields: commercialEntryFields,
      handler: () => {
        setTabErrors((prevState) => [...prevState, 0]);
      },
    },
    {
      fields: commercialPartnerFields,
      handler: () => {
        setTabErrors((prevState) => [...prevState, 1]);
      },
    },
  ]);

export function CommercialCustomerForm({ control, isNew, readonly, actions, tabErrors, showSelfDisclosure }: Props) {
  const [tabValue, setTabValue] = useState(0);

  const [legalForms, setLegalForms] = useState<OptionItem[]>([]);
  const [branches, setBranches] = useState<OptionItem[]>([]);
  const [countries, setCountries] = useState<OptionItem[]>([]);
  const [salutations, setSalutations] = useState<OptionItem[]>([]);
  const [maritalStatus, setMaritalStatus] = useState<OptionItem[]>([]);
  const [pepSpecifications, setPepSpecifications] = useState<OptionItem[]>([]);

  useEffect(() => {
    RestHttpClient.getLegalForms().then((res) => setLegalForms(res.data));
    RestHttpClient.getBranches().then((res) => setBranches(res.data));
    RestHttpClient.getCountries().then((res) => setCountries(res.data));
    RestHttpClient.getSalutations().then((res) => setSalutations(res.data));
    RestHttpClient.getMaritalStatus().then((res) => setMaritalStatus(res.data));
    RestHttpClient.getPepSpecifications().then((res) => setPepSpecifications(res.data));
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box className="commercial-customer-form">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Tabs aria-label="basic tabs example" value={tabValue} onChange={handleTabChange}>
          <Tab
            label={t('customers.edit.entryContact.label')}
            {...a11yProps(0)}
            sx={{ color: tabErrors.includes(0) ? 'red !important' : undefined }}
          />
          <Tab
            label={t('customers.edit.partner.label')}
            {...a11yProps(1)}
            sx={{ color: tabErrors.includes(1) ? 'red !important' : undefined }}
          />
        </Tabs>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {actions}
        </Box>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <EntryContact
          control={control}
          legalForms={legalForms}
          countries={countries}
          branches={branches}
          pepSpecifications={pepSpecifications}
          isNew={isNew}
          readonly={readonly}
          showSelfDisclosure={showSelfDisclosure}
        />
        <Divider />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Partner
          control={control}
          salutations={salutations}
          maritalStatus={maritalStatus}
          countries={countries}
          readonly={readonly}
        />
        <Divider />
      </TabPanel>
    </Box>
  );
}

import { Box, BoxProps, Divider } from '@mui/material';

interface ItemProps extends BoxProps {
  name: string;
  value?: string;
  last?: boolean;
}

export function Item(props: ItemProps) {
  const { sx, name, value, last, ...other } = props;
  return (
    <>
      <Box
        sx={{
          p: 1,
          m: 1,
          width: 1 / 6,
          textAlign: 'center',
          ...sx,
        }}
        justifyContent="center"
        {...other}
      >
        <h2 style={{ color: 'black' }}>{value ?? '-'}</h2>
        <h3 style={{ color: 'gray' }}>{name}</h3>
      </Box>
      {!last && <Divider orientation="vertical" variant="middle" flexItem />}
    </>
  );
}

import { GlobalApiMessage } from '../../generated/ApiClient';
import { history } from '../../views/App';
import { confirmHandler } from '../ConfirmModal';
import { t } from 'i18next';
import RestHttpClient from '../RestHttpClient';
import { pushNotificationHandler } from '../PushNotification';

const handleLockedDeal = (globalMessage: GlobalApiMessage) => {
  if (globalMessage.message === 'deal.locked') {
    const navigate = () => history.push('/deals');

    if (globalMessage.showForceUnlockDeal) {
      confirmHandler.confirm(
        t('deals.edit.lock.header'),
        () => {
          const dealId = globalMessage.arguments[0];
          RestHttpClient.unlockDeal(Number(dealId)).then(() => {
            pushNotificationHandler.publish(t('deals.edit.lock.success'), 'success');
            window.location.reload();
          });
        },
        navigate,
        t('errors.deal.locked', globalMessage.arguments),
        t('deals.edit.lock.unlock')
      );
    } else {
      navigate();
    }
  }
};

const globalErrorUserInteractions = (globalMessage: GlobalApiMessage) => {
  handleLockedDeal(globalMessage);
};

export default globalErrorUserInteractions;

import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { usePermissions } from '../../../common/auth/PermissionsContext';
import { MutableRefObject } from 'react';

interface Props {
  submitRef?: MutableRefObject<null>;
}
export function EditCustomerHeader({ submitRef }: Props) {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit = hasPermission('Customers', 'Edit');

  return (
    <Box className="view-header">
      {canEdit ? <h2>{t('customers.editCustomer')}</h2> : <h2>{t('customers.customer')}</h2>}
      <Box className="button-container">
        <Button component={Link} to="/" color="secondary" variant="outlined">
          {t('cancel')}
        </Button>
        {canEdit && (
          <Button type="submit" variant="contained" form="edit-customer-form" ref={submitRef}>
            {t('save')}
          </Button>
        )}
      </Box>
    </Box>
  );
}

import {
  CoApplicantOverview,
  CustomerType,
  LocalApiMessage,
} from '../../../../generated/ApiClient';
import { useTranslation } from 'react-i18next';
import { CommercialCustomerFormValues } from '../../../customers/data/CommercialCustomerFormValues';
import { PrivateCustomerFormValues } from '../../../customers/data/PrivateCustomerFormValues';
import { Box, Chip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { EditPrivateCustomerForm } from '../../../customers/edit/EditPrivateCustomerForm';
import { EditCommercialCustomerForm } from '../../../customers/edit/EditCommercialCustomerForm';
import RestHttpClient, { propertyPathToCamelCase } from '../../../../common/RestHttpClient';
import { usePermissions } from '../../../../common/auth/PermissionsContext';
import { NewCustomerTypeDropdown } from '../../../customers/new/NewCustomerTypeDropdown';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { CoApplicantValidationErrors } from '../DealDetail';

interface Props {
  dealId: number;
  customerId?: number;
  customerType: CustomerType;
  setCustomerType: Dispatch<SetStateAction<CustomerType>>;
  onAddMore: () => void;
  onSelectCustomer: (id: number) => void;
  onCommercialSubmit: (c: CommercialCustomerFormValues, changesMade: boolean, hasRelevantChanges?: boolean) => void;
  onPrivateSubmit: (c: PrivateCustomerFormValues, changesMade: boolean, hasRelevantChanges?: boolean) => void;
  onDelete: (id: number) => void;
  applicants: CoApplicantOverview[];
  validationErrors?: CoApplicantValidationErrors;
  readonly: boolean;
  hideForm: boolean;
  setUnsavedChanges?: Dispatch<SetStateAction<boolean>>;
  submitRef: MutableRefObject<null>;
}

export function EditApplicantForm({
  customerId,
  customerType,
  setCustomerType,
  onAddMore,
  onSelectCustomer,
  onDelete,
  applicants,
  validationErrors,
  onCommercialSubmit,
  dealId,
  onPrivateSubmit,
  hideForm,
  readonly,
  setUnsavedChanges,
  submitRef,
}: Props) {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit = hasPermission('Customers', 'Edit') && !readonly;

  const submitButton = () =>
    canEdit ? (
      <Button
        color="primary"
        variant="contained"
        style={{ height: 28 }}
        type="submit"
        form="edit-customer-form"
        ref={submitRef}
      >
        {customerId
          ? t('deals.new.applicant.submitSave')
          : t('deals.new.applicant.submitButtonAdd')}
      </Button>
    ) : (
      <span />
    );

  function checkIfCustomerIsApplicant() {
    return customerId && applicants.findIndex((a) => a.customerId === customerId) !== -1;
  }

  const showEditForm = (customerId || applicants.length < 2) && !hideForm;

  const getErrors = (cId?: number) => (cId && validationErrors ? validationErrors[cId] : undefined);

  const mapToCustomerProps = (e: LocalApiMessage) => {
    const splits = e.target.split('.');
    e.target = propertyPathToCamelCase(splits.length > 1 ? splits[1] : e.target);
    return e;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', p: 2 }}>
          <Typography variant="subtitle1">{t('deals.new.applicant.hint')}</Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {applicants.map((a) => (
              <Chip
                key={a.customerId}
                sx={{ marginX: 1 }}
                variant="outlined"
                color={getErrors(a.customerId) ? 'error' : undefined}
                label={`${a.firstName} ${a.lastName ?? ''}`}
                onClick={() => onSelectCustomer(a.customerId)}
                onDelete={canEdit ? () => onDelete(a.customerId) : undefined}
              />
            ))}
          </Box>
        </Box>

        {canEdit && (
          <Button
            disabled={applicants.length > 1}
            style={{ height: 28 }}
            className="white"
            onClick={() => onAddMore()}
          >
            {t('deals.new.applicant.addMoreButton')}
          </Button>
        )}
      </Box>
      {!customerId && showEditForm && (
        <Box className="new-customer">
          <NewCustomerTypeDropdown onChange={setCustomerType} value={customerType} />
        </Box>
      )}

      {showEditForm && customerType === CustomerType.Private && (
        <EditPrivateCustomerForm
          onSubmit={(values, changesMade, _submitType, _callback, hasRelevantChanges) => onPrivateSubmit(values, changesMade, hasRelevantChanges)}
          customerId={customerId ? customerId : undefined}
          isNew={!customerId}
          customerPromise={
            checkIfCustomerIsApplicant()
              ? customerId
                ? () => RestHttpClient.getPrivateDealCustomer(dealId, customerId)
                : undefined
              : customerId
              ? () => RestHttpClient.getPrivateCustomer(customerId)
              : undefined
          }
          actions={submitButton()}
          errors={getErrors(customerId)?.map(mapToCustomerProps)}
          readonly={readonly}
          setUnsavedChanges={setUnsavedChanges}
          isCoApplicant
          showSelfDisclosure
        />
      )}
      {showEditForm && customerType === CustomerType.Commercial && (
        <EditCommercialCustomerForm
          onSubmit={(values, isDirty, _submitType, _callback, hasRelevantChanges) => onCommercialSubmit(values, isDirty, hasRelevantChanges)}
          customerId={customerId ? customerId : undefined}
          customerPromise={
            checkIfCustomerIsApplicant()
              ? customerId
                ? () => RestHttpClient.getCommercialDealCustomer(dealId, customerId)
                : undefined
              : customerId
              ? () => RestHttpClient.getCommercialCustomer(customerId)
              : undefined
          }
          actions={submitButton()}
          errors={getErrors(customerId)?.map(mapToCustomerProps)}
          isNew={!customerId}
          setUnsavedChanges={setUnsavedChanges}
          readonly={readonly}
          showSelfDisclosure
        />
      )}
    </Box>
  );
}

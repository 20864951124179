import { Control, useWatch } from 'react-hook-form';
import { CoOptIntermFile, FinancesDrawerFormValues } from '../RightDrawerSchema';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import RestHttpClient from '../../../../../common/RestHttpClient';
import { UploadInput } from '../../../../../component/UploadInput';
import { Icons } from '../../../../../component/icons/Icons';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';

interface Props {
  control: Control<FinancesDrawerFormValues>;
  setValue: UseFormSetValue<FinancesDrawerFormValues>;
  dealId?: number;
  calculationId?: number;
  index: number;
  intermFiles: CoOptIntermFile[];
  setIntermFiles: Dispatch<SetStateAction<CoOptIntermFile[]>>;
  disabled?: boolean;
}

export const ConversionOptionDocumentField = ({
  control,
  index,
  dealId,
  calculationId,
  setValue,
  intermFiles,
  setIntermFiles,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File>();

  const id = useWatch({ control, name: `conversionOptions.${index}.id` });
  const fileId = useWatch({ control, name: `conversionOptions.${index}.fileId` });
  const fileName = useWatch({ control, name: `conversionOptions.${index}.fileName` });

  const downloadFile = () => {
    if (dealId && calculationId && Number.isInteger(id)) {
      RestHttpClient.downloadSupplierInvoice(dealId, calculationId, id!).then((res) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(res.data);
        link.download = fileName ? fileName : 'download';
        link.click();
      });
    }
  };

  if (id === undefined) {
    return <span />;
  }

  const handleFileChange = (file?: File) => {
    if (file && dealId && calculationId && Number.isInteger(id)) {
      setFile(file);
      RestHttpClient.uploadSupplierInvoice(dealId, calculationId, id, {
        fileName: file.name,
        data: file,
      })
        .then((res) => {
          setValue(`conversionOptions.${index}.fileId`, res.data.fileId);
          setValue(`conversionOptions.${index}.fileName`, file.name);
        })
        .finally(() => setFile(undefined));
    } else if (file) {
      setValue(`conversionOptions.${index}.fileName`, file.name);
      const existingIndex = intermFiles.findIndex((interFile) => interFile.index === index);
      if (existingIndex === -1) {
        setIntermFiles([...intermFiles, { index, file }]);
      } else {
        const newFiles = [...intermFiles];
        newFiles[existingIndex] = { index, file };
      }
    }
  };

  const handleFileDelete = () => {
    if (dealId && calculationId && Number.isInteger(id)) {
      RestHttpClient.removeSupplierInvoice(dealId, calculationId, id)
        .then(() => {
          setValue(`conversionOptions.${index}.fileId`, '');
          setValue(`conversionOptions.${index}.fileName`, '');
          setValue(`conversionOptions.${index}.fileId`, null);
          setValue(`conversionOptions.${index}.fileName`, null);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setValue(`conversionOptions.${index}.fileId`, null);
      setValue(`conversionOptions.${index}.fileName`, null);
      setIntermFiles(intermFiles.filter((interFile) => interFile.index !== index));
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box>
        {!disabled && (
          <UploadInput
            label={t('deals.new.financesConfiguration.calculationDrawer.agentOption.uploadInvoice')}
            onFileChange={handleFileChange}
            small
            file={file}
            accept=".pdf,.jpg,.png"
          />
        )}
      </Box>
      {(fileId || fileName) && (
        <Box sx={{ display: 'flex', flexDirection: 'row', mx: 2 }}>
          <Button disabled={!fileId} onClick={downloadFile}>
            {fileName}
          </Button>
          {!disabled && <IconButton onClick={handleFileDelete}>{Icons.clearAlt()}</IconButton>}
        </Box>
      )}
    </Box>
  );
};

import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Divider, FormLabel } from '@mui/material';
import { Control } from 'react-hook-form';
import { CoOptIntermFile, FinancesDrawerFormValues } from '../RightDrawerSchema';
import { OptionItem } from '../../../../../generated/ApiClient';
import * as React from 'react';
import { FinancesAgentOptions } from './FinancesAgentOptions';
import { FinancesConversionOptions } from './FinancesConversionOptions';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { Dispatch, SetStateAction } from 'react';

interface Props extends BoxProps {
  control: Control<FinancesDrawerFormValues>;
  dealId?: number;
  calculationId?: number;
  dealers: OptionItem[];
  suppliers: OptionItem[];
  loading: boolean;
  onRelevantChange: (name: string, value: unknown) => void;
  setValue: UseFormSetValue<FinancesDrawerFormValues>;
  displayGross: boolean;
  intermFiles: CoOptIntermFile[];
  setIntermFiles: Dispatch<SetStateAction<CoOptIntermFile[]>>;
  readonly?: boolean;
}

export const FinancesNova = ({
  control,
  dealers,
  suppliers,
  loading,
  onRelevantChange,
  displayGross,
  setValue,
  dealId,
  calculationId,
  intermFiles,
  setIntermFiles,
  readonly,
  ...other
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box {...other}>
      <Box sx={{ m: 2 }}>
        <h2 style={{ marginBottom: 16 }}>
          {t('deals.new.financesConfiguration.calculationDrawer.agentOption.drawerTitle')}
        </h2>
      </Box>

      <FinancesAgentOptions
        control={control}
        dealers={dealers}
        loading={loading}
        onRelevantChange={onRelevantChange}
        displayGross={displayGross}
        readonly={readonly}
      />

      <Divider sx={{ my: '20px' }} />

      <FinancesConversionOptions
        control={control}
        suppliers={suppliers}
        loading={loading}
        onRelevantChange={onRelevantChange}
        setValue={setValue}
        displayGross={displayGross}
        dealId={dealId}
        calculationId={calculationId}
        intermFiles={intermFiles}
        setIntermFiles={setIntermFiles}
        readonly={readonly}
      />

      <Divider sx={{ mt: '20px' }} />
      <Box sx={{ flexDirection: 'column', display: 'flex', marginTop: '20px' }}>
        <FormLabel>{t('customers.edit.legend.mandatory')}</FormLabel>
      </Box>
    </Box>
  );
};

import { Controller } from 'react-hook-form';
import { TextField, debounce } from '@mui/material';
import { FieldValues } from 'react-hook-form/dist/types';
import { FormComponentProps } from './FormComponentProps';
import { Icons } from '../icons/Icons';
import { DatePicker } from '@mui/x-date-pickers';

interface Props<TFieldValues extends FieldValues> extends FormComponentProps<TFieldValues> {
  maxDate?: Date | undefined;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  disabled?: boolean;
  readOnly?: boolean;
  dark?: boolean;
  required?: boolean;
}

export const FormDatePicker = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  onChange,
  onBlur,
  maxDate,
  disabled,
  dark,
  readOnly,
  required,
}: Props<TFieldValues>) => {
  const debouncedChange = debounce(onChange ? onChange : () => {}, 1000);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          disabled={disabled}
          maxDate={maxDate}
          {...field}
          value={field.value ?? null}
          label={required ? `${label}*` : label}
          renderInput={(params) => (
            <TextField
              onBlur={onBlur}
              variant="standard"
              {...params}
              fullWidth={true}
              error={!!error}
              sx={
                required && !error && !disabled
                  ? {
                      '& .MuiInput-underline:not(.Mui-focused):before': {
                        borderBottom: '2px solid black',
                      },
                      '& .MuiInput-underline:not(.Mui-focused):after': {
                        borderBottom: '2px solid black',
                      },
                    }
                  : undefined
              }
              InputProps={{ readOnly }}
              helperText={error ? error.message : ''}
            />
          )}
          components={{
            OpenPickerIcon: dark ? Icons.calendarWhite : Icons.calendar,
          }}
          onChange={(e) => {
            if (e) {
              if (!isNaN(e.valueOf())) {
                var reg = new RegExp(
                  /(0[1-9]|1[0-9]|2[0-9]|3[0-9]|[0-9]).(0[1-9]|[1-9]|1[0-2]).(19[0-9][0-9]|20[0-9][0-9]|21[0-9][0-9])/
                );
                if (reg.test(e.toLocaleDateString('de'))) {
                  e = new Date(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate()));
                } else {
                  return;
                }
              }
            }
            if (onChange) {
              debouncedChange({ target: { value: e, name } });
            }
            field.onChange(e);
          }}
          mask="__.__.____"
        />
      )}
    />
  );
};

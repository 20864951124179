import { Chip, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function CustomerChip({ isValidCustomer }: { isValidCustomer: boolean; }) {
  const { t } = useTranslation();

  const title = isValidCustomer ? t('customers.singular') : t('customers.potentialBuyer');

  return (
    <Tooltip title={title}>
      <Chip
        label={title.charAt(0)}
        className="customer-chip"
        color={isValidCustomer ? 'info' : 'warning'} />
    </Tooltip>
  );
}

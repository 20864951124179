import { Control, useWatch } from 'react-hook-form';
import { FinancesDrawerFormValues } from '../../RightDrawerSchema';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { FormTextField } from '../../../../../../component/form/FormTextField';
import * as React from 'react';
import { DateTimeFormatter } from '../../../../../../common/formatter/DateTimeFormatter';

interface Props {
  control: Control<FinancesDrawerFormValues>;
  showVoting: boolean;
}

const ExtendedConfigurationMeta = ({ control, showVoting }: Props) => {
  const { t } = useTranslation();

  const creationDate = useWatch({ control, name: 'creationDate' });
  const editedDate = useWatch({ control, name: 'editedDate' });
  const voterDate = useWatch({ control, name: 'voterDate' });

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} display="flex" flexDirection="column">
        <FormTextField
          control={control}
          label={t(
            'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.createdBy'
          )}
          name="createdBy"
          disabled
        />
        <Typography variant="subtitle1">
          {t(
            'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.creationDate'
          )}
        </Typography>
        <Typography variant="subtitle2">
          {DateTimeFormatter.format(creationDate ?? undefined)}
        </Typography>
      </Grid>
      <Grid item xs={4} display="flex" flexDirection="column">
        <FormTextField
          control={control}
          label={t(
            'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.editedBy'
          )}
          name="editedBy"
          disabled
        />
        <Typography variant="subtitle1">
          {t('deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.editedDate')}
        </Typography>
        <Typography variant="subtitle2">
          {DateTimeFormatter.format(editedDate ?? undefined)}
        </Typography>
      </Grid>
      {showVoting && (
        <Grid item xs={4} display="flex" flexDirection="column">
          <FormTextField
            control={control}
            label={t(
              'deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.voterUser'
            )}
            name="voterUser"
            disabled
          />
          <Typography variant="subtitle1">
            {t('deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.voterDate')}
          </Typography>
          <Typography variant="subtitle2">
            {DateTimeFormatter.format(voterDate ?? undefined)}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ExtendedConfigurationMeta;

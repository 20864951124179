import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormEvent, useEffect } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { FormTextField } from '../../../../../../component/form/FormTextField';
import { FormDatePicker } from '../../../../../../component/form/FormDatePicker';

interface Props {
  dealId?: number;
  loading: boolean;
  onSubmit: (values: ReconsiderationSearchType) => void;
}

const schema = z.object({
  id: z.string().optional(),
  salesPersonName: z.string().optional(),
  creationDate: z.date().optional(),
  customerName: z.string().optional(),
});

export type ReconsiderationSearchType = z.infer<typeof schema>;

const ReconsiderationSearch = ({ dealId, onSubmit, loading }: Props) => {
  const { t } = useTranslation();

  const { control, reset, handleSubmit } = useForm<ReconsiderationSearchType>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    reset();
    // eslint-disable-next-line
  }, [dealId]);

  const onHandleSubmit = (event: FormEvent) => {
    event.stopPropagation();
    handleSubmit((values) => {
      onSubmit(values);
    }, console.error)(event);
  };

  const handleReset = () => {
    reset();
    onSubmit({});
  };

  return (
    <div>
      <form onSubmit={onHandleSubmit} noValidate id="submitted-deal-search">
        <Box className="search-panel search-header">
          <Grid spacing={3} container>
            <Grid item md={5} container>
              <FormTextField
                control={control}
                name="id"
                label={t('deals.new.financesConfiguration.calculationDrawer.reconsideration.id')}
                withIcon={true}
              />
              <FormTextField
                control={control}
                name="salesPersonName"
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.reconsideration.salesPerson'
                )}
                withIcon={true}
              />
            </Grid>

            <Grid item md={5} container>
              <FormTextField
                control={control}
                name="customerName"
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.reconsideration.customerName'
                )}
                withIcon={true}
              />
              <FormDatePicker
                control={control}
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.reconsideration.creationDate'
                )}
                name="creationDate"
              />
            </Grid>
            <Grid item md={2} container>
              <Button
                variant="contained"
                form="submitted-deal-search"
                fullWidth
                type="submit"
                disabled={loading}
              >
                {t('search.start')}
              </Button>
              <Button className="reset" variant="text" onClick={handleReset} fullWidth>
                {t('reset')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
};

export default ReconsiderationSearch;

import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { CoOptIntermFile, FinancesDrawerFormValues } from '../RightDrawerSchema';
import { OptionItem } from '../../../../../generated/ApiClient';
import { useTranslation } from 'react-i18next';
import { Box, Button, debounce, Grid, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import { FormTextField } from '../../../../../component/form/FormTextField';
import { FormDropdown } from '../../../../../component/form/FormDropdown';
import { FormPriceInput } from '../../../../../component/form/FormPriceInput';
import { Icons } from '../../../../../component/icons/Icons';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { ConversionOptionDocumentField } from './ConversionOptionDocumentField';
import { Dispatch, SetStateAction } from 'react';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';

interface Props {
  control: Control<FinancesDrawerFormValues>;
  suppliers: OptionItem[];
  loading: boolean;
  onRelevantChange: (name: string, value: unknown) => void;
  setValue: UseFormSetValue<FinancesDrawerFormValues>;
  displayGross: boolean;
  dealId?: number;
  calculationId?: number;
  intermFiles: CoOptIntermFile[];
  setIntermFiles: Dispatch<SetStateAction<CoOptIntermFile[]>>;
  readonly?: boolean;
}

export const FinancesConversionOptions = ({
  control,
  suppliers,
  displayGross,
  loading,
  onRelevantChange,
  setValue,
  dealId,
  calculationId,
  intermFiles,
  setIntermFiles,
  readonly,
}: Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit =
    (hasPermission('FinancingProducts', 'Edit') || hasPermission('LeasingProducts', 'Edit')) &&
    !readonly;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'conversionOptions',
  });

  const conversionWatcher = useWatch({ control, name: 'conversionOptions' }) || [];

  const handleAdd = () => {
    append({
      id: Math.random(),
      price: 0,
      title: '',
      supplierId: 0,
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
    onRelevantChange('conversionOptions', conversionWatcher);
  };

  const triggerReCalc = debounce(() => {
    onRelevantChange('conversionOptions', conversionWatcher);
  }, 500);

  const handleSupplierChange =
    (index: number) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      // eslint-disable-next-line
      if (e.target.value != '0') {
        setValue(`conversionOptions.${index}.unknownSupplier`, null);
      }
    };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ pt: 2 }}>
          {t('deals.new.financesConfiguration.calculationDrawer.agentOption.conversion')}
        </Typography>

        {canEdit && (
          <Button
            onClick={handleAdd}
            disabled={loading}
            sx={{ height: '28px', width: '94px', border: 1, borderColor: 'black', color: 'black' }}
          >
            {t('deals.new.financesConfiguration.calculationDrawer.agentOption.add')}
          </Button>
        )}
      </Box>

      {fields.map((field, index) => (
        <Grid key={`${index}-${field.id}`} container sx={{ mt: 1 }}>
          <Grid item xs={10}>
            <FormTextField
              control={control}
              label={t('deals.new.financesConfiguration.calculationDrawer.agentOption.title')}
              name={`conversionOptions.${index}.title`}
              required
              sx={{ m: 1 }}
              disabled={loading}
            />

            <FormDropdown
              control={control}
              options={suppliers}
              label={t('deals.new.financesConfiguration.calculationDrawer.agentOption.dealer')}
              name={`conversionOptions.${index}.supplierId`}
              emptyText={t(
                'deals.new.financesConfiguration.calculationDrawer.agentOption.unknownSupplier'
              )}
              emptyValue={0}
              onChange={handleSupplierChange(index)}
              sx={{ m: 1 }}
              disabled={loading || !canEdit}
            />

            <FormTextField
              control={control}
              label={t(
                'deals.new.financesConfiguration.calculationDrawer.agentOption.unknownSupplier'
              )}
              name={`conversionOptions.${index}.unknownSupplier`}
              sx={{ m: 1 }}
              disabled={
                loading ||
                (conversionWatcher[index] && conversionWatcher[index].supplierId !== 0) ||
                !canEdit
              }
            />

            <FormPriceInput
              control={control}
              label={t('deals.new.financesConfiguration.calculationDrawer.agentOption.price')}
              name={`conversionOptions.${index}.price`}
              onBlur={triggerReCalc}
              required
              boxProps={{
                sx: { m: 1 },
              }}
              pricesIncludingVAT={displayGross}
              disabled={loading || !canEdit}
            />
            <ConversionOptionDocumentField
              control={control}
              index={index}
              dealId={dealId}
              calculationId={calculationId}
              setValue={setValue}
              intermFiles={intermFiles}
              setIntermFiles={setIntermFiles}
              disabled={!canEdit}
            />
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <div>
              {canEdit && (
                <IconButton disabled={loading} onClick={() => handleRemove(index)}>
                  {Icons.trash()}
                </IconButton>
              )}
            </div>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

import { Info } from '@mui/icons-material';
import { IVinParameters, VinState } from '../../../../generated/ApiClient';
import { Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function VinStatusIcon({ vinDate, vinIssue, vinStatus, vinState }: IVinParameters) {
  const { t } = useTranslation();
  
  if (!vinStatus) {
    return null;
  }

  const color =
    vinState === VinState.Error
      ? 'error'
      : vinState === VinState.Success
      ? 'success'
      : vinState === VinState.UpdateRequired
      ? 'warning'
      : 'info';

  const statusText =
    vinState === VinState.Error
      ? t('errors.creditCheck.lockedVin')
      : vinState === VinState.UpdateRequired
      ? t('errors.creditCheck.missingUpdateConfirmation')
      : vinStatus;

  const tooltipContent = (
    <Stack minWidth="18em">
      <Stack flexDirection="row" justifyContent="space-between" gap={1}>
        <Typography>{t('deals.new.modelConfiguration.body.vehicleDetails.vinStatus')}:</Typography>
        <Typography>{statusText}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" gap={1}>
        <Typography>{t('deals.new.modelConfiguration.body.vehicleDetails.vinIssue')}:</Typography>
        <Typography>{vinIssue}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" gap={1}>
        <Typography>{t('deals.new.modelConfiguration.body.vehicleDetails.vinDate')}:</Typography>
        <Typography>{vinDate?.toLocaleDateString()}</Typography>
      </Stack>
    </Stack>
  );

  return (
    <Tooltip title={tooltipContent}>
      <Info color={color} />
    </Tooltip>
  );
}

import { getUser } from '../common/auth/OidcConfig';

export class ApiClientBase {
  transformOptions(request: RequestInit): Promise<RequestInit> {
    if (process.env.NODE_ENV !== 'development') {
      const user = getUser();
      if (user && user.access_token) {
        var headers = new Headers(request.headers);
        headers.set('Authorization', `Bearer ${user.access_token}`);
        request.headers = headers;
      }
    }

    return Promise.resolve(request);
  }
}

import { OptionItem, SubsidyView } from '../../../../../../generated/ApiClient';
import { Control } from 'react-hook-form';
import { FinancesDrawerFormValues, SubsidyValues } from '../../RightDrawerSchema';
import i18n from '../../../../../../common/i18n/i18n';
import { Box, Checkbox, Typography } from '@mui/material';
import React from 'react';
import { HeadCell } from '../../../../../../common/table/EnhancedTableHead';
import { useTranslation } from 'react-i18next';
import { EnhancedTable } from '../../../../../../common/table/EnhancedTable';
import { NumberFormatter } from '../../../../../../common/formatter/NumberFormatter';
import { toSubsidyDisplayText } from './ExtendedConfigurationViolations';
import { usePermissions } from '../../../../../../common/auth/PermissionsContext';
import { residualValueIncrease } from '../salesAids/SubsidyForm';

interface Props {
  control: Control<FinancesDrawerFormValues>;
  subsidies: SubsidyView[];
  givers: OptionItem[];
  recipientUnits: { [index: number]: string };
}

interface SubsidyRow extends SubsidyValues {
  id: string;
  subsidyGiver: string;
  subsidyRecipient: string;
  isFixValueIcon: JSX.Element;
  fixumText: string;
  perc: string | undefined;
}

const ExtendedConfigurationAids = ({ subsidies, givers, recipientUnits }: Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  const isInternalUser = hasPermission('Unrestricted', 'Impersonate');

  const columns: readonly HeadCell<SubsidyRow>[] = [
    {
      id: 'subsidyGiver',
      disablePadding: false,
      align: 'left',
      label: t('deals.new.financesConfiguration.calculationDrawer.salesAids.subsidyGiver'),
    },
    {
      id: 'subsidyRecipient',
      disablePadding: false,
      align: 'left',
      label: t('deals.new.financesConfiguration.calculationDrawer.salesAids.subsidyRecipient'),
    },
    {
      id: 'perc',
      disablePadding: false,
      align: 'left',
      label: t('deals.new.financesConfiguration.calculationDrawer.salesAids.percentage'),
    },
    {
      id: 'fixumText',
      disablePadding: false,
      align: 'left',
      label: t('deals.new.financesConfiguration.calculationDrawer.salesAids.fixum'),
    },
    {
      id: 'isFixValueIcon',
      disablePadding: false,
      align: 'left',
      label: t('deals.new.financesConfiguration.calculationDrawer.salesAids.isFixValue'),
    },
  ];

  const rows = subsidies
    .filter(
      (s) => s.isVisible && (s.subsidyRecipientId !== residualValueIncrease || isInternalUser)
    )
    .map((s, index) => ({
      id: `${index}`,
      ...s,
      subsidyGiver: givers.find((g) => g.value === s.subsidyGiverId)?.label ?? '-',
      subsidyRecipient: i18n.t([
        `deals.new.financesConfiguration.calculationDrawer.subsidyValues.${s.subsidyRecipientId}`,
        '',
      ]),
      isFixValueIcon: <Checkbox checked={s.isFixValue} />,
      fixumText:
        s.fixum != null
          ? toSubsidyDisplayText(
              s.isFixValue ? s.fixum * -1 : s.fixum,
              s.subsidyRecipientId,
              recipientUnits
            )
          : '',
      perc: s.isFixValue ? undefined : NumberFormatter.format(s.percentage),
    }));

  if (!rows.length) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <Typography variant="subtitle1">
          {t('deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.noSalesAids')}
        </Typography>
      </Box>
    );
  }

  return (
    <EnhancedTable
      rows={rows}
      variant="default"
      headCells={columns}
      hideCheckbox={true}
      minWidth={300}
    />
  );
};

export default ExtendedConfigurationAids;

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { InsuranceFormValues } from '../InsuranceSchema';
import { FormTextField } from '../../../../../component/form/FormTextField';

interface Props {
  control: Control<InsuranceFormValues>;
  loading: boolean;
  readonly: boolean;
}

export const MoreInfo = ({ control, loading, readonly }: Props) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={6}>
        <h3>{t('deals.edit.insurance.moreInfo')}</h3>

        <Box sx={{ mx: 1 }}>
          <FormTextField
            label={t('deals.edit.insurance.fields.externalCalculationNumber')}
            name="externalCalculationNumber"
            control={control}
            disabled={loading || readonly}
            sx={{ my: 1 }}
          />

          <FormTextField
            label={t('deals.edit.insurance.fields.externalCalculationInfo')}
            name="externalCalculationInfo"
            control={control}
            disabled={loading || readonly}
            sx={{ my: 1 }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

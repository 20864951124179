import { InsuranceFormValues } from '../InsuranceSchema';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { FormDropdown } from '../../../../../component/form/FormDropdown';
import React from 'react';
import { OptionItem } from '../../../../../generated/ApiClient';
import { FormNumberInput } from '../../../../../component/form/FormNumberInput';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';

interface Props {
  control: Control<InsuranceFormValues>;
  onRelevantChange: (name: string, value: unknown) => void;
  loading: boolean;
  bmLevels: OptionItem[];
  usageTypes: OptionItem[];
  paymentPeriods: OptionItem[];
  paymentTypes: OptionItem[];
  readonly: boolean;
  licenseHolders: OptionItem[]
}

export const InsuranceBMIObjectInfo = ({
  control,
  onRelevantChange,
  loading,
  bmLevels,
  usageTypes,
  paymentPeriods,
  paymentTypes,
  readonly,
  licenseHolders
}: Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit = hasPermission('InsuranceProducts', 'Edit');

  const enableKasko = useWatch({ control, name: 'enableKasko' });
  const enableLiability = useWatch({ control, name: 'enableLiability' });
  const isPKW = useWatch({ control, name: 'isPKW' });
  const isCo2Required = useWatch({ control, name: 'isCo2Required' });
  const applicantId = useWatch({control, name: 'applicantId'});
  const additionalApplicantId = useWatch({control, name: 'additionalApplicantId'});

  const handleRelevantBlurEvent = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onRelevantChange(event.target.name, event.target.value);
  };

  const handleRelevantChangeEvent = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onRelevantChange(event.target.name, event.target.value);
  };

  return (
    <Grid container columnSpacing={6}>
      <Grid item xs={6}>
        <h3>{t('deals.edit.insurance.bmInfo')}</h3>
        <FormDropdown
          isRequired
          control={control}
          name="bmLevel"
          label={t('deals.edit.insurance.fields.bmLevel')}
          options={bmLevels}
          sx={{ m: 1 }}
          shrink
          disabled={loading || !canEdit || readonly}
          onChange={handleRelevantChangeEvent}
        />

        <FormDropdown
          control={control}
          name="liability_BonusMalus_Internal_Id"
          label={t('deals.edit.insurance.fields.liabilityBonusMalusInternal')}
          options={bmLevels}
          sx={{ m: 1 }}
          shrink
          disabled={loading || !canEdit || readonly}
          onChange={handleRelevantChangeEvent}
          emptyText={t('deals.edit.insurance.fields.noLiabilityBonusMalusInternal')}
          emptyValue={0}
        />
        <FormDropdown
          isRequired
          control={control}
          name="usageTypeId"
          label={t('deals.edit.insurance.fields.usageType')}
          options={usageTypes}
          sx={{ m: 1 }}
          shrink
          disabled={loading || !canEdit || readonly}
          onChange={handleRelevantChangeEvent}
        />
      </Grid>

      <Grid item xs={6}>
        <h3>{t('deals.edit.insurance.objectInfo')}</h3>

        <FormNumberInput
          label={t('deals.edit.insurance.fields.kw')}
          name="kw"
          control={control}
          sx={{ m: 1 }}
          disabled={loading || !canEdit || readonly}
          onBlur={handleRelevantBlurEvent}
          required
        />

        {isCo2Required && (
          <FormNumberInput
            label={t('deals.edit.insurance.fields.co2')}
            name="co2"
            control={control}
            sx={{ m: 1 }}
            required={isCo2Required}
            disabled={loading || !canEdit || readonly}
            onBlur={handleRelevantBlurEvent}
          />
        )}

        {!isPKW && (
          <FormNumberInput
            label={t('deals.edit.insurance.fields.weight')}
            name="weight"
            control={control}
            sx={{ m: 1 }}
            disabled={loading || !canEdit || readonly}
            onBlur={handleRelevantBlurEvent}
          />
        )}

        <FormNumberInput
          label={t('deals.edit.insurance.fields.numberOfSeats')}
          name="numberOfSeats"
          control={control}
          sx={{ m: 1 }}
          required
          disabled={loading || !canEdit || readonly}
          onBlur={handleRelevantBlurEvent}
        />
        {!isPKW && (
          <FormNumberInput
            label={t('deals.edit.insurance.fields.maxTotalWeight')}
            name="maxTotalWeight"
            control={control}
            sx={{ m: 1 }}
            disabled={loading || !canEdit || readonly}
            onBlur={handleRelevantBlurEvent}
          />
        )}
      </Grid>

      <Grid item xs={6}>
        <h3>{t('deals.edit.insurance.paymentDetails')}</h3>

        <FormDropdown
          isRequired
          control={control}
          name="paymentPeriodId"
          label={t('deals.edit.insurance.fields.paymentPeriod')}
          options={paymentPeriods}
          sx={{ m: 1 }}
          shrink
          disabled={loading || !canEdit || readonly}
          onChange={handleRelevantChangeEvent}
        />
        <FormDropdown
          control={control}
          name="paymentTypeId"
          label={t('deals.edit.insurance.paymentType')}
          options={paymentTypes}
          required
          sx={{ m: 1 }}
          shrink
          disabled={loading || !canEdit || (!enableKasko && !enableLiability) || readonly}
          onChange={handleRelevantChangeEvent}
        />
      </Grid>
      <Grid item xs={6}>
          <FormDropdown
            control={control}
            name="applicantId"
            sx={{ m: 1 }}
            options={licenseHolders.filter(x => x.value !== additionalApplicantId)}
            label={t('deals.edit.insurance.applicant')}
            disabled={loading || !canEdit || readonly}
          />
          <FormDropdown
            control={control}
            name="additionalApplicantId"
            sx={{ m: 1 }}
            options={licenseHolders.filter(x => x.value !== applicantId)}
            label={t('deals.edit.insurance.additionalApplicant')}
            disabled={loading || !canEdit || readonly}
            emptyText='-'
          />
      </Grid>
    </Grid>
  );
};

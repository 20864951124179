import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IVehicleDetail } from '../../../generated/ApiClient';
import { ModelImage } from '../../deals/ModelImage';

interface Props {
  vehicle?: IVehicleDetail;
}

export function OverlayVehicle({ vehicle }: Props) {
  const { t } = useTranslation();

  console.log(vehicle?.vehicleCondition);
  return (
    <>
      <Grid container width="100%" mt={2}>
        <Grid item xs={6}>
          <Grid container sx={{ justifyContent: 'start', alignItems: 'start' }}>
            <Grid item xs={6} className="value-column">
              <div>{t('dashboard.contractsOverlay.vehicle.object')}</div>
            </Grid>
            <Grid item xs={6} className="value-column">
              <div>{vehicle?.objectName ?? ''}</div>
            </Grid>
            <Grid item xs={6} className="value-column">
              <div>{t('dashboard.contractsOverlay.vehicle.fin')}</div>
            </Grid>
            <Grid item xs={6} className="value-column">
              <div>{vehicle?.fin ?? ''}</div>
            </Grid>
            <Grid item xs={6} className="value-column">
              <div>{t('dashboard.contractsOverlay.vehicle.state')}</div>
            </Grid>
            <Grid item xs={6} className="value-column">
              <div>
                {vehicle?.vehicleCondition !== null
                  ? // @ts-ignore
                    t(`dashboard.contractsOverlay.vehicle.conditions.${vehicle?.vehicleCondition}`)
                  : ''}
              </div>
            </Grid>

            <Grid item xs={6} className="value-column">
              <div>{t('dashboard.contractsOverlay.vehicle.licencePlate')}</div>
            </Grid>

            <Grid item xs={6} className="value-column">
              <div>{vehicle?.numberPlate ?? '-'}</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {vehicle?.modelId && <ModelImage modelId={vehicle?.modelId} />}
        </Grid>
      </Grid>

      <div className="line" />
    </>
  );
}

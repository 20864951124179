import { Box, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import { t } from 'i18next';
import { Controller, FieldValues } from 'react-hook-form';
import { FormComponentProps } from './FormComponentProps';
import * as React from 'react';

interface RadioButtonOption {
  label: string;
  value: any;
}

interface Props<TFieldValues> extends FormComponentProps<TFieldValues> {
  options?: RadioButtonOption[];
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string | boolean;
}

const defaultOptions: RadioButtonOption[] = [
  { label: t('yes'), value: 'true' },
  { label: t('no'), value: 'false' },
];

export const FormRadioButton = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  className,
  options,
  disabled,
  required,
  defaultValue
}: Props<TFieldValues>) => {
  const items = options ?? defaultOptions;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
          if (value === 'true' || value === 'false') {
            field.onChange({
              target: {
                name: name,
                value: value === 'true',
              },
            });
          } else {
            field.onChange(e);
          }
        };
        return (
          <>
            <Box className="form-radio-button">
              <FormLabel
                component="label"
                error={!!error}
                sx={required && !error ? { color: 'black', fontWeight: 'bold' } : undefined}
              >
                {label} {required ? '*' : ''}
              </FormLabel>
              <RadioGroup
                {...field}
                value={String(field.value ?? defaultValue)}
                onChange={handleChange}
                className={className}
                sx={{ flexDirection: 'row' }}
              >
                {items.map((o) => (
                  <FormControlLabel
                    key={o.value}
                    control={<Radio />}
                    value={o.value}
                    label={o.label}
                    disabled={disabled}
                  />
                ))}
              </RadioGroup>
            </Box>
            <FormHelperText error={!!error}>{error ? error.message : ''}</FormHelperText>
          </>
        );
      }}
    />
  );
};

import { CircularProgress, Modal, Paper, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  loading: boolean;
}

export const LoadingModal = ({ loading }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={loading}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      className={'loading-page'}
    >
      <Paper elevation={0} className={'page'}>
        <CircularProgress
          classes={{
            root: 'circular',
          }}
        />
        <Typography variant="subtitle1" className={'text'}>
          {t('loading')}
        </Typography>
      </Paper>
    </Modal>
  );
};

import { Box, Button, debounce } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FormTextField } from '../../../component/form/FormTextField';
import { FormCheckbox } from '../../../component/form/FormCheckbox';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, FormEvent, SetStateAction, useContext, useEffect, useState } from 'react';
import { CustomerContext } from '../data/CustomerContext';
import RestHttpClient from '../../../common/RestHttpClient';

interface Props {
  toggleBasicSearch: Dispatch<SetStateAction<boolean>>;
  customerTypeId?: number;
  includePotentialBuyer?: boolean;
  excludeCustomerIds?: number[];
}

const schema = z.object({
  searchTerm: z.string().optional(),
  isPotentialBuyer: z.boolean().optional(),
});

type CustomerSearchFormValues = z.infer<typeof schema>;

export function CustomerSearch({
  toggleBasicSearch,
  customerTypeId,
  includePotentialBuyer,
  excludeCustomerIds,
}: Props) {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [isPotentialBuyer, setIsPotentialBuyer] = useState<boolean | undefined>(
    includePotentialBuyer
  );
  const { customers, setCustomers, page, setPage, setLoading, setTotal, orderSelectors } =
    useContext(CustomerContext);

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<CustomerSearchFormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      isPotentialBuyer: includePotentialBuyer,
    },
  });

  const fetchCustomers = (
    pb = isPotentialBuyer,
    st = searchTerm,
    p = page,
    os: string[] = orderSelectors
  ) => {
    setLoading(true);
    RestHttpClient.getCustomerOverview(st, customerTypeId, !pb, excludeCustomerIds, p, 25, os)
      .then((res) => {
        const newCustomers = res.data;
        setTotal(res.data.total);
        if (p === 0 && newCustomers.items.length) {
          setCustomers(newCustomers.items);
        } else if (newCustomers.items.length) {
          setCustomers(customers.concat(newCustomers.items));
        }
      })
      .catch((_) => {
        setCustomers([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (event: FormEvent) =>
    handleSubmit(async (values) => {
      setCustomers([]);
      setPage(0);
      setSearchTerm(values.searchTerm);
      fetchCustomers(values.isPotentialBuyer, values.searchTerm, 0);
    })(event).catch((_) => {
      // TODO Set validation errors here
      setError('searchTerm', {
        type: 'custom',
        message: 'invalid',
      });
    });

  useEffect(() => {
    if (page > 0) {
      fetchCustomers();
    }
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    setPage(0);
    fetchCustomers(isPotentialBuyer, searchTerm, 0, orderSelectors);
    // eslint-disable-next-line
  }, [excludeCustomerIds?.length]);

  useEffect(() => {
    setPage(0);
    setCustomers([]);
    fetchCustomers(isPotentialBuyer, searchTerm, 0, orderSelectors);
    // eslint-disable-next-line
  }, [orderSelectors]);

  const handleToggle = () => {
    setPage(0);
    toggleBasicSearch(false);
  };

  const handleOnBlurChange = (isPotentialBuyer?: boolean, searchTerm?: string, os?: string[]) => {
    setCustomers([]);
    setPage(0);
    setSearchTerm(searchTerm);
    setIsPotentialBuyer(isPotentialBuyer);
    fetchCustomers(isPotentialBuyer, searchTerm, 0, os);
  };

  const debouncedSearch = debounce((searchTerm?: string) => {
    setSearchTerm(searchTerm);
    setCustomers([]);
    setPage(0);
    fetchCustomers(isPotentialBuyer, searchTerm, 0, orderSelectors);
  }, 500);

  return (
    <Box
      className={`search-panel search-header ${
        includePotentialBuyer ? 'four-items' : 'three-items'
      }`}
    >
      <form onSubmit={onSubmit} noValidate>
        <div className="search-container">
          <FormTextField
            sx={{ minWidth: 200 }}
            control={control}
            name="searchTerm"
            label={t('customers.search.placeholder')}
            withIcon={true}
            onChange={(event) => debouncedSearch(event.target.value)}
          />

          {includePotentialBuyer && (
            <div>
              <FormCheckbox
                control={control}
                name="isPotentialBuyer"
                label={t('customers.search.potentialBuyer')}
                onChange={(event) => {
                  handleOnBlurChange(event.target.checked, searchTerm, orderSelectors);
                }}
              />
            </div>
          )}

          <Button variant="contained" type="submit" disabled={isSubmitting}>
            {t('search.start')}
          </Button>
          <Button variant="text" className="toggle-search" onClick={handleToggle}>
            {t('search.advanced')}
          </Button>
        </div>
      </form>
    </Box>
  );
}

import { Box, Button } from '@mui/material';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { FormTextField } from '../../../component/form/FormTextField';
import { DashboardSearchContext } from './DashboardSearchContext';
import RestHttpClient from '../../../common/RestHttpClient';

interface Props {
  onHandleSubmit: () => void;
}

const schema = z.object({
  searchTerm: z.string(),
});

type DashboardSearchFormValues = z.infer<typeof schema>;

export function DashboardSearch({ onHandleSubmit }: Props) {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<DashboardSearchFormValues>({
    resolver: zodResolver(schema),
  });

  const { page, setPage, showAll, setTotal, setLoading, results, setResults, orderSelectors } =
    useContext(DashboardSearchContext);

  const fetchDocuments = (sa = showAll, st = searchTerm, p = page, os = orderSelectors) => {
    setLoading(true);
    RestHttpClient.searchContracts(sa, st, p, 25, os)
      .then((res) => {
        const newResults = res.data;
        setTotal(newResults.total);
        if (p === 0 && newResults.items.length) {
          setResults(newResults.items);
        } else if (newResults.items.length) {
          setResults(results.concat(newResults.items));
        }
      })
      .catch(() => {
        setResults([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (event: FormEvent) =>
    handleSubmit(async (values) => {
      setResults([]);
      setPage(0);
      setSearchTerm(values.searchTerm);
      fetchDocuments(showAll, values.searchTerm, 0);
      onHandleSubmit();
    })(event).catch((_) => {
      // TODO Set validation errors here
      setError('searchTerm', {
        type: 'custom',
        message: 'invalid',
      });
    });

  useEffect(() => {
    if (page > 0) {
      fetchDocuments();
    }
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (!searchTerm) return;
    setPage(0);
    setResults([]);
    fetchDocuments(showAll, searchTerm, 0, orderSelectors);
    // eslint-disable-next-line
  }, [orderSelectors]);

  useEffect(() => {
    if (!searchTerm) return;
    setPage(0);
    setResults([]);
    fetchDocuments(showAll, searchTerm, 0, orderSelectors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAll]);

  return (
    <Box className="search-panel search-header four-items">
      <form onSubmit={onSubmit} noValidate>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FormTextField
            sx={{ maxWidth: '350px', mr: 2, mb: 1 }}
            control={control}
            name="searchTerm"
            label={t('dashboard.search.placeholder')}
            withIcon={true}
          />
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            {t('search.start')}
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export type Order = 'asc' | 'desc';

export function compare<T>(a: T, b: T, orderBy: keyof T, order: Order, transform?: (value: any) => any): number {
  const valueA = transform ? transform(a[orderBy]) : a[orderBy];
  const valueB = transform ? transform(b[orderBy]) : b[orderBy];
  
  if (valueA === valueB) return 0;
  if (valueA === null || valueA === undefined) return 1;
  if (valueB === null || valueB === undefined) return -1;

  return compareValues(valueA, valueB) * (order === 'asc' ? 1 : -1);
}

function compareValues(valueA: any, valueB: any) {
  if (valueB > valueA) return 1;
  if (valueB < valueA) return -1;
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  transform?: (value: any) => any
): (
  a: { [key in Key]?: number | string | undefined | null | string[] },
  b: { [key in Key]?: number | string | undefined | null | string[] }
) => number {
  return (a, b) => compare(a, b, orderBy, order, transform);
}

import { IGetDealResponse } from '../../../../generated/ApiClient';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Item } from '../../new/common/Item';

interface Props {
  deal?: IGetDealResponse;
}

export function DealHeader({ deal }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        p: 1,
        m: 1,
        width: '100%',
      }}
    >
      <Item name={t('deals.edit.header.vehicleTypeName')} value={deal?.vehicleTypeName} />
      <Item name={t('deals.edit.header.vehicleConditionName')} value={deal?.vehicleConditionName} />
      <Item
        style={{ width: 'unset' }}
        name={t('deals.edit.header.modelName')}
        value={deal?.modelName}
      />
      {deal?.registrationDate && (
        <Item
          name={t('deals.edit.header.registrationDate')}
          value={deal?.registrationDate?.toLocaleDateString()}
        />
      )}
      <Item
        last={!deal?.source}
        name={t('deals.edit.header.engineTypeName')}
        value={deal?.engineTypeName}
      />
      {deal?.source && <Item last name={t('deals.edit.header.source')} value={deal?.source} />}
    </Box>
  );
}

import { SubmittedDealOverview } from '../../../../../../generated/ApiClient';
import { useTranslation } from 'react-i18next';
import { HeadCell } from '../../../../../../common/table/EnhancedTableHead';
import { EnhancedTable } from '../../../../../../common/table/EnhancedTable';
import { DateTimeFormatter } from '../../../../../../common/formatter/DateTimeFormatter';
import React, { Dispatch, SetStateAction } from 'react';

interface Props {
  deals: SubmittedDealOverview[];
  loadNewPage: () => void;
  loading: boolean;
  selectedDealId: number | undefined;
  setSelectedDealId: Dispatch<SetStateAction<number | undefined>>;
  orderSelectors: string[];
  setOrderSelectors: Dispatch<SetStateAction<string[]>>;
}

interface SubmittedDealRow {
  id: string;
  salesPersonName: string;
  creationDate: string;
  customerName: string;
  trademarkName: string;
  modelName: string;
}

function mapDeal(deal: SubmittedDealOverview): SubmittedDealRow {
  return {
    id: deal.id.toString(),
    salesPersonName: deal.salesPersonName,
    creationDate: deal.creationDate ? DateTimeFormatter.format(deal.creationDate) : '-',
    customerName: deal.customerName,
    trademarkName: deal.trademarkName,
    modelName: deal.modelName,
  };
}

const ReconsiderationList = ({
  deals,
  loadNewPage,
  loading,
  selectedDealId,
  setSelectedDealId,
  orderSelectors,
  setOrderSelectors,
}: Props) => {
  const { t } = useTranslation();

  const headCells: readonly HeadCell<SubmittedDealRow>[] = [
    {
      id: 'salesPersonName',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.reconsideration.salesPerson'),
    },
    {
      id: 'id',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.reconsideration.id'),
      columnName: 'id',
      allowSorting: true,
    },
    {
      id: 'creationDate',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.reconsideration.creationDate'),
      columnName: 'creationDate',
      allowSorting: true,
    },
    {
      id: 'customerName',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.reconsideration.customerName'),
      columnName: 'customerName',
      allowSorting: true,
    },
    {
      id: 'trademarkName',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.reconsideration.trademarkName'),
      columnName: 'trademarkName',
      allowSorting: true,
    },
    {
      id: 'modelName',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.calculationDrawer.reconsideration.modelName'),
      columnName: 'modelName',
      allowSorting: true,
    },
  ];

  const rows: SubmittedDealRow[] = deals.map(mapDeal);

  const handleSelection = (_: React.SyntheticEvent<unknown>, names: readonly string[]) => {
    if (names.length > 0) {
      setSelectedDealId(Number(names[0]));
    } else {
      setSelectedDealId(undefined);
    }
  };

  const onSortRequest = (orderSelectors: string[]) => {
    setOrderSelectors(orderSelectors);
  };

  return (
    <EnhancedTable
      rows={rows}
      headCells={headCells}
      loadNewPage={loadNewPage}
      variant="endless"
      loading={loading}
      hideCheckbox={false}
      rowsUntilLoad={1}
      enableSelection
      enableSingleSelection
      onSortRequest={onSortRequest}
      onSelection={handleSelection}
      defaultSelected={selectedDealId ? [selectedDealId.toString()] : []}
    />
  );
};

export default ReconsiderationList;

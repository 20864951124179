import { Box, Grid, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IContractDetail, NotificationModel } from '../../../generated/ApiClient';
import { formatToEuro } from '../../deals/new/finances/FinancesCalculationList';
import { dateFormat, DateTimeFormatter } from '../../../common/formatter/DateTimeFormatter';
import RestHttpClient from '../../../common/RestHttpClient';
import { pushNotificationHandler } from '../../../common/PushNotification';

interface Props {
  contract?: IContractDetail;
  cmsId?: string;
}

export function OverlayContract({ contract, cmsId }: Props) {
  const { t } = useTranslation();

  const [inquiryText, setInquiryText] = useState('');

  const sendInquiry = () => {
    if (cmsId) {
      RestHttpClient.sendInquire(
        cmsId,
        new NotificationModel({
          messageBody: inquiryText,
        })
      ).then(() => {
        pushNotificationHandler.publish(
          t('dashboard.contractsOverlay.contract.success'),
          'success'
        );
      });
    }
  };

  return (
    <>
      <Box mt={2}>
        <Typography variant="subtitle1">{t('dashboard.contractsOverlay.netInfo')}</Typography>
      </Box>

      <Grid container width="100%" mt={2}>
        <Grid item xs={6} container>
          <Grid item xs={6} className="value-column">
            <div>{t('dashboard.contractsOverlay.contract.salesPerson')}</div>
            <div>{t('dashboard.contractsOverlay.contract.customer')}</div>
            <div>{t('dashboard.contractsOverlay.contract.customerAddress')}</div>
            <div>{t('dashboard.contractsOverlay.contract.product')}</div>
          </Grid>
          <Grid item xs={6} className="value-column">
            <div>{contract?.salesPerson}</div>
            <div>{contract?.customerName}</div>
            <div>{contract?.customerAddress}</div>
            <div>{contract?.financingType}</div>
          </Grid>
        </Grid>
        <Grid item xs={6} container>
          <Grid item xs={6} className="value-column">
            <div>{t('dashboard.contractsOverlay.contract.term')}</div>
            <div>{t('dashboard.contractsOverlay.contract.mileage')}</div>
            <div>{t('dashboard.contractsOverlay.contract.downPayment')}</div>
            <div>{t('dashboard.contractsOverlay.contract.price')}</div>
          </Grid>
          <Grid item xs={6} className="value-column">
            <div>
              {t('dashboard.contractsOverlay.contract.months', { amount: contract?.duration })}
            </div>
            <div>{contract?.mileage} km</div>
            <div>{formatToEuro(contract?.prepayment)}</div>
            <div>{formatToEuro(contract?.purchasePrice)}</div>
          </Grid>
        </Grid>
      </Grid>

      <div className="line" />

      <div className="section-title">{t('dashboard.contractsOverlay.contract.history')}</div>

      <Grid container width="100%" mt={2}>
        <Grid item xs={6} container>
          <Grid item xs={6} className="value-column">
            <div>{t('dashboard.contractsOverlay.contract.contractStart')}</div>
            <div>{t('dashboard.contractsOverlay.contract.contractEnd')}</div>
          </Grid>
          <Grid item xs={6} className="value-column">
            <div>
              {DateTimeFormatter.format(contract?.contractStartDate, { format: dateFormat })}
            </div>
            <div>{DateTimeFormatter.format(contract?.contractEndDate, { format: dateFormat })}</div>
          </Grid>
        </Grid>
        <Grid item xs={6} container>
          <Grid item xs={6} className="value-column">
            <div>{t('dashboard.contractsOverlay.contract.approvalDate')}</div>
            <div>{t('dashboard.contractsOverlay.contract.comment')}</div>
          </Grid>
          <Grid item xs={6} className="value-column">
            <div>{DateTimeFormatter.format(contract?.dateOfApproval, { format: dateFormat })}</div>
            <div>{contract?.note ?? '-'}</div>
          </Grid>
        </Grid>
      </Grid>

      <div className="line" />
      <div className="section-title">{t('dashboard.contractsOverlay.contract.placeRequest')}</div>

      <Grid container spacing={1} mt={2}>
        <Grid item xs={10}>
          <TextField
            value={inquiryText}
            onChange={(e) => setInquiryText(e.target.value)}
            variant="standard"
            fullWidth
            placeholder={t('dashboard.contractsOverlay.contract.placeholder')}
          />
        </Grid>
        <Grid item xs={1}>
          <Button color="secondary" variant="outlined" onClick={sendInquiry}>
            {t('dashboard.contractsOverlay.contract.send')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

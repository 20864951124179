import { Box, Divider, FormLabel, Tab, Tabs } from '@mui/material';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { a11yProps, TabPanel } from '../../../component/TabPanel';
import { Finance } from './Finance';
import { Income } from './Income';
import { PersonalData } from './PersonalData';
import RestHttpClient from '../../../common/RestHttpClient';
import { PrivateCustomerFormValues } from '../data/PrivateCustomerFormValues';
import { JobTitleView, OptionItem } from '../../../generated/ApiClient';
import { customErrorHandler } from '../../../common/validation/CustomErrorHandler';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

export interface PrivateCustomerFormProps {
  control: Control<PrivateCustomerFormValues>;
  isNew?: boolean;
  readonly: boolean;
  actions?: JSX.Element;
  setValue: UseFormSetValue<PrivateCustomerFormValues>;
  tabErrors: number[];
  showSelfDisclosure?: boolean;
}

export const privatePersonalFields: Array<keyof PrivateCustomerFormValues> = [
  'salutationId',
  'titleId',
  'titleInternationalId',
  'firstName',
  'lastName',
  'birthDate',
  'maritalStatusId',
  'citizenshipId',
  'ksvId',
  'addressStreet',
  'addressHouseNumber',
  'addressPostalCode',
  'addressCity',
  'addressCountryId',
  'phoneNumber',
  'additionalPhoneNumber',
  'emailAddress',
  'numberOfSupportedChildren',
  'numberOfChildrenUnderEighteen',
  'residencePermitValidSince',
  'residencePermitValidTill',
  'inAustriaSince',
  'workPermitValidSince',
  'workPermitValidTill',
  'bankAccountIBAN',
  'bankAccountBIC',
  'pepSpecificationId',
  'emailMarketingAggreed',
  'phoneMarketingAggreed',
  'postalMarketingAggreed',
  'dataInformationSheetTransferred',
];

export const privateIncomeFields: Array<keyof PrivateCustomerFormValues> = [
  'jobTitleId',
  'currentEmployerName',
  'currentEmployerAddress',
  'employedSince',
  'verifiableMonthlyIncome',
  'verifiableMonthlyAddIncome',
  'verifiableMonthlyAddIncomeType',
];

export const privateFinanceFields: Array<keyof PrivateCustomerFormValues> = [
  'residenceTypeId',
  'monthlyRentInclAddCharges',
  'monthlyLoanRepayment',
  'currentBankLoansAndOverdrafts',
  'maintenanceObligations',
  'currentHousehold',
  'assetCity',
  'assetTypeId',
  'assetSize',
  'otherAssets',
  'otherAssetTypeId',
  'selfDisclosureAgreed'
];

export const errorHandler = (
  err: FieldErrors<PrivateCustomerFormValues>,
  setTabErrors: Dispatch<SetStateAction<number[]>>
) =>
  customErrorHandler<PrivateCustomerFormValues>(err, [
    {
      fields: privatePersonalFields,
      handler: () => {
        setTabErrors((prevState) => [...prevState, 0]);
      },
    },
    {
      fields: privateIncomeFields,
      handler: () => {
        setTabErrors((prevState) => [...prevState, 1]);
      },
    },
    {
      fields: privateFinanceFields,
      handler: () => {
        setTabErrors((prevState) => [...prevState, 2]);
      },
    },
  ]);

export function PrivateCustomerForm({
  control,
  isNew,
  readonly,
  actions,
  tabErrors,
  setValue,
  showSelfDisclosure
}: PrivateCustomerFormProps) {
  const [value, setTabValue] = useState(0);

  const [salutations, setSalutations] = useState<OptionItem[]>([]);
  const [titles, setTitles] = useState<OptionItem[]>([]);
  const [titlesInternational, setTitlesInternational] = useState<OptionItem[]>([]);
  const [maritalStatus, setMaritalStatus] = useState<OptionItem[]>([]);
  const [countries, setCountries] = useState<OptionItem[]>([]);
  const [residenceTypes, setResidenceTypes] = useState<OptionItem[]>([]);
  const [assetTypes, setAssetTypes] = useState<OptionItem[]>([]);
  const [otherAssetTypes, setOtherAssetTypes] = useState<OptionItem[]>([]);
  const [jobTitles, setJobTitles] = useState<JobTitleView[]>([]);
  const [pepSpecifications, setPepSpecifications] = useState<OptionItem[]>([]);

  const firstname = useWatch({
    control,
    name: 'firstName',
    defaultValue: '',
  });
  const lastname = useWatch({
    control,
    name: 'lastName',
    defaultValue: '',
  });

  const jobTitleId = useWatch({control: control, name: "jobTitleId"});
  const noIncome = jobTitles.find(x => x.id === jobTitleId)?.noIncome ?? false;
  
  useEffect(() => {
    if (noIncome) {
      setValue("verifiableMonthlyIncome", 0);
      setValue("verifiableMonthlyAddIncome", 0);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTitleId, noIncome]);


  useEffect(() => {
    RestHttpClient.getSalutations().then((res) => setSalutations(res.data));
    RestHttpClient.getMaritalStatus().then((res) => setMaritalStatus(res.data));
    RestHttpClient.getCountries().then((res) => setCountries(res.data));
    RestHttpClient.getTitles().then((res) => setTitles(res.data));
    RestHttpClient.getTitlesInternational().then((res) => setTitlesInternational(res.data));
    RestHttpClient.getResidenceTypes().then((res) => setResidenceTypes(res.data));
    RestHttpClient.getAssetTypes().then((res) => setAssetTypes(res.data));
    RestHttpClient.getOtherAssetTypes().then((res) => setOtherAssetTypes(res.data));
    RestHttpClient.getJobTitles().then((res) => setJobTitles(res.data));
    RestHttpClient.getPepSpecifications().then((res) => setPepSpecifications(res.data));
  }, []);

  const personalDropdownValues = {
    salutations: salutations,
    titles: titles,
    titlesInternational: titlesInternational,
    maritalStatus: maritalStatus,
    countries: countries,
    pepSpecifications: pepSpecifications,
  };

  const financeDropdownValues = {
    residenceTypes: residenceTypes,
    assetTypes: assetTypes,
    otherAssetTypes: otherAssetTypes,
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function DisplayCustomerName() {
    return (
      <h2 style={{ margin: 'auto 0' }}>
        {firstname}, {lastname}
      </h2>
    );
  }

  return (
    <Box
      className="private-customer-form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: 'unset' },
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {!isNew && <DisplayCustomerName />}
        <Tabs aria-label="basic tabs example" value={value} onChange={handleChange}>
          <Tab
            label={t('customers.edit.personalData.label')}
            {...a11yProps(0)}
            sx={{ color: tabErrors.includes(0) ? 'red !important' : undefined }}
          />
          <Tab
            label={t('customers.edit.income.label')}
            {...a11yProps(1)}
            sx={{ color: tabErrors.includes(1) ? 'red !important' : undefined }}
          />
          <Tab
            label={t('customers.edit.finance.label')}
            {...a11yProps(2)}
            sx={{ color: tabErrors.includes(2) ? 'red !important' : undefined }}
          />
        </Tabs>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {actions}
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <PersonalData
          control={control}
          dropdownValues={personalDropdownValues}
          isNew={isNew}
          readonly={readonly}
          setValue={setValue}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Income control={control} jobTitles={jobTitles} readonly={readonly} noIncome={noIncome} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Finance control={control} dropdownValues={financeDropdownValues} readonly={readonly} showSelfDisclosure={showSelfDisclosure} />
      </TabPanel>
      <Divider />
      <Box sx={{ flexDirection: 'column', display: 'flex', marginTop: '20px' }}>
        <FormLabel>{t('customers.edit.legend.mandatory')}</FormLabel>
        <FormLabel>{t('customers.edit.legend.ksv')}</FormLabel>
      </Box>
    </Box>
  );
}

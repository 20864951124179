import React, { useContext, useEffect } from 'react';
import {
  NotificationLocation,
  NotificationSeverity,
  NotificationView,
} from '../../generated/ApiClient';
import { SnackbarKey, enqueueSnackbar, useSnackbar } from 'notistack';
import { notificationHandler } from './NotificationModal';
import { Button } from '@mui/material';
import { t } from 'i18next';
import { GlobalContext, storageKey } from './GlobalContext';
import { getUser } from '../auth/OidcConfig';

export const useNotifications = (location: NotificationLocation) => {
  const { notifications, onClose } = useNotificationsBase();
  
  useEffect(() => {
    const relevantNotifications = getRelevantNotifcations(notifications, location);

    const popUpNotifications = relevantNotifications.filter((x) => x.isPopUp);
    const pushNotifications = relevantNotifications.filter((x) => !x.isPopUp);

    pushNotifications.forEach((x) => enqueueNotification(x, onClose));

    if (popUpNotifications.length) {
      notificationHandler.confirm(
        () => omitNotifications(popUpNotifications.filter((x) => !x.isRecurring).map((x) => x.id)),
        popUpNotifications
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications.length]);
};

export const useNotificationsBase = () => {
  var { notifications, isNotificationsFetched } = useContext(GlobalContext);
  const { closeSnackbar } = useSnackbar();

  const onClose = (key: SnackbarKey, notification: NotificationView) => {
    if (!notification.isRecurring) omitNotifications([notification.id]);
    closeSnackbar(key);
  };

  return {
    onClose,
    notifications,
    closeSnackbar,
    isNotificationsFetched
  };
};

export const getRelevantNotifcations = (
  notifications: NotificationView[],
  location: NotificationLocation,
  ignoreRead?: boolean
) => {
  const now = new Date();
  const relevantNotifications = notifications
    .filter((x) => x.locations.includes(location))
    .filter((x) => x.validFrom <= now && (x.validTo === undefined || x.validTo >= now));

  if (ignoreRead) return relevantNotifications;

  const readMessages = ignoreRead ? [] : localStorage.getItem(storageKey)?.split(';') ?? [];

  return relevantNotifications.filter((x) => !readMessages.includes(x.id));
};

export const mapNotificationColor = (severity: NotificationSeverity) => {
  switch (severity) {
    case NotificationSeverity.Error:
      return 'error';
    case NotificationSeverity.Warning:
      return 'warning';
    case NotificationSeverity.Information:
      return 'info';
  }
};

export const enqueueNotification = (
  x: NotificationView,
  onClose: (key: SnackbarKey, x: NotificationView) => void
) => {
  enqueueSnackbar(<div dangerouslySetInnerHTML={{ __html: x.content }}></div>, {
    persist: true,
    anchorOrigin: { horizontal: 'center', vertical: 'top' },
    preventDuplicate: true,
    variant: mapNotificationColor(x.severity),
    key: x.id,
    action: (key) => (
      <Button color="secondary" onClick={() => onClose(key, x)}>
        {x.isRecurring ? t('close') : t('omit')}
      </Button>
    ),
  });
};

export const omitNotifications = (ids: string[]) => {
  const readMessages = localStorage.getItem(storageKey)?.split(';') ?? [];
  readMessages.push(...ids);

  localStorage.setItem(storageKey, readMessages.join(';'));
};


export const isShowLoginMessage = () => {
  const lastLoginMessage = localStorage.getItem('lastLoginMessage');

  if (!lastLoginMessage) return true;

  const user = getUser();

  if (!user?.profile.iat) return false;

  return user.profile.iat > parseInt(lastLoginMessage);
}

export const updateLastLoginMessage = () => {
  const user = getUser();

  if (!user?.profile.iat) return;

  localStorage.setItem('lastLoginMessage', user.profile.iat.toString());
}
import { Box } from '@mui/material';
import { Icons } from '../../../../component/icons/Icons';
import { useTranslation } from 'react-i18next';

interface Props {
  date: Date | undefined;
  isPrimaryCalculation: boolean;
  locked?: boolean;
  extendedAt?: Date;
}

export function SendToContractManagementSystemDate(props: Props) {
  const { date, locked, extendedAt } = props;

  return date !== undefined ? (
    extendedAt !== undefined ? (
      <Extended {...props} />
    ) : (
      <Submitted {...props} />
    )
  ) : locked ? (
    <Locked />
  ) : (
    <Default />
  );
}

function Submitted({ isPrimaryCalculation, date }: Props) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        gap: 1,
      }}
    >
      {isPrimaryCalculation ? Icons.ellipse() : Icons.rectangle()}
      <Box sx={{ pr: 1 }}>
        {' '}
        {` ${t('deals.new.financesConfiguration.list.sendToContractManagementSystemDateSended')} `}
      </Box>
      {date?.toLocaleString()}
    </Box>
  );
}

function Default() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        gap: 1,
      }}
    >
      {Icons.polygon()}{' '}
      <span>
        {t('deals.new.financesConfiguration.list.sendToContractManagementSystemDateNotSended')}
      </span>
    </Box>
  );
}

function Locked() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        gap: 1,
      }}
    >
      {Icons.search()} <span>{t('deals.new.financesConfiguration.list.locked')}</span>
    </Box>
  );
}

function Extended({ isPrimaryCalculation, extendedAt }: Props) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        gap: 1,
      }}
    >
      {isPrimaryCalculation ? Icons.ellipse() : Icons.rectangle()}
      <Box sx={{ pr: 1 }}>
        {' '}
        {` ${t(
          'deals.new.financesConfiguration.list.sendToContractManagementSystemDateExtended'
        )} `}
      </Box>
      {extendedAt?.toLocaleString()}
    </Box>
  );
}

import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { ApplicationModel } from '../../../generated/ApiClient';

type DashboardSearchState = {
  showAll: boolean;
  setShowAll: Dispatch<SetStateAction<boolean>>;
  results: ApplicationModel[];
  setResults: Dispatch<SetStateAction<ApplicationModel[]>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  total: number;
  setTotal: Dispatch<SetStateAction<number>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  orderSelectors: string[] | undefined;
  setOrderSelectors: Dispatch<SetStateAction<string[] | undefined>>;
};

export const DashboardSearchContext = createContext<DashboardSearchState>(
  {} as DashboardSearchState
);

export function DashboardSearchProvider({ children }: { children: React.ReactNode }) {
  const [results, setResults] = useState<ApplicationModel[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [orderSelectors, setOrderSelectors] = useState<string[] | undefined>(undefined);

  return (
    <DashboardSearchContext.Provider
      value={{
        showAll,
        setShowAll,
        page,
        setPage,
        total,
        setTotal,
        results,
        setResults,
        loading,
        setLoading,
        orderSelectors,
        setOrderSelectors,
      }}
    >
      {children}
    </DashboardSearchContext.Provider>
  );
}

import React, { useEffect, useState } from 'react';
import { EnhancedDialog } from '../../../../../../component/EnhancedDialog';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReconsiderationSearch, { ReconsiderationSearchType } from './ReconsiderationSearch';
import {
  SubmittedDealOverview,
  SubmittedDealOverviewIPagedList,
} from '../../../../../../generated/ApiClient';
import ReconsiderationList from './ReconsiderationList';
import { Control, useWatch } from 'react-hook-form';
import { FinancesDrawerFormValues } from '../../RightDrawerSchema';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { Icons } from '../../../../../../component/icons/Icons';
import { useNavigate } from 'react-router';
import { confirmHandler } from '../../../../../../common/ConfirmModal';
import { Info } from '@mui/icons-material';
interface Props {
  control: Control<FinancesDrawerFormValues>;
  dealId?: number;
  calculationId?: number;
  disabled?: boolean;
  fetchSubmittedDeals: (
    page?: number,
    id?: string,
    salesPersonName?: string,
    creationDate?: Date,
    customerName?: string,
    orderSelectors?: string[]
  ) => Promise<SubmittedDealOverviewIPagedList>;
  setValue: UseFormSetValue<FinancesDrawerFormValues>;
  onRelevantChange: (name: string, value: unknown) => void;
}

const Reconsideration = ({
  dealId,
  control,
  calculationId,
  disabled,
  fetchSubmittedDeals,
  setValue,
  onRelevantChange,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [overtakeCustomer, setOvertakeCustomer] = useState(false);
  const [deals, setDeals] = useState<SubmittedDealOverview[]>([]);
  const [searchValues, setSearchValues] = useState<ReconsiderationSearchType>({});
  const [orderSelectors, setOrderSelectors] = useState<string[]>(['id|desc']);

  const referencesCalculationId = useWatch({ control, name: 'referencesCalculationId' });
  const referencesDealId = useWatch({ control, name: 'referencesDealId' });
  const [selectedDeadId, setSelectedDealId] = useState<number | undefined>(
    referencesDealId ?? undefined
  );

  const fetchDeals = async (
    p: number,
    id?: string,
    salesPersonName?: string,
    creationDate?: Date,
    customerName?: string,
    orderSelectors?: string[]
  ) => {
    setLoading(true);
    try {
      const data = await fetchSubmittedDeals(
        p,
        id,
        salesPersonName,
        creationDate,
        customerName,
        orderSelectors
      );
      if (p === 0) {
        setDeals(data.items);
      } else {
        setDeals(deals.concat(data.items));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearchSubmit = (values: ReconsiderationSearchType) => {
    setPage(0);
    setSearchValues(values);
    setDeals([]);
    fetchDeals(0, values.id, values.salesPersonName, values.creationDate, values.customerName);
  };

  useEffect(() => {
    if (open) {
      setOvertakeCustomer(false);
      setPage(0);
      setSearchValues({});
      fetchDeals(0);
      setValue('removeReconsiderationReference', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealId, calculationId, open]);

  useEffect(() => {
    if (open) {
      const { id, salesPersonName, customerName, creationDate } = searchValues;
      fetchDeals(0, id, salesPersonName, creationDate, customerName, orderSelectors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSelectors]);

  useEffect(() => {
    if (page > 0) {
      const { id, salesPersonName, customerName, creationDate } = searchValues;
      fetchDeals(page, id, salesPersonName, creationDate, customerName, orderSelectors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleLoadNew = () => {
    setPage(page + 1);
  };

  const handleSave = () => {
    const saveCallback = () => {
      setValue('referencesCalculationId', undefined);
      setValue('referencesDealId', selectedDeadId);
      setValue('overtakeCustomer', overtakeCustomer);
      setOpen(false);
      onRelevantChange('referencesDealId', selectedDeadId);
    };

    if (overtakeCustomer) {
      confirmHandler.confirm(
        t('deals.new.financesConfiguration.calculationDrawer.reconsideration.attention'),
        saveCallback,
        undefined,
        t('deals.new.financesConfiguration.calculationDrawer.reconsideration.attentionText'),
        t('continue'),
        t('back')
      );
    } else {
      saveCallback();
    }
  };

  const handleClear = () => {
    setValue('referencesCalculationId', undefined);
    setValue('referencesDealId', undefined);
    setValue('removeReconsiderationReference', true);
    setValue('overtakeCustomer', false);
    setValue('overtakenCustomerId', undefined);
    setValue('removeReconsiderationReference', true);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ml: 2, gap: 1 }}>
        {!referencesDealId && !referencesCalculationId ? (
            <>
              <Button
                disabled={disabled}
                color="secondary"
                size="small"
                variant="outlined"
                onClick={() => setOpen(true)}>
                {t('deals.new.financesConfiguration.calculationDrawer.reconsideration.button')}
              </Button>
              <Tooltip placement='right' title={t('deals.new.financesConfiguration.calculationDrawer.reconsideration.hint')}>
                  <Info color='primary' />
              </Tooltip>
            </>
        ) : (
          <>
            <Typography
              onClick={() => navigate(`/deals/${referencesDealId}/${referencesCalculationId}`)}
              variant="h5"
              sx={{ cursor: 'pointer' }}
            >
              {referencesDealId}
            </Typography>
            {!disabled && (
              <IconButton size="small" onClick={handleClear}>
                {Icons.clearAlt()}
              </IconButton>
            )}
          </>
        )}
      </Box>

      <EnhancedDialog
        maxWidth={'xl'}
        width="90vw"
        isDialogOpened={open}
        setIsDialogOpened={setOpen}
      >
        <DialogTitle>
          <Typography variant="h5" sx={{ pt: 0 }}>
            {t('deals.new.financesConfiguration.calculationDrawer.reconsideration.title')}
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <ReconsiderationSearch
              onSubmit={handleSearchSubmit}
              dealId={dealId}
              loading={loading}
            />
            <ReconsiderationList
              deals={deals}
              loadNewPage={handleLoadNew}
              loading={loading}
              selectedDealId={selectedDeadId}
              setSelectedDealId={setSelectedDealId}
              orderSelectors={orderSelectors}
              setOrderSelectors={setOrderSelectors}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <FormControlLabel
                onChange={() => setOvertakeCustomer(!overtakeCustomer)}
                control={<Checkbox checked={overtakeCustomer} />}
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.reconsideration.overtakeCustomer'
                )}
              />
              <Button variant="contained" color="primary" onClick={handleSave}>
                {t('deals.new.financesConfiguration.calculationDrawer.reconsideration.save')}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </EnhancedDialog>
    </>
  );
};

export default Reconsideration;

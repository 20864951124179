import { Dispatch, SetStateAction } from 'react';
import { EnhancedDialog } from '../../../component/EnhancedDialog';
import { TypeCertificateType } from './TypeCertificateForm';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SendTypeCertificateRequest, TypeCertificateReason } from '../../../generated/ApiClient';
import RestHttpClient from '../../../common/RestHttpClient';
import { pushNotificationHandler } from '../../../common/PushNotification';
import { Send } from '@mui/icons-material';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: TypeCertificateType;
  contractId: number;
  dealId: number;
  reasons: TypeCertificateReason[];
}

export function TypeCertificateDialog({
  data,
  isOpen,
  setIsOpen,
  contractId,
  dealId,
  reasons,
}: Props) {
  const { t } = useTranslation();

  const onSend = async () => {
    const req = new SendTypeCertificateRequest({
      ...data,
      contractId: contractId,
      dealId: dealId,
      additionalInfo: data.additionalInfo ?? undefined,
    });

    await RestHttpClient.sendTypeCertificate(req);
    pushNotificationHandler.publish(
      t('dashboard.contractsOverlay.typeCertificate.success'),
      'success'
    );
    setIsOpen(false);
  };

  const createItem = (label: string, value: string) => {
    return (
      <>
        <Grid item xs={6}>
          <Typography align="right">{label}:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{value}</Typography>
        </Grid>
      </>
    );
  };

  const reason = reasons.find((r) => r.id === String(data.reasonId));

  return (
    <EnhancedDialog isDialogOpened={isOpen} setIsDialogOpened={setIsOpen} maxWidth="md">
      <DialogTitle>{t('dashboard.contractsOverlay.typeCertificate.label')}</DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2}>
          {createItem(t('dashboard.contractsOverlay.typeCertificate.recipient'), data.recipient)}
          {data.additionalInfo &&
            createItem(
              t('dashboard.contractsOverlay.typeCertificate.additionalInfo'),
              data.additionalInfo
            )}
          {createItem(t('dashboard.contractsOverlay.typeCertificate.street'), data.street)}
          {createItem(t('dashboard.contractsOverlay.typeCertificate.city'), data.city)}
          {createItem(t('dashboard.contractsOverlay.typeCertificate.postalCode'), data.postalCode)}
          {createItem(t('dashboard.contractsOverlay.typeCertificate.country'), data.country)}
          {createItem(
            t('dashboard.contractsOverlay.typeCertificate.reasonId'),
            reason?.name ?? '-'
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="flex-end">
          <Button onClick={() => onSend()} variant="contained" endIcon={<Send />}>
            {t('dashboard.contractsOverlay.typeCertificate.send')}
          </Button>
        </Stack>
      </DialogActions>
    </EnhancedDialog>
  );
}

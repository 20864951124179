import React, { useEffect, useState } from 'react';
import RestHttpClient from '../../../common/RestHttpClient';
import { Box, CircularProgress } from '@mui/material';
import { OverviewListEntry } from './OverviewListEntry';
import { useTranslation } from 'react-i18next';
import { GlobalTextIdentifiers, useGlobalTitles } from '../../../common/global/useGlobalTitles';

interface Props {
  showAll: boolean;
  onFilterChange: (filter?: number) => void;
}

const DashboardPreliminaryWidget = ({ showAll, onFilterChange }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{ [key: string]: number }>();
  
  const getTitle = useGlobalTitles();

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await RestHttpClient.getPreliminaryWidget(showAll);
      setData(data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAll]);

  return (
    <Box className="card" width="100%">
      <h2 style={{ cursor: 'pointer' }} onClick={() => onFilterChange(undefined)}>
        {getTitle(GlobalTextIdentifiers.DashboardPreliminary)}
      </h2>
      <span className="line" />

      {data && !loading && (
        <>
          <OverviewListEntry
            title={t('dashboard.contractsAdvance.incomplete')}
            value={data[5]}
            color="blue"
            onClick={() => onFilterChange(5)}
          />
          <OverviewListEntry
            title={t('dashboard.contractsAdvance.contractEntry')}
            value={data[7]}
            color="green"
            onClick={() => onFilterChange(7)}
          />
        </>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        {loading && <CircularProgress />}
      </Box>
    </Box>
  );
};

export default DashboardPreliminaryWidget;

import { Controller } from 'react-hook-form';
import { FormComponentProps } from './FormComponentProps';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { FieldValues } from 'react-hook-form/dist/types';

export interface OptionItem {
  label: string;
  value: string | number;
}

interface FormDropdownProps<TFieldValues extends FieldValues>
  extends FormComponentProps<TFieldValues> {
  options: OptionItem[];
  emptyText?: string;
  emptyValue?: number | string;
  isRequired?: boolean;
  readOnly?: boolean;
}

export const FormDropdown = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  options,
  placeholder,
  shrink,
  emptyText,
  emptyValue,
  onChange,
  isRequired,
  readOnly,
  ...others
}: FormDropdownProps<TFieldValues> & TextFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            label={label}
            error={!!error}
            placeholder={label}
            required={isRequired}
            {...field}
            onChange={(event) => {
              if (event.target.value === '') {
                // @ts-ignore
                event.target.value = null;
              }
              field.onChange(event);
              if (onChange) {
                onChange(event);
              }
            }}
            InputProps={{ readOnly }}
            value={field.value || 0}
            fullWidth={true}
            select={true}
            InputLabelProps={{ shrink }}
            variant="standard"
            helperText={error ? error.message : ''}
            {...others}
            sx={
              (others.required || isRequired) && !error && !others.disabled
                ? {
                    '& .MuiInput-underline:not(.Mui-focused):before': {
                      borderBottom: '2px solid black',
                    },
                    '& .MuiInput-underline:not(.Mui-focused):after': {
                      borderBottom: '2px solid black',
                    },
                    ...others.sx,
                  }
                : others.sx
            }
          >
            {emptyText && (
              <MenuItem value={emptyValue !== undefined ? emptyValue : ''} key={null}>
                <em>{emptyText}</em>
              </MenuItem>
            )}
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    ></Controller>
  );
};

FormDropdown.defaultProps = {
  emptyValue: 0,
};

import { t } from 'i18next';
import { z } from 'zod';
import {
  checkBoxMustBeTrue,
  getDateBefore,
  optionalDate,
  dateLessOrEqualToday,
  radioButton,
  swiftCode,
  iban,
  phoneNumber,
  //optionalMobileNumberRegex,
  requiredString,
  emptyStringToUndefined,
} from './../../../common/validation/Validations';

export const commercialCustomerBaseSchema = z.object({
  legalFormId: z.number(),
  companyName: requiredString,
  addressStreet: requiredString,
  addressHouseNumber: requiredString,
  addressPostalCode: requiredString,
  addressCity: requiredString,
  addressCountryId: z.number(),
  additionalPhoneNumber: phoneNumber.or(emptyStringToUndefined).optional().nullable(),
  phoneNumber: phoneNumber.or(emptyStringToUndefined).optional().nullable(),
  emailAddress: z.string().email(),
  foundationDate: optionalDate,
  uidNumber: z.string().optional().nullable(),
  commercialRegisterNumber: z.string().optional().nullable(),
  branchId: z.number().optional().nullable(),
  companyBusiness: z.string().optional().nullable(),
  bankAccountIBAN: iban.optional().nullable(),
  bankAccountBIC: swiftCode.optional().nullable(),
  ksvId: z.string().optional().nullable(),
  ksvSearchDone: z.boolean().optional().nullable(),
  dataInformationSheetTransferred: checkBoxMustBeTrue,
  emailMarketingAggreed: radioButton,
  phoneMarketingAggreed: radioButton,
  postalMarketingAggreed: radioButton,
  salutationId: z.number().optional().nullable(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  birthDate: z.date().max(getDateBefore(18)).optional().nullable(),
  maritalStatusId: z.number().optional().nullable(),
  citizenshipId: z.number().optional().nullable(),
  numberOfSupportedChildren: z.number().optional().nullable(),
  numberOfChildrenUnderEighteen: z.number().optional().nullable(),
  residencePermitValidSince: optionalDate,
  residencePermitValidTill: optionalDate,
  inAustriaSince: dateLessOrEqualToday.optional().nullable(),
  selfEmployedSince: dateLessOrEqualToday.optional().nullable(),
  isGeneralPartner: z.boolean().optional().nullable(),
  isPotentialBuyer: radioButton,
  selfDisclosureAgreed: z.boolean().optional().nullable(),
  selfDisclosureAgreedAt: z.date().optional().nullable(),
  pepSpecificationId: z.number(),
  politicalExposedPersonRelated: z.boolean().optional().nullable(),
  riskIncreasingCircumstances: radioButton
});

export const commercialPotentialBuyerSchema = commercialCustomerBaseSchema
  .deepPartial()
  .refine((args) => args.companyName, {
    message: t('errors.invalid_type', { ns: 'zod' }),
    path: ['companyName'],
  })
  .refine((args) => args.dataInformationSheetTransferred, {
    message: t('errors.invalid_type', { ns: 'zod' }),
    path: ['dataInformationSheetTransferred'],
  });

const refinedCommercialCustomerSchema = z
  .object({
    phoneNumber: phoneNumber.or(emptyStringToUndefined).optional().nullable(),
    additionalPhoneNumber: phoneNumber.or(emptyStringToUndefined).optional().nullable(),
    legalFormId: z.number().optional(),
    residencePermitValidSince: optionalDate,
    residencePermitValidTill: optionalDate,
  })
  .refine(
    (args) => {
      const hasPhoneNumber =
        args.phoneNumber !== undefined && args.phoneNumber != null && args.phoneNumber.length > 0;
      const hasAdditionalPhoneNumber =
        args.additionalPhoneNumber !== undefined &&
        args.additionalPhoneNumber != null &&
        args.additionalPhoneNumber.length > 0;
      return hasPhoneNumber || hasAdditionalPhoneNumber;
    },
    {
      message: t('errors.invalid_type', { ns: 'zod' }),
      path: ['phoneNumber'],
    }
  )
  .refine(
    (args) =>
      args.residencePermitValidSince === undefined ||
      args.residencePermitValidTill === undefined ||
      args.residencePermitValidSince === null ||
      args.residencePermitValidTill === null ||
      args.residencePermitValidSince < args.residencePermitValidTill,
    {
      message: t('customers.edit.personalData.residencePermitValidSinceValidationError'),
      path: ['residencePermitValidSince'],
    }
  )
  .refine((args) => args.legalFormId !== 398, {
    message: t('errors.invalid_type', { ns: 'zod' }),
    path: ['legalFormId'],
  });

export const commercialCustomerRequestSchema = z.intersection(
  commercialCustomerBaseSchema,
  refinedCommercialCustomerSchema
);

export type CommercialCustomerFormValues = z.infer<typeof commercialCustomerRequestSchema>;

export const netToGross = (net?: number): number => {
  if (!net) {
    return 0;
  }
  return net * ((100.0 + 20) / 100);
};

export const grossToNet = (gross?: number): number => {
  if (!gross) {
    return 0;
  }
  return gross / (20 / 100 + 1);
};

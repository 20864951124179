import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { usePermissions } from '../../common/auth/PermissionsContext';

export function CustomerHeader() {
  const { hasPermission } = usePermissions();
  const { t } = useTranslation();

  return (
    <Box className="view-header">
      <h2>{t('customers.header')}</h2>
      <Box className="button-container">
        {hasPermission('Customers', 'Create') && (
          <Button component={Link} to="new" variant="contained">
            {t('customers.newCustomer')}
          </Button>
        )}
      </Box>
    </Box>
  );
}

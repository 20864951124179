import { PrivateCustomerFormValues } from '../data/PrivateCustomerFormValues';
import { Control, useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { OptionItem } from '../../../generated/ApiClient';
import RestHttpClient from '../../../common/RestHttpClient';
import { Box, Divider, FormLabel } from '@mui/material';
import { t } from 'i18next';
import { FormDropdown } from '../../../component/form/FormDropdown';
import { FormTextField } from '../../../component/form/FormTextField';
import { FormDatePicker } from '../../../component/form/FormDatePicker';
import { getDateBefore } from '../../../common/validation/Validations';
import { FormCheckbox } from '../../../component/form/FormCheckbox';
import { FormRadioButton } from '../../../component/form/FormRadioButton';

interface Props {
  control: Control<PrivateCustomerFormValues>;
  actions?: JSX.Element;
  isNew?: boolean;
  readonly?: boolean;
}

export const PotentialPrivateCustomerForm = ({ control, actions, isNew, readonly }: Props) => {
  const [salutations, setSalutations] = useState<OptionItem[]>([]);

  useEffect(() => {
    RestHttpClient.getSalutations().then((res) => setSalutations(res.data));
  }, []);

  const isCoApplicant = useWatch({
    control,
    name: 'isCoApplicant',
    defaultValue: false,
  });

  const dataInformationSheetTransferred = useWatch({
    control,
    name: 'dataInformationSheetTransferred',
  });

  return (
    <Box
      className="potential-customer-form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: 'unset' },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        {actions}
      </Box>
      <FormDropdown
        fullWidth
        control={control}
        name="salutationId"
        label={t('customers.edit.personalData.salutation')}
        options={salutations}
        disabled={readonly}
      />
      <FormTextField
        fullWidth
        control={control}
        name="firstName"
        label={t('customers.edit.personalData.firstName')}
        required
        disabled={readonly}
      />

      <FormTextField
        fullWidth
        control={control}
        name="lastName"
        label={t('customers.edit.personalData.lastName')}
        required
        disabled={readonly}
      />

      <FormDatePicker
        control={control}
        maxDate={getDateBefore(18)}
        name="birthDate"
        label={t('customers.edit.personalData.birthDate') + ' **'}
        disabled={readonly}
      />
      <FormTextField
        fullWidth
        control={control}
        name="addressPostalCode"
        transform={(s) => s.replace(/\D/g, '')}
        label={t('customers.edit.personalData.addressPostalCode') + ' **'}
        disabled={readonly}
      />

      {isCoApplicant && (
        <Box sx={{ m: 1 }}>
          <FormRadioButton
            required
            control={control}
            name="isApplicantSpouse"
            label={t('customers.edit.personalData.isApplicantSpouse')}
            disabled={readonly}
          />
        </Box>
      )}

      {(isNew || !dataInformationSheetTransferred) && (
        <Box sx={{ m: 1 }}>
          <FormCheckbox
            control={control}
            name="dataInformationSheetTransferred"
            label={t('customers.edit.personalData.dataInformationSheetTransferred') + '*'}
            disabled={readonly}
          />
        </Box>
      )}

      <Divider />
      <Box sx={{ flexDirection: 'column', display: 'flex', marginTop: '20px' }}>
        <FormLabel>{t('customers.edit.legend.mandatory')}</FormLabel>
        <FormLabel>{t('customers.edit.legend.insurance')}</FormLabel>
      </Box>
    </Box>
  );
};

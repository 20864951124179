import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export interface ErrorMap<TFieldValues extends FieldValues> {
  fields: Array<keyof TFieldValues>;
  handler: () => void | Promise<void>;
}

export const customErrorHandler = <TFieldValues extends FieldValues>(
  errors: FieldErrors<TFieldValues>,
  mapper: ErrorMap<TFieldValues>[]
) => {
  Object.keys(errors).forEach((error) => {
    const errorMap: ErrorMap<TFieldValues> | undefined = mapper.find((map) =>
      // @ts-ignore
      map.fields.includes(error)
    );
    if (errorMap) {
      errorMap.handler();
    }
  });
};

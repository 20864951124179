import { Dispatch, SetStateAction, useState } from 'react';
import { DealList } from '../DealList';
import { useInfiniteQuery } from 'react-query';
import RestHttpClient from '../../../common/RestHttpClient';
import { DealSearch } from './DealSearch';
import { DealOverviewIPagedList } from '../../../generated/ApiClient';

interface Props {
  toggleBasicSearch: Dispatch<SetStateAction<boolean>>;
}

export function DealSimpleSearchList({ toggleBasicSearch }: Props) {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [orderSelectors, setOrderSelectors] = useState<string[]>(['id|desc']);

  const { data, fetchNextPage, isFetching, refetch, remove} = useInfiniteQuery({
    queryKey: ['deals', searchTerm, orderSelectors],
    queryFn: ({ pageParam }) => fetchDeals(searchTerm, pageParam, orderSelectors),
    getNextPageParam: (lastPage: DealOverviewIPagedList) => {
      return lastPage.index + 1;
    },
    refetchOnWindowFocus: false,
  });

  const fetchDeals = async (st = searchTerm, p = 0, os: string[] = orderSelectors) => {
    const res = await RestHttpClient.getDealOverview(st, p, 10, os);
    return res.data;
  };

  const handleRefetch = (st: string | undefined) => {
    setSearchTerm(st);

    if (st === searchTerm) {
        remove();
        refetch();
    } 
  };
  return (
    <>
      <DealSearch
        toggleBasicSearch={toggleBasicSearch}
        setSearchTerm={setSearchTerm}
        refetch={handleRefetch}
      />
      <DealList
        deals={data?.pages.flatMap((x) => x.items) ?? []}
        loadNewPage={fetchNextPage}
        loading={isFetching}
        setOrderSelectors={setOrderSelectors}
        total={data?.pages[0].total ?? 0}
      />
    </>
  );
}

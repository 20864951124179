import { grey, red, yellow } from '@mercedes-benz/mui5-theme/';
import { ExpiredDealState } from '../../../generated/ApiClient';

export function DashboardContractExpiredIndicator({
  state
}: {
  state: ExpiredDealState
}) {
  if (state === ExpiredDealState.LessThan14Days) {
    return <span className="dashboard-indicator" style={{ background: red[65] }} />;
  }

  if (state === ExpiredDealState.LessThan25Days) {
    return <span className="dashboard-indicator" style={{ background: yellow[70] }} />;
  }

  return <span className="dashboard-indicator" style={{ background: grey[25] }} />;
}

import { zodResolver } from '@hookform/resolvers/zod';
import { Button, DialogContent, DialogTitle, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import { FormEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';
import { FormTextField } from '../../../../../component/form/FormTextField';
import { ApiconPriceFormUiState, ApiconSpecialCasePriceFormState } from '../../../../../generated/ApiClient';
import { apiconSpecialCaseSchema, SpecialCaseValues } from '../RightDrawerSchema';

interface Props {
  isFinancesSpecialCasePriceOpened: boolean;
  loading: boolean;
  readonly?: boolean;
  serviceVariantId: number | undefined;
  specialCaseValues: SpecialCaseValues;
  onRequestSpecialCasePrice: (values: SpecialCaseValues) => void;
  onClose: () => void;
}

export const FinancesSpecialCasePrice = ({
  isFinancesSpecialCasePriceOpened,
  loading,
  readonly,
  serviceVariantId,
  specialCaseValues,
  onRequestSpecialCasePrice,
  onClose
}: Props) => {
  const { hasPermission } = usePermissions();
  const canEdit = hasPermission('Services', 'Edit') && !readonly;
  const { control, reset, getValues, setValue } = useForm<SpecialCaseValues>({
    resolver: zodResolver(apiconSpecialCaseSchema),
  });

  useEffect(() => {
    reset();
    if (isFinancesSpecialCasePriceOpened) {
      setValue('priceFormState', specialCaseValues?.priceFormState ?? ApiconSpecialCasePriceFormState.Initial);
      setValue('priceFormUiState', specialCaseValues?.priceFormUiState ?? ApiconPriceFormUiState.Initial);
      setValue('priceNet', specialCaseValues?.priceNet ?? null);
      setValue('vatAsValue', specialCaseValues?.vatAsValue ?? null);
      setValue('comment', specialCaseValues?.comment ?? null);
      setValue('phone', specialCaseValues?.phone ?? null);
      setValue('email', specialCaseValues?.email ?? null);
      setValue('answer', specialCaseValues?.answer ?? null);
      setValue('offerId', specialCaseValues?.offerId ?? null);
      setValue('specialCaseId', specialCaseValues?.specialCaseId ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinancesSpecialCasePriceOpened]);

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onRequestSpecialCasePrice(getValues());
  };

  const displayAnswerAndInformation = () => {
    return specialCaseValues?.priceFormState == ApiconSpecialCasePriceFormState.SpecialCaseRequestApproved
        || specialCaseValues?.priceFormState == ApiconSpecialCasePriceFormState.SpecialCaseRequestRejected
        || specialCaseValues?.priceFormState == ApiconSpecialCasePriceFormState.SpecialCaseRequestFinished
  };

  return (
    <>
      <DialogTitle>
        {t('deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.requestSpecialCasePrice')}
      </DialogTitle>
      <DialogContent dividers>
        <form id="finances-special-case-price-form" onSubmit={onFormSubmit} noValidate>
          <Box>{t('deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.remark')}</Box>
          <Grid container spacing={4} sx={{ paddingY: 3 }}>
            <Grid item xs={12}>
              <FormTextField
                control={control}
                name="comment"
                label={t('deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.comment')}
                disabled={loading || !canEdit}
                multiline
                shrink
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                control={control}
                name="phone"
                label={t('deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.phone')}
                disabled={loading || !canEdit}
                shrink
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                control={control}
                name="email"
                label={t('deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.email')}
                disabled={loading || !canEdit}
                shrink
              />
            </Grid>
            { displayAnswerAndInformation() &&
              <Grid item xs={12}>
                <FormTextField
                  control={control}
                  name="answer"
                  label={t('deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.answer')}
                  disabled={true}
                  shrink
                />
              </Grid>
            }
          </Grid>
          <Box sx={{ textAlign: 'right' }}>
            <Button
              onClick={onClose}
              disabled={loading || !canEdit || serviceVariantId == undefined}
              sx={{ border: 1, borderColor: 'black', color: 'black', marginRight: '4px' }}
            >
              {t('cancel')}
            </Button>
            <Button type="submit" form="finances-special-case-price-form" variant="contained" disabled={loading}>
              {t('deals.new.financesConfiguration.calculationDrawer.options.specialCasePrice.requestSpecialCasePrice')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </>
  );
};

import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { confirmHandler } from '../../../../common/ConfirmModal';
import { pushNotificationHandler } from '../../../../common/PushNotification';
import RestHttpClient, { propertyPathToCamelCase } from '../../../../common/RestHttpClient';
import { LoadingModal } from '../../../../component/LoadingModal';
import {
  CustomerType,
  EditCommercialDealCustomerRequest,
  EditPrivateDealCustomerRequest,
  LocalApiMessage,
  NotificationLocation,
} from '../../../../generated/ApiClient';
import { CommercialCustomerFormValues } from '../../../customers/data/CommercialCustomerFormValues';
import { PrivateCustomerFormValues } from '../../../customers/data/PrivateCustomerFormValues';
import { EditCommercialCustomerForm } from '../../../customers/edit/EditCommercialCustomerForm';
import { EditCustomerHeader } from '../../../customers/edit/EditCustomerHeader';
import { EditPrivateCustomerForm } from '../../../customers/edit/EditPrivateCustomerForm';
import { usePermissions } from '../../../../common/auth/PermissionsContext';
import { useNotifications } from '../../../../common/global/useNotifications';

interface Props {
  dealId: number;
  customerId: number;
  readonlyOverwrite: boolean;
  errors?: LocalApiMessage[];
  reload?: () => void;
  setUnsavedChanges?: Dispatch<SetStateAction<boolean>>;
  submitRef: MutableRefObject<null>;
}

export function EditDealCustomer({
  dealId,
  customerId,
  readonlyOverwrite,
  errors,
  reload,
  setUnsavedChanges,
  submitRef,
}: Props) {
  const { t } = useTranslation();
  const [customerType, setCustomerType] = useState(CustomerType.Undefined);
  const [loading, setLoading] = useState(false);
  const { hasPermission } = usePermissions();
  const canEdit = hasPermission('Customers', 'Edit');


  useNotifications(NotificationLocation.DealCustomer);

  useEffect(() => {
    if (customerId) {
      setLoading(true);
      RestHttpClient.getCustomerType(Number(customerId))
        .then((res) => {
          setCustomerType(res.data);
        })
        .catch((err) => {
          console.error(err);
          setCustomerType(CustomerType.Undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dealId, customerId]);

  function SaveCustomerData(
    customerType: CustomerType,
    values: PrivateCustomerFormValues | CommercialCustomerFormValues,
    applyChangesToMainCustomer: boolean,
    hasRelevantChanges: boolean
  ) {
    if (customerType === CustomerType.Private) {
      const req = new EditPrivateDealCustomerRequest();
      req.init({
        ...values,
        customerId: customerId,
        dealId: dealId,
        applyChangesToMainCustomer: applyChangesToMainCustomer,
        hasRelevantChanges: hasRelevantChanges
      });
      setLoading(true);
      return RestHttpClient.editPrivateDealCustomer(req)
        .then((_) => {
          if (reload) reload();
          pushNotificationHandler.publish(t('customers.saved'), 'success');
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const req = new EditCommercialDealCustomerRequest();
      req.init({
        ...values,
        customerId: customerId,
        dealId: dealId,
        applyChangesToMainCustomer: applyChangesToMainCustomer,
        hasRelevantChanges: hasRelevantChanges
      });
      setLoading(true);
      return RestHttpClient.editCommercialDealCustomer(req)
        .then((_) => {
          if (reload) reload();
          pushNotificationHandler.publish(t('customers.saved'), 'success');
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const submitPrivate = (
    values: PrivateCustomerFormValues,
    changesMade: boolean,
    _?: string,
    callback?: Function,
    hasRelevantChanges?: boolean,
  ) => {
    confirmHandler.confirm(
      t('customers.confirmSaveMasterRecordTitle'),
      () => {
        SaveCustomerData(CustomerType.Private, values, true, hasRelevantChanges ?? true).then(() => {
          if (callback) {
            callback();
          }
        });
      },
      () => {
        SaveCustomerData(CustomerType.Private, values, false, hasRelevantChanges ?? true).then(() => {
          if (callback) {
            callback();
          }
        });
      },
      t('customers.confirmSaveMasterRecord')
    );
  };

  const submitCommercial = (
    values: CommercialCustomerFormValues,
    changesMade: boolean,
    _?: string,
    callback?: Function,
    hasRelevantChanges?: boolean
  ) => {
    confirmHandler.confirm(
      t('customers.confirmSaveMasterRecordTitle'),
      () => {
        SaveCustomerData(CustomerType.Commercial, values, true, hasRelevantChanges ?? true).then(() => {
          if (callback) {
            callback();
          }
        });
      },
      () => {
        SaveCustomerData(CustomerType.Commercial, values, false, hasRelevantChanges ?? true).then(() => {
          if (callback) {
            callback();
          }
        });
      },
      t('customers.confirmSaveMasterRecord')
    );
  };

  const customerErrors = errors?.map((e) => {
    const splits = e.target.split('.');
    return LocalApiMessage.fromJS({
      ...e,
      target: propertyPathToCamelCase(splits.length > 1 ? splits[1] : e.target),
    });
  });

  return (
    <div className="edit-customer">
      <LoadingModal loading={loading} />
      {customerType === CustomerType.Private && (
        <div>
          {!readonlyOverwrite && <EditCustomerHeader submitRef={submitRef} />}
          <EditPrivateCustomerForm
            onSubmit={submitPrivate}
            customerPromise={() => RestHttpClient.getPrivateDealCustomer(dealId, Number(customerId))}
            readonly={readonlyOverwrite || !canEdit}
            errors={customerErrors}
            isNew={!customerId}
            setUnsavedChanges={setUnsavedChanges}
            showSelfDisclosure
          />
        </div>
      )}
      {customerType === CustomerType.Commercial && (
        <div>
          {!readonlyOverwrite && <EditCustomerHeader submitRef={submitRef} />}
          <EditCommercialCustomerForm
            onSubmit={submitCommercial}
            customerPromise={() => RestHttpClient.getCommercialDealCustomer(dealId, Number(customerId))}
            readonly={readonlyOverwrite || !canEdit}
            errors={customerErrors}
            isNew={!customerId}
            setUnsavedChanges={setUnsavedChanges}
            showSelfDisclosure
          />
        </div>
      )}
    </div>
  );
}

export function parseRomanHousenumber(text: string) {
  const regex = /\b[LMIXVC]+\b/g;
  let lastMatchIndex = -1;
  let match;
  // only match last occurence
  while ((match = regex.exec(text)) !== null) {
    lastMatchIndex = match.index;
  }

  if (lastMatchIndex > 0) {
    const street = text.substring(0, lastMatchIndex);
    const housenumber = text.substring(lastMatchIndex);
    return { street, housenumber };
  }
  return { street: text, housenumber: '' };
}

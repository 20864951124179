import { grey, red, yellow, blue } from '@mercedes-benz/mui5-theme/';
import { DashboardStatusIndicator, DefaultDashboardStatusIndicator } from '../common/DashboardStatusIndicator';

export function PreliminaryDashboardStatusIndicator({ date, showHandover, state }: { date?: Date, showHandover: boolean, state: number }) {
  if (showHandover)
    return <DashboardStatusIndicator color={mapDefaultDashboardStatus(date)} />;

  return <DefaultDashboardStatusIndicator state={state} />
}

export const mapDefaultDashboardStatus = (date?: Date) => {
  const today = new Date();

  if (!date) return blue[70];

  const diff = (today.getTime() - date.getTime()) / (1000 * 3600 * 24);

  if (diff <= 13) {
    return grey[25];
  } else if (diff <= 21) {
    return yellow[70];
  } else {
    return red[70];
  }
};

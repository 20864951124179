import { z } from 'zod';
import * as IBAN from 'iban';
import i18next from 'i18next';

const getDateBefore = (years: number): Date => {
  var date = new Date();
  date.setFullYear(date.getFullYear() - years);
  date.setHours(23, 59, 59, 999);
  return date;
};

const requiredString = z
  .string()
  .min(1, { message: i18next.t('errors.invalid_type', { ns: 'zod' }) });

const optionalDate = z.date().optional().nullable();

const dateLessOrEqualToday = z.date().max(getDateBefore(0));

const radioButton = z.union([z.string(), z.boolean()]).transform((value) => {
  if (typeof value === 'string') {
    return value === 'true';
  } else {
    return value;
  }
});

const integerGreaterZero = z.union([
  z.undefined(),
  z.null(),
  z
    .string()
    .transform((n) => Number(n.replace('.', ',')))
    .refine((n) => n >= 0 && Number.isInteger(n)),
  z.number().refine((n) => n >= 0 && Number.isInteger(n)),
]);

const doubleOrNil = z.union([
  z.undefined(),
  z.null(),
  z
    .string()
    .transform((n) => {
      return Number(n.replace('.', ','));
    })
    .refine((n) => n >= 0),
  z.number().refine((n) => n >= 0),
]);

const requiredPosDouble = z.union([
  z
    .string()
    .transform((n) => {
      return Number(n.replace('.', ','));
    })
    .refine((n) => n >= 0),
  z.number().refine((n) => n >= 0),
]);

const chassisNumberRegex = new RegExp('^[a-zA-Z0-9]{17}$');
const chassisNumber = z
  .string()
  .transform((s) => (s.length > 0 ? s : undefined))
  .refine((val) => {
    if (!val) return true;
    return chassisNumberRegex.test(val);
  });

const discount = z.union([
  z
    .string()
    .transform((n) => {
      return Number(n.replace('.', ','));
    })
    .refine((n) => n >= 0 && n <= 100),
  z.number().refine((n) => n >= 0 && n <= 100),
]);

const requiredPosNonZeroDouble = z.union([
  z
    .string()
    .transform((n) => {
      return Number(n.replace('.', ','));
    })
    .refine((n) => n > 0),
  z.number().refine((n) => n > 0),
]);

const integer = z.union([z.string().transform((n) => Number(n.replace('.', ','))), z.number()]);

const integerString = z.union([
  z.undefined(),
  z.null(),
  z.string().transform((n) => (n != null ? n.replace('.', ',') : n)),
  z.number().transform((n) => (n != null ? n.toFixed(2).replace('.', ',') : n)),
]);

const requiredInteger = z.union([
  z
    .string()
    .transform((n) => Number(n.replace('.', ',')))
    .refine((n) => n >= 0 && Number.isInteger(n)),
  z.number().refine((n) => n >= 0 && Number.isInteger(n)),
]);

const numberGreaterZero = z.number().nonnegative().optional().nullable();

const checkBoxMustBeTrue = z.boolean().refine((val) => val, 'Required');

const swiftCodeRegex = new RegExp('^[A-Za-z]{6}[A-Za-z0-9]{2}[A-Za-z0-9]{3}$');
const swiftCode = z
  .string()
  .transform((s) => (s.length > 0 ? s : undefined))
  .refine((val) => {
    if (!val) return true;
    return swiftCodeRegex.test(val);
  });

const phoneNumberRegex = new RegExp('^(\\+|0{2})?(\\(\\d{1,4}\\)|\\d{1,4})[-\\s\\./0-9]+$');
const phoneNumber = z.string().max(25).regex(phoneNumberRegex);

const emptyStringToUndefined = z.literal('').transform(() => undefined);

const iban = z
  .string()
  .transform((s) => (s.length > 0 ? s : undefined))
  .refine((data) => {
    return data ? IBAN.isValid(data) : false;
  });

const removeNullValues = (a: any, ignore: string[] = []) => {
  const cleaned = { ...a };
  const keys = Object.keys(cleaned);
  keys.forEach((key) => {
    if ((cleaned[key] === null || cleaned[key] === '') && !ignore.includes(key)) {
      cleaned[key] = undefined;
    }
  });

  return cleaned;
};

const houseNumberLength = 50;

export {
  optionalDate,
  dateLessOrEqualToday,
  radioButton,
  chassisNumber,
  requiredString,
  integerGreaterZero,
  requiredInteger,
  numberGreaterZero,
  doubleOrNil,
  checkBoxMustBeTrue,
  requiredPosDouble,
  requiredPosNonZeroDouble,
  integer,
  integerString,
  swiftCode,
  iban,
  phoneNumber,
  emptyStringToUndefined,
  discount,
  removeNullValues,
  getDateBefore,
  houseNumberLength,
};

import { Grid, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FormNumberInput } from '../../../component/form/FormNumberInput';
import React, { useEffect, useState } from 'react';
import { FormDropdown } from '../../../component/form/FormDropdown';
import RestHttpClient from '../../../common/RestHttpClient';
import { OptionItem } from '../../../generated/ApiClient';


export interface DealInfoProps {
  listPrice: number,
  purchasePrice: number,
  extrasPrice: number,
  coFinancedExtras: number | undefined,
  listPriceIncludingExtras: number,
  calculationBase: number,
  discountCategory: number | undefined,
  businessType: number,
}

export function DealInfo(props: DealInfoProps) {
  const { t } = useTranslation();
  const [dealTypes, setDealTypes] = useState<OptionItem[]>([]);
  const [discountCategories, setDiscountCategories] = useState<OptionItem[]>([]);
  const {control} = useForm<DealInfoProps>({defaultValues: props});

  const inputCol = {
    sm: 12,
    md: 3,
    lg: 3,
    sx: {
      display: 'flex',
      flexDirection: 'column',
    },
  };

  
  useEffect(() => {
    RestHttpClient.getDealTypes().then((res) => {
      setDealTypes(res.data);
    });
    RestHttpClient.getDiscountCategories(props.businessType).then((res) => {
      setDiscountCategories(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.businessType]);

  return (
    <Grid
      container
      item
      spacing="24px"
      sx={{
        '& .MuiTextField-root, .price-input': { m: 1, width: 'unset', flexGrow: 1 },
      }}
    >
      <Grid item {...inputCol}>
        <FormNumberInput
          fixedDecimalScale
          control={control}
          name="listPrice"
          label={t('deals.new.financesConfiguration.header.listPrice')}
          startAdornment={<InputAdornment position="start">€</InputAdornment>}
          disabled
        />
        <FormNumberInput
          control={control}
          fixedDecimalScale
          name="purchasePrice"
          label={t('deals.new.financesConfiguration.header.purchasePrice')}
          startAdornment={<InputAdornment position="start">€</InputAdornment>}
          disabled
        />
      </Grid>
      <Grid item {...inputCol}>
        <FormNumberInput
          control={control}
          fixedDecimalScale
          name="extrasPrice"
          label={t('deals.new.financesConfiguration.header.extrasPrice')}
          startAdornment={<InputAdornment position="start">€</InputAdornment>}
          disabled
        />
        <FormNumberInput
          control={control}
          fixedDecimalScale
          name="coFinancedExtras"
          placeholder='-'
          label={t('deals.new.financesConfiguration.header.coFinancedExtras')}
          startAdornment={<InputAdornment position="start">€</InputAdornment>}
          disabled
        />
      </Grid>
      <Grid item {...inputCol}>
        <FormNumberInput
          control={control}
          fixedDecimalScale
          name="listPriceIncludingExtras"
          label={t('deals.new.financesConfiguration.header.listPriceIncludingExtras')}
          startAdornment={<InputAdornment position="start">€</InputAdornment>}
          disabled
        />
        <FormNumberInput
          control={control}
          fixedDecimalScale
          name="calculationBase"
          label={t('deals.new.financesConfiguration.header.calculationBase')}
          startAdornment={<InputAdornment position="start">€</InputAdornment>}
          disabled
        />
      </Grid>
      <Grid item {...inputCol}>
        <FormDropdown
          control={control}
          name="discountCategory"
          label={t('deals.new.financesConfiguration.header.customerCategory')}
          options={discountCategories}
          disabled
        />

        <FormDropdown
          control={control}
          name="businessType"
          label={t('deals.new.modelConfiguration.body.generalInfo.businessType')}
          options={dealTypes}
          disabled
        />
      </Grid>
    </Grid>
  );
}



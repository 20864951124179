import { Box } from '@mui/material';
import { useContext } from 'react';
import { EnhancedTable } from '../../../common/table/EnhancedTable';
import { HeadCell } from '../../../common/table/EnhancedTableHead';
import { distinct } from '../../../common/util/ArrayUtil';
import { CustomerMergeContext } from './CustomerMergeContext';
import { mapSimilarCustomer, SimilarCustomerRow } from './SimilarCustomerRow';

export function CustomerMergeSelection({
  headCells,
}: {
  headCells: readonly HeadCell<SimilarCustomerRow>[];
}) {
  const { suggestion, search } = useContext(CustomerMergeContext);

  const selectedSuggestion = suggestion.customers.filter((c) =>
    suggestion.selected.includes(c.id.toString())
  );
  const selectedSearch = search.customers.filter((c) => search.selected.includes(c.id.toString()));

  const selectedItems = selectedSearch
    .concat(selectedSuggestion)
    .filter((x, i, arr) => distinct(x.id, i, arr))
    .map((c) => mapSimilarCustomer(c));

  return (
    <Box>
      <EnhancedTable rows={selectedItems} headCells={headCells} hideCheckbox={true} />
    </Box>
  );
}

import React, { Dispatch, SetStateAction, useState } from 'react';
import RestHttpClient from '../../../../common/RestHttpClient';
import { HeadCell } from '../../../../common/table/EnhancedTableHead';
import { useTranslation } from 'react-i18next';
import { EnhancedTable } from '../../../../common/table/EnhancedTable';
import { IconButton, Tooltip } from '@mui/material';
import { Icons } from '../../../../component/icons/Icons';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PDFPreviewDialog, { PdfPreview } from '../../../../component/PDFPreviewDialog';
import { DateTimeFormatter } from '../../../../common/formatter/DateTimeFormatter';
import { isMobileSafari } from 'react-device-detect';
import { UploadedDocumentView } from '../../../../generated/ApiClient';
import { UploadedDocRow } from './EditDealUploadedDocuments';


export function UploadedDocuments({
  docs, setDocLoading, loading,
}: {
  setDocLoading: Dispatch<SetStateAction<boolean>>;
  docs: UploadedDocumentView[];
  loading: boolean;
}) {
  const [openDocFile, setOpenDocFile] = useState<PdfPreview | undefined>();
  const { t } = useTranslation();

  const handleRowClick = (id: string) => {
    setDocLoading(true);

    let win: Window | undefined;
    if (isMobileSafari) {
      win = window.open(undefined, '_blank') ?? undefined;
    }
    RestHttpClient.getUploadedDocument(id)
      .then((res) => {
        if (!win) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result as string;
            setOpenDocFile({ base64: base64data, filename: res.fileName });
          };
          reader.readAsDataURL(res.data);
          return;
        }
        win.location.href = window.webkitURL.createObjectURL(res.data);
      })
      .finally(() => {
        setDocLoading(false);
      });
  };

  const handleDownloadClick = (id: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      setDocLoading(true);
      RestHttpClient.getUploadedDocument(id).then((res) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(res.data);
        link.download = res.fileName ?? 'download';
        link.click();
      });
    } catch (e) {
      console.error(e);
    } finally {
      setDocLoading(false);
    }
  };

  const mapRow = (d: UploadedDocumentView) => {
    return {
      id: d.fileId,
      name: d.name,
      documentName: d.documentName,
      created: DateTimeFormatter.format(d.created),
      isProcessed: d.isProcessed ? Icons.checked() : Icons.ellipse(),
      icon: (
        <>
          {d.contentType === 'application/pdf' && (
            <Tooltip title={t('deals.edit.documents.view')}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleRowClick(d.fileId);
                }}
              >
                <VisibilityIcon opacity={0.4} color="secondary" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={t('deals.edit.documents.download')}>
            <IconButton onClick={handleDownloadClick(d.fileId)}>{Icons.download()}</IconButton>
          </Tooltip>
        </>
      ),
    };
  };

  const rows = docs.map(mapRow);

  const headCells: readonly HeadCell<UploadedDocRow>[] = [
    {
      id: 'isProcessed',
      disablePadding: true,
      label: t('deals.edit.documents.status'),
    },
    {
      id: 'documentName',
      disablePadding: false,
      label: t('deals.edit.documents.documentType'),
    },
    {
      id: 'name',
      disablePadding: false,
      label: t('deals.edit.documents.name'),
    },
    {
      id: 'created',
      disablePadding: false,
      label: t('deals.edit.documents.created'),
    },
    {
      id: 'icon',
      align: 'right',
      disablePadding: false,
      label: '',
    },
  ];

  return (
    <>
      <PDFPreviewDialog file={openDocFile} onClose={() => setOpenDocFile(undefined)} />

      <EnhancedTable
        loading={loading}
        rows={rows}
        headCells={headCells}
        hideCheckbox
        variant="endless" />
    </>
  );
}

import { EditCustomerHeader } from './EditCustomerHeader';
import { useEffect, useState } from 'react';
import RestHttpClient from '../../../common/RestHttpClient';
import { useNavigate, useParams } from 'react-router';
import {
  CustomerType,
  EditCommercialCustomerRequest,
  EditPrivateCustomerRequest,
  NotificationLocation,
  ReplaceMainCustomerRequest,
} from '../../../generated/ApiClient';
import { NewCustomerTypeDropdown } from '../new/NewCustomerTypeDropdown';
import { EditCommercialCustomerForm } from './EditCommercialCustomerForm';
import { EditPrivateCustomerForm } from './EditPrivateCustomerForm';
import { PrivateCustomerFormValues } from '../data/PrivateCustomerFormValues';
import { CommercialCustomerFormValues } from '../data/CommercialCustomerFormValues';
import { pushNotificationHandler } from '../../../common/PushNotification';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { CustomerMergeDialog } from '../merge/CustomerMergeDialog';
import { usePermissions } from '../../../common/auth/PermissionsContext';
import { useNotifications } from '../../../common/global/useNotifications';

export function EditCustomer() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  useNotifications(NotificationLocation.Customer);

  const { hasPermission } = usePermissions();
  const canEdit = hasPermission('Customers', 'Edit');

  const [isMergeOpen, setIsMergeOpen] = useState(false);
  const [customerType, setCustomerType] = useState(CustomerType.Undefined);

  useEffect(() => {
    if (id) {
      RestHttpClient.getCustomerType(Number(id))
        .then((res) => {
          setCustomerType(res.data);
        })
        .catch((err) => {
          console.error(err);
          setCustomerType(CustomerType.Undefined);
        });
    }
  }, [id]);

  const submitPrivate = async (values: PrivateCustomerFormValues): Promise<void> => {
    const req = new EditPrivateCustomerRequest();
    req.init({ ...values, customerId: id });
    await RestHttpClient.editPrivateCustomer(req);
    pushNotificationHandler.publish(t('customers.saved'), 'success');
    navigate('/customers');
  };

  const submitCommercial = async (
    values: CommercialCustomerFormValues,
    changesMade: boolean
  ): Promise<void> => {
    const req = new EditCommercialCustomerRequest();
    req.init({ ...values, customerId: id });
    await RestHttpClient.editCommercialCustomer(req);
    pushNotificationHandler.publish(t('customers.saved'), 'success');
    navigate('/customers');
  };

  const mergeAction = (
    <Button variant="outlined" color="secondary" onClick={() => setIsMergeOpen(true)}>
      {t('customers.merge.mergeCustomer')}
    </Button>
  );

  const handleMerge = (customerIds: string[]) => {
    const req = new ReplaceMainCustomerRequest();
    req.sourceCustomerId = Number(id);
    req.targetCustomerIds = customerIds.map((x) => parseInt(x));

    RestHttpClient.replaceMainCustomer(req).then(() => {
      pushNotificationHandler.publish(t('customers.saved'), 'success');
      setIsMergeOpen(false);
    });
  };

  const handleCancel = () => setIsMergeOpen(false);

  return (
    <div className="edit-customer">
      <EditCustomerHeader />
      <NewCustomerTypeDropdown value={customerType} disabled />
      {customerType === CustomerType.Private && (
        <>
          <EditPrivateCustomerForm
            onSubmit={submitPrivate}
            customerId={Number(id)}
            readonly={!canEdit}
            actions={hasPermission('Unrestricted', 'Impersonate') ? mergeAction : undefined}
          />
          <CustomerMergeDialog
            customerId={Number(id)}
            handleMerge={handleMerge}
            handleCancel={handleCancel}
            isOpen={isMergeOpen}
            setIsOpen={setIsMergeOpen}
            isPrivate
          />
        </>
      )}
      {customerType === CustomerType.Commercial && (
        <>
          <EditCommercialCustomerForm
            onSubmit={submitCommercial}
            customerId={Number(id)}
            readonly={!canEdit}
            actions={hasPermission('Unrestricted', 'Impersonate') ? mergeAction : undefined}
          />
          <CustomerMergeDialog
            customerId={Number(id)}
            handleMerge={handleMerge}
            handleCancel={handleCancel}
            isOpen={isMergeOpen}
            setIsOpen={setIsMergeOpen}
          />
        </>
      )}
    </div>
  );
}

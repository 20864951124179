import { Control, UseFormClearErrors, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RestHttpClient from '../../../common/RestHttpClient';
import { HeadCell } from '../../../common/table/EnhancedTableHead';
import { PrivateCustomerKsvResponse } from '../../../generated/ApiClient';
import { PrivateCustomerFormValues } from '../data/PrivateCustomerFormValues';
import { KsvSearch } from './KsvSearch';
import { forwardRef, Ref } from 'react';
import { parseRomanHousenumber } from './KsvSearch.util';

interface PrivateCustomerKsvSearchProps {
  getFormValues: () => PrivateCustomerFormValues;
  setError: UseFormSetError<PrivateCustomerFormValues>;
  clearErrors: UseFormClearErrors<PrivateCustomerFormValues>;
  setValue: UseFormSetValue<PrivateCustomerFormValues>;
  control: Control<PrivateCustomerFormValues>;
}

export const PrivateCustomerKsvSearch = forwardRef(
  (props: PrivateCustomerKsvSearchProps, ref: Ref<HTMLButtonElement>) => {
    interface PrivateKsvResultRow {
      id: string;
      firstName: string;
      lastName: string;
      birthDate: string | undefined;
      address: string;
    }

    const { getFormValues, setError, clearErrors, setValue, control } = props;

    const { t } = useTranslation();
    const headCells: readonly HeadCell<PrivateKsvResultRow>[] = [
      {
        id: 'id',
        disablePadding: false,
        label: t('customers.ksv.result.private.id'),
      },
      {
        id: 'firstName',
        disablePadding: false,
        label: t('customers.ksv.result.private.firstName'),
      },
      {
        id: 'lastName',
        disablePadding: false,
        label: t('customers.ksv.result.private.lastName'),
      },
      {
        id: 'birthDate',
        disablePadding: false,
        label: t('customers.ksv.result.private.birthDate'),
      },
      {
        id: 'address',
        disablePadding: false,
        label: t('customers.ksv.result.private.address'),
      },
    ];

    async function search(
      values: PrivateCustomerFormValues
    ): Promise<PrivateCustomerKsvResponse[]> {
      var result = await RestHttpClient.searchPrivateCustomerKSV(
        values.firstName,
        values.lastName,
        values.birthDate!,
        values.addressStreet,
        values.addressHouseNumber,
        values.addressPostalCode,
        values.addressCity,
        undefined
      );
      setValue('ksvSearchDone', true);
      return result.data;
    }

    function map(result: PrivateCustomerKsvResponse): PrivateKsvResultRow {
      return {
        id: result.ksvId,
        firstName: result.firstName,
        lastName: result.lastName,
        address: `${result.zipCode} ${result.city}, ${result.street} ${result.house}`,
        birthDate: result.birthDate?.toLocaleDateString() ?? '',
      };
    }

    function resultSelected(result: PrivateCustomerKsvResponse, overwrite?: boolean) {
      setValue('ksvId', result.ksvId);
      setValue('firstName', result.firstName.trim());
      setValue('lastName', result.lastName.trim());
      setValue('birthDate', result.birthDate);

      if (overwrite) {
        setValue('addressPostalCode', result.zipCode.trim());
        setValue('addressCity', result.city.trim());

        if (result.house) {
          setValue('addressStreet', result.street.trim());
          setValue('addressHouseNumber', result.house.trim());
        } else {
          const { street, housenumber } = parseRomanHousenumber(result.street);
          setValue('addressStreet', street.trim());
          setValue('addressHouseNumber', housenumber.trim());
        }
      }

      setValue('selfDisclosureAgreed', false);
    }

    function onReset() {
      setValue('ksvId', undefined);
    }

    return KsvSearch<PrivateCustomerFormValues, PrivateCustomerKsvResponse, PrivateKsvResultRow>({
      getFormValues,
      setError,
      clearErrors,
      headCells,
      search,
      requiredFields: ['firstName', 'lastName', 'birthDate'],
      resultSelected,
      map,
      control,
      onReset,
      ref,
      enableOverwrite: true,
    });
  }
);

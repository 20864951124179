import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

export function DashboardHeader() {
  const { t } = useTranslation();

  return (
    <Box className="view-header">
      <h2>{t('dashboard.header')}</h2>
    </Box>
  );
}

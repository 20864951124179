import { Grid } from '@mui/material';
import { t } from 'i18next';
import { Control } from 'react-hook-form';
import { FormDatePicker } from '../../../component/form/FormDatePicker';
import { FormDropdown } from '../../../component/form/FormDropdown';
import { FormTextField } from '../../../component/form/FormTextField';
import { PrivateCustomerFormValues } from '../data/PrivateCustomerFormValues';
import { FormFixedNumberInput } from '../../../component/form/FormFixedNumberInput';
import { JobTitleView } from '../../../generated/ApiClient';

interface Props {
  control: Control<PrivateCustomerFormValues>;
  jobTitles: JobTitleView[];
  noIncome: boolean;
  readonly: boolean;
}

export function Income({ control, readonly, jobTitles, noIncome }: Props) {
  const gridCol = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 3,
    xl: 3,
  };
  return (
    <Grid container spacing={3} className="income" width="100%">
      <Grid item {...gridCol}>
        <FormDropdown
          control={control}
          name="jobTitleId"
          label={t('customers.edit.income.jobTitle')}
          options={jobTitles.map(x => ({label: x.name, value: x.id}))}
          emptyText="-"
          required
          disabled={readonly}
        />
        <FormTextField
          control={control}
          name="currentEmployerName"
          label={t('customers.edit.income.currentEmployerName')}
          required
          disabled={readonly}
        />

        <FormTextField
          control={control}
          name="currentEmployerAddress"
          label={t('customers.edit.income.currentEmployerAddress')}
          required
          disabled={readonly}
        />

        <FormDatePicker
          control={control}
          name="employedSince"
          label={t('customers.edit.income.employedSince')}
          disabled={readonly}
          required
        />

        <FormFixedNumberInput
          control={control}
          name="verifiableMonthlyIncome"
          label={t('customers.edit.income.verifiableMonthlyIncome')}
          required
          disabled={readonly || noIncome}
        />

        <FormFixedNumberInput
          control={control}
          name="verifiableMonthlyAddIncome"
          label={t('customers.edit.income.verifiableMonthlyAddIncome')}
          disabled={readonly || noIncome}
        />

        <FormTextField
          control={control}
          name="verifiableMonthlyAddIncomeType"
          label={t('customers.edit.income.verifiableMonthlyAddIncomeType')}
          disabled={readonly}
        />
      </Grid>
    </Grid>
  );
}

import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RestHttpClient from '../../../common/RestHttpClient';
import { distinct } from '../../../common/util/ArrayUtil';
import { a11yProps, TabPanel } from '../../../component/TabPanel';
import { OptionItem, IMergeCustomerView } from '../../../generated/ApiClient';
import { CustomerMergeSelection } from './CustomerMergeSelection';
import { CustomerMergeSuggestion } from './CustomerMergeSuggestion';
import { CustomerMergeContext } from './CustomerMergeContext';
import {
  similarCommercialCustomerHeadCells,
  similarPrivateCustomerHeadCells,
} from './SimilarCustomerRow';
import { PrivateCustomerMergeSearch } from './PrivateCustomerMergeSearch';
import { CommercialCustomerMergeSearch } from './CommercialCustomerMergeSearch';

interface Props {
  customerId: number;
  handleMerge: (selectedIds: string[]) => void;
  handleCancel: () => void;
  isPrivate?: boolean;
  isPoseidon?: boolean;
}

export function CustomerMergeForm({
  customerId,
  isPrivate,
  isPoseidon,
  handleMerge,
  handleCancel,
}: Props) {
  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  const [countries, setCountries] = useState<OptionItem[]>([]);
  const [customer, setCustomer] = useState<IMergeCustomerView | undefined>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { search, suggestion } = useContext(CustomerMergeContext);

  useEffect(() => {
    RestHttpClient.getCountries().then((res) => setCountries(res.data));

    if (isPoseidon) {
      RestHttpClient.getMergeCustomer(customerId).then((res) => setCustomer(res.data));
    }

    fetchCustomers();
    // eslint-disable-next-line
  }, []);

  const fetchCustomers = (p = suggestion.page) => {
    suggestion.setLoading(true);
    RestHttpClient.getSimilarCustomer(customerId, p, 20, [])
      .then((res) => {
        const newCustomers = res.data;
        if (p === 0 && newCustomers.items.length) {
          suggestion.setCustomers(newCustomers.items);
        } else if (newCustomers.items.length) {
          suggestion.setCustomers(suggestion.customers.concat(newCustomers.items));
        }
      })
      .catch((_) => {
        suggestion.setCustomers([]);
      })
      .finally(() => {
        suggestion.setLoading(false);
      });
  };

  useEffect(() => {
    if (suggestion.page > 0) {
      fetchCustomers();
    }
    // eslint-disable-next-line
  }, [suggestion.page]);

  const headCells = isPrivate
    ? similarPrivateCustomerHeadCells
    : similarCommercialCustomerHeadCells;

  const selectedIds = search.selected.concat(suggestion.selected).filter(distinct);

  const selectionLabel = (
    <Box className="chip">
      {t('customers.merge.selection')}
      {<Chip size="small" label={selectedIds.length} />}
    </Box>
  );

  return (
    <>
      <DialogTitle>
        <Typography variant="h6">{t('customers.merge.mergeCustomer')}</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ height: '973px', display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body1">{t('customers.merge.hint')}</Typography>
          {customer && (
            <Box sx={{ display: 'flex', mt: 2, gap: 1 }}>
              {isPrivate ? (
                <>
                  <TextField
                    disabled
                    label={t('customers.merge.list.firstName')}
                    value={customer.firstName}
                  />
                  <TextField
                    disabled
                    label={t('customers.merge.list.lastName')}
                    value={customer.lastName}
                  />
                  <TextField
                    disabled
                    label={t('customers.merge.list.birthDate')}
                    value={customer.birthDate?.toLocaleDateString()}
                  />
                  <TextField
                    disabled
                    label={t('customers.merge.list.dealer')}
                    value={customer.dealer}
                  />
                </>
              ) : (
                <>
                  <TextField
                    disabled
                    label={t('customers.merge.list.companyName')}
                    value={customer.companyName}
                  />
                  <TextField
                    disabled
                    label={t('customers.merge.list.address')}
                    value={customer.address}
                  />
                  <TextField
                    disabled
                    label={t('customers.merge.list.dealer')}
                    value={customer.dealer}
                  />
                </>
              )}
            </Box>
          )}
          <Box
            sx={{
              mt: 2,
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Tabs aria-label="basic tabs example" value={value} onChange={handleChange}>
              <Tab label={t('customers.merge.suggestion')} {...a11yProps(0)} />
              <Tab label={t('customers.merge.search')} {...a11yProps(1)} />
              <Tab label={selectionLabel} {...a11yProps(2)} />
            </Tabs>
          </Box>
          <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
            <TabPanel value={value} index={0}>
              <CustomerMergeSuggestion headCells={headCells} isPoseidon={isPoseidon} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              {isPrivate ? (
                <PrivateCustomerMergeSearch
                  customerId={customerId}
                  countries={countries}
                  headCells={headCells}
                  isPoseidon={isPoseidon}
                />
              ) : (
                <CommercialCustomerMergeSearch
                  customerId={customerId}
                  countries={countries}
                  headCells={headCells}
                  isPoseidon={isPoseidon}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              <CustomerMergeSelection headCells={headCells} />
            </TabPanel>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, position: 'sticky' }}>
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            {isPoseidon ? t('customers.merge.newCustomer') : t('cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleMerge(selectedIds)}
            disabled={!selectedIds.length}
          >
            {t('customers.merge.mergeSelection')}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}

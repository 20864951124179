import BaseFormatter, { FormatterProperties } from './BaseFormatter';
import lightFormat from 'date-fns/lightFormat';
import parse from 'date-fns/parse';

const defaultFormat = 'dd.MM.yyyy HH:mm';
export const dateFormat = 'dd.MM.yyyy';

interface DateTimeFormatterProperties extends FormatterProperties {
  format?: string;
}

export const DateTimeFormatter: BaseFormatter<Date, DateTimeFormatterProperties> = {
  format(value?: Date, properties: DateTimeFormatterProperties = {}): string {
    if (!value) {
      return '';
    }
    const format = properties.format || defaultFormat;
    return lightFormat(value, format);
  },
  parse(value?: string, properties: DateTimeFormatterProperties = {}): Date | undefined {
    if (!value) {
      return undefined;
    }
    const format = properties.format || defaultFormat;
    return parse(value, format, new Date());
  },
};

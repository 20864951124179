import { Box, FormControlLabel, Switch } from '@mui/material';
import { ApplicationModel } from '../../../generated/ApiClient';
import { DateTimeFormatter } from '../../../common/formatter/DateTimeFormatter';
import { DefaultDashboardStatusIndicator } from '../common/DashboardStatusIndicator';
import React, { useContext, useState } from 'react';
import { HeadCell } from '../../../common/table/EnhancedTableHead';
import { useTranslation } from 'react-i18next';
import { DashboardApplicationOverlay } from '../applications/DashboardApplicationOverlay';
import { usePermissions } from '../../../common/auth/PermissionsContext';
import { DashboardSearchContext } from './DashboardSearchContext';
import { EnhancedTable } from '../../../common/table/EnhancedTable';

interface DashboardRow {
  id: string;
  contractNumber: string;
  salesPerson: string;
  customer: string;
  creationDate: string;
  expiryDate?: string;
  status: JSX.Element;
}

const mapApplicationModelToRow = (am: ApplicationModel): DashboardRow => ({
  id: am.contractId.toString(),
  contractNumber: am.contractId?.toString() ?? '',
  customer: am.customerName ?? '',
  expiryDate: DateTimeFormatter.format(am.expirationDate, { format: 'dd.MM.yyyy' }),
  creationDate: DateTimeFormatter.format(am.creationDate),
  salesPerson: am.salesPerson ?? '',
  status: <DefaultDashboardStatusIndicator state={(am.state?.value ?? -1)} />,
});

const DashboardSearchTable = () => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  const [openDoc, setOpenDoc] = useState<string | undefined>();
  const handleRowClick = (e: React.MouseEvent<unknown>, id: string) => {
    e.stopPropagation();
    setOpenDoc(id);
  };

  const { showAll, setShowAll, total, page, setPage, loading, results, setOrderSelectors } =
    useContext(DashboardSearchContext);
  const headCells: readonly HeadCell<DashboardRow>[] = [
    {
      id: 'status',
      disablePadding: true,
      label: '',
    },
    {
      id: 'contractNumber',
      disablePadding: false,
      columnName: 'contractId',
      allowSorting: true,
      label: t('dashboard.applications.contractNumber'),
    },
    {
      id: 'salesPerson',
      disablePadding: false,
      columnName: 'salesPerson',
      allowSorting: true,
      label: t('dashboard.applications.salesPerson'),
    },
    {
      id: 'customer',
      disablePadding: false,
      columnName: 'customerName',
      allowSorting: true,
      label: t('dashboard.applications.customer'),
    },
    {
      id: 'creationDate',
      disablePadding: false,
      label: t('dashboard.applications.creationDate'),
    },
    {
      id: 'expiryDate',
      disablePadding: false,
      label: t('dashboard.applications.expiryDate'),
    },
  ];

  const loadNewPage = () => {
    setPage(page + 1);
  };

  const onSortRequest = (orderSelectors: string[]) => {
    setOrderSelectors(orderSelectors);
  };

  return (
    <>
      <DashboardApplicationOverlay
        isDrawerOpen={Boolean(openDoc)}
        onClose={() => setOpenDoc(undefined)}
        cmsId={openDoc}
      />

      {!hasPermission('Dashboard.AllUsersContracts', 'View') &&
        hasPermission('Dashboard.EmployeeContracts', 'View') && (
          <Box sx={{ ml: 1 }}>
            <FormControlLabel
              onChange={() => setShowAll(!showAll)}
              control={<Switch checked={showAll} />}
              label={t('dashboard.applications.showAll')}
            />
          </Box>
        )}

      <EnhancedTable
        rows={results.map(mapApplicationModelToRow)}
        headCells={headCells}
        hideCheckbox={true}
        onRowClick={handleRowClick}
        variant="endless"
        total={total}
        loadNewPage={loadNewPage}
        loading={loading}
        onSortRequest={onSortRequest}
      />
    </>
  );
};

export default DashboardSearchTable;

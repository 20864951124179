import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDisolveDetail, IReportEntry, IVehicleDetail } from '../../../generated/ApiClient';
import { formatToEuro } from '../../deals/new/finances/FinancesCalculationList';
import { ModelImage } from '../../deals/ModelImage';
import { OverlaySurcharge } from './OverlaySurcharge';
import { getDissolveText } from './OverlayDissolveValues.utils';

interface Props {
  dissolve?: IDisolveDetail;
  vehicle?: IVehicleDetail;
  report?: IReportEntry;
}


export function OverlayDissolveValues({ dissolve, vehicle, report }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Box mt={2}>
        <Typography variant="subtitle1" sx={{ wordWrap: 'break-word' }}>
          {t('dashboard.contractsOverlay.dissolveInfo')}
          <br />
          {t('dashboard.contractsOverlay.netInfo')}
        </Typography>
      </Box>

      <Grid container width="100%" mt={2}>
        <Grid item xs={6} container>
          <Grid item xs={6} className="value-column">
            <div>{getDissolveText(dissolve?.dueDate)}</div>
            <div>{getDissolveText(dissolve?.dueDate, 1)}</div>
            <div>{getDissolveText(dissolve?.dueDate, 2)}</div>
          </Grid>
          <Grid item xs={6} className="value-column">
            <div>{formatToEuro(dissolve?.dissolveValue)}</div>
            <div>{formatToEuro(dissolve?.nextMonth)}</div>
            <div>{formatToEuro(dissolve?.theMonthAfterNextMonth)}</div>
          </Grid>
          <Grid item xs={6} className="value-column">
            <div>{t('dashboard.contractsOverlay.dissolveValues.residualValue')}</div>
            <div>{t('dashboard.contractsOverlay.dissolveValues.rkv')}</div>
            <div>{t('dashboard.contractsOverlay.dissolveValues.listprice')}</div>
          </Grid>
          <Grid item xs={6} className="value-column">
            <div>{formatToEuro(report?.rediualValue)}</div>
            <div>{report?.rkv || '-'}</div>
            <div>{formatToEuro(report?.listPrice)}</div>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {vehicle?.modelId && <ModelImage modelId={vehicle?.modelId} />}
        </Grid>
        <OverlaySurcharge dissolve={dissolve} report={report} />
      </Grid>

      <div className="line" />
    </>
  );
}

import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormDropdown } from '../../../../component/form/FormDropdown';
import RestHttpClient from '../../../../common/RestHttpClient';
import { ModelFormValues } from './ModelForm';
import { OptionItem } from '../../../../generated/ApiClient';
import i18n from '../../../../common/i18n/i18n';

interface ModelConfigurationGeneralProps {
  control: Control<ModelFormValues>;
  isDistributionChannelEditable: boolean;
}

export function ModelConfigurationGeneral({
  control,
  isDistributionChannelEditable,
}: ModelConfigurationGeneralProps) {
  const { t } = useTranslation();

  const dealType = useWatch({ control, name: 'businessType' });
  const [dealTypes, setDealTypes] = useState<OptionItem[]>([]);

  const distributionChannel = useWatch({ control, name: 'distributionChannel' });
  const [distributionChannels, setDistributionChannels] = useState<OptionItem[]>([]);
  const [discountCategories, setDiscountCategories] = useState<OptionItem[]>([]);

  useEffect(() => {
    FetchDealTypes();
    FetchDistributionChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dealType != null) {
      FetchDiscountCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealType]);

  async function FetchDealTypes() {
    try {
      let { data } = await RestHttpClient.getDealTypes();
      data = TranslateDealTypes(data);
      setDealTypes(data);
    } catch (err) {
      console.error(err);
      setDealTypes([]);
    }
  }

  function TranslateDealTypes(data: OptionItem[]): OptionItem[] {
    (Object.keys(data) as (keyof typeof OptionItem)[]).forEach((key, index) => {
      data[index].label = i18n.t([`deals.new.dealTypes.${data[index].label}`, data[index].label]);
    });

    return data;
  }

  async function FetchDistributionChannels() {
    try {
      const { data } = await RestHttpClient.getSalesChannels();
      setDistributionChannels(data);
    } catch (err) {
      console.error(err);
      setDistributionChannels([]);
    }
  }

  async function FetchDiscountCategories() {
    try {
      const { data } = await RestHttpClient.getDiscountCategories(dealType);
      setDiscountCategories(data);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Box sx={{ width: 1 / 3, p: 1, m: 1 }}>
      <h1>{t('deals.new.modelConfiguration.body.generalInfo.generalInfo')}</h1>
      <FormDropdown
        isRequired={false}
        control={control}
        name="businessType"
        label={t('deals.new.modelConfiguration.body.generalInfo.businessType')}
        options={dealTypes}
        required={true}
      />
      <br />
      <FormDropdown
        isRequired={false}
        control={control}
        name="distributionChannel"
        label={t('deals.new.modelConfiguration.body.generalInfo.distributionChannel')}
        options={distributionChannels}
        disabled={!isDistributionChannelEditable}
        required={true}
      />
      {distributionChannel === 11 && (
        <FormDropdown
          isRequired={true}
          control={control}
          name="discountCategory"
          label={t('deals.new.modelConfiguration.body.generalInfo.discountCategory')}
          options={discountCategories}
          required={true}
        />
      )}
    </Box>
  );
}

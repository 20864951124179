import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { EnhancedDialog } from '../../../../component/EnhancedDialog';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import RestHttpClient, { renderValidationErrors } from '../../../../common/RestHttpClient';
import {
  DocumentDefinition,
  PrintInsuranceDocumentsRequest,
  SendInsuranceDocumentsRequest,
  SignInsuranceDocumentsRequest,
  SignInsuranceDocumentsResponse,
} from '../../../../generated/ApiClient';
import { SendDocumentsDialog } from '../common/SendDocumentsDialog';
import { SignDocumentsDialog } from '../common/SignDocumentsDialog';
import { pushNotificationHandler } from '../../../../common/PushNotification';
import { OptionItem } from '../../../../component/form/OptionItem';
import { customerDocToForm, docToForm } from '../finances/FinancePrintDialog';
import InsurancePrintForm, {
  InsurancePrintFormType,
  insurancePrintSchema,
} from './InsurancePrintForm';
import { confirmHandler } from '../../../../common/ConfirmModal';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  insuranceId?: number;
}

export function InsurancePrintDialog({ isOpen, setIsOpen, insuranceId }: Props) {
  const { t } = useTranslation();
  const [contractTypes, setContractTypes] = useState<OptionItem[]>([]);
  const [previousInsurers, setPreviousInsurers] = useState<OptionItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [insuranceDocuments, setInsuranceDocuments] = useState<DocumentDefinition[]>([]);
  const [customerDocuments, setCustomerDocuments] = useState<DocumentDefinition[]>([]);
  const [signInsuranceDocuments, setSignInsuranceDocuments] = useState<
    SignInsuranceDocumentsResponse[]
  >([]);
  const [isSignDocumentsOpened, setIsSignDocumentsOpened] = useState(false);
  const [isSendDocumentsOpened, setIsSendDocumentsOpened] = useState(false);

  const [isPrintRequired, setIsPrintRequired] = useState(false);

  // @ts-ignore
  const tPrint = (key: string): string => t(`deals.edit.insurance.printDialog.${key}`);
  const { control, handleSubmit, reset, setError, getValues, watch } =
    useForm<InsurancePrintFormType>({
      resolver: zodResolver(insurancePrintSchema),
      defaultValues: {
        insuranceDocuments: [],
        customerDocuments: [],
      },
    });

  const handleClose = () => {
    if (isPrintRequired) {
      confirmHandler.confirm(
        '',
        () => {},
        () => setIsOpen(false),
        t('deals.edit.insurance.printDialog.closeHint'),
        t('ok'),
        t('abortAnyways')
      );
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        type === 'change' &&
        (name === 'offerSupplement' ||
          name === 'previousInsuranceCompanyName' ||
          name === 'previousTerminateContract' ||
          name === 'previousContractNumber' ||
          name === 'previousLicensePlate' ||
          name === 'previousContractTypeId' ||
          name === 'waiverOfBonus')
      ) {
        setIsPrintRequired(true);
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const onHandleSubmit = (event: FormEvent) => {
    handleSubmit(async (values) => {
      try {
        setLoading(true);
        const req = new PrintInsuranceDocumentsRequest({
          insuranceCalculationId: insuranceId ?? 0,
          offerSupplement: values.offerSupplement ?? '',
          previousInsuranceInfoRequired: values.previousInsuranceInfoRequired,
          previousInsuranceCompanyName: values.previousInsuranceCompanyName ?? '',
          previousTerminateContract: values.previousTerminateContract ?? false,
          previousContractNumber: values.previousContractNumber ?? '',
          previousLicensePlate: values.previousLicensePlate ?? '',
          previousContractTypeId: values.previousContractTypeId ?? undefined,
          waiverOfBonus: values.waiverOfBonus ?? false,
          insuranceDocumentIds: values.insuranceDocuments,
          customerDocumentIds: values.customerDocuments,
        });
        await RestHttpClient.printInsuranceDocuments(req).then((data) => {
          const tmpDownloadLink = document.createElement('a');
          tmpDownloadLink.href = window.URL.createObjectURL(data.data);
          tmpDownloadLink.download = data.fileName ?? 'Documents.pdf';
          tmpDownloadLink.click();
        });
        setIsPrintRequired(false);
      } catch (err) {
        renderValidationErrors(err, setError);
      } finally {
        setLoading(false);
      }
    }, console.error)(event);
  };

  const fetchPrintDocuments = () => {
    if (insuranceId) {
      setLoading(true);
      RestHttpClient.getPrintInsuranceDocuments(insuranceId)
        .then((res) => {
          const { insuranceDocuments, customerDocuments, ...d } = res.data;
          setInsuranceDocuments(insuranceDocuments);
          setCustomerDocuments(customerDocuments);
          reset({
            ...d,
            insuranceDocuments: docToForm(insuranceDocuments),
            customerDocuments: customerDocToForm(customerDocuments),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    try {
      RestHttpClient.getInsuranceContractTypes().then((res) => {
        setContractTypes(res.data);
      });
      RestHttpClient.getPreviousInsurers().then((res) => {
        setPreviousInsurers(res.data.map((x) => new OptionItem({ label: x.name, value: x.value })));
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchPrintDocuments();
    } else {
      reset({
        insuranceDocuments: [],
        customerDocuments: [],
      });
      setIsPrintRequired(false);
      setInsuranceDocuments([]);
      setCustomerDocuments([]);
    }
    // eslint-disable-next-line
  }, [insuranceId, isOpen]);

  const onSendDocuments = async (subject: string, text: string, sendAlsoToSalesman: boolean) => {
    const req = new SendInsuranceDocumentsRequest({
      insuranceCalculationId: insuranceId ?? 0,
      offerSupplement: getValues().offerSupplement ?? '',
      previousInsuranceInfoRequired: getValues().previousInsuranceInfoRequired,
      previousInsuranceCompanyName: getValues().previousInsuranceCompanyName ?? '',
      previousTerminateContract: getValues().previousTerminateContract ?? false,
      previousContractNumber: getValues().previousContractNumber ?? '',
      previousLicensePlate: getValues().previousLicensePlate ?? '',
      previousContractTypeId: getValues().previousContractTypeId ?? undefined,
      waiverOfBonus: getValues().waiverOfBonus ?? false,
      insuranceDocumentIds: getValues().insuranceDocuments,
      customerDocumentIds: getValues().customerDocuments,
      subject: subject,
      text: text,
      sendAlsoToSaltesman: sendAlsoToSalesman,
    });
    try {
      await RestHttpClient.sendInsuranceDocuments(req);
      pushNotificationHandler.publish(t('deals.edit.sendDocuments.sended'), 'success');
      setIsSendDocumentsOpened(false);
      setIsPrintRequired(false);
    } catch (err) {
      renderValidationErrors(err, setError);
    }
  };

  const handleOpenSendMail = () => {
    if (getValues().hasCustomerEmail) setIsSendDocumentsOpened(true);
    else pushNotificationHandler.publish(tPrint('sendDocumentWithoutEmail'), 'error');
  };

  const handleOpenSignDocuments = (event: FormEvent) => {
    handleSubmit(async (values) => {
      try {
        setLoading(true);
        const req = new SignInsuranceDocumentsRequest({
          insuranceCalculationId: insuranceId ?? 0,
          offerSupplement: values.offerSupplement ?? '',
          previousInsuranceInfoRequired: values.previousInsuranceInfoRequired,
          previousInsuranceCompanyName: values.previousInsuranceCompanyName ?? '',
          previousTerminateContract: values.previousTerminateContract ?? false,
          previousContractNumber: values.previousContractNumber ?? '',
          previousLicensePlate: values.previousLicensePlate ?? '',
          previousContractTypeId: values.previousContractTypeId ?? undefined,
          waiverOfBonus: values.waiverOfBonus ?? false,
          insuranceDocumentIds: values.insuranceDocuments,
          customerDocumentIds: values.customerDocuments,
        });
        await RestHttpClient.signInsuranceDocuments(req).then((data) => {
          setSignInsuranceDocuments(data.data);
          setIsSignDocumentsOpened(true);
        });
        setIsPrintRequired(false);
      } catch (err) {
        renderValidationErrors(err, setError);
      } finally {
        setLoading(false);
      }
    }, console.error)(event);
  };

  const handleSignDocumentsClosed = (isCancel: boolean) => {
    setIsSignDocumentsOpened(false);
    if (!isCancel) setIsOpen(false);
  };

  return (
    <EnhancedDialog
      isDialogOpened={isOpen}
      setIsDialogOpened={setIsOpen}
      handleCancel={handleClose}
    >
      <DialogTitle>
        {loading && <LinearProgress />}
        {tPrint('title')}
      </DialogTitle>

      <DialogContent dividers>
        <Typography sx={{ mb: 2 }} variant="subtitle1">
          {tPrint('text')}
        </Typography>
        <InsurancePrintForm
          control={control}
          insuranceDocuments={insuranceDocuments}
          customerDocuments={customerDocuments}
          onSubmit={onHandleSubmit}
          loading={loading}
          previousInsurers={previousInsurers}
          contractTypes={contractTypes}
        />
      </DialogContent>

      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            color="primary"
            disabled={loading}
            type="submit"
            form="insurance-print-dialog"
          >
            {tPrint('print')}
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleOpenSendMail}
            form="insurance-print-dialog"
          >
            {tPrint('sendMail')}
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleOpenSignDocuments}
            type="submit"
            form="insurance-print-dialog"
          >
            {tPrint('check')}
          </Button>
        </Box>
      </DialogActions>

      <SendDocumentsDialog
        isOpen={isSendDocumentsOpened}
        setOpen={setIsSendDocumentsOpened}
        onSend={onSendDocuments}
      />

      <SignDocumentsDialog
        setOpen={setIsSignDocumentsOpened}
        isOpen={isSignDocumentsOpened}
        signDocuments={signInsuranceDocuments}
        onClose={handleSignDocumentsClosed}
      />
    </EnhancedDialog>
  );
}

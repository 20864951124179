import { UploadInput } from '../../../../component/UploadInput';
import { z } from 'zod';
import React, { FormEvent, useEffect, useState } from 'react';
import RestHttpClient from '../../../../common/RestHttpClient';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { UploadedDocumentView } from '../../../../generated/ApiClient';
import { tryUploadFiles, useDocumentUpload } from '../../../../common/util/OcrUploadUtil';
import { FormDropdown } from '../../../../component/form/FormDropdown';
import { UploadedDocuments } from './UploadedDocuments';

interface Props {
  dealId: number;
  reload?: () => void;
}

export interface UploadedDocRow {
  id: string;
  name: string;
  documentName: string;
  created: string;
  isProcessed: JSX.Element;
  icon: JSX.Element;
}

const schema = z.object({
  documentId: z.number(),
});
type UploadDocSchema = z.infer<typeof schema>;

export function EditDealUploadedDocuments({ dealId, reload }: Props) {
  const { t } = useTranslation();

  const [docLoading, setDocLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [docs, setDocs] = useState<UploadedDocumentView[]>([]);

  const { acceptedSize, allowedExtensions, removeChars, requiredDocs, validateFile } =
    useDocumentUpload(dealId);

  const { control, handleSubmit, reset } = useForm<UploadDocSchema>({
    resolver: zodResolver(schema),
  });

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const { data } = await RestHttpClient.getUploadedDocuments(dealId);
      setDocs(data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealId]);

  const handleFileChange = async (file?: File) => {
    setFile(file);
  };

  const cancelUpload = () => {
    setFile(undefined);
  };

  const resetUpload = async () => {
    await fetchDocuments();
    setFile(undefined);
    setLoading(false);
    setUploading(false);
    reset();
    if (reload) reload();
  };

  const onSubmit = (event: FormEvent) => {
    handleSubmit(async (values) => {
      if (file && validateFile(file)) {
        setLoading(true);
        tryUploadFiles(values.documentId, false, dealId, resetUpload, removeChars, file);
      }
    })(event);
  };

  return (
    <>
      {docLoading && <LinearProgress />}
      <UploadInput
        onFileChange={handleFileChange}
        file={file}
        disabled={loading}
        accept={allowedExtensions}
        accept_size={acceptedSize}
      />

      <Dialog
        open={Boolean(file) && !uploading}
        onClose={cancelUpload}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">{t('deals.edit.documents.uploadConfirm')}</DialogTitle>
        <form onSubmit={onSubmit} noValidate>
          <DialogContent>
            <FormDropdown
              control={control}
              options={requiredDocs}
              label={t('deals.edit.documents.documentType')}
              name="documentId"
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={cancelUpload}>
              {t('cancel')}
            </Button>
            <Button variant="contained" type="submit" color="primary">
              {t('deals.edit.documents.confirm')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <UploadedDocuments docs={docs} loading={loading} setDocLoading={setDocLoading} />
    </>
  );
}

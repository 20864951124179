import { z } from 'zod';
import { optionalDate, radioButton } from '../../../../common/validation/Validations';
import { Control, UseFormWatch } from 'react-hook-form';
import { Box, Grid, Typography } from '@mui/material';
import {
  FormCheckboxGroup,
  OptionWithChildren,
  ReadonlyOptionItem,
} from '../../../../component/form/FormCheckboxGroup';
import { FormRadioButton } from '../../../../component/form/FormRadioButton';
import { FormDatePicker } from '../../../../component/form/FormDatePicker';
import { FormDropdown } from '../../../../component/form/FormDropdown';
import { FormNumberInput } from '../../../../component/form/FormNumberInput';
import { FormTextField } from '../../../../component/form/FormTextField';
import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentDefinition } from '../../../../generated/ApiClient';
import { OptionItem } from '../../../../component/form/OptionItem';

const mapDocumentToCheckbox = (dd: DocumentDefinition): OptionWithChildren => {
  const opt = new OptionWithChildren({
    label: dd.name ?? '-',
    value: dd.customerId > 0 ? dd.id + '|' + dd.customerId : dd.id,
  });

  opt.readonly = dd.readonly;
  opt.children = dd.subDocuments?.map((subDoc) => {
    const o = new ReadonlyOptionItem();
    o.initial = subDoc.selected;
    o.label = subDoc.name ?? '';
    o.value = subDoc.id;
    o.readonly = subDoc.readonly;
    return o;
  });
  return opt;
};

export const financePrintSchema = z.object({
  expectedDispatchDate: optionalDate,
  euriborDatesEnabled: z.boolean(),
  selectedEuriborId: z.number(),
  euriborRate: z.number().optional(),
  termsheet: z.string().optional().nullable(),
  hasViewPrintDetailsPermission: z.boolean(),
  leasingContractNotes: z.string().nullable().optional(),
  offerSupplement: z.string().optional().nullable(),
  calculationGross: radioButton,
  termsheetCalculationGross: radioButton,
  isFlexPurchase: z.boolean(),
  hasMultipleAccounts: z.boolean(),
  offerDocuments: z.array(z.number()),
  termsheetDocuments: z.array(z.number()),
  contractDocuments: z.array(z.number()),
  miscellaneousDocuments: z.array(z.number()),
  customerDocuments: z.array(z.string()),
  hasCustomerEmail: z.boolean(),
  useCoApplicantAccount: radioButton,
  turnOverTaxStatementRequested: radioButton,
});

export type FinancePrintFormType = z.infer<typeof financePrintSchema>;

interface Props {
  control: Control<FinancePrintFormType>;
  onSubmit: (event: FormEvent) => void;
  loading: boolean;
  watch: UseFormWatch<FinancePrintFormType>;
  offerDocuments: DocumentDefinition[];
  termsheetDocuments: DocumentDefinition[];
  contractDocuments: DocumentDefinition[];
  miscellaneousDocuments: DocumentDefinition[];
  customerDocuments: DocumentDefinition[];
  euriborDates: OptionItem[];
}

const FinancePrintForm = ({
  control,
  loading,
  onSubmit,
  watch,
  offerDocuments,
  termsheetDocuments,
  contractDocuments,
  miscellaneousDocuments,
  customerDocuments,
  euriborDates,
}: Props) => {
  const { t } = useTranslation();

  // @ts-ignore
  const tPrint = (key: string): string => t(`deals.printDialog.${key}`);

  const euriborDatesEnabled = watch('euriborDatesEnabled');
  const hasViewPrintDetailsPermission = watch('hasViewPrintDetailsPermission');
  const selectedContracts = watch('contractDocuments');
  const selectedOffers = watch('offerDocuments');
  const selectedTermsheets = watch('termsheetDocuments');
  const hasMultipleAccounts = watch('hasMultipleAccounts');
  const isFlexPurchase = watch('isFlexPurchase');
  return (
    <form className="finance-print-dialog" id="finance-print-dialog" onSubmit={onSubmit}>
      <h3 style={{ marginBottom: 0 }}>{tPrint('documentSelection')}</h3>

      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={4}>
          <Box sx={{ ml: 1 }}>
            <Typography sx={{ mt: 2 }} variant="h4">
              {tPrint('categoryOffer')}
            </Typography>
            <FormCheckboxGroup
              options={offerDocuments.map(mapDocumentToCheckbox)}
              label={''}
              name="offerDocuments"
              control={control}
              selectChildsOnChange={false}
              disabled={loading}
            />

            {selectedOffers.length > 0 && (
              <FormRadioButton
                control={control}
                label=""
                disabled={loading}
                name="calculationGross"
                options={[
                  { label: t('price.gross'), value: 'true' },
                  { label: t('price.net'), value: 'false' },
                ]}
              />
            )}
          </Box>

          <Box sx={{ ml: 1 }}>
            <Typography sx={{ mt: 2 }} variant="h4">
              {tPrint('categorySubmit')}
            </Typography>
            <FormCheckboxGroup
              options={termsheetDocuments.map(mapDocumentToCheckbox)}
              label={''}
              name="termsheetDocuments"
              control={control}
              selectChildsOnChange={false}
              disabled={loading}
            />

            {selectedTermsheets.length > 0 && (
              <FormRadioButton
                control={control}
                label={''}
                name="termsheetCalculationGross"
                options={[
                  { label: t('price.gross'), value: 'true' },
                  { label: t('price.net'), value: 'false' },
                ]}
                disabled={loading}
              />
            )}
          </Box>

          <Box sx={{ ml: 1 }}>
            <Typography sx={{ mt: 2 }} variant="h4">
              {tPrint('categoryContract')}
            </Typography>
            <FormCheckboxGroup
              options={contractDocuments.map(mapDocumentToCheckbox)}
              label={''}
              name="contractDocuments"
              control={control}
              selectChildsOnChange={false}
              disabled={loading}
            />
            {selectedContracts.length > 0 && hasMultipleAccounts && (
              <FormRadioButton
                control={control}
                label={tPrint('useAccountData')}
                name="useCoApplicantAccount"
                options={[
                  { label: tPrint('useCustomer'), value: 'false' },
                  { label: tPrint('useCoApplicant'), value: 'true' },
                ]}
              />
            )}
            {selectedContracts.length > 0 && isFlexPurchase && (
              <FormRadioButton
                control={control}
                label={tPrint('turnOverTaxStatement')}
                name="turnOverTaxStatementRequested"
                options={[
                  { label: t('yes'), value: 'true' },
                  { label: t('no'), value: 'false' },
                ]}
                disabled={loading}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box>
            <Typography sx={{ mt: 2 }} variant="h4">
              {tPrint('categoryOther')}
            </Typography>
            <FormCheckboxGroup
              options={miscellaneousDocuments.map(mapDocumentToCheckbox)}
              label={''}
              name="miscellaneousDocuments"
              control={control}
              selectChildsOnChange={false}
              disabled={loading}
            />
          </Box>

          <Box>
            <Typography sx={{ mt: 2 }} variant="h4">
              {tPrint('categoryCustomer')}
            </Typography>
            <FormCheckboxGroup
              options={customerDocuments.map(mapDocumentToCheckbox)}
              label={''}
              name="customerDocuments"
              control={control}
              selectChildsOnChange={false}
              disabled={loading}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <h2>{tPrint('customerAdditionalInfoFinance')}</h2>
          <Box sx={{ my: 2 }}>
            <FormDatePicker
              control={control}
              label={tPrint('expectedDispatchDate')}
              name="expectedDispatchDate"
              disabled={loading}
            />
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <FormDropdown
                disabled={!euriborDatesEnabled || loading}
                control={control}
                name="selectedEuriborId"
                label={tPrint('selectedEuribor')}
                options={euriborDates}
              />
            </Grid>
            <Grid item xs={6}>
              <FormNumberInput
                control={control}
                label={tPrint('euriborRate')}
                name="euriborRate"
                scale={3}
                fixedDecimalScale
                disabled
              />
            </Grid>
          </Grid>

          <FormTextField
            sx={{ my: 2 }}
            control={control}
            label={tPrint('termsheet')}
            name="termsheet"
            disabled={loading}
          />
          {hasViewPrintDetailsPermission && (
            <>
              <FormTextField
                sx={{ my: 1 }}
                control={control}
                label={tPrint('leasingContractNotes')}
                name="leasingContractNotes"
                disabled={loading}
              />
              <FormTextField
                sx={{ my: 1 }}
                control={control}
                label={tPrint('offerSupplement')}
                name="offerSupplement"
                disabled={loading}
              />
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default FinancePrintForm;

import { useAuth } from 'react-oidc-context';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Dashboard } from './dashboard/Dashboard';
import { Deals } from './deals/Deals';
import { Customers } from './customers/Customers';
import { NotFound } from './not-found/NotFound';
import { NewDeal } from './deals/new/NewDeal';
import { NewCustomer } from './customers/new/NewCustomer';
import { DealDetail } from './deals/detail/DealDetail';
import { EditCustomer } from './customers/edit/EditCustomer';

export function RouteConfig() {
  const auth = useAuth();

  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="deals" element={<Outlet />}>
        <Route index element={<Deals />} />
        <Route path=":id" element={<DealDetail />} />
        <Route path=":id/:calculationId" element={<DealDetail />} />
        <Route path="new" element={<NewDeal />} />
      </Route>
      <Route path="customers" element={<Outlet />}>
        <Route index element={<Customers />} />
        <Route path=":id" element={<EditCustomer />} />
        <Route path="new" element={<NewCustomer />} />
      </Route>
      <Route
        path="/"
        element={auth.isAuthenticated ? <Navigate to="dashboard" replace={true} /> : <></>}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

/* eslint-disable no-unused-vars */
import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { HeadCell } from './EnhancedTableHead';
import { Icons } from '../../component/icons/Icons';
import { Box } from '@mui/material';

interface TableFilterRowProps<T> {
  columns: readonly HeadCell<T>[];
  filters: {
    id: keyof T;
    filterValue: string;
  }[];
  onFilterChanged: (id: keyof T, value: string) => void;
}

export function EnhancedTableFilter<T extends { id: string }>({
  columns = [],
  filters,
  onFilterChanged,
}: TableFilterRowProps<T>) {
  const renderDefaultFilter = (columnDef: HeadCell<T>) => {
    return (
      <TextField
        style={{ textAlign: columnDef.align }}
        fullWidth
        variant="standard"
        value={filters.find((filter) => filter.id === columnDef.id)?.filterValue || ''}
        onChange={(event) => {
          onFilterChanged(columnDef.id, event.target.value);
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">{Icons.search()}</InputAdornment>,
        }}
      />
    );
  };

  const getComponentForColumn = (columnDef: HeadCell<T>) => {
    if (!columnDef.allowFiltering) {
      return null;
    }

    if (columnDef.id) {
      return renderDefaultFilter(columnDef);
    }
  };

  return (
    <TableRow style={{ height: 10 }}>
      {/*There's one static column at the start, fill it with an empty box*/}
      <Box />
      {columns.map((columnDef) => (
        <TableCell key={columnDef.id as string}>{getComponentForColumn(columnDef)}</TableCell>
      ))}
    </TableRow>
  );
}

import './polyfills';
import '@mercedes-benz/typeface-mb-corpo-s';
import '@mercedes-benz/typeface-mb-corpo-a';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { CssBaseline, Theme, ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom';
import './common/i18n/i18n';
import App, { history } from './views/App';
import { LoadingIndicator } from './views/LoadingIndicator';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ConfirmModal } from './common/ConfirmModal';
import { AuthProvider } from 'react-oidc-context';
import { OidcConfig, onSigninCallback } from './common/auth/OidcConfig';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { SnackbarProvider } from 'notistack';

import PermissionProvider from './common/auth/PermissionsContext';

import { pdfjs } from 'react-pdf';

// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { CustomTheme } from './themes/theme';
import { GlobalProvider } from './common/global/GlobalContext';
import { NotificationModal } from './common/global/NotificationModal';
import { QueryClient, QueryClientProvider } from 'react-query';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

if (process.env.NODE_ENV === 'development') {
  // inspect({
  //   // options
  //   // url: 'https://stately.ai/viz?inspect', // (default)
  //   iframe: false // open in new window
  // });
}

const myCache = createCache({
  key: 'mui',
  prepend: true,
});

async function main() {
  const CustomLightTheme: Theme = {
    ...CustomTheme,
    palette: {
      ...CustomTheme.palette,
      background: {
        default: '#ffffff',
        paper: '#ffffff',
      },
    },
  };

  const formats = {
    normalDate: 'dd.MM.yyyy',
    keyboardDate: 'dd.MM.yyyy',
  };

  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
  });

  ReactDOM.render(
    <CacheProvider value={myCache}>
      <ThemeProvider theme={CustomLightTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={formats}>
          <CssBaseline>
            <SnackbarProvider
              maxSnack={8}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              style={{ minWidth: '300px' }}
            >
              {/*// @ts-ignore*/}
              <HistoryRouter history={history}>
                <AuthProvider {...OidcConfig(window)} onSigninCallback={onSigninCallback}>
                  <PermissionProvider>
                    <QueryClientProvider client={queryClient}>
                      <GlobalProvider>
                        <App />
                      </GlobalProvider>
                    </QueryClientProvider>
                  </PermissionProvider>
                </AuthProvider>
              </HistoryRouter>
            </SnackbarProvider>
          </CssBaseline>
          <ConfirmModal />
          <NotificationModal />
          <LoadingIndicator />
        </LocalizationProvider>
      </ThemeProvider>
    </CacheProvider>,
    document.getElementById('root')
  );
}

main();

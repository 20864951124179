import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import { HeadCell } from './EnhancedTableHead';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import { EnhancedTableFilter } from './EnhancedTableFilter';
import * as React from 'react';

export interface PaginatedTableBodyProps<T extends { id: string; [key: string]: any }> {
  rows: T[];
  headCells: readonly HeadCell<T>[];
  hideCheckbox: boolean;
  handleClick: (ev: React.MouseEvent<unknown>, name: string) => void;
  isSelected: (id: string) => boolean;
  isSelectable?: (row: T) => boolean;
  withPagination: boolean;
  rowsPerPage: number;
  emptyRows: number;
  page: number;
  filters: {
    id: keyof T;
    filterValue: string;
  }[];
  onFilterChanged: (id: keyof T, value: string) => void;
}

export function PaginatedTableBody<T extends { id: string; [key: string]: any; className?: string }>({
  headCells,
  rows,
  hideCheckbox,
  handleClick,
  isSelected,
  isSelectable,
  withPagination,
  rowsPerPage,
  emptyRows,
  page,
  filters,
  onFilterChanged,
}: PaginatedTableBodyProps<T>) {
  const hasFilter = headCells.some((headerCell) => headerCell.allowFiltering);

  return (
    <TableBody>
      {hasFilter && (
        <EnhancedTableFilter
          columns={headCells}
          filters={filters}
          onFilterChanged={onFilterChanged}
        />
      )}
      {rows
        .slice(
          withPagination ? page * rowsPerPage : undefined,
          withPagination ? page * rowsPerPage + rowsPerPage : undefined
        )
        .map((row: T, index: number) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={
                !isSelectable || isSelectable(row)
                  ? (event) => handleClick(event, row.id)
                  : () => {}
              }
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={isItemSelected}
              className={row.className}
            >
              {hideCheckbox ? (
                <Box />
              ) : (
                <TableCell padding="checkbox">
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="primary"
                    checked={isItemSelected}
                    disabled={isSelectable && !isSelectable(row)}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </TableCell>
              )}
              {headCells.map((x: HeadCell<T>) => (
                <TableCell align={x.align ?? 'left'} key={x.id as string}>
                  {row[x.id]}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: 53 * emptyRows,
          }}
        >
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
}

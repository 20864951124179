import { CustomerType } from '../../../../generated/ApiClient';
import { Box, Chip, Typography } from '@mui/material';
import { EditPrivateCustomerForm } from '../../../customers/edit/EditPrivateCustomerForm';
import { EditCommercialCustomerForm } from '../../../customers/edit/EditCommercialCustomerForm';
import { PrivateCustomerFormValues } from '../../../customers/data/PrivateCustomerFormValues';
import { CommercialCustomerFormValues } from '../../../customers/data/CommercialCustomerFormValues';
import { Dispatch, SetStateAction, useContext } from 'react';
import { StateMachineContext } from '../state/StateMachineContextProvider';
import { useActor } from '@xstate/react';
import { ADD_APPLICANT, REMOVE_APPLICANT } from '../state/orderStateMachine';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { NewCustomerTypeDropdown } from '../../../customers/new/NewCustomerTypeDropdown';

interface Props {
  customerId?: string;
  customerType: CustomerType;
  setCustomerType: Dispatch<SetStateAction<CustomerType>>;

  onAddMore: () => void;
  onSelectCustomer: (id: string, keepType: boolean) => void;
}

export function ApplicantConfiguration({
  customerId,
  customerType,
  setCustomerType,
  onAddMore,
  onSelectCustomer,
}: Props) {
  const { t } = useTranslation();
  const stateMachine = useContext(StateMachineContext);
  const [state, send] = useActor(stateMachine);

  const submitDealCustomer = (
    values: PrivateCustomerFormValues | CommercialCustomerFormValues,
    type: CustomerType,
    applyMainCustomer: boolean
  ) => {
    const id =
      customerId && customerId !== ''
        ? customerId
        : `new-${(state.context.applicants ?? []).length.toString()}`;
    send({
      type: ADD_APPLICANT,
      info: {
        customerId: id,
        customer: values,
        type: type,
        applyChangesToMainCustomer: applyMainCustomer,
      },
    });
    onSelectCustomer(id, true);
  };

  const handleSave = (
    c: PrivateCustomerFormValues | CommercialCustomerFormValues,
    type: CustomerType,
    isDirty: boolean
  ) => {
    submitDealCustomer(c, type, false);
  };

  const handleDelete = (id?: string) => {
    if (id) {
      send({
        type: REMOVE_APPLICANT,
        info: id,
      });
    }
  };

  const handleClick = (id?: string) => {
    if (id) {
      onSelectCustomer(id, false);
    }
  };

  const { customer } =
    (state.context.applicants ?? []).find((a) => a.customerId === customerId) || {};

  const submitButton = () => (
    <Button
      color="primary"
      variant="contained"
      style={{ height: 28 }}
      type="submit"
      form="edit-customer-form"
    >
      {customer ? t('deals.new.applicant.submitSave') : t('deals.new.applicant.submitButtonAdd')}
    </Button>
  );

  const getCustomerLabel = (c: PrivateCustomerFormValues | CommercialCustomerFormValues) => {
    if ('companyName' in c) {
      return `${c.companyName}`;
    } else {
      return `${c.firstName} ${c.lastName}`;
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', p: 2 }}>
          <Typography variant="subtitle1">{t('deals.new.applicant.hint')}</Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {(state.context.applicants ?? []).map((a) => (
              <Chip
                key={a.customerId}
                sx={{ marginX: 1 }}
                variant="outlined"
                label={getCustomerLabel(a.customer)}
                onClick={() => handleClick(a.customerId)}
                onDelete={() => handleDelete(a.customerId)}
              />
            ))}
          </Box>
        </Box>

        <Button
          disabled={state.context.applicants && state.context.applicants.length > 1}
          style={{ height: 28 }}
          className="white"
          onClick={() => onAddMore()}
        >
          {t('deals.new.applicant.addMoreButton')}
        </Button>
      </Box>
      {!customerId && (
        <Box className="new-customer">
          <NewCustomerTypeDropdown onChange={setCustomerType} value={customerType} />
        </Box>
      )}

      {customerType === CustomerType.Private && (
        <EditPrivateCustomerForm
          onSubmit={(x) => handleSave(x, CustomerType.Private, false)}
          customerId={customerId !== '' ? Number(customerId) : undefined}
          overrideValues={customer}
          actions={submitButton()}
          readonly={false}
          isNew={!customerId}
          isCoApplicant
          showSelfDisclosure
        />
      )}
      {customerType === CustomerType.Commercial && (
        <EditCommercialCustomerForm
          onSubmit={(x) => handleSave(x, CustomerType.Commercial, false)}
          customerId={customerId !== '' ? Number(customerId) : undefined}
          overrideValues={customer}
          actions={submitButton()}
          readonly={false}
          isNew={!customerId}
          showSelfDisclosure
        />
      )}
    </Box>
  );
}

import { Box, Grid } from '@mui/material';
import { FormCheckbox } from '../../../../../component/form/FormCheckbox';
import { Control } from 'react-hook-form';
import { InsuranceFormValues } from '../InsuranceSchema';
import { useTranslation } from 'react-i18next';
import { FormDropdown } from '../../../../../component/form/FormDropdown';
import React from 'react';
import { OptionItem } from '../../../../../generated/ApiClient';
import { FormCheckboxGroup } from '../../../../../component/form/FormCheckboxGroup';
import { FormRadioButton } from '../../../../../component/form/FormRadioButton';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';

interface Props {
  control: Control<InsuranceFormValues>;
  onRelevantChange: (name: string, value: unknown) => void;
  loading: boolean;
  liabilityCompanies: OptionItem[];
  liabilityInsurances: OptionItem[];
  kaskoCompanies: OptionItem[];
  kaskoInsurances: OptionItem[];
  kaskoAdditionalProducts: OptionItem[];
  readonly: boolean;
}

export const InsuranceCompanies = ({
  control,
  onRelevantChange,
  loading,
  liabilityCompanies,
  liabilityInsurances,
  kaskoCompanies,
  kaskoInsurances,
  kaskoAdditionalProducts,
  readonly
}: Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit = hasPermission('InsuranceProducts', 'Edit');
  const handleRelevantChangeEvent = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onRelevantChange(event.target.name, event.target.value);
  };

  return (
    <Grid container sx={{ mt: 2 }} columnSpacing={6}>
      <Grid item xs={6}>
        <Box sx={{ mx: 1 }}>
          <FormCheckbox
            control={control}
            variant="switch"
            name="enableLiability"
            label={t('deals.edit.insurance.fields.enableLiability')}
            disabled={!canEdit || readonly}
          />
        </Box>
        <Box>
          <FormDropdown
            control={control}
            name="liabilityInsuranceCompanyId"
            label={t('deals.edit.insurance.fields.liabilityInsuranceCompany')}
            onChange={handleRelevantChangeEvent}
            options={liabilityCompanies}
            sx={{ m: 1 }}
            shrink
            disabled={loading || !canEdit || readonly}
          />
          <FormDropdown
            control={control}
            name="liabilityInsuranceId"
            label={t('deals.edit.insurance.fields.liabilityInsurance')}
            onChange={handleRelevantChangeEvent}
            options={liabilityInsurances}
            sx={{ m: 1 }}
            shrink
            disabled={loading || !canEdit || readonly}
          />

          <h3>{t('deals.edit.insurance.additionalProducts')}</h3>
          <Box sx={{ mx: 1 }}>
            {/* Temporary disabled. See Bug 56321
                            <FormCheckbox
                            control={control}
                            label={t('deals.edit.insurance.fields.enableLegalProtection')}
                            name="enableLegalProtection"
                        /> */}
            <FormCheckbox
              control={control}
              label={t('deals.edit.insurance.fields.enableAccident')}
              name="enableAccident"
              onChange={handleRelevantChangeEvent}
              disabled={loading || !canEdit || readonly}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Box sx={{ mx: 1 }}>
          <FormCheckbox
            control={control}
            variant="switch"
            name="enableKasko"
            label={t('deals.edit.insurance.fields.enableKasko')}
            disabled={!canEdit || readonly}
          />
        </Box>
        <Box>
          <FormDropdown
            control={control}
            name="kaskoInsuranceCompanyId"
            label={t('deals.edit.insurance.fields.kaskoInsuranceCompany')}
            onChange={handleRelevantChangeEvent}
            options={kaskoCompanies}
            sx={{ m: 1 }}
            shrink
            disabled={loading || !canEdit || readonly}
          />
          <FormDropdown
            control={control}
            name="kaskoInsuranceId"
            label={t('deals.edit.insurance.fields.kaskoInsurance')}
            onChange={handleRelevantChangeEvent}
            options={kaskoInsurances}
            sx={{ m: 1 }}
            shrink
            disabled={loading || !canEdit || readonly}
          />

          <Box sx={{ m: 1 }}>
            <FormCheckboxGroup
              control={control}
              options={kaskoAdditionalProducts}
              label={t('deals.edit.insurance.fields.kaskoAdditionalProducts')}
              name="kaskoAdditionalProductIds"
              onChange={handleRelevantChangeEvent}
              disabled={loading || !canEdit || readonly}
            />
          </Box>

          <Box sx={{ m: 1 }}>
            <FormRadioButton
              control={control}
              label={t('deals.edit.insurance.fields.inputTaxDeduction')}
              name="inputTaxDeduction"
              disabled={!canEdit || readonly}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

import { grey, blue, red, green, yellow } from '@mercedes-benz/mui5-theme/';
import { Box } from '@mui/material';

interface Props {
  title: string;
  value: string | number;
  color?: string;
  onClick: Function;
}

export function OverviewListEntry({ title, value, color, onClick }: Props) {
  const mapColor = (c?: string) => {
    if (c === 'blue') {
      return blue[70];
    }
    if (c === 'red') {
      return red[70];
    }
    if (c === 'grey') {
      return grey[65];
    }
    if (c === 'green') {
      return green[70];
    }
    if (c === 'yellow') {
      return yellow[60];
    }
    return grey[25];
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'noWrap',
        justifyContent: 'start',
        flexDirection: 'row',
        padding: '8px 20px',
        alignItems: 'center',
        width: '100%',
        cursor: 'pointer',
      }}
      onClick={() => onClick()}
    >
      <span className="dashboard-indicator" style={{ background: mapColor(color) }} />

      <Box sx={{ textAlign: 'right', minWidth: '50px', marginRight: '10px' }}>{value}</Box>
      <Box>{title}</Box>
    </Box>
  );
}

import { AlertColor } from '@mui/material';
import { ApiClient, ApiException, ApiMessages, MessageSeverity, UnauthorizedResult } from '../generated/ApiClient';
import { pushNotificationHandler } from './PushNotification';
import { t } from 'i18next';
import globalErrorUserInteractions from './validation/GlobalErrorUserInteractions';
import { UserManager, UserManagerSettings } from "oidc-client-ts";
import { OidcConfig } from './auth/OidcConfig';

const apiClient = new ApiClient(process.env.NODE_ENV !== 'development' ? '/api' : undefined, {
  async fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
    if (!init) init = {};
    init.redirect = 'follow';

    let response: Response;
    try {
      response = await fetch(url, init);
    } catch (error) {
      console.log(error);
      pushNotificationHandler.publish('Es ist ein Netzwerkfehler aufgetreten', 'error');
      return Response.error();
    }

    const clonedResponse = response.clone();
    switch (response.status) {
      case 200:
      case 201:
      case 202:
      case 204:
        await handleGlobalMessages(response);
        break;

      //HTTP 300s will be handled by browser

      case 400:
        await handleGlobalMessages(response);
        break;

      case 401:
      case 403:
        handleUnauthorized(response);
        break;

      //HTTP 4xx should be handled by the caller
      // case 404:
      // case 405:
      //     handleNotFound(response);
      //     break;

      default:
        handleGenericError(response);
        break;
    }
    return clonedResponse;
  },
});

export default apiClient;

async function handleGlobalMessages(response: Response) {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') === -1) return;

  let apiResult = await response.json();

  if (!apiResult.messages) return;

  const apiMessages = ApiMessages.fromJS(apiResult.messages);
  if (!apiMessages) {
    console.warn('response.messages is not of type `ApiMessages`');
    return;
  }

  for (let globalMessage of apiMessages.global) {
    if (globalMessage.translate) {
      const message = t(`errors.${globalMessage.message}`, globalMessage.arguments);
      pushGlobalMessage(message, globalMessage.severity);
    } else {
      pushGlobalMessage(globalMessage.message, globalMessage.severity);
    }
    globalErrorUserInteractions(globalMessage);
  }
}

async function handleUnauthorized(response: Response) {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') === -1) return;

  let apiResult = await response.json();

  const unauthorizedResult = UnauthorizedResult.fromJS(apiResult);
  if (unauthorizedResult && unauthorizedResult.redirectLogout) {
    const mgr = new UserManager(OidcConfig(window) as UserManagerSettings);
    await mgr.signoutRedirect()
  } else {
    pushGlobalMessage('Sie sind nicht autorisiert diese Aktion auszuführen.', MessageSeverity.Error);
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function handleNotFound(_: Response) {
  pushGlobalMessage(
    'Die von Ihnen angeforderte Ressource ist nicht verfügbar.',
    MessageSeverity.Warning
  );
}

function handleGenericError(_: Response) {
  pushGlobalMessage(
    'Es ist ein Fehler aufgetreten. Sollte dies öfter vorkommen, so informieren Sie bitte Ihren zuständigen IT Dienstleister.',
    MessageSeverity.Error
  );
}

function pushGlobalMessage(message: string, severity: MessageSeverity) {
  var severityString = getAlertColorBySeverity(severity);
  pushNotificationHandler.publish(message, severityString);
}

function getAlertColorBySeverity(severity: MessageSeverity): AlertColor {
  switch (severity) {
    case MessageSeverity.Info:
      return 'info';

    case MessageSeverity.Warning:
      return 'warning';

    case MessageSeverity.Error:
      return 'error';
  }
}

export function renderValidationErrors<T>(
  error: any,
  setError: (fieldName: keyof T, error: { type: string; message: string }) => void
) {
  let messages: ApiMessages;
  if (error instanceof ApiMessages) {
    messages = error;
  } else if (error instanceof ApiException && error.status === 400) {
    messages = JSON.parse(error.response).messages;
  } else {
    messages = error.messages;
  }

  if (!messages) return;

  for (let localMessage of messages.local) {
    const message = localMessage.translate
      ? t(`errors.${localMessage.message}`)
      : localMessage.message;
    const path = propertyPathToCamelCase(localMessage.target) as keyof T;
    setError(path, { type: 'custom', message: message });
  }
}

export function propertyPathToCamelCase(str: string) {
  return str
    .split('.')
    .map((part) => part[0].toLowerCase() + part.substring(1))
    .join('.');
}

import { Grid, InputAdornment } from '@mui/material';
import { t } from 'i18next';
import { Control } from 'react-hook-form';
import { FormDropdown, OptionItem } from '../../../component/form/FormDropdown';
import { FormNumberInput } from '../../../component/form/FormNumberInput';
import { FormTextField } from '../../../component/form/FormTextField';
import { PrivateCustomerFormValues } from '../data/PrivateCustomerFormValues';
import { FormCheckbox } from '../../../component/form/FormCheckbox';

interface Props {
  control: Control<PrivateCustomerFormValues>;
  dropdownValues: { [name: string]: OptionItem[] };
  readonly: boolean;
  showSelfDisclosure?: boolean;
}

export function Finance({ control, dropdownValues, readonly, showSelfDisclosure }: Props) {
  const gridCol = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 3,
    xl: 3,
  };

  return (
    <Grid container spacing={3} className="finance">
      <Grid item {...gridCol}>
        <FormDropdown
          control={control}
          name="residenceTypeId"
          label={t('customers.edit.finance.residenceType')}
          options={dropdownValues.residenceTypes}
          emptyText="-"
          required
          disabled={readonly}
        />

        <FormNumberInput
          control={control}
          name="monthlyRentInclAddCharges"
          label={t('customers.edit.finance.monthlyRentInclAddCharges')}
          disabled={readonly}
        />

        <FormNumberInput
          control={control}
          name="monthlyLoanRepayment"
          label={t('customers.edit.finance.monthlyLoanRepayment')}
          disabled={readonly}
        />
      </Grid>
      <Grid item {...gridCol}>
        <FormNumberInput
          control={control}
          name="currentBankLoansAndOverdrafts"
          label={t('customers.edit.finance.currentBankLoansAndOverdrafts')}
          disabled={readonly}
        />

        <FormNumberInput
          control={control}
          name="maintenanceObligations"
          label={t('customers.edit.finance.maintenanceObligations')}
          disabled={readonly}
        />

        <FormNumberInput
          control={control}
          name="currentHousehold"
          label={t('customers.edit.finance.currentHousehold')}
          disabled={readonly}
        />
      </Grid>
      <Grid item {...gridCol}>
        <FormTextField
          control={control}
          name="assetCity"
          label={t('customers.edit.finance.assetCity')}
          disabled={readonly}
        />

        <FormDropdown
          control={control}
          name="assetTypeId"
          label={t('customers.edit.finance.assetType')}
          options={dropdownValues.assetTypes}
          emptyText="-"
          disabled={readonly}
        />

        <FormNumberInput
          endAdornment={<span>m²</span>}
          control={control}
          name="assetSize"
          label={t('customers.edit.finance.assetSize')}
          disabled={readonly}
        />
      </Grid>
      <Grid item {...gridCol}>
        <FormNumberInput
          startAdornment={<InputAdornment position="start">€</InputAdornment>}
          control={control}
          name="otherAssets"
          label={t('customers.edit.finance.otherAssets')}
          disabled={readonly}
        />

        <FormDropdown
          control={control}
          name="otherAssetTypeId"
          label={t('customers.edit.finance.otherAssetType')}
          options={dropdownValues.otherAssetTypes}
          emptyText="-"
          disabled={readonly}
        />

        {showSelfDisclosure && (
          <FormCheckbox
            control={control}
            name="selfDisclosureAgreed"
            label={t('customers.edit.selfDisclosureAgreed')}
            disabled={readonly}
            labelSx={{whiteSpace: "pre-line"}}
          />
        )}
      </Grid>
    </Grid>
  );
}

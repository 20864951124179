import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import RestHttpClient from '../../../../common/RestHttpClient';
import { HeadCell } from '../../../../common/table/EnhancedTableHead';
import { EnhancedTable } from '../../../../common/table/EnhancedTable';
import { Box, IconButton, LinearProgress, Tooltip } from '@mui/material';
import * as React from 'react';
import { Icons } from '../../../../component/icons/Icons';
import { confirmHandler } from '../../../../common/ConfirmModal';
import { usePermissions } from '../../../../common/auth/PermissionsContext';
import PDFPreviewDialog, { PdfPreview } from '../../../../component/PDFPreviewDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClearIcon from '@mui/icons-material/Clear';
import { Inventory } from '@mui/icons-material';
import { pushNotificationHandler } from '../../../../common/PushNotification';
import { isMobileSafari } from 'react-device-detect';

interface Props {
  dealId: number;
}

interface SignedDocRow {
  id: string;
  name: string;
  calculationNumber: string;
  icon: JSX.Element;
}

export function EditDealSignedDocuments({ dealId }: Props) {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  const [docLoading, setDocLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState<SignedDocRow[]>([]);
  const [openDocFile, setOpenDocFile] = useState<PdfPreview | undefined>();

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const { data } = await RestHttpClient.getSignedDocuments(dealId);
      setDocs(
        data.map((d) => ({
          id: d.id.toString(),
          name: d.name,
          calculationNumber: d.calculationNumber,
          icon: (
            <>
              <Tooltip title={t('deals.edit.documents.view')}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRowClick(d.id.toString());
                  }}
                >
                  <VisibilityIcon opacity={0.4} color="secondary" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('deals.edit.documents.download')}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownloadClick(d.id.toString());
                  }}
                >
                  {Icons.download()}
                </IconButton>
              </Tooltip>
              {hasPermission('SignatureDocument', 'Edit') && (
                <Tooltip title={t('deals.edit.documents.cancel')}>
                  <IconButton onClick={(e) => handleCancelClick(e, d.id)}>
                    <ClearIcon opacity={0.4} color="secondary" />
                  </IconButton>
                </Tooltip>
              )}
              {hasPermission('SignatureDocument', 'Execute') && (
                <Tooltip title={t('deals.edit.documents.archive')}>
                  <IconButton onClick={(e) => handleArchiveClick(e, d.id.toString())}>
                    <Inventory opacity={0.4} color="secondary" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ),
        }))
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealId]);

  const headCells: readonly HeadCell<SignedDocRow>[] = [
    {
      id: 'name',
      disablePadding: false,
      label: t('deals.edit.documents.name'),
    },
    {
      id: 'calculationNumber',
      disablePadding: false,
      label: t('deals.edit.documents.calculationNumber'),
    },
    {
      id: 'icon',
      align: 'right',
      disablePadding: false,
      label: '',
    },
  ];

  const handleRowClick = (id: string) => {
    setDocLoading(true);
    let win: Window | undefined;
    if (isMobileSafari) {
      win = window.open(undefined, '_blank') ?? undefined;
    }
    RestHttpClient.getSignedDocument(dealId, Number(id))
      .then((res) => {
        if (!win) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result as string;
            setOpenDocFile({ base64: base64data, filename: res.fileName });
          };
          reader.readAsDataURL(res.data);
          return;
        }
        win.location.href = window.webkitURL.createObjectURL(res.data);
      })
      .finally(() => {
        setDocLoading(false);
      });
  };

  const handleDownloadClick = (id: string) => {
    try {
      setDocLoading(true);
      RestHttpClient.getSignedDocument(dealId, Number(id)).then((res) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(res.data);
        link.download = res.fileName ?? 'download';
        link.click();
      });
    } catch (e) {
      console.error(e);
    } finally {
      setDocLoading(false);
    }
  };

  const handleArchiveClick = async (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    confirmHandler.confirm(t('deals.edit.documents.archiveConfirm'), async () => {
      try {
        await RestHttpClient.archiveSignedDocument(dealId, [Number(id)]);
        pushNotificationHandler.publish(t('deals.edit.documents.archiveSuccess'), 'success');
        fetchDocuments();
      } catch (e) {
        console.error(e);
      }
    });
  };

  const handleCancelClick = async (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    confirmHandler.confirm(t('deals.edit.documents.cancelConfirm'), async () => {
      try {
        await RestHttpClient.cancelSignedDocument(dealId, id);
        fetchDocuments();
      } catch (e) {
        console.error(e);
      }
    });
  };

  const handleArchiveAll = (e: React.MouseEvent) => {
    e.stopPropagation();
    confirmHandler.confirm(t('deals.edit.documents.archiveBatchConfirm'), async () => {
      try {
        setLoading(true);
        await RestHttpClient.archiveSignedDocument(
          dealId,
          docs.filter((d) => d.id).map((d) => Number(d.id))
        );
        pushNotificationHandler.publish(t('deals.edit.documents.archiveSuccess'), 'success');
        fetchDocuments();
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    });
  };

  return (
    <>
      {docLoading && <LinearProgress />}
      <PDFPreviewDialog file={openDocFile} onClose={() => setOpenDocFile(undefined)} />
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        {hasPermission('SignatureDocument', 'Execute') && Boolean(docs.length) && (
          <Tooltip title={t('deals.edit.documents.archiveBatch')}>
            <IconButton disabled={!docs.length} onClick={handleArchiveAll}>
              <Inventory opacity={0.8} color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <EnhancedTable
        loading={loading}
        onRowClick={(_, id) => handleRowClick(id)}
        rows={docs}
        headCells={headCells}
        hideCheckbox
        variant="endless"
      />
    </>
  );
}

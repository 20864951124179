import { Control, Controller, useWatch } from 'react-hook-form';
import { FinancesDrawerFormValues, SalesAidValues } from '../RightDrawerSchema';
import { OptionItem, SalesAidView, SubsidyRecipientView } from '../../../../../generated/ApiClient';
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import { CopyAll, ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormDatePicker } from '../../../../../component/form/FormDatePicker';
import RestHttpClient from '../../../../../common/RestHttpClient';
import { Icons } from '../../../../../component/icons/Icons';
import { SalesAidDialog } from './salesAids/SalesAidDialog';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';
import SalesAidDetails from './salesAids/SalesAidDetails';

interface Props {
  control: Control<FinancesDrawerFormValues>;
  loading: boolean;
  onRelevantChange: (name: string, value: unknown) => void;
  specialOffers: SalesAidView[];
  dealSalesAids: (SalesAidValues | SalesAidView)[];
  onAddSalesAid: (val: SalesAidValues) => void;
  onEditSalesAid: (salesAid: SalesAidValues, index: number) => void;
  givers: OptionItem[];
  recipients: SubsidyRecipientView[];
  recipientUnits: { [index: number]: string };
  readonly?: boolean;
}

function addOrIncrementNumber(str: string) {
  const regex = /#(\d+)$/; // only matches numbers with a "#" prefix at the end of the string
  const match = str.match(regex);

  if (match && match.index !== undefined) {
    const num = parseInt(match[1]);
    const startIndex = match.index;
    const endIndex = startIndex + match[0].length;
    return str.substring(0, startIndex) + '#' + (num + 1) + str.substring(endIndex);
  } else {
    return str + ' #' + 1;
  }
}

export const FinancesCalculationPromotions = ({
  control,
  loading,
  onRelevantChange,
  specialOffers,
  dealSalesAids,
  onAddSalesAid,
  onEditSalesAid,
  givers,
  recipients,
  recipientUnits,
  readonly,
}: Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  const canViewLocked = hasPermission('LockSalesAid', 'Execute');
  const specialOfferId = useWatch({ control, name: 'specialOfferId' });
  const selDealSalesAid = useWatch({ control, name: 'dealSalesAidId' });

  const defaultOpen = Boolean(specialOfferId || selDealSalesAid != null);

  const [open, setOpen] = useState(defaultOpen);
  const [switchChecked, setSwitchChecked] = useState(defaultOpen);

  useEffect(() => {
    if (specialOfferId != null || selDealSalesAid != null) {
      setOpen(true);
      setSwitchChecked(true);
    } else {
      setOpen(false);
      setSwitchChecked(false);
    }
  }, [specialOfferId, selDealSalesAid]);

  const [dealAidEditIndex, setDealAidEditIndex] = useState<number | undefined>(undefined);
  const [salesAid, setSalesAid] = useState<SalesAidView | SalesAidValues>();

  const handleClickOpen = (event: any) => {
    event.preventDefault();
    setOpen(!open);
  };

  const handleEdit = (salesAidId: number) => (event: React.MouseEvent<unknown>) => {
    event.preventDefault();
    RestHttpClient.getSalesAid(salesAidId).then((res) => {
      setSalesAid({
        ...res.data,
        id: undefined,
        specialOfferId: res.data.id,
        isEditable: true,
        name: addOrIncrementNumber(res.data.name),
      });
    });
  };

  const handleEditDealAid =
    (salesAid: SalesAidView | SalesAidValues, index?: number) =>
    (event: React.MouseEvent<unknown>) => {
      console.log(salesAid);
      event.preventDefault();
      if (index !== undefined) {
        setDealAidEditIndex(index);
        setSalesAid({
          ...salesAid,
          specialOfferId: salesAid.specialOfferId,
          name: salesAid.name,
        });
      } else {
        setSalesAid({
          ...salesAid,
          id: undefined,
          isEditable: true,
          specialOfferId: salesAid.specialOfferId,
          name: addOrIncrementNumber(salesAid.name),
        });
      }
    };

  const handleNew = () => {
    setSalesAid(
      new SalesAidView({
        name: 'Neue Verkaufshilfe',
        subsidies: [],
        isEditable: true,
        isLocked: false,
      })
    );
  };

  const handleCreate = (values: SalesAidValues) => {
    if (dealAidEditIndex === undefined) {
      onAddSalesAid({ ...salesAid, ...values });
    } else {
      onEditSalesAid({ ...salesAid, ...values }, dealAidEditIndex);
      setDealAidEditIndex(undefined);
    }
  };

  const handleClose = () => {
    setSalesAid(undefined);
  };

  const handleSwitchChecked = (event: any) => {
    if (!open && !switchChecked) {
      setSwitchChecked(event.target.checked);
      onRelevantChange('specialOfferEnabled', !open);
      setOpen(!open);
    } else if (!switchChecked && open) {
      setSwitchChecked(event.target.checked);
      onRelevantChange('specialOfferEnabled', open);
    } else if (open && switchChecked) {
      setSwitchChecked(event.target.checked);
      onRelevantChange('specialOfferEnabled', !open);
      setOpen(!open);
    } else {
      setSwitchChecked(event.target.checked);
      setOpen(false);
    }
  };

  const getSelAid = (): (SalesAidValues | SalesAidView) | undefined => {
    if (selDealSalesAid != null) {
      return dealSalesAids[selDealSalesAid];
    }
    if (specialOfferId != null) {
      return specialOffers.find((s) => s.id === specialOfferId);
    }
  };

  return (
    <Box>
      <ListItemButton disabled={readonly}>
        <ListItemText
          primary={
            <FormControlLabel
              onChange={handleSwitchChecked}
              control={<Switch disabled={readonly} checked={switchChecked} />}
              label={t('deals.new.financesConfiguration.calculationDrawer.promotions', {
                value: [...specialOffers, ...dealSalesAids].filter(x => !x.isLocked || canViewLocked).length,
              })}
            />
          }
        ></ListItemText>
        {open ? <ExpandLess onClick={handleClickOpen} /> : <ExpandMore onClick={handleClickOpen} />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ mx: 2, my: 1 }}>
          {hasPermission('PurchaseContractDate', 'Edit') && (
            <FormDatePicker
              control={control}
              label={t(
                'deals.new.financesConfiguration.calculationDrawer.salesAids.purchaseContractDate'
              )}
              name="purchaseContractDate"
              onChange={(event) => {
                onRelevantChange('purchaseContractDate', event.target.value);
              }}
            />
          )}
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="specialOfferId"
              render={({ field }) => {
                return (
                  <Box sx={{ ml: '20px' }}>
                    <RadioGroup
                      {...field}
                      value={field.value ?? null}
                      onChange={(e) => {
                        if (readonly) return;
                        const value = e.target.value ? Number(e.target.value) : undefined;
                        onRelevantChange('specialOfferId', value);
                        field.onChange({
                          target: {
                            name: 'specialOfferId',
                            value: value,
                          },
                        });
                      }}
                    >
                      {specialOffers.map((sa) => (
                        <Box key={sa.id}>
                          <FormControlLabel control={<Radio />} label={sa.name} value={sa.id} />
                          {sa.id !== undefined && sa.isEditable && !readonly && (
                            <IconButton onClick={handleEdit(sa.id)}>
                              <CopyAll />
                            </IconButton>
                          )}
                        </Box>
                      ))}
                    </RadioGroup>
                  </Box>
                );
              }}
            />

            <Controller
              control={control}
              name="dealSalesAidId"
              render={({ field }) => {
                // @ts-ignore
                return (
                  <Box sx={{ ml: '20px' }}>
                    <RadioGroup
                      {...field}
                      value={field.value ?? null}
                      onChange={(e) => {
                        if (readonly) return;
                        const value = e.target.value ? Number(e.target.value) : undefined;
                        onRelevantChange('dealSalesAidId', value);
                        field.onChange({
                          target: {
                            name: 'dealSalesAidId',
                            value: value,
                          },
                        });
                      }}
                    >
                      {dealSalesAids.map(
                        (dsa, i) =>
                          (!dsa.isLocked || i === field.value || canViewLocked) && (
                            <Box key={`${dsa.name}-${i}`}>
                              <FormControlLabel
                                control={<Radio disabled={readonly} />}
                                label={dsa.name}
                                value={i}
                              />
                              {!readonly &&
                                // @ts-ignore
                                (dsa.isEditable ? (
                                  <IconButton onClick={handleEditDealAid(dsa, i)}>
                                    {Icons.pencil()}
                                  </IconButton>
                                ) : (
                                  <IconButton onClick={handleEditDealAid(dsa)}>
                                    <CopyAll />
                                  </IconButton>
                                ))}
                            </Box>
                          )
                      )}
                    </RadioGroup>
                  </Box>
                );
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <SalesAidDetails salesAid={getSelAid()} />
          </Grid>
        </Grid>

        {!readonly && (
          <Button
            onClick={() => handleNew()}
            sx={{ background: 'black' }}
            variant="contained"
            disabled={loading || !hasPermission('Subsidies', 'Create')}
          >
            {t('deals.new.financesConfiguration.calculationDrawer.salesAids.new')}
          </Button>
        )}
      </Collapse>

      <SalesAidDialog
        financingControl={control}
        onRelevantChange={onRelevantChange}
        salesAid={salesAid}
        onClose={handleClose}
        onSubmit={handleCreate}
        givers={givers}
        recipientUnits={recipientUnits}
        recipients={recipients}
      />
    </Box>
  );
};

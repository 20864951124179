import { Box, FormControlLabel, Grid, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { usePermissions } from '../../../common/auth/PermissionsContext';
import DashboardApplicationWidget from './DashboardApplicationWidget';
import DashboardPreliminaryWidget from './DashboardPreliminaryWidget';
import DashboardSpecialOfferWidget from './DashboardSpecialOfferWidget';
import DashboardDocumentsWidget from './DashboardDocumentsWidget';
import DashboardIncentives from './DashboardIncentives';
import { applicationsTab, contractsAdvanceTab, docsTab, specialOfferTab } from '../Dashboard';

interface Props {
  loading: boolean;
  incentiveAccepted: boolean | undefined;
  documentId: string | undefined;
  handleApprove: (isAccepted: boolean) => Promise<void>;
  setActiveTab: Dispatch<SetStateAction<number>>;
  onTabChange: (tab: number, filter?: number | string) => void;
}

export function DashboardOverview({
  loading,
  incentiveAccepted,
  documentId,
  handleApprove,
  setActiveTab,
  onTabChange,
}: Props) {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  const [showAll, setShowAll] = useState(false);

  return (
    <Grid mt={2} container spacing={2} className="dashboard-overview">
      {!hasPermission('Dashboard.AllUsersContracts', 'View') &&
        hasPermission('Dashboard.EmployeeContracts', 'View') && (
          <Box sx={{ ml: 5 }}>
            <FormControlLabel
              onChange={() => setShowAll(!showAll)}
              control={<Switch checked={showAll} />}
              label={t('dashboard.applications.showAll')}
            />
          </Box>
        )}

      <Grid item xs={12} md={12} lg={incentiveAccepted === false ? 12 : 9} container spacing={2}>
        <DashboardApplicationWidget
          showAll={showAll}
          onFilterChange={(f) => onTabChange(applicationsTab, f)}
        />

        <Grid
          item
          xs={6}
          lg={3}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <DashboardPreliminaryWidget
            showAll={showAll}
            onFilterChange={(f) => onTabChange(contractsAdvanceTab, f)}
          />
          {/* <DashboardActiveWidget showAll={showAll} /> */}
        </Grid>

        <DashboardSpecialOfferWidget
          showAll={showAll}
          onFilterChange={(f) => onTabChange(specialOfferTab, f)}
        />

        <DashboardDocumentsWidget
          showAll={showAll}
          onFilterChange={(f) => onTabChange(docsTab, f)}
        />
      </Grid>

      {documentId != undefined && (
        <DashboardIncentives
          loading={loading}
          incentiveAccepted={incentiveAccepted}
          documentId={documentId}
          handleApprove={handleApprove}
          setActiveTab={setActiveTab}
        />
      )}
    </Grid>
  );
}

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import RestHttpClient from '../../../../common/RestHttpClient';
import { IconButton, LinearProgress, Tooltip } from '@mui/material';
import { HeadCell } from '../../../../common/table/EnhancedTableHead';
import { EnhancedTable } from '../../../../common/table/EnhancedTable';
import { Icons } from '../../../../component/icons/Icons';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as React from 'react';
import PDFPreviewDialog, { PdfPreview } from '../../../../component/PDFPreviewDialog';
import { isMobileSafari } from 'react-device-detect';

interface Props {
  dealId: number;
}

interface ArchivedDocRow {
  id: string;
  name: string;
  icon: JSX.Element;
}

export function EditDealArchivedDocuments({ dealId }: Props) {
  const { t } = useTranslation();

  const [docLoading, setDocLoading] = useState(false);

  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState<ArchivedDocRow[]>([]);
  const [openDocFile, setOpenDocFile] = useState<PdfPreview | undefined>();

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const { data } = await RestHttpClient.getArchivedDocuments(dealId);
      setDocs(
        data.map((d) => ({
          id: d.id.toString(),
          name: d.name,
          icon: (
            <>
              <Tooltip title={t('deals.edit.documents.view')}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRowClick(d.id.toString());
                  }}
                >
                  <VisibilityIcon opacity={0.4} color="secondary" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('deals.edit.documents.download')}>
                <IconButton onClick={() => handleDownloadClick(d.id)}>
                  {Icons.download()}
                </IconButton>
              </Tooltip>
            </>
          ),
        }))
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealId]);

  const headCells: readonly HeadCell<ArchivedDocRow>[] = [
    {
      id: 'name',
      disablePadding: false,
      label: t('deals.edit.documents.name'),
    },
    {
      id: 'icon',
      align: 'right',
      disablePadding: false,
      label: '',
    },
  ];
  const handleRowClick = (id: string) => {
    let win: Window | undefined;
    if (isMobileSafari) {
      win = window.open(undefined, '_blank') ?? undefined;
    }

    RestHttpClient.getArchivedDocument(dealId, id)
      .then((res) => {
        if (!win) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result as string;
            setOpenDocFile({ base64: base64data, filename: res.fileName });
          };
          reader.readAsDataURL(res.data);
          return;
        }
        win.location.href = window.webkitURL.createObjectURL(res.data);
      })
      .finally(() => {
        setDocLoading(false);
      });
  };
  const handleDownloadClick = (id: string) => {
    try {
      setDocLoading(true);
      RestHttpClient.getArchivedDocument(dealId, id).then((res) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(res.data);
        link.download = res.fileName ?? 'download';
        link.click();
      });
    } catch (e) {
      console.error(e);
    } finally {
      setDocLoading(false);
    }
  };
  return (
    <>
      {docLoading && <LinearProgress />}
      <PDFPreviewDialog file={openDocFile} onClose={() => setOpenDocFile(undefined)} />

      <EnhancedTable
        loading={loading}
        onRowClick={(_, id) => handleRowClick(id)}
        rows={docs}
        headCells={headCells}
        hideCheckbox
        variant="endless"
      />
    </>
  );
}

import { Box } from '@mui/material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Icons } from '../../component/icons/Icons';
import { EnhancedTable } from '../../common/table/EnhancedTable';
import { HeadCell } from '../../common/table/EnhancedTableHead';
import { CustomerOverview, DealType } from '../../generated/ApiClient';
import { useContext } from 'react';
import { CustomerContext } from './data/CustomerContext';
import { CustomerChip } from './CustomerChip';

interface CustomerRow {
  id: string;
  name?: string | null;
  lastName?: string | null;
  address?: string | null;
  customerType?: string;
  isValidCustomer: JSX.Element;
  icon?: JSX.Element;
}

function MapCustomer(customer: CustomerOverview, icon?: JSX.Element): CustomerRow {
  return {
    id: customer.id.toString(),
    name: customer.name,
    lastName: customer.name2,
    address: customer.address,
    customerType:
      customer.customerType === DealType.Private
        ? t('customers.customerType.private')
        : t('customers.customerType.commercial'),
    isValidCustomer: <CustomerChip isValidCustomer={customer.isValidCustomer} />,
    icon: icon ? icon : Icons.pencil(),
  };
}

interface Props {
  onRowClick?: (id: string) => void;
}

export function CustomerList({ onRowClick }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { customers, page, setPage, loading, total, setOrderSelectors } =
    useContext(CustomerContext);

  const headCells: readonly HeadCell<CustomerRow>[] = [
    {
      id: 'isValidCustomer',
      disablePadding: false,
      label: '',
      align: 'right',
    },
    {
      id: 'name',
      disablePadding: false,
      label: t('customers.list.name'),
      allowSorting: true,
      columnName: 'Name',
    },
    {
      id: 'lastName',
      disablePadding: false,
      label: t('customers.list.lastName'),
      allowSorting: true,
      columnName: 'Name2',
    },
    {
      id: 'address',
      disablePadding: false,
      label: t('customers.list.address'),
      allowSorting: true,
      columnName: 'Address',
    },
    {
      id: 'customerType',
      disablePadding: false,
      label: t('customers.list.customerType'),
      allowSorting: true,
      columnName: 'CustomerType',
    },
    {
      id: 'icon',
      align: 'right',
      disablePadding: false,
      label: '',
    },
  ];

  const rows: CustomerRow[] = customers.map((c) =>
    MapCustomer(c, c.customerType === DealType.Commercial ? Icons.commercial() : Icons.user())
  );

  const onClick = (_: React.MouseEvent<unknown>, id: string) => {
    if (onRowClick) {
      onRowClick(id);
    } else {
      navigate(id);
    }
  };

  const loadNewPage = () => {
    setPage(page + 1);
  };

  const onSortRequest = (orderSelectors: string[]) => {
    setOrderSelectors(orderSelectors);
  };

  return (
    <Box className="customer-list">
      {
        <EnhancedTable
          rows={rows}
          total={total}
          headCells={
            onRowClick
              ? headCells.filter((hc) => hc.id !== 'customerType' && hc.id !== 'icon')
              : headCells
          }
          hideCheckbox={true}
          onRowClick={onClick}
          variant="endless"
          loadNewPage={loadNewPage}
          loading={loading}
          onSortRequest={onSortRequest}
        ></EnhancedTable>
      }
    </Box>
  );
}


import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Box, Collapse, InputAdornment, ListItemButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Control } from 'react-hook-form';
import { InsuranceFormValues } from '../InsuranceSchema';
import { FormNumberInput } from '../../../../../component/form/FormNumberInput';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';
import * as React from 'react';
import { FormCheckbox } from '../../../../../component/form/FormCheckbox';

interface Props {
  control: Control<InsuranceFormValues>;
  loading: boolean;
  readonly: boolean;
  onRelevantChange: (name: string, value: unknown) => void;
}

export const InsuranceDiscount = ({ control, loading, readonly, onRelevantChange}: Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit = hasPermission('InsuranceProducts', 'Edit');
  const canRemoveCommission = hasPermission('Insurance.RemoveCommission', 'Edit');

  const [open, setOpen] = useState(false);

  const handleClickOpen = (event: any) => {
    event.preventDefault();
    setOpen(!open);
  };
  
  const handleRelevantChangeEvent = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onRelevantChange(event.target.name, event.target.value);
  };

  return (
    <>
      <ListItemButton disabled={!canEdit} onClick={handleClickOpen}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            h3: {
              margin: 0,
            },
          }}
        >
          <h3>{t('deals.edit.insurance.discount')}</h3>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ mx: 1, maxWidth: '450px' }}>
          <FormNumberInput
            label={t('deals.edit.insurance.fields.liabilityPremiumDiscount')}
            name="liabilityPremiumDiscount"
            control={control}
            disabled={loading || !canEdit || readonly}
            endAdornment={<span>%</span>}
            fixedDecimalScale
            sx={{ my: 1 }}
          />

          <FormNumberInput
            label={t('deals.edit.insurance.fields.kaskoPremiumDiscount')}
            name="kaskoPremiumDiscount"
            control={control}
            disabled={loading || !canEdit || readonly}
            endAdornment={<span>%</span>}
            fixedDecimalScale
            sx={{ my: 1 }}
          />

          <FormNumberInput
            label={t('deals.edit.insurance.fields.commissionTotal')}
            name="commissionTotal"
            startAdornment={<InputAdornment position="start">€</InputAdornment>}
            control={control}
            disabled
            fixedDecimalScale
            sx={{ my: 1 }}
          />

          {canRemoveCommission && (
            <FormCheckbox
              label={t('deals.edit.insurance.fields.removeCommission')}
              name="removeCommission"
              control={control}
              onChange={handleRelevantChangeEvent}
            />
          )}
        </Box>
      </Collapse>
    </>
  );
};

import { Box, Button, debounce } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FormTextField } from '../../../component/form/FormTextField';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, FormEvent, SetStateAction } from 'react';

interface Props {
  toggleBasicSearch: Dispatch<SetStateAction<boolean>>;
  setSearchTerm: Dispatch<SetStateAction<string | undefined>>;
  refetch: (searchTerm: string | undefined) => void;
}

const schema = z.object({
  searchTerm: z.string().optional().nullable(),
});

type DealSearchFormValues = z.infer<typeof schema>;

export function DealSearch({ toggleBasicSearch, setSearchTerm, refetch}: Props) {
  const { t } = useTranslation();
    
  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<DealSearchFormValues>({
    resolver: zodResolver(schema)
  });

  const onSubmit = (event: FormEvent) =>
    handleSubmit(async (values) => {
      refetch(values.searchTerm ?? undefined);
    })(event).catch((_) => {
      setError('searchTerm', {
        type: 'custom',
        message: 'invalid',
      });
    });

  const handleToggle = () => {
    toggleBasicSearch(false);
  };

  const debouncedFetch = debounce((s?: string) => {
    setSearchTerm(s);
  }, 500);

  return (
    <Box className="search-panel search-header three-items">
      <form onSubmit={onSubmit} noValidate>
        <div className="search-container">
          <FormTextField
            control={control}
            name="searchTerm"
            label={t('deals.search.placeholder')}
            withIcon={true}
            onChange={(event) => debouncedFetch(event.target.value)}
          />
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            {t('search.start')}
          </Button>
          <Button
            variant="text"
            className="toggle-search"
            disabled={isSubmitting}
            onClick={handleToggle}
          >
            {t('search.advanced')}
          </Button>
        </div>
      </form>
    </Box>
  );
}

import {IGetDealResponse } from '../../../../generated/ApiClient';
import * as React from 'react';
import { netToGross } from '../../../../common/util/VatUtil';
import { DealInfo } from '../../common/DealInfo';
import { DealInfoSkeleton } from '../../common/DealInfoSkeleton';

interface Props {
  deal?: IGetDealResponse;
  isLoading: boolean;
}

export function ExistingDealInfo({ deal, isLoading }: Props) {

  if (isLoading)
    return <DealInfoSkeleton />

  if (!deal) 
    return null;

  const toDisplay = (price: number | undefined) => {
    if (price)
      return deal.isIncludeVat ? price : netToGross(price);

    return 0;
  } 

  const values = {
    listPrice: toDisplay(deal.listPrice),
    purchasePrice: toDisplay(deal.purchasePrice),
    extrasPrice: toDisplay(deal.extrasPrice),
    coFinancedExtras: toDisplay(deal.coFinancedExtras),
    listPriceIncludingExtras: toDisplay(deal.listPrice + deal.extrasPrice),
    calculationBase: toDisplay(deal.calculationBase),
    discountCategory: deal.discountCategoryId,
    businessType: deal.dealTypeId
  }

  return <DealInfo {...values} />
}

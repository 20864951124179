import React, { createContext, ReactNode } from 'react';
import { useInterpret } from '@xstate/react';
import { orderStateMachine } from './orderStateMachine';
import { InterpreterFrom } from 'xstate';

export const StateMachineContext = createContext({} as InterpreterFrom<typeof orderStateMachine>);

interface Props {
  children: ReactNode;
}

export const StateMachineContextProvider = (props: Props) => {
  const machine = useInterpret(orderStateMachine, {
    devTools: process.env.NODE_ENV === 'development',
  });

  return (
    <StateMachineContext.Provider value={machine}>{props.children}</StateMachineContext.Provider>
  );
};

import { Box, BoxProps, Button, Divider, InputAdornment, Typography } from '@mui/material';
import { Control, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';
import { pushNotificationHandler } from '../../../../../common/PushNotification';
import { FormDropdown, OptionItem } from '../../../../../component/form/FormDropdown';
import { FormNumberInput } from '../../../../../component/form/FormNumberInput';
import { FormTextField } from '../../../../../component/form/FormTextField';
import { FinancesDrawerFormValues } from '../RightDrawerSchema';
import { FormIntegerNumberInput } from '../../../../../component/form/FormIntegerNumberInput';

type Props = {
  control: Control<FinancesDrawerFormValues>;
  getValues: UseFormGetValues<FinancesDrawerFormValues>;
  loading: boolean;
  readonly?: boolean;
  isDurationReadonly: boolean;
  isMileageReadonly: boolean;
  mileages: OptionItem[];
  onRequestServices: () => void;
} & BoxProps;
export const FinancesServiceParameter = ({
  control,
  getValues,
  loading,
  readonly,
  isMileageReadonly,
  isDurationReadonly,
  mileages,
  onRequestServices,
  ...others
}: Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit = hasPermission('Services', 'Edit') && !readonly;

  const onRequestServicesClicked = () => {
    if (!getValues().service.baumuster)
      pushNotificationHandler.publish(
        t('deals.new.financesConfiguration.calculationDrawer.options.baumusterRequired'),
        'error'
      );
    else onRequestServices();
  };

  return (
    <Box {...others}>
      <h2 style={{ marginBottom: 16 }}>
        {t('deals.new.financesConfiguration.calculationDrawer.parameter.addService', {
          id: '7551152005',
        })}
      </h2>
      <Typography sx={{ pt: 2 }}>
        {t('deals.new.financesConfiguration.calculationDrawer.parameter.parameter')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box>
          <FormIntegerNumberInput
            disableUnderline
            control={control}
            sx={{ m: 1, width: '45%' }}
            name="service.duration"
            label={t(
              'deals.new.financesConfiguration.calculationDrawer.konfiguration.durationInMonths'
            )}
            shrink
            disabled={!canEdit || loading || isDurationReadonly}
            allowNegativeValues={false}
          />
          <FormNumberInput
            disableUnderline
            control={control}
            sx={{ m: 1, width: '20%' }}
            fixedDecimalScale
            name="service.serviceCharge"
            label={t('deals.new.financesConfiguration.calculationDrawer.parameter.serviceCharge')}
            startAdornment={<InputAdornment position="start">€</InputAdornment>}
            shrink
            disabled={true}
          />
          <FormNumberInput
            disableUnderline
            control={control}
            sx={{ m: 1, width: '20%' }}
            fixedDecimalScale
            name="service.serviceChargeNet"
            label={t('price.exclVat')}
            startAdornment={<InputAdornment position="start">€</InputAdornment>}
            shrink
            disabled={true}
          />
        </Box>
        <FormDropdown
          disableUnderline
          control={control}
          sx={{ m: 1, width: '25%' }}
          name="service.mileageId"
          label={t('deals.new.financesConfiguration.calculationDrawer.konfiguration.mileage')}
          disabled={!canEdit || loading || isMileageReadonly}
          options={mileages}
          shrink
        />
        {getValues().service.showModelPrototypeData && (
          <Box>
            <FormTextField
              control={control}
              name="service.baumuster"
              label={t('deals.new.financesConfiguration.calculationDrawer.options.baumuster')}
              disabled={!canEdit || loading || getValues().service.baumusterNstCodeReadOnly}
              shrink
              sx={{ m: 1, width: '45%' }}
            />
            <FormTextField
              control={control}
              name="service.nstCode"
              label={t('deals.new.financesConfiguration.calculationDrawer.options.nstCode')}
              disabled={!canEdit || loading || getValues().service.baumusterNstCodeReadOnly}
              shrink
              sx={{ m: 1, width: '45%' }}
            />
            <Box sx={{ m: 1 }}>
              <Button
                onClick={onRequestServicesClicked}
                disabled={!canEdit || loading}
                sx={{
                  height: '28px',
                  border: 1,
                  whiteSpace: 'nowrap',
                  minWidth: 'auto',
                }}
              >
                {t('deals.new.financesConfiguration.calculationDrawer.options.requestServices')}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Divider sx={{ mr: 1, ml: 1 }} />
    </Box>
  );
};

import { Autocomplete, AutocompleteProps, FilterOptionsState, TextField } from '@mui/material';
import { matchSorter } from 'match-sorter';
import * as React from 'react';
import { useState } from 'react';

export interface Option {
  label: string;
  value: any;
}

interface Props<T>
  extends Omit<
    AutocompleteProps<Option, undefined, undefined, undefined>,
    'options' | 'onChange' | 'renderInput'
  > {
  toOptionsMapper: (obj: T) => Option;
  getOptions: (searchTerm: string) => Promise<T[]>;
  onChange: (val: any) => void;
  value?: any;
  label: string;
}

const filterOptions = (options: Option[], { inputValue }: FilterOptionsState<Option>) => {
  if (!inputValue || !inputValue.length)
    return options;
  const words = inputValue.split('%');
  if (!words)
    return options;

  return words.reduceRight((results, word) => matchSorter(results, word, { keys: ["label"] }), options)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AsyncAutocomplete = <T, I extends string | number>({
  label,
  toOptionsMapper,
  getOptions,
  onChange,
  ...others
}: Props<T>) => {
  const [options, setOptions] = useState<Option[]>([]);

  const handleInputChange = async (_: React.SyntheticEvent, value: string) => {
    const newOptions: T[] = await getOptions(value);
    setOptions(newOptions.map(toOptionsMapper));
  };
  return (
    <Autocomplete
      isOptionEqualToValue={(option: Option, value) => option.value === value}
      filterOptions={filterOptions}
      getOptionLabel={(option: Option | null) => {
        if (typeof option === 'object' && option) {
          return option.label;
        }

        const selOption = options.find((o) => o.value === option);
        return selOption ? selOption.label : '';
      }}
      onChange={(_: React.SyntheticEvent, value: Option | null) => {
        onChange(value?.value);
      }}
      onInputChange={handleInputChange}
      renderInput={(params) => <TextField {...params} label={label} variant="standard" />}
      options={options}
      noOptionsText="-"
      {...others}
    />
  );
};

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import RestHttpClient from '../../../../common/RestHttpClient';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { FormDropdown } from '../../../../component/form/FormDropdown';
import { getDefaultCommissionDealer } from '../creditCheck/commissionDealerUtil';

export const schema = z.object({
  commissionDealerId: z.number().nonnegative().nullable().optional(),
});

export type SubmitInsuranceFormValues = z.infer<typeof schema>;

export function SubmitInsuranceDialog({
  dealId,
  isSubmitting,
  setIsSubmitting,
  submitInsurance,
}: {
  submitInsurance: (data: SubmitInsuranceFormValues) => void;
  dealId: number;
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
}) {
  const { data: commissionDealers = [] } = useQuery({
    queryKey: ['commissionDealers', dealId],
    queryFn: () => RestHttpClient.getCommissionDealer(dealId),
    select: (data) => data.data,
  });

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const { control, handleSubmit, setError, setValue } = useForm<SubmitInsuranceFormValues>({
    resolver: zodResolver(schema),
  });

  const dialogRequired = commissionDealers.length > 1;

  const onSubmit = async (event?: FormEvent) => {
    handleSubmit(
      (values) => {
        if (commissionDealers.length > 0 && !values.commissionDealerId) {
          setError('commissionDealerId', {
            message: t('deals.edit.creditCheck.commissionDealerIsRequired'),
          });
          return;
        }
        setOpen(false);
        submitInsurance(values);
      },
      (err) => {
        console.error(err);
      }
    )(event);
  };

  useEffect(() => {
    setValue('commissionDealerId', getDefaultCommissionDealer(commissionDealers));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commissionDealers]);

  useEffect(() => {
    if (isSubmitting) {
      if (dialogRequired) {
        setOpen(true);
      } else {
        onSubmit();
      }
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  return (
    <Dialog
      open={open}
      onClose={() => setIsSubmitting(false)}
      PaperProps={{ sx: { width: '300px' } }}
    >
      <DialogContent>
        <FormDropdown
          control={control}
          name="commissionDealerId"
          label={t('deals.edit.creditCheck.commissionDealer')}
          options={commissionDealers}
          shrink
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit}>{t('confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
}

import { IconButton } from '@mui/material';
import { t } from 'i18next';
import { HeadCell } from '../../../common/table/EnhancedTableHead';
import { Icons } from '../../../component/icons/Icons';
import { IMergeCustomerView } from '../../../generated/ApiClient';
import { CustomerChip } from '../CustomerChip';

export const similarPrivateCustomerHeadCells: readonly HeadCell<SimilarCustomerRow>[] = [
  {
    id: 'isValidCustomer',
    disablePadding: false,
    label: '',
    align: 'right',
  },
  {
    id: 'firstName',
    disablePadding: false,
    label: t('customers.merge.list.firstName'),
  },
  {
    id: 'lastName',
    disablePadding: false,
    label: t('customers.merge.list.lastName'),
  },
  {
    id: 'address',
    disablePadding: false,
    label: t('customers.merge.list.address'),
  },
  {
    id: 'birthDate',
    disablePadding: false,
    label: t('customers.merge.list.birthDate'),
  },
  {
    id: 'dealer',
    disablePadding: false,
    label: t('customers.merge.list.dealer'),
  },
  {
    id: 'countSubmittedCalculations',
    disablePadding: false,
    label: t('customers.merge.list.calculations'),
  },
  {
    id: 'countSubmittedInsurances',
    disablePadding: false,
    label: t('customers.merge.list.insurances'),
  },
  {
    id: 'icon',
    align: 'right',
    disablePadding: false,
    label: '',
  },
];

export const similarCommercialCustomerHeadCells: readonly HeadCell<SimilarCustomerRow>[] = [
  {
    id: 'isValidCustomer',
    disablePadding: false,
    label: '',
    align: 'right',
  },
  {
    id: 'companyName',
    disablePadding: false,
    label: t('customers.merge.list.companyName'),
  },
  {
    id: 'address',
    disablePadding: false,
    label: t('customers.merge.list.address'),
  },
  {
    id: 'dealer',
    disablePadding: false,
    label: t('customers.merge.list.dealer'),
  },
  {
    id: 'countSubmittedCalculations',
    disablePadding: false,
    label: t('customers.merge.list.calculations'),
  },
  {
    id: 'countSubmittedInsurances',
    disablePadding: false,
    label: t('customers.merge.list.insurances'),
  },
  {
    id: 'icon',
    align: 'right',
    disablePadding: false,
    label: '',
  },
];

export interface SimilarCustomerRow {
  id: string;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  address?: string | null;
  birthDate?: string;
  dealer?: string;
  countSubmittedCalculations: number;
  countSubmittedInsurances: number;
  icon?: JSX.Element;
  isValidCustomer: JSX.Element;
}

const onClick = (id: number, ev: React.MouseEvent) => {
  window.open('/customers/' + id, '_blank', 'noreferrer');
  ev.preventDefault();
};

export function mapSimilarCustomer(customer: IMergeCustomerView): SimilarCustomerRow {
  return {
    id: customer.id.toString(),
    companyName: customer.companyName,
    firstName: customer.firstName,
    lastName: customer.lastName,
    address: customer.address,
    birthDate: customer.birthDate?.toLocaleDateString(),
    dealer: customer.dealer,
    countSubmittedCalculations: customer.countSubmittedCalculations,
    countSubmittedInsurances: customer.countSubmittedInsurances,
    icon: <IconButton onClick={(e) => onClick(customer.id, e)}>{Icons.open()}</IconButton>,
    isValidCustomer: <CustomerChip isValidCustomer={customer.isValid} />,
  };
}

import React, { Component, createContext, Dispatch, SetStateAction } from 'react';
import RestHttpClient from '../RestHttpClient';

type Permissions = {
  [key: string]: string[];
};
export interface PermissionHandler {
  load: () => Promise<void>;
  getForResource: (resource: string) => string[];
  hasPermission: (resource: string, action: string) => boolean;
}

interface PermissionsContextType {
  permissions: Permissions;
  setPermissions: Dispatch<SetStateAction<Permissions>>;
  load: () => Promise<void>;
  getForResource: (resource: string) => string[];
  hasPermission: (resource: string, action: string) => boolean;
}

export const PermissionsContext = createContext<PermissionsContextType>(
  {} as PermissionsContextType
);
export const usePermissions = () => React.useContext(PermissionsContext);
interface Props {
  children: React.ReactNode;
}

export default class PermissionProvider
  extends Component<Props, Permissions>
  implements PermissionHandler
{
  constructor(props: Props) {
    super(props);
    this.state = {} as Permissions;
    permissionsHandler = this;
    this.load = this.load.bind(this);
    this.getForResource = this.getForResource.bind(this);
    this.getForResource = this.getForResource.bind(this);
    this.hasPermission = this.hasPermission.bind(this);
  }

  async load(): Promise<void> {
    try {
      const { data } = await RestHttpClient.getPermissions();
      const perms = data.reduce((grouped, permission) => {
        (grouped[permission.resource] = grouped[permission.resource] || []).push(permission.action);
        return grouped;
      }, {} as Permissions);
      this.setState(perms);
    } catch (e) {
      console.error(e);
      this.setState({});
    }
  }

  getForResource(resource: string): string[] {
    if (this.state[resource]) {
      return this.state[resource];
    }
    return [];
  }

  hasPermission(resource: string, action: string): boolean {
    if (this.state['Application'] && this.state['Application'].includes('Manage')) {
      return true;
    }
    if (this.state[resource]) {
      return this.state[resource].findIndex((p) => p === action) !== -1;
    }
    return false;
  }

  render() {
    let { children } = this.props;

    return (
      <PermissionsContext.Provider
        value={{
          permissions: this.state,
          setPermissions: this.setState,
          load: this.load,
          getForResource: this.getForResource,
          hasPermission: this.hasPermission,
        }}
      >
        {children}
      </PermissionsContext.Provider>
    );
  }
}

export let permissionsHandler: PermissionHandler;

import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18next';
import { FormEvent, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import RestHttpClient from '../../../common/RestHttpClient';
import { EnhancedTable } from '../../../common/table/EnhancedTable';
import { HeadCell } from '../../../common/table/EnhancedTableHead';
import { optionalDate } from '../../../common/validation/Validations';
import { FormDatePicker } from '../../../component/form/FormDatePicker';
import { FormDropdown } from '../../../component/form/FormDropdown';
import { FormTextField } from '../../../component/form/FormTextField';
import { IOptionItem } from '../../../generated/ApiClient';
import { CustomerMergeContext } from './CustomerMergeContext';
import { mapSimilarCustomer, SimilarCustomerRow } from './SimilarCustomerRow';

const schema = z.object({
  addressCountryId: z.number().nonnegative().optional(),
  addressPostalCode: z.string().optional(),
  addressCity: z.string().optional(),
  addressStreet: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  birthDate: optionalDate,
});

export type PrivateCustomerMergeFormValues = z.infer<typeof schema>;

interface Props {
  customerId: number;
  countries: IOptionItem[];
  headCells: readonly HeadCell<SimilarCustomerRow>[];
  isPoseidon?: boolean;
}

export function PrivateCustomerMergeSearch({
  customerId,
  countries,
  headCells,
  isPoseidon,
}: Props) {
  const {
    search: {
      selected,
      setSelected,
      setLoading,
      setPage,
      page,
      setCustomers,
      customers,
      loading,
      private: { searchValues, setSearchValues },
    },
    suggestion,
  } = useContext(CustomerMergeContext);
  const { control, handleSubmit } = useForm<PrivateCustomerMergeFormValues>({
    resolver: zodResolver(schema),
    defaultValues: searchValues,
  });

  const fetchCustomers = (sv = searchValues, p = page) => {
    setLoading(true);

    //Backend unfortunately accepts country name only...
    var country;
    if (sv.addressCountryId)
      // eslint-disable-next-line eqeqeq
      country = countries.filter((c) => c.value == sv.addressCountryId)[0].label;

    RestHttpClient.searchSimilarPrivate(
      customerId,
      sv.firstName,
      sv.lastName,
      sv.birthDate ?? undefined,
      country,
      sv.addressCity,
      sv.addressStreet,
      sv.addressPostalCode,
      p,
      25,
      []
    )
      .then((res) => {
        const newCustomers = res.data;

        if (p === 0 && newCustomers.items.length) {
          setCustomers(newCustomers.items);
        } else if (newCustomers.items.length) {
          setCustomers(customers.concat(newCustomers.items));
        }
      })
      .catch((_) => {
        setCustomers([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (page > 0) {
      fetchCustomers();
    }
    // eslint-disable-next-line
  }, [page]);

  const onSubmit = (event: FormEvent) => {
    handleSubmit(async (values) => {
      setSelected([]);
      setCustomers([]);
      setPage(0);
      setSearchValues(values);
      fetchCustomers(values, 0);
    })(event);
  };

  const rows: SimilarCustomerRow[] = customers.map((c) => mapSimilarCustomer(c));

  const onSelection = (ev: React.SyntheticEvent<unknown>, names: readonly string[]) => {
    setSelected(names.slice());

    if (isPoseidon) {
      suggestion.setSelected([]);
    }
  };

  const loadNewPage = () => {
    setPage(page + 1);
  };

  return (
    <Box>
      <form onSubmit={onSubmit} noValidate>
        <Box className="search-panel">
          <Grid item container spacing={4} md={12}>
            <Grid item md={4}>
              <FormTextField
                control={control}
                label={t('customers.merge.list.firstName')}
                name="firstName"
              />
              <FormTextField
                control={control}
                label={t('customers.merge.list.lastName')}
                name="lastName"
              />
              <FormDatePicker
                control={control}
                label={t('customers.merge.list.birthDate')}
                name="birthDate"
              />
            </Grid>

            <Grid item md={4}>
              <FormDropdown
                control={control}
                label={t('customers.search.addressCountry')}
                name="addressCountryId"
                options={countries}
              />
              <FormTextField
                control={control}
                label={t('customers.search.addressCity')}
                name="addressCity"
              />
            </Grid>

            <Grid item md={4}>
              <FormTextField
                control={control}
                label={t('customers.search.addressStreet')}
                name="addressStreet"
              />
              <FormTextField
                control={control}
                label={t('customers.search.addressPostalCode')}
                name="addressPostalCode"
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex' }}>
            <Button onClick={onSubmit} variant="contained" sx={{ ml: 'auto' }}>
              {t('search.start')}
            </Button>
          </Box>
        </Box>
      </form>
      <EnhancedTable
        rows={rows}
        headCells={headCells}
        hideCheckbox={false}
        variant="endless"
        loadNewPage={loadNewPage}
        loading={loading}
        enableSelection
        enableSingleSelection={isPoseidon}
        onSelection={onSelection}
        defaultSelected={selected}
      />
      {!loading && !customers.length && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
          <Typography variant="body1">{t('search.startHint')}</Typography>
        </Box>
      )}
    </Box>
  );
}

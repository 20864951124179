import { Control, useWatch, Controller, UseFormSetValue } from 'react-hook-form';
import { InsuranceFormValues } from '../InsuranceSchema';
import { GetInsuranceSpecialOffersResponse } from '../../../../../generated/ApiClient';
import { useTranslation } from 'react-i18next';
import {
  Switch,
  Collapse,
  FormControlLabel,
  ListItemButton,
  ListItemText,
  Box,
  RadioGroup,
  Radio,
  Grid,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Dispatch, SetStateAction, useState, useEffect  } from 'react';
import { format } from 'date-fns';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';

interface Props {
  control: Control<InsuranceFormValues>;
  setValue: UseFormSetValue<InsuranceFormValues>;
  specialOffers: GetInsuranceSpecialOffersResponse[];
  onRelevantChange: (name: string, value: unknown) => void;
  isPromotionsOpened: boolean;
  setPromotionsOpened: Dispatch<SetStateAction<boolean>>;
  readonly: boolean;
}

export const InsurancePromotions = ({ control, setValue, specialOffers, onRelevantChange, isPromotionsOpened, setPromotionsOpened, readonly }: Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit = hasPermission('InsuranceProducts', 'Edit');

  const specialOfferId = useWatch({ control, name: 'specialOfferId' });
  const [switchChecked, setSwitchChecked] = useState(Boolean(specialOfferId));

  useEffect(() => {
    setSwitchChecked(Boolean(specialOfferId));
    setPromotionsOpened(Boolean(specialOfferId));
}, [specialOfferId]);

  const handleClickOpen = (event: any) => {
    event.preventDefault();
    setPromotionsOpened(!isPromotionsOpened);
  };

  const onSpecialOfferChange = (id: number) => {
    const selectedSpecialOffers = specialOffers.filter(s => s.id === id);
    if (selectedSpecialOffers.length === 1) {
      setValue('liabilityPremiumDiscount', selectedSpecialOffers[0].liabilityPremiumDiscount);
      setValue('kaskoPremiumDiscount', selectedSpecialOffers[0].kaskoPremiumDiscount);
    }
  };

  const handleSwitchChecked = (event: any) => {
    const isSwitchBeingTurnedOff = switchChecked && !event.target.checked;

    if (!isPromotionsOpened && !switchChecked) {
        setSwitchChecked(event.target.checked);
        onRelevantChange('specialOfferEnabled', !isPromotionsOpened);
        setPromotionsOpened(!isPromotionsOpened);
    } else if (!switchChecked && isPromotionsOpened) {
        setSwitchChecked(event.target.checked);
        onRelevantChange('specialOfferEnabled', isPromotionsOpened);
    } else if (isPromotionsOpened && switchChecked) {
        setSwitchChecked(event.target.checked);
        onRelevantChange('specialOfferEnabled', !isPromotionsOpened);
        setPromotionsOpened(!isPromotionsOpened);
        if (isSwitchBeingTurnedOff) {
            setValue('specialOfferId', 0);
        }
    } else {
        setSwitchChecked(event.target.checked);
        setPromotionsOpened(false);
        if (isSwitchBeingTurnedOff) {
            setValue('specialOfferId', 0);
        }
    }
};

  return (
    <>
      <ListItemButton disabled={!canEdit}>
        <ListItemText
          primary={
            <FormControlLabel
              onChange={handleSwitchChecked}
              control={<Switch checked={switchChecked} disabled={!canEdit} />}
              label={t('deals.edit.insurance.specialOffer', { amount: specialOffers.length })}
            />
          }
        ></ListItemText>
        {isPromotionsOpened ? <ExpandLess onClick={handleClickOpen} /> : <ExpandMore onClick={handleClickOpen} />}
      </ListItemButton>
      <Collapse in={isPromotionsOpened} timeout="auto" unmountOnExit>
        <Controller
          control={control}
          name="specialOfferId"
          render={({ field, fieldState: { error } }) => {
            return (
              <Box sx={{ ml: '20px' }}>
                <RadioGroup
                  {...field}
                  value={field.value ?? null}
                  onChange={(e) => {
                    if (!canEdit || readonly) return;
                    const value = e.target.value ? Number(e.target.value) : undefined;
                    onSpecialOfferChange(value ?? -1);
                    onRelevantChange('specialOfferId', value);
                    field.onChange({
                      target: {
                        name: 'specialOfferId',
                        value: value,
                      },
                    });
                  }}
                >
                  {specialOffers.map((so) => (
                    <Grid container justifyContent="center" alignItems="center" key={so.id}>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={<Radio disabled={!canEdit || readonly} />}
                          label={so.name}
                          value={so.id}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ span: { ml: '20px' } }}>
                        {/*todo make utils formatter*/}
                        <span>von: {format(so.validFrom, 'dd.MM.yyyy')}</span>
                        {so.validTo && <span> bis: {format(so.validTo, 'dd.MM.yyyy')}</span>}
                      </Grid>
                    </Grid>
                  ))}
                </RadioGroup>
              </Box>
            );
          }}
        />
      </Collapse>
    </>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { DashboardHeader } from './DashboardHeader';
import { DashboardSearch } from './search/DashboardSearch';
import { Box, Button, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { a11yProps, TabPanel } from '../../component/TabPanel';
import { DashboardOverview } from './overview/DashboardOverview';
import { DashboardApplications } from './applications/DashboardApplications';
import { DashboardContractPreliminary } from './contractsPreliminary/DashboardContractPreliminary';
import { DashboardContracts } from './contracts/DashboardContracts';
import { DashboardDocuments } from './documents/DashboardDocuments';
import {
  DocumentCategory,
  NotificationLocation,
  SetApprovalRequest,
} from '../../generated/ApiClient';
import { usePermissions } from '../../common/auth/PermissionsContext';
import { DashboardSearchProvider } from './search/DashboardSearchContext';
import DashboardSearchTable from './search/DashboardSearchTable';
import RestHttpClient from '../../common/RestHttpClient';
import { Icons } from '../../component/icons/Icons';
import { DashboardContractExpired } from './expired/DashboardContractExpired';
import { useNotifications } from '../../common/global/useNotifications';
import { GlobalTextIdentifiers, useGlobalTitles } from '../../common/global/useGlobalTitles';

export const overViewTab = 0;
export const applicationsTab = 1;
export const contractsAdvanceTab = 2;
export const contractsTab = 3;
export const specialOfferTab = 4;
export const docsTab = 5;
export const incentivesTab = 6;
export const searchTab = 7;
export const expiredTab = 8;

export const dashboardSideGrid = {
  xs: 3,
  md: 3,
  lg: 2,
  xl: 1
}

export const dashboardContentGrid = {
  xs: 9,
  md: 9,
  lg: 10,
  xl: 11
}


export function Dashboard() {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canViewDashboard = hasPermission('Dashboard', 'Login');
  const canViewSpecialOffer = hasPermission('Dashboard.SpecialOffers', 'View');
  const canViewReports = hasPermission('Dashboard.Reports', 'View');

  const getTile = useGlobalTitles();

  const [activeTab, setActiveTab] = useState(0);
  const [activeFilter, setActiveFilter] = useState<number | string | undefined>(undefined);

  const [loading, setLoading] = useState(false);

  const [incentiveAccepted, setIncentiveAccepted] = useState<boolean | undefined>();
  const [documentId, setDocumentId] = useState<string | undefined>(undefined);

  const fetchIncentive = async () => {
    try {
      setLoading(true);
      const { data } = await RestHttpClient.getSalesContest();
      setIncentiveAccepted(data.hasAccepted);
      setDocumentId(data.termsOfConditionId);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadDissolveReport = () => {
    RestHttpClient.getDissolveValuesReport().then((res) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(res.data);
      link.download = res.fileName ?? 'download';
      link.click();
    });
  };

  const handleDownloadContractReport = () => {
    RestHttpClient.getContractsReport().then((res) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(res.data);
      link.download = res.fileName ?? 'download';
      link.click();
    });
  };

  const handleApprove = async (isAccepted: boolean) => {
    setLoading(true);
    await RestHttpClient.setApproval(
      new SetApprovalRequest({
        isAccepted,
      })
    );
    await fetchIncentive();
  };

  const handleTabChange = (tab: number, filter?: number | string) => {
    setActiveFilter(filter);
    setActiveTab(tab);
  };

  useNotifications(NotificationLocation.Dashboard);

  useEffect(() => {
    fetchIncentive();
  }, []);

  return (
    <Box className="dashboard">
      {canViewDashboard && (
        <>
          <DashboardSearchProvider>
            <DashboardHeader />
            <DashboardSearch onHandleSubmit={() => setActiveTab(7)} />

            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Tabs value={activeTab} onChange={(_, val) => handleTabChange(val, undefined)}>
                <Tab
                  label={getTile(GlobalTextIdentifiers.DashboardOverview)}
                  {...a11yProps(overViewTab)}
                />
                <Tab
                  label={getTile(GlobalTextIdentifiers.DashboardApplications)}
                  {...a11yProps(applicationsTab)}
                />
                <Tab
                  label={getTile(GlobalTextIdentifiers.DashboardPreliminary)}
                  {...a11yProps(contractsAdvanceTab)}
                />
                <Tab
                  label={getTile(GlobalTextIdentifiers.DashboardContracts)}
                  {...a11yProps(contractsTab)}
                />
                <Tab
                  label={getTile(GlobalTextIdentifiers.DashboardExpired)}
                  {...a11yProps(expiredTab)}
                />
                {canViewSpecialOffer && (
                  <Tab
                    label={getTile(GlobalTextIdentifiers.DashboardSpecialOffer)}
                    {...a11yProps(specialOfferTab)}
                  />
                )}
                <Tab
                  label={getTile(GlobalTextIdentifiers.DashboardDocuments)}
                  {...a11yProps(docsTab)}
                />
                {incentiveAccepted && (
                  <Tab
                    label={getTile(GlobalTextIdentifiers.DashboardIncentiveName)}
                    {...a11yProps(incentivesTab)}
                  />
                )}

                {activeTab === searchTab && (
                  <Tab label={t('dashboard.search.header')} {...a11yProps(searchTab)} />
                )}
              </Tabs>

              {canViewReports && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    color="secondary"
                    variant="outlined"
                    sx={{ mr: 2 }}
                    startIcon={Icons.download()}
                    onClick={handleDownloadContractReport}
                  >
                    {`${t('dashboard.overview.downloadContractReport')}`}
                  </Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    startIcon={Icons.download()}
                    onClick={handleDownloadDissolveReport}
                  >
                    {t('dashboard.overview.downloadDissolveReport')}
                  </Button>
                </Box>
              )}
            </Box>

            <Box height="100%" padding={0}>
              <TabPanel value={activeTab} index={overViewTab}>
                <DashboardOverview
                  loading={loading}
                  incentiveAccepted={incentiveAccepted}
                  documentId={documentId}
                  handleApprove={handleApprove}
                  setActiveTab={setActiveTab}
                  onTabChange={handleTabChange}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={applicationsTab}>
                <DashboardApplications
                  defaultFilter={typeof activeFilter === 'number' ? activeFilter : undefined}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={contractsAdvanceTab}>
                <DashboardContractPreliminary
                  defaultFilter={typeof activeFilter === 'number' ? activeFilter : undefined}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={contractsTab}>
                <DashboardContracts
                  defaultFilter={typeof activeFilter === 'string' ? activeFilter : undefined}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={expiredTab}>
                <DashboardContractExpired />
              </TabPanel>
              {canViewSpecialOffer && (
                <TabPanel value={activeTab} index={specialOfferTab}>
                  <DashboardDocuments
                    category={DocumentCategory.SpecialOffer}
                    defaultGroup={typeof activeFilter === 'string' ? activeFilter : undefined}
                  />
                </TabPanel>
              )}
              <TabPanel value={activeTab} index={docsTab}>
                <DashboardDocuments
                  category={DocumentCategory.Uncategorized}
                  defaultGroup={typeof activeFilter === 'string' ? activeFilter : undefined}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={incentivesTab}>
                <DashboardDocuments
                  category={DocumentCategory.Incentive}
                  defaultGroup={typeof activeFilter === 'string' ? activeFilter : undefined}
                />
              </TabPanel>
              <TabPanel index={searchTab} value={activeTab}>
                <DashboardSearchTable />
              </TabPanel>
            </Box>
          </DashboardSearchProvider>
        </>
      )}
    </Box>
  );
}

import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { OptionItem } from '../../../../generated/ApiClient';
import { FormDropdown } from '../../../../component/form/FormDropdown';
import { Control, useWatch } from 'react-hook-form';
import { CommercialCustomerFormValues } from '../../data/CommercialCustomerFormValues';
import { FormTextField } from '../../../../component/form/FormTextField';
import { FormDatePicker } from '../../../../component/form/FormDatePicker';
import { FormCheckbox } from '../../../../component/form/FormCheckbox';
import { getDateBefore } from './../../../../common/validation/Validations';
import { FormNumberInput } from '../../../../component/form/FormNumberInput';

interface Props {
  control: Control<CommercialCustomerFormValues>;
  salutations: OptionItem[];
  maritalStatus: OptionItem[];
  countries: OptionItem[];
  readonly: boolean;
}
export function Partner({ control, salutations, maritalStatus, countries, readonly }: Props) {
  const { t } = useTranslation();
  const gridCol = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 3,
    xl: 3,
  };

  const numberOfSupportedChildren = useWatch({ control, name: 'numberOfSupportedChildren' });

  return (
    <Grid container spacing={4} className="partner">
      <Grid item {...gridCol}>
        <FormDropdown
          control={control}
          name="salutationId"
          label={t('customers.edit.partner.salutation')}
          options={salutations}
          disabled={readonly}
        />

        <FormTextField
          control={control}
          name="firstName"
          label={t('customers.edit.partner.firstName')}
          disabled={readonly}
        />

        <FormTextField
          control={control}
          name="lastName"
          label={t('customers.edit.partner.lastName')}
          disabled={readonly}
        />

        <FormDatePicker
          maxDate={getDateBefore(18)}
          control={control}
          name="birthDate"
          label={t('customers.edit.partner.birthDate')}
          disabled={readonly}
        />

        <FormDropdown
          control={control}
          name="maritalStatusId"
          label={t('customers.edit.partner.maritalStatus')}
          options={maritalStatus}
          disabled={readonly}
        />

        <FormDropdown
          control={control}
          name="citizenshipId"
          label={t('customers.edit.personalData.citizenship')}
          options={countries}
          disabled={readonly}
        />

        {/*TODO dropdowns --> laut  FIGMA*/}
        {/*Familienstand*/}
        {/*Staatsbürgerschaft*/}
      </Grid>
      <Grid item {...gridCol}>
        <FormNumberInput
          control={control}
          name="numberOfSupportedChildren"
          label={t('customers.edit.partner.numberOfSupportedChildren')}
          disabled={readonly}
        />
        <FormNumberInput
          control={control}
          name="numberOfChildrenUnderEighteen"
          label={t('customers.edit.partner.numberOfChildrenUnderEighteen')}
          disabled={
            readonly ||
            numberOfSupportedChildren === null ||
            numberOfSupportedChildren === undefined ||
            numberOfSupportedChildren == 0
          }
        />

        <FormDatePicker
          control={control}
          name="selfEmployedSince"
          label={t('customers.edit.partner.selfEmployedSince')}
          disabled={readonly}
        />
        <FormDatePicker
          maxDate={getDateBefore(0)}
          control={control}
          name="residencePermitValidSince"
          label={t('customers.edit.partner.residencePermitValidSince')}
          disabled={readonly}
        />
        <FormDatePicker
          control={control}
          name="residencePermitValidTill"
          label={t('customers.edit.partner.residencePermitValidTill')}
          disabled={readonly}
        />
        <FormDatePicker
          maxDate={getDateBefore(0)}
          control={control}
          name="inAustriaSince"
          label={t('customers.edit.partner.inAustriaSince')}
          disabled={readonly}
        />
        <FormCheckbox
          control={control}
          name="isGeneralPartner"
          label={t('customers.edit.partner.GeneralPartner')}
          disabled={readonly}
        />
      </Grid>
    </Grid>
  );
}

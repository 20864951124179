import React from 'react';
import { DialogContent, DialogTitle, Dialog, DialogActions, Button } from '@mui/material';
import { t } from 'i18next';

interface ConfirmModalHandler {
  confirm: (
    title: string,
    callbackPositive: Function,
    callbackNegative?: Function,
    text?: string,
    okLabel?: string,
    cancelLabel?: string,
    callbackAlternative?: Function | undefined,
    alternativeLabel?: string | undefined,
  ) => void;
}

interface ConfirmModalState {
  visible: boolean;
}

export let confirmHandler: ConfirmModalHandler;

export class ConfirmModal
  extends React.Component<{}, ConfirmModalState>
  implements ConfirmModalHandler
{
  private title: string;
  private text: string | undefined;
  private callbackPositive: Function | undefined;
  private callbackNegative?: Function | undefined;
  private callbackAlternative?: Function | undefined;

  private okLabel: string | undefined;
  private cancelLabel: string | undefined;
  private alternativeLabel: string | undefined;

  constructor(props: {}) {
    super(props);
    this.title = '';
    this.text = '';
    this.state = { visible: false };
    confirmHandler = this;
  }

  public confirm(
    title: string,
    callbackPositive: Function,
    callbackNegative?: Function,
    text?: string | undefined,
    okLabel?: string,
    cancelLabel?: string,
    callbackAlternative?: Function,
    alternativeLabel?: string | undefined
  ): void {
    this.title = title;
    this.callbackPositive = callbackPositive;
    this.callbackNegative = callbackNegative;
    this.callbackAlternative = callbackAlternative;
    this.text = text;
    this.okLabel = okLabel;
    this.cancelLabel = cancelLabel;
    this.alternativeLabel = alternativeLabel;
    this._open();
  }

  private _open = () => {
    this.setState({ visible: true });
  };

  private _close = () => {
    if (this.callbackNegative) {
      this.callbackNegative();
    }
    this.setState({ visible: false });
  };

  private _alterative = () => {
    if (this.callbackAlternative) {
      this.callbackAlternative();
    }
    this.setState({ visible: false });
  };

  private _confirm = () => {
    if (this.callbackPositive) {
      this.callbackPositive();
    }
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;

    return (
      <Dialog open={visible} onClose={this._close} aria-labelledby="confirm-dialog">
        <DialogTitle id="confirm-dialog">{this.title}</DialogTitle>
        <DialogContent>{this.text}</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this._close}>
            {this.cancelLabel ?? t('no')}
          </Button>
          {this.alternativeLabel && (
            <Button variant="outlined" onClick={this._alterative}>
              {this.alternativeLabel}
            </Button>
          )}
          <Button variant="contained" onClick={this._confirm} color="primary">
            {this.okLabel ?? t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

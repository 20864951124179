import { CustomerType } from '../../../../generated/ApiClient';
import { PrivateCustomerFormValues } from '../../../customers/data/PrivateCustomerFormValues';
import { CommercialCustomerFormValues } from '../../../customers/data/CommercialCustomerFormValues';
import { Box } from '@mui/material';
import { EditPrivateCustomerForm } from '../../../customers/edit/EditPrivateCustomerForm';
import { EditCommercialCustomerForm } from '../../../customers/edit/EditCommercialCustomerForm';

interface Props {
  customerId: string;
  customer?: PrivateCustomerFormValues | CommercialCustomerFormValues;
  customerType: CustomerType;
  onSubmit: (
    values: PrivateCustomerFormValues | CommercialCustomerFormValues,
    type: CustomerType,
    changesMade: boolean,
    submitType?: string
  ) => void;
}

export function CustomerConfiguration({ customerId, customerType, onSubmit, customer }: Props) {
  function handleSubmitPrivateCustomer(
    values: PrivateCustomerFormValues | CommercialCustomerFormValues,
    changesMade: boolean,
    submitType?: string
  ) {
    onSubmit(values, CustomerType.Private, changesMade, submitType);
  }
  function handleSubmitCommercialCustomer(
    values: PrivateCustomerFormValues | CommercialCustomerFormValues,
    changesMade: boolean,
    submitType?: string
  ) {
    onSubmit(values, CustomerType.Commercial, changesMade, submitType);
  }

  return (
    <Box>
      {customerType === CustomerType.Private && (
        <EditPrivateCustomerForm
          onSubmit={handleSubmitPrivateCustomer}
          customerId={customerId !== '' ? Number(customerId) : undefined}
          overrideValues={customer}
          isNew={!customerId}
          readonly={false}
          showSelfDisclosure
        />
      )}
      {customerType === CustomerType.Commercial && (
        <EditCommercialCustomerForm
          onSubmit={handleSubmitCommercialCustomer}
          customerId={customerId !== '' ? Number(customerId) : undefined}
          overrideValues={customer}
          isNew={!customerId}
          readonly={false}
          showSelfDisclosure
        />
      )}
    </Box>
  );
}

import React from 'react';
import { Controller } from 'react-hook-form';
import { InputBaseComponentProps, TextField, TextFieldProps } from '@mui/material';
import { FormComponentProps } from './FormComponentProps';
import { FieldValues } from 'react-hook-form/dist/types';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { getNestedValue } from '../../common/util/ArrayUtil';

interface Props<TFieldValues>
  extends FormComponentProps<TFieldValues>,
    Omit<NumericFormatProps, 'name'> {
  endAdornment?: JSX.Element;
  startAdornment?: JSX.Element;
  fixedDecimalScale?: boolean;
  scale?: number;
}

export const NumberFormatCustom = React.forwardRef<typeof NumericFormat, InputBaseComponentProps>(
  (props, ref) => {
    const { onChange, defaultValue, ...other } = props;
    return (
      <NumericFormat
        {...other}
        defaultValue={defaultValue as string}
        getInputRef={ref}
        onValueChange={(values) => {
          (onChange as any)({
            target: {
              name: props.name,
              value: values.floatValue != null ? values.floatValue : null,
            },
          });
        }}
        onBlur={() => {
          if (props.onBlur) {
            (props.onBlur as any)({
              target: {
                name: props.name,
                value: props.value,
              },
            });
          }
        }}
        decimalSeparator=","
        thousandSeparator="."
        allowNegative={false}
        onFocus={(e) => e.target.select()}
        decimalScale={2}
      />
    );
  }
);

export const FixedDecimalFormatCustom = (scale = 2) =>
  React.forwardRef<typeof NumericFormat, InputBaseComponentProps>((props, ref) => {
    const { onChange, defaultValue, ...other } = props;

    return (
      <NumericFormat
        {...other}
        defaultValue={defaultValue as string}
        getInputRef={ref}
        onValueChange={(values) => {
          (onChange as any)({
            target: {
              name: props.name,
              value: values.floatValue != null ? values.floatValue : null,
            },
          });
        }}
        onBlur={() => {
          if (props.onBlur) {
            (props.onBlur as any)({
              target: {
                name: props.name,
                value: props.value,
              },
            });
          }
        }}
        decimalSeparator=","
        thousandSeparator="."
        fixedDecimalScale
        decimalScale={scale}
        onFocus={(e) => e.target.select()}
        allowNegative={true}
      />
    );
  });

export const FormNumberInput = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  placeholder,
  className,
  shrink,
  disableUnderline,
  endAdornment,
  startAdornment,
  readOnly,
  onBlur,
  fixedDecimalScale,
  scale,
  ...others
}: Props<TFieldValues> & TextFieldProps) => {
  const fixedDecimalFormat = React.memo(FixedDecimalFormatCustom(scale));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState, fieldState: { error } }) => (
        <TextField
          error={!!error}
          {...field}
          value={field.value !== undefined ? (field.value as number) : ''}
          label={label}
          placeholder={placeholder}
          className={className}
          fullWidth={true}
          variant="standard"
          sx={
            others.required && !error && !others.disabled
              ? {
                  '& .MuiInput-underline:not(.Mui-focused):before': {
                    borderBottom: '2px solid black',
                  },
                  '& .MuiInput-underline:not(.Mui-focused):after': {
                    borderBottom: '2px solid black',
                  },
                }
              : undefined
          }
          InputProps={{
            inputComponent: fixedDecimalScale ? fixedDecimalFormat : NumberFormatCustom,
            endAdornment,
            disableUnderline,
            startAdornment,
            readOnly,
          }}
          helperText={error ? error.message : undefined}
          InputLabelProps={{ shrink }}
          onBlur={(e) => {
            const isDirty = getNestedValue(formState.dirtyFields, field.name);
            if (onBlur && isDirty) {
              onBlur(e);
            }
            field.onBlur();
          }}
          {...others}
        />
      )}
    />
  );
};

import { Box, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormDropdown } from '../../../../../component/form/FormDropdown';
import { Control, useWatch } from 'react-hook-form';
import { FinancesDrawerFormValues } from '../RightDrawerSchema';
import React from 'react';
import { OptionItem, SubmittedDealOverviewIPagedList } from '../../../../../generated/ApiClient';
import Reconsideration from './reconsideration/Reconsideration';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { FormIntegerNumberInput } from '../../../../../component/form/FormIntegerNumberInput';

interface FinancesCalculationConfigurationProps {
  dealId?: number;
  calculationId?: number;
  control: Control<FinancesDrawerFormValues>;
  financingTypes: OptionItem[];
  mileages: OptionItem[];
  interestCalcMethods: OptionItem[];
  rateCategories: OptionItem[];
  residualValueContracts: OptionItem[];
  hasResidualValue: boolean;
  onRelevantChange: (name: string, value: unknown) => void;
  loading: boolean;
  isMileageReadonly: boolean;
  isDurationReadonly: boolean;
  readonly?: boolean;
  fetchSubmittedDeals: (
    page?: number,
    id?: string,
    salesPersonName?: string,
    creationDate?: Date,
    customerName?: string,
    orderSelectors?: string[]
  ) => Promise<SubmittedDealOverviewIPagedList>;
  setValue: UseFormSetValue<FinancesDrawerFormValues>;
}

export const FinancesCalculationConfiguration = ({
  dealId,
  calculationId,
  control,
  financingTypes,
  mileages,
  interestCalcMethods,
  rateCategories,
  onRelevantChange,
  residualValueContracts,
  hasResidualValue,
  loading,
  isDurationReadonly,
  isMileageReadonly,
  readonly,
  fetchSubmittedDeals,
  setValue,
}: FinancesCalculationConfigurationProps) => {
  const durationInMonths: number = useWatch({ control, name: 'duration' });

  const { t } = useTranslation();

  const handleRelevantBlurEvent = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onRelevantChange(event.target.name, event.target.value);
  };

  const handleRelevantChangeEvent = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onRelevantChange(event.target.name, event.target.value);
  };

  return (
    <Grid container sx={{ 'p': 1, '& .MuiFormControl-root': { marginLeft: 0 } }} spacing={3}>
      <Grid item xs={6}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <h3>
            {t('deals.new.financesConfiguration.calculationDrawer.konfiguration.configuration')}
          </h3>
          <Reconsideration
            fetchSubmittedDeals={fetchSubmittedDeals}
            control={control}
            dealId={dealId}
            calculationId={calculationId}
            setValue={setValue}
            disabled={loading || readonly}
            onRelevantChange={onRelevantChange}
          />
        </Box>
        <FormDropdown
          required
          control={control}
          name="financingTypeId"
          label={t('deals.new.financesConfiguration.calculationDrawer.konfiguration.fundingType')}
          options={financingTypes}
          sx={{ m: 1 }}
          onChange={handleRelevantChangeEvent}
          shrink
          disabled={loading || readonly}
        />

        <Grid container sx={{ mt: 1, mb: 1 }}>
          <Grid item xs={8}>
            <FormIntegerNumberInput
              name="duration"
              label={t(
                'deals.new.financesConfiguration.calculationDrawer.konfiguration.durationInMonths'
              )}
              control={control}
              onBlur={handleRelevantBlurEvent}
              disabled={loading || isDurationReadonly || readonly}
              required
              allowNegativeValues={false}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label={t(
                'deals.new.financesConfiguration.calculationDrawer.konfiguration.durationInYears'
              )}
              value={
                durationInMonths
                  ? (durationInMonths / 12).toLocaleString('de-DE', { maximumFractionDigits: 2 })
                  : ''
              }
              variant="standard"
              disabled
            />
          </Grid>
        </Grid>
        <FormDropdown
          isRequired
          control={control}
          name="mileageId"
          label={t('deals.new.financesConfiguration.calculationDrawer.konfiguration.mileage')}
          options={mileages.map((value) => ({
            value: value.value,
            label: Number(value.label).toLocaleString('de-DE'),
          }))}
          sx={{ m: 1 }}
          shrink
          onChange={handleRelevantChangeEvent}
          disabled={loading || isMileageReadonly || readonly}
          emptyText={t(
            'deals.new.financesConfiguration.calculationDrawer.konfiguration.noSelectionAvailable'
          )}
          emptyValue={1}
        />
      </Grid>
      <Grid item xs={6}>
        <h3>
          {t('deals.new.financesConfiguration.calculationDrawer.konfiguration.extendedParameters')}
        </h3>
        <FormDropdown
          isRequired
          control={control}
          name="interestRateCalculationMethodId"
          label={t(
            'deals.new.financesConfiguration.calculationDrawer.konfiguration.interestRateCalculationMethod'
          )}
          options={interestCalcMethods}
          sx={{ m: 1 }}
          onChange={handleRelevantChangeEvent}
          shrink
          disabled={loading || readonly}
        />
        {hasResidualValue && (
          <FormDropdown
            isRequired
            control={control}
            name="residualValueId"
            label={t(
              'deals.new.financesConfiguration.calculationDrawer.konfiguration.residualValue'
            )}
            options={residualValueContracts}
            sx={{ m: 1 }}
            onChange={handleRelevantChangeEvent}
            shrink
            disabled={loading || readonly}
          />
        )}
        <FormDropdown
          isRequired
          control={control}
          name="rateCategoryId"
          label={t('deals.new.financesConfiguration.calculationDrawer.konfiguration.tariffGroup')}
          options={rateCategories}
          sx={{ m: 1 }}
          onChange={handleRelevantChangeEvent}
          shrink
          disabled={loading || readonly}
        />
      </Grid>
    </Grid>
  );
};

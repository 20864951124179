import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormNumberInput } from '../../../../../component/form/FormNumberInput';
import { Control } from 'react-hook-form';
import { FormPriceInput } from '../../../../../component/form/FormPriceInput';
import React from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { FinancesDrawerFormValues } from '../RightDrawerSchema';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';

interface FinancesObjectPricingProps {
  control: Control<FinancesDrawerFormValues>;
  setValue: UseFormSetValue<FinancesDrawerFormValues>;
  getValues: UseFormGetValues<FinancesDrawerFormValues>;
  isIncludingVat: boolean;
  vatFree: boolean;
  onRelevantChange: (name: string, value: unknown) => void;
  loading: boolean;
  readonly?: boolean;
}

export const FinancesObjectPricing = ({
  control,
  isIncludingVat,
  vatFree,
  onRelevantChange,
  setValue,
  getValues,
  loading,
  readonly,
}: FinancesObjectPricingProps) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEditCalculationBase = hasPermission('Object.CalculationBase', 'Edit');
  const handleRelevantBlurEvent = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onRelevantChange(event.target.name, event.target.value);
  };

  function recalculateTotal() {
    const { listPriceIncludingExtras, discountInEuro } = getValues().objectConfig;
    setValue('objectConfig.totalPrice', listPriceIncludingExtras - (discountInEuro || 0));
    setValue('objectConfig.calculationBase', listPriceIncludingExtras - (discountInEuro || 0));
    setValue('objectConfig.purchasePrice', listPriceIncludingExtras - (discountInEuro || 0));
  }

  function recalculateDiscountPercent() {
    const { listPriceIncludingExtras, discountInEuro } = getValues().objectConfig;

    if (listPriceIncludingExtras && discountInEuro) {
      setValue('objectConfig.discountPercent', (discountInEuro / listPriceIncludingExtras) * 100);
    } else {
      setValue('objectConfig.discountPercent', 0);
    }
  }

  function changeListPrice(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = Number(event.target.value);
    setValue('objectConfig.listPrice', value);
    const sp = getValues().objectConfig.extrasPrice || 0;
    setValue('objectConfig.listPriceIncludingExtras', sp + value);
    recalculateDiscountPercent();
    recalculateTotal();
  }

  function changeExtrasPrice(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = Number(event.target.value);
    setValue('objectConfig.extrasPrice', value);
    const listPrice: number = getValues('objectConfig.listPrice') || 0;

    setValue('objectConfig.listPriceIncludingExtras', listPrice + value);
    recalculateDiscountPercent();
    recalculateTotal();
  }

  function changeDiscountPercentage(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = Number(event.target.value);
    if (isNaN(value)) {
      setValue('objectConfig.discountPercent', 0);
      setValue('objectConfig.discountInEuro', 0);
    } else {
      setValue('objectConfig.discountPercent', value);
      const listPriceIncludingExtras = getValues().objectConfig.listPriceIncludingExtras;
      const discountEuro = (listPriceIncludingExtras / 100) * value;
      setValue('objectConfig.discountInEuro', discountEuro);
    }
    recalculateTotal();
  }

  function changeDiscountEuro(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = Number(event.target.value);
    const listPriceIncludingExtras = getValues().objectConfig.listPriceIncludingExtras;

    if (listPriceIncludingExtras && value) {
      setValue('objectConfig.discountPercent', (value / listPriceIncludingExtras) * 100);
    } else {
      setValue('objectConfig.discountPercent', 0);
    }
    recalculateTotal();
  }

  function changeLpIncExtras(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = Number(event.target.value);
    const { listPrice } = getValues().objectConfig;
    if (value < listPrice) {
      setValue('objectConfig.listPriceIncludingExtras', value);
      setValue('objectConfig.listPrice', value);
      setValue('objectConfig.extrasPrice', 0);
    } else {
      const newSpecial = value - listPrice;
      setValue('objectConfig.extrasPrice', newSpecial);
      setValue('objectConfig.listPriceIncludingExtras', value);
    }
    recalculateDiscountPercent();
    recalculateTotal();
  }

  function changeTotal(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = Number(event.target.value);
    const { listPriceIncludingExtras } = getValues().objectConfig;
    if (value > listPriceIncludingExtras) {
      setValue('objectConfig.totalPrice', listPriceIncludingExtras);
      setValue('objectConfig.discountInEuro', 0);
      event.target.value = listPriceIncludingExtras.toString();
    } else {
      const newDiscount = listPriceIncludingExtras - value;
      setValue('objectConfig.discountInEuro', newDiscount);
      setValue('objectConfig.totalPrice', value);
    }
    recalculateDiscountPercent();
  }

  const handleBlur =
    (callBack: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void) =>
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      callBack(event);
      handleRelevantBlurEvent(event);
    };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pr: 3, pl: 2 }}>
      <h3>{t('deals.new.financesConfiguration.calculationDrawer.objectConfig.priceDetails')}</h3>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormPriceInput
              pricesIncludingVAT={isIncludingVat}
              vatFree={vatFree}
              control={control}
              disabled={loading || !canEditCalculationBase || readonly}
              sx={{ m: 1 }}
              name="objectConfig.listPrice"
              label={t('deals.new.financesConfiguration.calculationDrawer.objectConfig.listPrice')}
              shrink
              onBlur={handleBlur(changeListPrice)}
            />
            <FormPriceInput
              pricesIncludingVAT={isIncludingVat}
              vatFree={vatFree}
              control={control}
              disabled={loading || !canEditCalculationBase || readonly}
              sx={{ m: 1 }}
              name="objectConfig.extrasPrice"
              label={t(
                'deals.new.financesConfiguration.calculationDrawer.objectConfig.extrasPrice'
              )}
              shrink
              onBlur={handleBlur(changeExtrasPrice)}
            />

            <FormPriceInput
              pricesIncludingVAT={isIncludingVat}
              vatFree={vatFree}
              control={control}
              disabled
              sx={{ m: 1 }}
              name="objectConfig.listPriceIncludingExtras"
              label={t(
                'deals.new.financesConfiguration.calculationDrawer.objectConfig.listPriceIncludingExtras'
              )}
              shrink
              disableUnderline
              onBlur={handleBlur(changeLpIncExtras)}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', pr: 0 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
              <FormNumberInput
                control={control}
                disabled
                name="objectConfig.discountPercent"
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.objectConfig.discountPercent'
                )}
                shrink
                endAdornment={<span>%</span>}
                fixedDecimalScale
                sx={{ width: '30%', mr: 1 }}
                onBlur={handleBlur(changeDiscountPercentage)}
              />

              <FormPriceInput
                pricesIncludingVAT={isIncludingVat}
                vatFree={vatFree}
                control={control}
                disabled
                name="objectConfig.discountInEuro"
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.objectConfig.discountInEuro'
                )}
                shrink
                sx={{ width: '70%', ml: 1 }}
                onBlur={handleBlur(changeDiscountEuro)}
              />
            </Box>
            <FormPriceInput
              pricesIncludingVAT={isIncludingVat}
              vatFree={vatFree}
              control={control}
              disabled={loading || !canEditCalculationBase || readonly}
              sx={{ m: 1, pr: 1 }}
              name="objectConfig.totalPrice"
              label={t('deals.new.financesConfiguration.calculationDrawer.objectConfig.totalPrice')}
              shrink
              onBlur={handleBlur(changeTotal)}
            />
            <FormPriceInput
              pricesIncludingVAT={isIncludingVat}
              vatFree={vatFree}
              disableUnderline
              control={control}
              sx={{ m: 1 }}
              name="objectConfig.coFinancedExtras"
              label={t(
                'deals.new.financesConfiguration.calculationDrawer.objectConfig.coFinancedExtras'
              )}
              shrink
              disabled
            />
            <FormPriceInput
              pricesIncludingVAT={isIncludingVat}
              vatFree={vatFree}
              disableUnderline
              control={control}
              sx={{ m: 1 }}
              name="objectConfig.purchasePrice"
              label={t(
                'deals.new.financesConfiguration.calculationDrawer.objectConfig.purchasePrice'
              )}
              shrink
              disabled
            />
            <FormPriceInput
              pricesIncludingVAT={isIncludingVat}
              vatFree={vatFree}
              disableUnderline
              control={control}
              sx={{ m: 1 }}
              name="objectConfig.calculationBase"
              label={t(
                'deals.new.financesConfiguration.calculationDrawer.objectConfig.calculationBasis'
              )}
              shrink
              disabled
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnhancedTable } from '../../../../common/table/EnhancedTable';
import { HeadCell } from '../../../../common/table/EnhancedTableHead';
import { EnhancedTableToolbarButton } from '../../../../common/table/EnhancedTableToolbar';
import { Tab, Tabs, Box, Button, Typography, IconButton } from '@mui/material';
import { TabPanel, a11yProps } from '../../../../component/TabPanel';
import { Icons } from '../../../../component/icons/Icons';
import { FinancesCalculationContextValues } from '../../common/financeOverlay/RightDrawerSchema';

export interface FinanceCalculation {
  id: string;
  financingType: string;
  calculationNumber: string;
  isPrimaryCalculation: boolean;
  sentDate: Date | undefined;
  sendToContractManagementSystemDate: JSX.Element | undefined;
  duration: number;
  residualValue: string | undefined;
  rateCategory: string | undefined;
  mileage: string | undefined;
  rateIncludingVAT: string;
  serviceInstalment: string;
  downPayment: string | undefined;
  deposit: string | undefined;
  purchasePrice: string | undefined;
  icon?: JSX.Element | undefined;
  isLeasing: boolean;
  isCredit: boolean;
  isFuelFinancingType?: boolean;
  isCreditCheckAvailable?: boolean;
  className?: string;
}

export const formatToEuro = (n: number | undefined | null, noUnit = false): string => {
  return n
    ? `${n.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} ${noUnit ? '' : '€'}`
    : '-';
  // .replace('.', ',')} ${noUnit ? '' : '€'}
};

interface FinancesCalculationListProps {
  items: FinancesCalculationContextValues[];
  vehicleAgeIsValid: boolean;
  onNew?: () => void;
  onRowClick?: (values: FinancesCalculationContextValues) => void;
}

export function FinancesCalculationList(props: FinancesCalculationListProps) {
  const { onNew, items, vehicleAgeIsValid, onRowClick } = props;
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const headCells: readonly HeadCell<FinanceCalculation>[] = [
    {
      id: 'id',
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'financingType',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.financingType'),
    },
    {
      id: 'duration',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.duration'),
    },
    {
      id: 'rateIncludingVAT',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.rate'),
    },
    {
      id: 'serviceInstalment',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.serviceInstalment'),
    },
    {
      id: 'mileage',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.mileage'),
    },
    {
      id: 'residualValue',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.residualValue'),
    },
    {
      id: 'downPayment',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.downPayment'),
    },
    {
      id: 'deposit',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.deposit'),
    },
    {
      id: 'purchasePrice',
      align: 'right',
      disablePadding: false,
      label: t('deals.new.financesConfiguration.list.purchasePrice'),
    },
    {
      id: 'icon',
      align: 'right',
      disablePadding: false,
      label: '',
    },
  ];

  const rows: FinanceCalculation[] = items
    .map((financeCalculation, index: number) => ({
      id: financeCalculation.id ? financeCalculation.id.toString() : `${index}`,
      calculationNumber: '',
      financingType: financeCalculation.financingType,
      isPrimaryCalculation: false,
      sentDate: undefined,
      sendToContractManagementSystemDate: undefined,
      duration: financeCalculation.duration,
      residualValue: formatToEuro(financeCalculation.residualValue, true),
      rateCategory: financeCalculation.rateCategory,
      mileage: financeCalculation.mileage
        ? Number(financeCalculation.mileage).toLocaleString('de-DE')
        : '-',
      rateIncludingVAT: formatToEuro(financeCalculation.rateIncludingVAT, true),
      serviceInstalment: financeCalculation.regularInstalmentTotalGross
        ? formatToEuro(
            financeCalculation.regularInstalmentTotalGross - financeCalculation.rateIncludingVAT
          )
        : formatToEuro(0),
      downPayment: formatToEuro(financeCalculation.downPayment, true),
      deposit: formatToEuro(financeCalculation.deposit, true),
      isLeasing: financeCalculation.isLeasing,
      isCredit: financeCalculation.isCredit,
      purchasePrice: formatToEuro(financeCalculation.objectConfig.purchasePrice, true),
      icon: onRowClick ? (
        <IconButton onClick={() => onRowClick(financeCalculation)}>{Icons.pencil()}</IconButton>
      ) : undefined,
      className: undefined
    }))
    .reverse();

  const toolbarButtons: EnhancedTableToolbarButton[] = [
    {
      label: 'Select All',
    },
    {
      label: 'Show',
    },
  ];
  const allTable = (
    <EnhancedTable
      rows={rows}
      headCells={headCells}
      hideCheckbox={false}
      toolbarButtons={toolbarButtons}
      variant="endless"
      enableSelection={true}
      loadNewPage={() => {}}
    ></EnhancedTable>
  );
  const leasingTable = (
    <EnhancedTable
      rows={rows.filter((item) => item.isLeasing)}
      headCells={headCells}
      hideCheckbox={false}
      toolbarButtons={toolbarButtons}
      variant="endless"
      enableSelection={true}
      loadNewPage={() => {}}
    ></EnhancedTable>
  );
  const creditTable = (
    <EnhancedTable
      rows={rows.filter((item) => item.isCredit)}
      headCells={headCells}
      hideCheckbox={false}
      toolbarButtons={toolbarButtons}
      variant="endless"
      enableSelection={true}
      loadNewPage={() => {}}
    ></EnhancedTable>
  );

  return (
    <>
      <Box sx={{ display: 'flex', height: '80px', gap: '2.5%', alignItems: 'flex-end' }}>
        <Typography variant="h3">{t('deals.new.financesConfiguration.list.financing')}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tabs aria-label="basic tabs example" value={value} onChange={handleChange}>
            <Tab label={t('deals.new.financesConfiguration.list.all')} {...a11yProps(0)} />
            <Tab label={t('deals.new.financesConfiguration.list.leasing')} {...a11yProps(1)} />
            <Tab label={t('deals.new.financesConfiguration.list.credit')} {...a11yProps(2)} />
          </Tabs>
        </Box>

        {onNew && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '2%',
              justifyContent: 'flex-end',
              width: '90%',
              height: '100%',
            }}
          >
            <Button disabled={true} className="white" sx={{ height: '28px' }}>
              {t('deals.new.financesConfiguration.list.forCreditCheck')}
            </Button>
            <Button
              disabled={!vehicleAgeIsValid}
              variant="contained"
              type="button"
              sx={{ height: '28px' }}
              onClick={() => onNew()}
            >
              {t('deals.new.financesConfiguration.list.newCalculation')}
            </Button>
          </Box>
        )}
      </Box>
      <TabPanel value={value} index={0}>
        {allTable}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {leasingTable}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {creditTable}
      </TabPanel>
    </>
  );
}

import { useQuery } from 'react-query';
import RestHttpClient from '../../../../common/RestHttpClient';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { z } from 'zod';
import { chassisNumber } from '../../../../common/validation/Validations';
import {
  FleetVinAssignment,
  ISignFinancingCalculationDocumentsResponse,
  PrintFleetDocumentsRequest,
  UpdateHandOverRequest,
  VinAssignmentStatus,
} from '../../../../generated/ApiClient';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormTextField } from '../../../../component/form/FormTextField';
import { FormCheckbox } from '../../../../component/form/FormCheckbox';
import { pushNotificationHandler } from '../../../../common/PushNotification';
import { SignDocumentsDialog } from '../common/SignDocumentsDialog';

const handoverSchema = z
  .object({
    position: z.number(),
    vin: chassisNumber.optional().nullable(),
    commissionNumber: z.string().optional().nullable(),
    cmsId: z.string(),
    status: z.nativeEnum(VinAssignmentStatus),
    print: z.boolean(),
  })
  .refine((x) => (x.print ? !!x.vin : true), {
    path: ['vin'],
  });

const schema = z.object({
  values: z.array(handoverSchema),
});

type HandOverValues = z.infer<typeof schema>;
type HandOverValue = z.infer<typeof handoverSchema>;

export function FleetHandOverDialog({
  dealId,
  open,
  setOpen,
}: {
  dealId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { refetch, isLoading } = useQuery({
    queryKey: ['fleet', dealId],
    queryFn: () => RestHttpClient.getFleetHandOver(dealId),
    select: (data) => data.data,
    enabled: false,
  });

  const [isPrinting, setIsPrinting] = useState(false);

  const [hasDownloaded, setHasDownloaded] = useState(false);

  const [signFinancingCalculationDocuments, setSignFinancingCalculationDocuments] = useState<
    ISignFinancingCalculationDocumentsResponse[]
  >([]);
  const [isSignDocumentsOpened, setIsSignDocumentsOpened] = useState(false);

  const { reset, control, handleSubmit } = useForm<HandOverValues>({
    resolver: zodResolver(schema),
  });

  const onRelevantChange = () => {
    setHasDownloaded(false);
  };

  useEffect(() => {
    if (open) {
      refetch().then((res) => {
        reset({
          values: res.data?.map((x) => ({
            position: x.position ?? -1,
            vin: x.vin ?? '',
            commissionNumber: x.commissionNumber ?? '',
            cmsId: x.cmsId ?? '',
            status: x.status ?? VinAssignmentStatus.Canceled,
            print:
              x.status === VinAssignmentStatus.Submitted ||
              x.status === VinAssignmentStatus.HandOverPrinting,
          })),
        });
      });
    }
  }, [dealId, open]);

  const { fields } = useFieldArray({ control, name: 'values' });

  const { t } = useTranslation();

  const onSubmit = (event: FormEvent) => {
    handleSubmit(async (values) => {
      setIsPrinting(true);
      try {
        const res = await RestHttpClient.printFleetDocuments(
          new PrintFleetDocumentsRequest({
            dealId: dealId,
            vinAssignments: values.values.map(
              (x) =>
                new FleetVinAssignment({
                  position: x.position,
                  vin: x.vin ?? undefined,
                  cmsId: x.cmsId,
                  status: getPrintStatus(x),
                  commissionNumber: x.commissionNumber ?? undefined,
                })
            ),
          })
        );
        const tmpDownloadLink = document.createElement('a');
        tmpDownloadLink.href = window.URL.createObjectURL(res.data);
        tmpDownloadLink.download = res.fileName ?? 'Documents.pdf';
        tmpDownloadLink.click();

        setHasDownloaded(true);
      } finally {
        setIsPrinting(false);
      }
    })(event);
  };

  const onSign = (event: FormEvent) => {
    handleSubmit(async (values) => {
      setIsPrinting(true);
      try {
        const res = await RestHttpClient.signFleetDocuments(
          new PrintFleetDocumentsRequest({
            dealId: dealId,
            vinAssignments: values.values.map(
              (x) =>
                new FleetVinAssignment({
                  position: x.position,
                  vin: x.vin ?? undefined,
                  cmsId: x.cmsId,
                  status: getPrintStatus(x),
                  commissionNumber: x.commissionNumber ?? undefined,
                })
            ),
          })
        );

        setSignFinancingCalculationDocuments(res.data);
        setIsSignDocumentsOpened(true);
      } finally {
        setIsPrinting(false);
      }
      setHasDownloaded(true);
    })(event);
  };

  const onHandOver = async () => {
    await RestHttpClient.updateHandOver(new UpdateHandOverRequest({ dealId }));
    pushNotificationHandler.publish(t('deals.fleet.handOverSuccess'), 'success');
    setOpen(false);
  };
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ sx: { maxWidth: 'unset' } }}>
        <DialogTitle>{t('deals.fleet.handOverTitle')}</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            {fields.map((field, index) => (
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 8fr 8fr 4fr 4fr',
                  alignItems: 'center',
                  gap: 1,
                }}
                key={field.position}
              >
                <Typography pt={1} textAlign="center">
                  {field.position}
                </Typography>
                <FormTextField
                  control={control}
                  disabled
                  name={`values.${index}.cmsId`}
                  label={t('deals.fleet.cmsId')}
                  placeholder="-"
                />
                <FormTextField
                  control={control}
                  name={`values.${index}.vin`}
                  label={t('deals.fleet.vin')}
                  onBlur={onRelevantChange}
                />
                <Typography textAlign="center">{field.status}</Typography>
                <FormCheckbox
                  onChange={onRelevantChange}
                  control={control}
                  name={`values.${index}.print`}
                  label={t('deals.fleet.print')}
                  disabled={
                    field.status === VinAssignmentStatus.Canceled ||
                    field.status === VinAssignmentStatus.New
                  }
                />
              </Box>
            ))}
          </Stack>
          {(isPrinting || isLoading) && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button disabled={!fields.some((x) => x.print) || isPrinting} onClick={onSubmit}>
            {t('deals.fleet.print')}
          </Button>
          <Button disabled={!fields.some((x) => x.print) || isPrinting} onClick={onSign}>
            {t('deals.fleet.sign')}
          </Button>
          <Button onClick={onHandOver} disabled={!hasDownloaded || isPrinting}>
            {t('deals.fleet.handOver')}
          </Button>
        </DialogActions>
      </Dialog>

      <SignDocumentsDialog
        signDocuments={signFinancingCalculationDocuments}
        onClose={() => setIsSignDocumentsOpened(false)}
        setOpen={setIsSignDocumentsOpened}
        isOpen={isSignDocumentsOpened}
      />
    </>
  );
}

const getPrintStatus = (value: HandOverValue) => {
  if (value.print) return VinAssignmentStatus.HandOverPrinting;
  if (value.status === VinAssignmentStatus.HandOverPrinting) return VinAssignmentStatus.Submitted;
  return value.status;
};

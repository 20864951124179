import React, { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { Box, Fab, useMediaQuery, useTheme } from '@mui/material';
import { Icons } from './icons/Icons';

interface Props {
  left: ReactNode;
  right: ReactNode;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const SideDrawer = ({ left, right, open, setOpen }: Props) => {
  const theme = useTheme();
  const [width, setWidth] = useState('100%');
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  const sideContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOpen(matches);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  useEffect(() => {
    setWidth(`calc(100vw - ${(sideContainer.current?.offsetWidth || 50) + 12}px)`);
  }, [sideContainer.current?.offsetWidth]);

  return (
    <Box height="100%" className="side-drawer">
      <Box
        ref={sideContainer}
        className="sidebar-dark"
        sx={{
          '.MuiListItemText-root': {
            display: open ? 'block' : 'none',
          },
        }}
      >
        {left}
        <Box className="button-container">
          <Fab size="small" color="info" onClick={() => setOpen(!open)}>
            {open ? Icons.chevronLeftWhite() : Icons.chevronRightWhite()}
          </Fab>
        </Box>
      </Box>
      <Box className="sidebar-content" width={width}>
        {right}
      </Box>
    </Box>
  );
};

export default SideDrawer;

import { Dispatch, FormEvent, SetStateAction, useState, useEffect } from 'react';
import { EnhancedDialog } from '../../../../component/EnhancedDialog';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import RestHttpClient, { renderValidationErrors } from '../../../../common/RestHttpClient';
import {
  AllowedSettingsReading,
  DocumentDefinition,
  ISignFinancingCalculationDocumentsResponse,
  PrintFinancingCalculationDocumentsRequest,
  PrintInsuranceDocumentsRequest,
  SendFinancingCalculationDocumentsRequest,
  SendInsuranceDocumentsRequest,
  SignFinancingCalculationDocumentsRequest,
  SignInsuranceDocumentsRequest,
} from '../../../../generated/ApiClient';
import { SendDocumentsDialog } from '../common/SendDocumentsDialog';
import { SignDocumentsDialog } from '../common/SignDocumentsDialog';
import { pushNotificationHandler } from '../../../../common/PushNotification';
import FinancePrintForm, { FinancePrintFormType, financePrintSchema } from './FinancePrintForm';
import { a11yProps, TabPanel } from '../../../../component/TabPanel';
import InsurancePrintForm, {
  InsurancePrintFormType,
  insurancePrintSchema,
} from '../insurances/InsurancePrintForm';
import { OptionItem } from '../../../../component/form/OptionItem';
import { isMobileSafari } from 'react-device-detect';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  calculationId?: number;
  singleInsuranceId?: number;
}

export const docToForm = (docs: DocumentDefinition[]) => {
  let selectedValues = docs.filter((d) => d.selected).map((d) => d.id);
  for (let doc of docs.filter((x) => x.selected)) {
    const subDocuments = doc.subDocuments;
    const subDocumentsSelectedValues = subDocuments?.length ? docToForm(subDocuments) : [];
    selectedValues = selectedValues.concat(subDocumentsSelectedValues);
  }
  return selectedValues;
};

export const customerDocToForm = (docs: DocumentDefinition[]): string[] => {
  let selectedValues = docs.filter((d) => d.selected).map((d) => d.id + '|' + d.customerId);
  for (let doc of docs.filter((x) => x.selected)) {
    const subDocuments = doc.subDocuments;
    const subDocumentsSelectedValues = subDocuments?.length ? customerDocToForm(subDocuments) : [];
    selectedValues = selectedValues.concat(subDocumentsSelectedValues);
  }
  return selectedValues;
};

const checkInsurancePrintable = (insuranceForm: UseFormReturn<InsurancePrintFormType>) => {
  const { insuranceDocuments, customerDocuments } = insuranceForm.getValues();
  return insuranceDocuments.length > 0 || customerDocuments.length > 0;
};

export function FinancePrintDialog({ isOpen, setIsOpen, calculationId, singleInsuranceId }: Props) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [offerDocuments, setOfferDocuments] = useState<DocumentDefinition[]>([]);
  const [termsheetDocuments, setTermsheetDocuments] = useState<DocumentDefinition[]>([]);
  const [contractDocuments, setContractDocuments] = useState<DocumentDefinition[]>([]);
  const [miscellaneousDocuments, setMiscellaneousDocuments] = useState<DocumentDefinition[]>([]);
  const [customerDocuments, setCustomerDocuments] = useState<DocumentDefinition[]>([]);
  const [signFinancingCalculationDocuments, setSignFinancingCalculationDocuments] = useState<
    ISignFinancingCalculationDocumentsResponse[]
  >([]);
  const [isSendDocumentsOpened, setIsSendDocumentsOpened] = useState(false);
  const [isSignDocumentsOpened, setIsSignDocumentsOpened] = useState(false);
  const [euriborDates, setEuriborDates] = useState<OptionItem[]>([]);

  const [tabErrors, setTabErrors] = useState<number[]>([]);

  // insurance values
  const insuranceForm = useForm<InsurancePrintFormType>({
    resolver: zodResolver(insurancePrintSchema),
    defaultValues: {
      insuranceDocuments: [],
      customerDocuments: [],
    },
  });

  const [insuranceDocuments, setInsuranceDocuments] = useState<DocumentDefinition[]>([]);
  const [previousInsurers, setPreviousInsurers] = useState<OptionItem[]>([]);
  const [contractTypes, setContractTypes] = useState<OptionItem[]>([]);
  const [separateDocuments, setSeparateDocuments] = useState<number[]>([]);

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    setLoading(true);
    setTabErrors([]);
    try {
      RestHttpClient.getInsuranceContractTypes().then((res) => {
        setContractTypes(res.data);
      });
      RestHttpClient.getPreviousInsurers().then((res) => {
        setPreviousInsurers(res.data.map((x) => new OptionItem({ label: x.name, value: x.value })));
      });
      RestHttpClient.getConfigurationValues(
        AllowedSettingsReading.FileDownload_SeparateDocuments
      ).then((res) => {
        const splitDocuments = res.data?.value?.split(',') ?? [];
        setSeparateDocuments(splitDocuments.map((x) => parseInt(x)));
      });
    } finally {
      setLoading(false);
    }
  }, []);

  // @ts-ignore
  const tPrint = (key: string): string => t(`deals.printDialog.${key}`);
  const { control, handleSubmit, reset, watch, setValue, getValues, setError } =
    useForm<FinancePrintFormType>({
      resolver: zodResolver(financePrintSchema),
      defaultValues: {
        offerDocuments: [],
        termsheetDocuments: [],
        contractDocuments: [],
        miscellaneousDocuments: [],
        customerDocuments: [],
      },
    });

  const getDefaultPrintRequest = (values: FinancePrintFormType) => {
    return new PrintFinancingCalculationDocumentsRequest({
      financingCalculationId: calculationId ?? 0,
      expectedDispatchDate: values.expectedDispatchDate ?? undefined,
      selectedEuriborId: values.selectedEuriborId,
      termsheet: values.termsheet ?? undefined,
      leasingContractNotes: values.leasingContractNotes ?? undefined,
      offerSupplement: values.offerSupplement ?? undefined,
      calculationGross: values.calculationGross,
      termsheetCalculationGross: values.termsheetCalculationGross,
      turnOverTaxStatementRequested: values.turnOverTaxStatementRequested,
      useCoApplicantAccount: values.useCoApplicantAccount,
      offerDocumentIds: [],
      termsheetDocumentIds: [],
      contractDocumentIds: [],
      miscellaneousDocumentIds: [],
      customerDocumentIds: [],
    });
  };

  const printDocumentAndDownload = (req: PrintFinancingCalculationDocumentsRequest) => {
    return RestHttpClient.printFinancingCalculationDocuments(req).then((data) => {
      const tmpDownloadLink = document.createElement('a');
      tmpDownloadLink.href = window.URL.createObjectURL(data.data);
      tmpDownloadLink.download = data.fileName ?? 'Documents.pdf';
      tmpDownloadLink.click();
    });
  };

  const hasDocuments = (req: PrintFinancingCalculationDocumentsRequest) => {
    return (
      req.offerDocumentIds.length ||
      req.termsheetDocumentIds.length ||
      req.contractDocumentIds.length ||
      req.miscellaneousDocumentIds.length ||
      req.customerDocumentIds.length
    );
  };

  const printAllDocuments = (values: FinancePrintFormType) => {
    const documentsRequest = getDefaultPrintRequest(values);
    documentsRequest.offerDocumentIds = values.offerDocuments;
    documentsRequest.termsheetDocumentIds = values.termsheetDocuments;
    documentsRequest.contractDocumentIds = values.contractDocuments;
    documentsRequest.miscellaneousDocumentIds = values.miscellaneousDocuments;
    documentsRequest.customerDocumentIds = values.customerDocuments;

    return printDocumentAndDownload(documentsRequest);
  } 

  const printSeparateDocuments = async (values: FinancePrintFormType) => {
    separateDocuments.forEach(async (separateDoc) => {
      const separateDocRequest = getDefaultPrintRequest(values);
      separateDocRequest.offerDocumentIds = values.offerDocuments.filter(
        (doc) => doc === separateDoc
      );
      separateDocRequest.termsheetDocumentIds = values.termsheetDocuments.filter(
        (doc) => doc === separateDoc
      );
      separateDocRequest.contractDocumentIds = values.contractDocuments.filter(
        (doc) => doc === separateDoc
      );
      separateDocRequest.miscellaneousDocumentIds = values.miscellaneousDocuments.filter(
        (doc) => doc === separateDoc
      );
      separateDocRequest.customerDocumentIds = values.customerDocuments.filter((doc) => {
        const docId = parseInt(doc.split('|')[0]);
        return docId === separateDoc;
      });

      if (hasDocuments(separateDocRequest)) await printDocumentAndDownload(separateDocRequest);
    });
  };

  const printRemainingDocuments = async (values: FinancePrintFormType) => {
    const documentsRequest = getDefaultPrintRequest(values);
    documentsRequest.offerDocumentIds = values.offerDocuments.filter(
      (doc) => !separateDocuments.some((separate) => doc === separate)
    );
    documentsRequest.termsheetDocumentIds = values.termsheetDocuments.filter(
      (doc) => !separateDocuments.some((separate) => doc === separate)
    );
    documentsRequest.contractDocumentIds = values.contractDocuments.filter(
      (doc) => !separateDocuments.some((separate) => doc === separate)
    );
    documentsRequest.miscellaneousDocumentIds = values.miscellaneousDocuments.filter(
      (doc) => !separateDocuments.some((separate) => doc === separate)
    );
    documentsRequest.customerDocumentIds = values.customerDocuments.filter((doc) => {
      const docId = parseInt(doc.split('|')[0]);
      return !separateDocuments.some((separate) => separate === docId);
    });

    if (hasDocuments(documentsRequest)) await printDocumentAndDownload(documentsRequest);
  };

  const onHandleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (singleInsuranceId && checkInsurancePrintable(insuranceForm)) {
      const valid = await insuranceForm.trigger();
      if (!valid) {
        setTabErrors([1]);
        return;
      }
      try {
        const req = new PrintInsuranceDocumentsRequest({
          insuranceCalculationId: singleInsuranceId,
          offerSupplement: insuranceForm.getValues().offerSupplement ?? '',
          previousInsuranceInfoRequired: insuranceForm.getValues().previousInsuranceInfoRequired,
          previousInsuranceCompanyName:
            insuranceForm.getValues().previousInsuranceCompanyName ?? '',
          previousTerminateContract: insuranceForm.getValues().previousTerminateContract ?? false,
          previousContractNumber: insuranceForm.getValues().previousContractNumber ?? '',
          previousLicensePlate: insuranceForm.getValues().previousLicensePlate ?? '',
          previousContractTypeId: insuranceForm.getValues().previousContractTypeId ?? undefined,
          waiverOfBonus: insuranceForm.getValues().waiverOfBonus ?? false,
          insuranceDocumentIds: insuranceForm.getValues().insuranceDocuments,
          customerDocumentIds: insuranceForm.getValues().customerDocuments,
        });

        const res = await RestHttpClient.printInsuranceDocuments(req);
        debugger;
        const tmpDownloadLink = document.createElement('a');
        tmpDownloadLink.href = window.URL.createObjectURL(res.data);
        tmpDownloadLink.download = res.fileName ?? 'Documents.pdf';
        tmpDownloadLink.click();
      } catch (e) {
        console.error(e);
        pushNotificationHandler.publish(t('deals.edit.insurance.printDialog.error'), 'error');
        renderValidationErrors(e, insuranceForm.setError);
      }
    }

    await handleSubmit(
      async (values) => {
        try {
          setLoading(true);

          if (isMobileSafari)
            await printAllDocuments(values);
          else 
            await Promise.all([printRemainingDocuments(values), printSeparateDocuments(values)]);
        } catch (err) {
          pushNotificationHandler.publish(t('deals.printDialog.error'), 'error');
          renderValidationErrors(err, setError);
        } finally {
          setLoading(false);
        }
      },
      (err) => {
        console.error(err);
        setTabErrors((prev) => [...prev, 1]);
      }
    )(event);
  };

  const fetchPrintDocuments = () => {
    if (calculationId) {
      setLoading(true);
      RestHttpClient.getPrintFinancialCalculationDocuments(calculationId)
        .then((res) => {
          const {
            offerDocuments,
            termsheetDocuments,
            contractDocuments,
            miscellaneousDocuments,
            customerDocuments,
            ...d
          } = res.data;
          setOfferDocuments(offerDocuments);
          setTermsheetDocuments(termsheetDocuments);
          setContractDocuments(contractDocuments);
          setMiscellaneousDocuments(miscellaneousDocuments);
          setCustomerDocuments(customerDocuments);
          reset({
            ...d,
            offerDocuments: docToForm(offerDocuments),
            termsheetDocuments: docToForm(termsheetDocuments),
            contractDocuments: docToForm(contractDocuments),
            miscellaneousDocuments: docToForm(miscellaneousDocuments),
            customerDocuments: customerDocToForm(customerDocuments),
            useCoApplicantAccount: false,
            turnOverTaxStatementRequested: false,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchInsurancePrintDocuments = async () => {
    if (singleInsuranceId) {
      const { data } = await RestHttpClient.getPrintInsuranceDocuments(singleInsuranceId);
      setInsuranceDocuments(data.insuranceDocuments);
      insuranceForm.reset({
        ...data,
        insuranceDocuments: docToForm(data.insuranceDocuments),
        customerDocuments: [],
      });
    } else {
      insuranceForm.reset({
        insuranceDocuments: [],
        customerDocuments: [],
      });
      setInsuranceDocuments([]);
    }
  };

  const selectedEuriborId = watch('selectedEuriborId');

  useEffect(() => {
    if (isOpen) {
      setTabValue(0);
      fetchPrintDocuments();
      fetchInsurancePrintDocuments();
    } else {
      reset({
        offerDocuments: [],
        termsheetDocuments: [],
        contractDocuments: [],
        miscellaneousDocuments: [],
        customerDocuments: [],
      });
      setOfferDocuments([]);
      setTermsheetDocuments([]);
      setContractDocuments([]);
      setMiscellaneousDocuments([]);
      setCustomerDocuments([]);
    }
    // eslint-disable-next-line
  }, [calculationId, isOpen]);

  useEffect(() => {
    if (selectedEuriborId && calculationId) {
      RestHttpClient.getEuriborDates(selectedEuriborId).then((res) => {
        setEuriborDates(res.data);
      });
      RestHttpClient.getEuriborRate(calculationId, selectedEuriborId).then((res) => {
        setValue('euriborRate', res.data);
      });
    }
    // eslint-disable-next-line
  }, [selectedEuriborId]);

  const onSendInsuranceDocs = async (
    subject: string,
    text: string,
    sendAlsoToSalesman: boolean
  ) => {
    if (!singleInsuranceId || !checkInsurancePrintable(insuranceForm)) {
      return;
    }
    const req = new SendInsuranceDocumentsRequest({
      insuranceCalculationId: singleInsuranceId ?? 0,
      offerSupplement: insuranceForm.getValues().offerSupplement ?? '',
      previousInsuranceInfoRequired: insuranceForm.getValues().previousInsuranceInfoRequired,
      previousInsuranceCompanyName: insuranceForm.getValues().previousInsuranceCompanyName ?? '',
      previousTerminateContract: insuranceForm.getValues().previousTerminateContract ?? false,
      previousContractNumber: insuranceForm.getValues().previousContractNumber ?? '',
      previousLicensePlate: insuranceForm.getValues().previousLicensePlate ?? '',
      previousContractTypeId: insuranceForm.getValues().previousContractTypeId ?? undefined,
      waiverOfBonus: insuranceForm.getValues().waiverOfBonus ?? false,
      insuranceDocumentIds: insuranceForm.getValues().insuranceDocuments,
      customerDocumentIds: [],
      subject: subject,
      text: text,
      sendAlsoToSaltesman: sendAlsoToSalesman,
    });
    try {
      await RestHttpClient.sendInsuranceDocuments(req);
    } catch (err) {
      renderValidationErrors(err, setError);
    }
  };

  const onSendDocuments = async (subject: string, text: string, sendAlsoToSalesman: boolean) => {
    const req = new SendFinancingCalculationDocumentsRequest({
      financingCalculationId: calculationId ?? 0,
      expectedDispatchDate: getValues().expectedDispatchDate ?? undefined,
      selectedEuriborId: getValues().selectedEuriborId,
      termsheet: getValues().termsheet ?? undefined,
      leasingContractNotes: getValues().leasingContractNotes ?? undefined,
      offerSupplement: getValues().offerSupplement ?? undefined,
      calculationGross: getValues().calculationGross,
      termsheetCalculationGross: getValues().termsheetCalculationGross,
      turnOverTaxStatementRequested: getValues().turnOverTaxStatementRequested,
      useCoApplicantAccount: getValues().useCoApplicantAccount,
      offerDocumentIds: getValues().offerDocuments,
      termsheetDocumentIds: getValues().termsheetDocuments,
      contractDocumentIds: getValues().contractDocuments,
      miscellaneousDocumentIds: getValues().miscellaneousDocuments,
      customerDocumentIds: getValues().customerDocuments,
      subject: subject,
      text: text,
      sendAlsoToSalesman: sendAlsoToSalesman,
    });
    try {
      await RestHttpClient.sendFinancingCalculationDocuments(req);
      await onSendInsuranceDocs(subject, text, sendAlsoToSalesman);
      pushNotificationHandler.publish(t('deals.edit.sendDocuments.sended'), 'success');
      setIsSendDocumentsOpened(false);
    } catch (err) {
      renderValidationErrors(err, setError);
    }
  };

  const handleOpenSendMail = () => {
    if (getValues().hasCustomerEmail) setIsSendDocumentsOpened(true);
    else pushNotificationHandler.publish(tPrint('sendDocumentWithoutEmail'), 'error');
  };

  const handleOpenSignDocuments = async (event: FormEvent) => {
    event.preventDefault();
    const signInDocs: ISignFinancingCalculationDocumentsResponse[] = [];
    if (singleInsuranceId && checkInsurancePrintable(insuranceForm)) {
      const valid = await insuranceForm.trigger();
      if (!valid) {
        setTabErrors([1]);
        return;
      }
      try {
        const req = new SignInsuranceDocumentsRequest({
          insuranceCalculationId: singleInsuranceId ?? 0,
          offerSupplement: insuranceForm.getValues().offerSupplement ?? '',
          previousInsuranceInfoRequired: insuranceForm.getValues().previousInsuranceInfoRequired,
          previousInsuranceCompanyName:
            insuranceForm.getValues().previousInsuranceCompanyName ?? '',
          previousTerminateContract: insuranceForm.getValues().previousTerminateContract ?? false,
          previousContractNumber: insuranceForm.getValues().previousContractNumber ?? '',
          previousLicensePlate: insuranceForm.getValues().previousLicensePlate ?? '',
          previousContractTypeId: insuranceForm.getValues().previousContractTypeId ?? undefined,
          waiverOfBonus: insuranceForm.getValues().waiverOfBonus ?? false,
          insuranceDocumentIds: insuranceForm.getValues().insuranceDocuments,
          customerDocumentIds: insuranceForm.getValues().customerDocuments,
        });

        const { data } = await RestHttpClient.signInsuranceDocuments(req);
        signInDocs.push(...data);
      } catch (e) {
        renderValidationErrors(e, insuranceForm.setError);
      }
    }

    await handleSubmit(
      async (values) => {
        try {
          setLoading(true);
          const req = new SignFinancingCalculationDocumentsRequest({
            financingCalculationId: calculationId ?? 0,
            expectedDispatchDate: values.expectedDispatchDate ?? undefined,
            selectedEuriborId: values.selectedEuriborId,
            termsheet: values.termsheet ?? undefined,
            leasingContractNotes: values.leasingContractNotes ?? undefined,
            offerSupplement: values.offerSupplement ?? undefined,
            calculationGross: values.calculationGross,
            termsheetCalculationGross: values.termsheetCalculationGross,
            turnOverTaxStatementRequested: values.turnOverTaxStatementRequested,
            useCoApplicantAccount: values.useCoApplicantAccount,
            offerDocumentIds: values.offerDocuments,
            termsheetDocumentIds: values.termsheetDocuments,
            contractDocumentIds: values.contractDocuments,
            miscellaneousDocumentIds: values.miscellaneousDocuments,
            customerDocumentIds: values.customerDocuments,
          });
          await RestHttpClient.signFinancingCalculationDocuments(req).then((data) => {
            setSignFinancingCalculationDocuments([...signInDocs, ...data.data]);
            setIsSignDocumentsOpened(true);
          });
        } catch (err) {
          renderValidationErrors(err, setError);
        } finally {
          setLoading(false);
        }
      },
      (err) => {
        console.error(err);
        setTabErrors((prev) => [...prev, 1]);
      }
    )(event);
  };

  const handleSignDocumentsClosed = (isCancel: boolean) => {
    setIsSignDocumentsOpened(false);
    if (!isCancel) setIsOpen(false);
  };

  const renderContent = () => {
    if (singleInsuranceId) {
      return (
        <>
          <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
            <Tab
              label={tPrint('financeCategory')}
              {...a11yProps(0)}
              sx={{ color: tabErrors.includes(0) ? 'red !important' : undefined }}
            />
            <Tab
              label={tPrint('insuranceCategory')}
              {...a11yProps(1)}
              sx={{ color: tabErrors.includes(1) ? 'red !important' : undefined }}
            />
          </Tabs>
          <TabPanel index={0} value={tabValue} renderAll>
            <FinancePrintForm
              control={control}
              onSubmit={onHandleSubmit}
              loading={loading}
              watch={watch}
              offerDocuments={offerDocuments}
              termsheetDocuments={termsheetDocuments}
              contractDocuments={contractDocuments}
              miscellaneousDocuments={miscellaneousDocuments}
              customerDocuments={customerDocuments}
              euriborDates={euriborDates}
            />
          </TabPanel>
          <TabPanel index={1} value={tabValue}>
            <InsurancePrintForm
              control={insuranceForm.control}
              insuranceDocuments={insuranceDocuments}
              onSubmit={onHandleSubmit}
              loading={loading}
              previousInsurers={previousInsurers}
              contractTypes={contractTypes}
            />
          </TabPanel>
        </>
      );
    }
    return (
      <FinancePrintForm
        control={control}
        onSubmit={onHandleSubmit}
        loading={loading}
        watch={watch}
        offerDocuments={offerDocuments}
        termsheetDocuments={termsheetDocuments}
        contractDocuments={contractDocuments}
        miscellaneousDocuments={miscellaneousDocuments}
        customerDocuments={customerDocuments}
        euriborDates={euriborDates}
      />
    );
  };

  return (
    <EnhancedDialog maxWidth={false} isDialogOpened={isOpen} setIsDialogOpened={setIsOpen}>
      <DialogTitle>
        {loading && <LinearProgress />}
        {tPrint('title')}
      </DialogTitle>

      <DialogContent dividers>
        <Typography sx={{ mb: 2 }} variant="subtitle1">
          {tPrint('text')}
        </Typography>
        {renderContent()}
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button variant="outlined" color="secondary" onClick={() => setIsOpen(false)}>
            {t('cancel')}
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            color="primary"
            disabled={loading}
            type="submit"
            form="finance-print-dialog"
          >
            {tPrint('print')}
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleOpenSendMail}
            form="finance-print-dialog"
          >
            {tPrint('sendMail')}
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleOpenSignDocuments}
            form="finance-print-dialog"
          >
            {tPrint('check')}
          </Button>
        </Box>
      </DialogActions>

      <SendDocumentsDialog
        isOpen={isSendDocumentsOpened}
        setOpen={setIsSendDocumentsOpened}
        onSend={onSendDocuments}
      />

      <SignDocumentsDialog
        signDocuments={signFinancingCalculationDocuments}
        onClose={handleSignDocumentsClosed}
        setOpen={setIsSignDocumentsOpened}
        isOpen={isSignDocumentsOpened}
      />
    </EnhancedDialog>
  );
}

declare global {
  interface Number {
    toFixed(n: number): string;
  }
}

// eslint-disable-next-line no-extend-native
Number.prototype.toFixed = function (fractionDigits) {
  return this.toLocaleString('en-EN', {
    useGrouping: false,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
};

export {};

import { Box, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormNumberInput } from '../../../../component/form/FormNumberInput';
import { FormPriceInput } from '../../../../component/form/FormPriceInput';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { ModelFormValues } from './ModelForm';
import { FormCheckbox } from '../../../../component/form/FormCheckbox';

interface ModelConfigurationVehicleDetailsProps {
  control: any;
  pricesIncludingVAT: boolean;
  setValue: UseFormSetValue<ModelFormValues>;
  getValues: UseFormGetValues<ModelFormValues>;
}

export function ModelConfigurationPriceDetails({
  control,
  pricesIncludingVAT,
  setValue,
  getValues,
}: ModelConfigurationVehicleDetailsProps) {
  const { t } = useTranslation();

  function recalculateTotal() {
    const { lpSaTotal, discountInEuro } = getValues();
    setValue('totalPrice', lpSaTotal - (discountInEuro || 0));
  }

  function recalculateDiscountPercent() {
    const { lpSaTotal, discountInEuro } = getValues();

    if (lpSaTotal && discountInEuro) {
      setValue('discountInPercentages', (discountInEuro / lpSaTotal) * 100);
    } else {
      setValue('discountInPercentages', 0);
    }
  }

  function ChangeListPrice(value: number) {
    setValue('listPrice', value);
    const sp = getValues().specialEquipment || 0;
    setValue('lpSaTotal', sp + value);
    recalculateDiscountPercent();
    recalculateTotal();
  }

  function ChangeSpecialEquipment(value: number) {
    setValue('specialEquipment', value);
    const listPrice: number = getValues('listPrice') || 0;

    setValue('lpSaTotal', listPrice + value);
    recalculateDiscountPercent();
    recalculateTotal();
  }

  function ChangeDiscountPercentage(value: number) {
    if (isNaN(value)) {
      setValue('discountInPercentages', 0);
      setValue('discountInEuro', 0);
    } else {
      setValue('discountInPercentages', value);
      const lpSaTotal = getValues().lpSaTotal;
      const discountEuro = (lpSaTotal / 100) * value;
      setValue('discountInEuro', discountEuro);
    }
    recalculateTotal();
  }

  function ChangeDiscountEuro(value: number) {
    const lpSaTotal = getValues().lpSaTotal;

    if (lpSaTotal && value) {
      setValue('discountInPercentages', (value / lpSaTotal) * 100);
    } else {
      setValue('discountInPercentages', 0);
    }
    recalculateTotal();
  }

  function ChangeLpSaTotal(value: number) {
    const { listPrice } = getValues();
    if (listPrice === 0 || isNaN(listPrice)) {
      setValue('listPrice', value);
      setValue('specialEquipment', 0);
    } else {
      if (value < listPrice) {
        setValue('lpSaTotal', value);
        setValue('listPrice', value);
        setValue('specialEquipment', 0);
      } else {
        const newSpecial = value - listPrice;
        setValue('specialEquipment', newSpecial);
        setValue('lpSaTotal', value);
      }
    }
    recalculateDiscountPercent();
    recalculateTotal();
  }

  function ChangeTotal(value: number) {
    const { lpSaTotal } = getValues();
    if (value > lpSaTotal) {
      setValue('totalPrice', lpSaTotal);
      setValue('discountInEuro', 0);
      ChangeDiscountEuro(0);
    } else {
      const newDiscount = lpSaTotal - value;
      setValue('discountInEuro', newDiscount);
      ChangeDiscountEuro(newDiscount);
    }
    recalculateDiscountPercent();
  }

  return (
    <Box sx={{ width: 1 / 3, p: 1, m: 1 }}>
      <h1>{t('deals.new.modelConfiguration.body.priceDetails.priceDetails')}</h1>
      <FormPriceInput
        control={control}
        name="listPrice"
        label={t('deals.new.modelConfiguration.body.priceDetails.listPrice')}
        required={true}
        sx={{ width: '100%', margin: 1, paddingRight: 2 }}
        shrink
        pricesIncludingVAT={pricesIncludingVAT}
        onBlur={(e) => ChangeListPrice(Number(e.target.value))}
      />
      <br />
      <FormPriceInput
        control={control}
        name="specialEquipment"
        label={t('deals.new.modelConfiguration.body.priceDetails.specialEquipment')}
        sx={{ width: '100%', margin: 1, paddingRight: 2 }}
        shrink
        pricesIncludingVAT={pricesIncludingVAT}
        onBlur={(e) => ChangeSpecialEquipment(Number(e.target.value))}
      />
      <br />
      <FormPriceInput
        control={control}
        name="lpSaTotal"
        label={t('deals.new.modelConfiguration.body.priceDetails.lpSaTotal')}
        sx={{ width: '100%', margin: 1, paddingRight: 2 }}
        shrink
        pricesIncludingVAT={pricesIncludingVAT}
        onBlur={(e) => ChangeLpSaTotal(Number(e.target.value))}
      />
      <br />

      <Grid container sx={{ mb: 2 }} spacing={2}>
        <Grid item xs={3}>
          <FormNumberInput
            control={control}
            name="discountInPercentages"
            label={t('deals.new.modelConfiguration.body.priceDetails.discountInPercentages')}
            shrink
            endAdornment={<span>%</span>}
            fixedDecimalScale
            onBlur={(e) => {
              ChangeDiscountPercentage(Number(e.target.value));
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <FormPriceInput
            control={control}
            name="discountInEuro"
            label={t('deals.new.modelConfiguration.body.priceDetails.discountInEuro')}
            shrink
            onBlur={(e) => ChangeDiscountEuro(Number(e.target.value))}
            pricesIncludingVAT={pricesIncludingVAT}
          />
        </Grid>
      </Grid>
      <FormPriceInput
        control={control}
        name="totalPrice"
        label={t('deals.new.modelConfiguration.body.priceDetails.totalPrice')}
        required={true}
        sx={{ width: '100%', margin: 1, paddingRight: 2 }}
        shrink
        pricesIncludingVAT={pricesIncludingVAT}
        onBlur={(e) => ChangeTotal(Number(e.target.value))}
      />
      <br />
      <FormCheckbox
        control={control}
        variant="switch"
        name="pricesIncludingVAT"
        label={t('deals.new.modelConfiguration.body.generalInfo.pricesIncludingVAT')}
      />
    </Box>
  );
}

export interface IOptionItem {
  value: number | string;
  label: string;
}

export class OptionItem {
  value!: number | string;
  label!: string;

  constructor(data?: IOptionItem) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}
import { DealHeader } from './DealHeader';
import { useState } from 'react';
import { DealHandoverDialog } from './DealHandoverDialog';
import { useNotifications } from '../../common/global/useNotifications';
import { NotificationLocation } from '../../generated/ApiClient';
import { DealSimpleSearchList } from './search/DealSimpleSearchList';
import { DealAdvancedSearchList } from './search/DealAdvancedSearchList';

export function Deals() {
  const [basicSearch, setBasicSearch] = useState(true);
  const [isHandoverOpen, setIsHandoverOpen] = useState(false);

  useNotifications(NotificationLocation.Deals);

  return (
    <div className="deals">
      <DealHeader setHandoverOpen={setIsHandoverOpen} />
      {basicSearch ? (
        <DealSimpleSearchList toggleBasicSearch={setBasicSearch} />
      ) : (
        <DealAdvancedSearchList toggleBasicSearch={setBasicSearch} />
      )}
      <DealHandoverDialog isOpen={isHandoverOpen} setIsOpen={setIsHandoverOpen} />
    </div>
  );
}

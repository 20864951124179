import Box from '@mui/material/Box';
import { Alert, Button, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { t } from 'i18next';
import { CreditCheckFormValues } from './CreditCheckSchema';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { schema } from './CreditCheckSchema';
import { FormTextField } from '../../../../component/form/FormTextField';
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { FormDatePicker } from '../../../../component/form/FormDatePicker';
import RestHttpClient, { renderValidationErrors } from '../../../../common/RestHttpClient';
import {
  CommissionDealer,
  OptionItem,
  SendToCreditCheckRequest,
} from '../../../../generated/ApiClient';
import { FormDropdown } from '../../../../component/form/FormDropdown';
import { pushNotificationHandler } from '../../../../common/PushNotification';
import { EnhancedDialog } from '../../../../component/EnhancedDialog';
import { getDefaultCommissionDealer } from './commissionDealerUtil';

interface Props {
  isCreditCheckOpened: boolean;
  onClose?: () => void;
  financialCalculationId: number;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreditCheckDialog = ({
  isCreditCheckOpened,
  onClose,
  financialCalculationId,
  isOpen,
  setOpen,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState<OptionItem[]>([]);
  const [euriborDates, setEuriborDates] = useState<OptionItem[]>([]);
  const [handoverDealers, setHandoverDealers] = useState<OptionItem[]>([]);
  const [commissionDealers, setCommissionDealers] = useState<CommissionDealer[]>([]);

  const handleClose = () => {
    if (onClose != null) onClose();
  };

  useEffect(() => {
    reset();
    if (isCreditCheckOpened) {
      fetchDefault().then((data) => {
        reset(data);
        fetchOptions();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreditCheckOpened, financialCalculationId]);

  useEffect(() => {
    setValue('commissionDealerId', getDefaultCommissionDealer(commissionDealers));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commissionDealers]);

  const fetchDefault = async (): Promise<any> => {
    try {
      setLoading(true);
      const { data } = await RestHttpClient.getCreditCheck(financialCalculationId);
      return data;
    } catch (err) {
      handleClose();
      // throw err;
    } finally {
      setLoading(false);
    }
  };

  const fetchOptions = () => {
    const { dealId, providerId, selectedEuriborDateId } = getValues();
    RestHttpClient.getProviders(dealId, providerId).then((res) => setProviders(res.data));
    RestHttpClient.getEuriborDates(selectedEuriborDateId).then((res) => setEuriborDates(res.data));
    RestHttpClient.getHandoverDealers().then((res) => setHandoverDealers(res.data));
    RestHttpClient.getCommissionDealer(dealId).then((res) => {
      setCommissionDealers(res.data);
    });
  };

  const { control, reset, getValues, handleSubmit, setError, setValue } =
    useForm<CreditCheckFormValues>({
      resolver: zodResolver(schema),
    });

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(
      (values) => {
        if (commissionDealers.length > 0 && !values.commissionDealerId) {
          setError('commissionDealerId', {
            message: t('deals.edit.creditCheck.commissionDealerIsRequired'),
          });
          return;
        }

        setLoading(true);
        RestHttpClient.sendToCreditCheck(
          new SendToCreditCheckRequest({
            financialCalculationId: values.financialCalculationId,
            termsheet: values.termsheet ?? undefined,
            expectedDispatchDate: values.expectedDispatchDate ?? undefined,
            providerId: values.providerId,
            selectedEuriborDateId: values.selectedEuriborDateId,
            handoverDealerId: values.handoverDealerId ?? 0,
            commissionDealerId: values.commissionDealerId ?? undefined,
          })
        )
          .then(() => {
            pushNotificationHandler.publish(
              t('deals.edit.creditCheck.forCreditCheckSended'),
              'success'
            );
            handleClose();
          })
          .catch((err) => {
            renderValidationErrors(err, setError);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      (err) => {
        console.error(err);
      }
    )(event);
  };

  return (
    <EnhancedDialog maxWidth="md" isDialogOpened={isOpen} setIsDialogOpened={setOpen}>
      <DialogTitle>{t('deals.new.financesConfiguration.list.forCreditCheck')}</DialogTitle>

      <DialogContent dividers>
        <form id="credit-check-form" onSubmit={onFormSubmit} noValidate>
          <Alert severity="warning">{t('deals.edit.creditCheck.remark')}</Alert>
          <Grid container spacing={4} sx={{ paddingY: 3 }}>
            <Grid item xs={12}>
              <FormTextField
                control={control}
                name="termsheet"
                label={t('deals.edit.creditCheck.termsheet')}
                disabled={loading}
                shrink
              />
            </Grid>
            <Grid item xs={6}>
              <FormDatePicker
                control={control}
                name="expectedDispatchDate"
                label={t('deals.edit.creditCheck.expectedDispatchDate')}
                disabled={loading}
                shrink
              />
            </Grid>
            <Grid item xs={6}>
              <FormDropdown
                control={control}
                name="providerId"
                label={t('deals.edit.creditCheck.provider')}
                disabled={loading}
                options={providers}
                shrink
              />
            </Grid>
            <Grid item xs={6}>
              <FormDropdown
                control={control}
                name="selectedEuriborDateId"
                label={t('deals.edit.creditCheck.euriborDate')}
                disabled={loading}
                options={euriborDates}
                shrink
              />
            </Grid>
            {getValues().handoverDealerIdEnabled && (
              <Grid item xs={6}>
                <FormDropdown
                  control={control}
                  name="handoverDealerId"
                  label={t('deals.edit.creditCheck.handoverDealer')}
                  disabled={loading}
                  options={handoverDealers}
                  shrink
                  emptyText="-"
                />
              </Grid>
            )}
            {commissionDealers.length > 1 && (
              <Grid item xs={6}>
                <FormDropdown
                  control={control}
                  name="commissionDealerId"
                  label={t('deals.edit.creditCheck.commissionDealer')}
                  disabled={loading}
                  options={commissionDealers}
                  shrink
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Box sx={{ textAlign: 'right' }}>
          <Button
            onClick={handleClose}
            sx={{ border: 1, borderColor: 'black', color: 'black', marginRight: '4px' }}
          >
            {t('back')}
          </Button>
          <Button type="submit" form="credit-check-form" variant="contained" disabled={loading}>
            {t('deals.new.financesConfiguration.list.forCreditCheck')}
          </Button>
        </Box>
      </DialogActions>
    </EnhancedDialog>
  );
};

import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from '../common/Item';
import RestHttpClient from '../../../../common/RestHttpClient';
import { ModelImage } from '../../ModelImage';

interface ModelHeaderConfigurationProps {
  modelId: number;
}

type ItemValue = {
  name: string;
  value: string;
};

export function ModelConfigurationHeader({ modelId }: ModelHeaderConfigurationProps) {
  const { t } = useTranslation();
  const [items, setItems] = useState<ItemValue[]>([]);

  async function generateItems(): Promise<ItemValue[]> {
    let itemArray = new Array<ItemValue>();
    // eslint-disable-next-line eqeqeq
    if (modelId != undefined) {
      let res = await RestHttpClient.getModelData(modelId);
      let modelData = res.data.modelData;
      itemArray = [
        {
          name: t('deals.new.modelConfiguration.header.brand'),
          value: modelData.tradeMark,
        },
        {
          name: t('deals.new.modelConfiguration.header.vehicleType'),
          value: modelData.vehicleType,
        },
        {
          name: t('deals.new.modelConfiguration.header.modelGroup'),
          value: modelData.modelCategory,
        },
        {
          name: t('deals.new.modelConfiguration.header.motorType'),
          value: modelData.engineType,
        },
        {
          name: t('deals.new.modelConfiguration.header.model'),
          value: modelData.model,
        },
      ];
      return itemArray;
    } else {
      return itemArray;
    }
  }

  useEffect(() => {
    generateItems().then((res) => {
      setItems(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);

  return (
    <div style={{ width: '100%', display: 'flex' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          p: 1,
          m: 1,
          width: '85%',
        }}
      >
        {items &&
          items.map((item) => {
            return <Item name={item.name} value={item.value} key={item.name} />;
          })}
      </Box>
      <Box sx={{ width: '15%', p: 1, m: 1 }}>
        <ModelImage modelId={modelId} />
      </Box>
    </div>
  );
}

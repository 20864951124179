import { Grid } from '@mui/material';
import { ModelConfigurationHeader } from '../model/ModelConfigurationHeader';
import { useContext } from 'react';
import { StateMachineContext } from '../state/StateMachineContextProvider';
import { useActor } from '@xstate/react';
import { DealInfo } from '../../common/DealInfo';
import { FinancesContextValues } from '../state/orderStateMachine';
import { ModelFormValues } from '../model/ModelForm';
import { netToGross } from '../../../../common/util/VatUtil';

export function FinancesConfiguration() {
  const stateMachine = useContext(StateMachineContext);
  const [state] = useActor(stateMachine);

  return (
    <Grid container p="24px 0" columnSpacing="24px">
      <Grid container item lg={12} gap="24px">
        {state.context.model && <ModelConfigurationHeader modelId={state.context.model?.modelId} />}
        {state.context.finances && state.context.model && (
          <NewDealInfo finances={state.context.finances} model={state.context.model} />
        )}
      </Grid>
    </Grid>
  );
}

function NewDealInfo({
  finances,
  model,
}: {
  finances: FinancesContextValues;
  model: ModelFormValues;
}) {
  const toDisplayText = (price: number) => {
    return model.pricesIncludingVAT ? price : netToGross(price);
  };

  const values = {
    listPrice: toDisplayText(model.listPrice),
    purchasePrice: toDisplayText(finances.purchasePrice),
    extrasPrice: toDisplayText(model.specialEquipment),
    coFinancedExtras: 0,
    listPriceIncludingExtras: toDisplayText(model.lpSaTotal),
    calculationBase: finances.calculationBasisInclVAT,
    discountCategory: model.discountCategory,
    businessType: model.businessType,
  };

  return <DealInfo {...values} />;
}

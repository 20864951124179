import * as React from 'react';
import { ReactNode } from 'react';
import { Box, Collapse, Fab, Fade } from '@mui/material';
import { Icons } from './icons/Icons';

interface Props {
  left: ReactNode;
  fadeLeft?: boolean;
  right?: ReactNode;
  fadeRight?: boolean;
  collapsedSize?: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SplitPanel({
  left,
  fadeLeft = true,
  right,
  fadeRight = true,
  collapsedSize = 100,
  open,
  setOpen,
}: Props) {
  const [transitioning, setTransitioning] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [width, setWidth] = React.useState(collapsedSize);

  const container = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setWidth(container.current?.offsetWidth ?? collapsedSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCollapseClick = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <>
      <Collapse
        in={open}
        collapsedSize={collapsedSize}
        orientation="horizontal"
        className="split-panel"
        onEnter={() => setTransitioning(true)}
        onEntered={(node) => {
          setWidth((node.children[0] as any).offsetWidth);
          setTransitioning(false);
        }}
        onExit={() => setTransitioning(true)}
        onExited={(node) => {
          setWidth((node.children[0] as any).offsetWidth);
          setTransitioning(false);
        }}
      >
        <div className="left" ref={container}>
          <Fade in={fadeLeft ? open : true} className="fade-left">
            <div>{left}</div>
          </Fade>
          <Box
            className="button-container"
            sx={{
              alignSelf: open ? 'flex-end' : 'flex-start',
            }}
          >
            <Fade in={!transitioning}>
              <Fab size="small" color="info" onClick={handleCollapseClick}>
                {open ? Icons.chevronLeftWhite() : Icons.chevronRightWhite()}
              </Fab>
            </Fade>
          </Box>
        </div>
      </Collapse>
      {right && (
        <Fade in={fadeRight ? !open : true}>
          <div className="right">{right}</div>
        </Fade>
      )}
    </>
  );
}

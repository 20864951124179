import { CommercialCustomerFormValues } from '../../../customers/data/CommercialCustomerFormValues';
import React, { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import { CustomerSelection } from '../../new/customer/CustomerSelection';
import { EditApplicantForm } from './EditApplicantForm';
import {
  CoApplicantCustomer,
  CoApplicantOverview,
  CommercialCustomerView,
  CustomerType,
  EditCommercialDealCustomerRequest,
  EditPrivateDealCustomerRequest,
  NotificationLocation,
  PrivateCustomerView,
} from '../../../../generated/ApiClient';
import RestHttpClient from '../../../../common/RestHttpClient';
import { PrivateCustomerFormValues } from '../../../customers/data/PrivateCustomerFormValues';
import { Box, Button, Dialog, LinearProgress } from '@mui/material';
import { Icons } from '../../../../component/icons/Icons';
import { confirmHandler } from '../../../../common/ConfirmModal';
import { useTranslation } from 'react-i18next';
import { pushNotificationHandler } from '../../../../common/PushNotification';
import { usePermissions } from '../../../../common/auth/PermissionsContext';
import { CoApplicantValidationErrors } from '../DealDetail';
import { useNotifications } from '../../../../common/global/useNotifications';

interface Props {
  dealId: number;
  dealCustomerId?: number;
  dealType: number;
  readonlyOverwrite: boolean;
  validationErrors?: CoApplicantValidationErrors;
  setUnsavedChanges?: Dispatch<SetStateAction<boolean>>;
  reload?: () => void;
  submitRef: MutableRefObject<null>;
}

export function EditDealApplicant({
  dealId,
  dealType,
  readonlyOverwrite,
  dealCustomerId,
  validationErrors,
  reload,
  setUnsavedChanges,
  submitRef,
}: Props) {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit = hasPermission('Customers', 'Edit');
  const [customerId, setCustomerId] = useState<number>();
  const [customerType, setCustomerType] = useState<CustomerType>(
    dealType === 1 ? CustomerType.Private : CustomerType.Commercial
  );
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [applicants, setApplicants] = useState<CoApplicantOverview[]>([]);
  const [hideForm, setHideForm] = useState<boolean>(true);

  useNotifications(NotificationLocation.CoApplicants);

  useEffect(() => {
    fetchCoApplicants();
    //eslint-disable-next-line
  }, []);

  const fetchCoApplicants = () => {
    setLoading(true);
    RestHttpClient.getDealCoApplicantOverview(dealId)
      .then((res) => {
        setApplicants(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleCustomerSelect = async (id: string) => {
    const idNum = Number(id);
    if (!Number.isNaN(idNum)) {
      await setCustomerTypeById(idNum);
      setCustomerId(idNum);
      setHideForm(false);
      setOpen(false);
      if (setUnsavedChanges) setUnsavedChanges(false);
    }
  };

  const setCustomerTypeById = async (id: number) => {
    setCustomerId(undefined);
    const { data } = await RestHttpClient.getCustomerType(Number(id));
    setCustomerType(data);
  };

  const handleCoApplicantSelect = async (id: number) => {
    await setCustomerTypeById(id);
    setHideForm(false);
    if (setUnsavedChanges) setUnsavedChanges(false);
    setCustomerId(id);
  };

  const handleAddMore = () => {
    setOpen(true);
    setCustomerId(undefined);
  };

  const handleTriggerCreate = () => {
    setCustomerId(undefined);
    setHideForm(false);
    setOpen(false);
  };

  const handleDelete = (id: number) => {
    setLoading(true);
    setCustomerId(undefined);
    RestHttpClient.deleteApplicant(dealId, id)
      .then(() => {
        fetchCoApplicants();
        if (reload) reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePrivateSubmit = (
    values: PrivateCustomerFormValues,
    changesMade: boolean,
    hasRelevantChanges?: boolean
  ) => {
    let save = (_: boolean) => {};
    if (customerId && applicants.findIndex((a) => a.customerId === customerId) !== -1) {
      save = (applyChangesToMainCustomer: boolean) => {
        const req = new EditPrivateDealCustomerRequest();
        req.init({
          ...values,
          customerId,
          dealId,
          applyChangesToMainCustomer,
          hasRelevantChanges,
        });
        RestHttpClient.editPrivateDealCustomer(req).then(() => {
          fetchCoApplicants();
          if (reload) reload();
          pushNotificationHandler.publish(t('deals.new.applicant.saved'), 'success');
        });
      };
    } else {
      save = (applyChangesToMainCustomer: boolean) => {
        const customerView = new PrivateCustomerView();
        customerView.init(values);
        RestHttpClient.addCoApplicant(
          dealId,
          new CoApplicantCustomer({
            customerId: customerId,
            applyChangesToMainCustomer: applyChangesToMainCustomer,
            privateCustomer: customerView,
          })
        ).then(() => {
          fetchCoApplicants();
          setHideForm(true);
          if (reload) reload();
          pushNotificationHandler.publish(t('deals.new.applicant.saved'), 'success');
        });
      };
    }

    if (
      changesMade &&
      customerId &&
      applicants.findIndex((a) => a.customerId === customerId) !== -1
    ) {
      confirmHandler.confirm(
        t('customers.confirmSaveMasterRecordTitle'),
        () => {
          save(true);
        },
        () => {
          save(false);
        },
        t('customers.confirmSaveMasterRecord')
      );
    } else {
      save(false);
    }
  };

  const handleCommercialSubmit = (
    values: CommercialCustomerFormValues,
    changesMade: boolean,
    hasRelevantChanges?: boolean
  ) => {
    let save = (_: boolean) => {};
    if (customerId && applicants.findIndex((a) => a.customerId === customerId) !== -1) {
      save = (applyChangesToMainCustomer: boolean) => {
        const req = new EditCommercialDealCustomerRequest();
        req.init({
          ...values,
          customerId,
          dealId,
          applyChangesToMainCustomer,
          hasRelevantChanges,
        });
        RestHttpClient.editCommercialDealCustomer(req).then((_) => {
          fetchCoApplicants();
          if (reload) reload();
          pushNotificationHandler.publish(t('deals.new.applicant.saved'), 'success');
        });
      };
    } else {
      save = (applyChangesToMainCustomer: boolean) => {
        const customerView = new CommercialCustomerView();
        customerView.init(values);
        RestHttpClient.addCoApplicant(
          dealId,
          new CoApplicantCustomer({
            customerId,
            applyChangesToMainCustomer: applyChangesToMainCustomer,
            commercialCustomer: customerView,
          })
        ).then(() => {
          fetchCoApplicants();
          setHideForm(true);
          if (reload) reload();
          pushNotificationHandler.publish(t('deals.new.applicant.saved'), 'success');
        });
      };
    }
    if (
      changesMade &&
      customerId &&
      applicants.findIndex((a) => a.customerId === customerId) !== -1
    ) {
      confirmHandler.confirm(
        t('customers.confirmSaveMasterRecordTitle'),
        () => {
          save(true);
        },
        () => {
          save(false);
        },
        t('customers.confirmSaveMasterRecord')
      );
    } else {
      save(false);
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Dialog open={open} maxWidth="lg" onClose={() => setOpen(false)}>
          <Box sx={{ textAlign: 'right' }}>
            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              sx={{ p: 0, height: '32px', width: '32px', minWidth: 'unset' }}
              aria-label="close"
            >
              {Icons.clear()}
            </Button>
          </Box>
          <Box sx={{ paddingX: 2 }}>
            <CustomerSelection
              onTriggerCreate={handleTriggerCreate}
              onRowClick={handleCustomerSelect}
              excludeCustomerIds={
                dealCustomerId
                  ? [dealCustomerId, ...applicants.map((value) => value.customerId)]
                  : undefined
              }
              includePotentialBuyer
            />
          </Box>
        </Dialog>
        <EditApplicantForm
          dealId={dealId}
          customerId={customerId}
          customerType={customerType}
          setCustomerType={setCustomerType}
          onAddMore={handleAddMore}
          onSelectCustomer={handleCoApplicantSelect}
          onCommercialSubmit={handleCommercialSubmit}
          onPrivateSubmit={handlePrivateSubmit}
          onDelete={handleDelete}
          applicants={applicants}
          validationErrors={validationErrors}
          hideForm={hideForm}
          readonly={readonlyOverwrite || !canEdit}
          setUnsavedChanges={setUnsavedChanges}
          submitRef={submitRef}
        />
      </Box>
    </>
  );
}

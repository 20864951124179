import { Dispatch, FormEvent, SetStateAction } from 'react';
import { EnhancedDialog } from '../../component/EnhancedDialog';
import { useTranslation } from 'react-i18next';
import { Box, Button, DialogContent, DialogTitle, Typography } from '@mui/material';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormTextField } from '../../component/form/FormTextField';
import { pushNotificationHandler } from '../../common/PushNotification';
import RestHttpClient from '../../common/RestHttpClient';
import { DealTransferResponse } from '../../generated/ApiClient';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const handOverSchema = z.object({
  offerNumber: z.string(),
});

type SchemaType = z.infer<typeof handOverSchema>;

export function DealHandoverDialog({ isOpen, setIsOpen }: Props) {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<SchemaType>({
    resolver: zodResolver(handOverSchema),
  });

  const onHandleSubmit = (event: FormEvent) => {
    handleSubmit((values) => {
      RestHttpClient.transferDeal(values.offerNumber).then((res) => {
        if (res.data.isSuccess) {
          pushNotificationHandler.publish(t('deals.acquireSuccess'), 'success');
          return;
        } else {
          ShowDetailedPoseidonMessage(res.data);
        }
      });
      setIsOpen(false);
    }, console.error)(event);
  };

  function ShowDetailedPoseidonMessage(result: DealTransferResponse) {
    console.log({ result });
    if (result.isDealerNotFound) {
      pushNotificationHandler.publish(t('deals.acquireErrorDealerNotFound'), 'error');
      return;
    }
    if (result.isErrorAtPoseidon) {
      pushNotificationHandler.publish(t('deals.acquireErrorPoseidon'), 'error');
      return;
    }
    if (result.isNotAllowed) {
      pushNotificationHandler.publish(t('deals.acquireErrorPermission'), 'error');
      return;
    }
    if (result.isNotFound) {
      pushNotificationHandler.publish(t('deals.acquireErrorOfferNotFound'), 'error');
      return;
    }
    if (result.isNotInDekato) {
      pushNotificationHandler.publish(t('deals.acquireErrorDealNotFound'), 'error');
      return;
    }
  }

  return (
    <EnhancedDialog isDialogOpened={isOpen} setIsDialogOpened={setIsOpen}>
      <DialogTitle>
        {t('deals.acquire')}
      </DialogTitle>
      <DialogContent>
        <form id="handover-dialog" onSubmit={onHandleSubmit} style={{ maxWidth: 630 }}>
          <Box sx={{ mb: 1 }}>
            <Typography sx={{ mb: 2 }} variant="subtitle1">
              {t('deals.acquireText')}
            </Typography>
            <Box sx={{ mb: 2, width: '50%' }}>
              <FormTextField
                control={control}
                name="offerNumber"
                color="secondary"
                label={t('deals.offerNumber')}
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button variant="outlined" color="secondary" onClick={() => setIsOpen(false)}>
              {t('cancel')}
            </Button>
            <Button
              sx={{ ml: 1 }}
              variant="contained"
              color="primary"
              type="submit"
              form="handover-dialog"
            >
              {t('confirm')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </EnhancedDialog>
  );
}

import { HeadCell } from '../../../common/table/EnhancedTableHead';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { EnhancedTable } from '../../../common/table/EnhancedTable';
import React, { useEffect, useState } from 'react';
import { DocumentCategory, IDocumentOverview } from '../../../generated/ApiClient';
import RestHttpClient from '../../../common/RestHttpClient';
import { Icons } from '../../../component/icons/Icons';
import LinkIcon from '@mui/icons-material/Link';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { DateTimeFormatter } from '../../../common/formatter/DateTimeFormatter';
import PDFPreviewDialog, { PdfPreview } from '../../../component/PDFPreviewDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { isMobileSafari } from 'react-device-detect';
import { dashboardContentGrid, dashboardSideGrid } from '../Dashboard';

interface DocumentRow {
  id: string;
  name?: string;
  group?: string;
  from?: string;
  to?: string;
  attachmentId?: string;
  external: boolean;
  externalUrl?: string;
  type: JSX.Element;
  icon: JSX.Element;
}

interface Props {
  category: DocumentCategory;
  defaultGroup?: string;
}

export function DashboardDocuments({ category, defaultGroup }: Props) {
  const { t } = useTranslation();

  const [groups, setGroups] = useState<string[]>([]);
  const [selGroup, setSelGroup] = useState<string | undefined>(defaultGroup);
  const [rows, setRows] = useState<DocumentRow[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderSelectors, setOrderSelectors] = useState<string[] | undefined>(undefined);

  const [openDocFile, setOpenDocFile] = useState<PdfPreview | undefined>();

  const mapDocToRow = (doc: IDocumentOverview): DocumentRow => ({
    ...doc,
    from: DateTimeFormatter.format(doc.from, { format: 'dd.MM.yyyy' }),
    to: DateTimeFormatter.format(doc.to, { format: 'dd.MM.yyyy' }),
    external: !doc.attachmentId && Boolean(doc.externalUrl),
    type:
      !doc.attachmentId && Boolean(doc.externalUrl) ? (
        <LinkIcon color="secondary" />
      ) : (
        <InsertDriveFileIcon color="secondary" />
      ),
    // icon: !doc.attachmentId && Boolean(doc.externalUrl) ? Icons.open() : Icons.download(),
    icon:
      !doc.attachmentId && Boolean(doc.externalUrl) ? (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (!doc.externalUrl) return;
            handleOpenClick(doc.externalUrl);
          }}
        >
          {Icons.open()}
        </IconButton>
      ) : (
        <>
          {doc.contentType === 'application/pdf' && doc.attachmentId && (
            <Tooltip title={t('deals.edit.documents.view')}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (!doc.attachmentId) return;
                  handlePreviewClick(doc.attachmentId);
                }}
              >
                <VisibilityIcon opacity={0.4} color="secondary" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={t('deals.edit.documents.download')}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                if (!doc.attachmentId) return;
                handleDownloadClick(doc.attachmentId);
              }}
            >
              {Icons.download()}
            </IconButton>
          </Tooltip>
        </>
      ),
  });

  const fetchGroups = async () => {
    const { data } = await RestHttpClient.getAllowedDocumentGroups(category);
    setGroups(data);
  };

  const fetchDocuments = async (p: number, g?: string, os?: string[]) => {
    setLoading(true);
    try {
      const { data } = await RestHttpClient.getDocuments(category, g, p, 10, os);
      setTotal(data.total);
      if (p === 0) {
        setRows(data.items.map(mapDocToRow));
      } else {
        setRows(rows.concat(data.items.map(mapDocToRow)));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page) {
      fetchDocuments(page, selGroup, orderSelectors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setPage(0);
    setRows([]);
    fetchDocuments(0, selGroup, orderSelectors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selGroup]);

  const headCells: readonly HeadCell<DocumentRow>[] = [
    {
      id: 'type',
      disablePadding: false,
      align: 'justify',
      columnName: 'external',
      allowSorting: true,
      label: '',
    },
    {
      id: 'name',
      disablePadding: false,
      align: 'justify',
      columnName: 'name',
      allowSorting: true,
      label: t('dashboard.documents.name'),
    },
    {
      id: 'group',
      columnName: 'group',
      disablePadding: false,
      allowSorting: true,
      label: t('dashboard.documents.group'),
    },
    {
      id: 'icon',
      align: 'right',
      disablePadding: false,
      label: '',
    },
  ];

  const handleLoadNew = () => {
    setPage(page + 1);
  };

  const onSortRequest = (os: string[]) => {
    setOrderSelectors(os);
    setPage(0);
    fetchDocuments(0, selGroup, os);
  };

  const getButtonProps = (group?: string) => ({
    selected: selGroup === group,
    onClick: () => setSelGroup(group),
    disabled: loading,
  });

  const handleDownloadClick = (attachmentId: string) => {
    RestHttpClient.downloadDocument(attachmentId).then((res) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(res.data);
      link.download = res.fileName ?? 'download';
      link.click();
    });
  };

  const handleOpenClick = (externalUrl: string) => {
    window.open(externalUrl, '_blank');
  };

  const handlePreviewClick = (attachmentId: string) => {
    let win: Window | undefined;
    if (isMobileSafari) {
      win = window.open(undefined, '_blank') ?? undefined;
    }
    RestHttpClient.downloadDocument(attachmentId).then((res) => {
      if (!win) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          setOpenDocFile({ base64: base64data, filename: res.fileName });
        };
        reader.readAsDataURL(res.data);
        return;
      }
      win.location.href = window.webkitURL.createObjectURL(res.data);
    });
  };

  return (
    <Grid container className="dashboard-table">
      <Grid item {...dashboardSideGrid} height="100%">
        <Box className="dashboard-table-sidebar">
          <List>
            <ListItemButton {...getButtonProps(undefined)}>
              <ListItemText primary={t('dashboard.all')} />
            </ListItemButton>

            <ListItem>
              <Typography sx={{ textDecorationLine: 'underline' }} variant="h5">
                {t('dashboard.category')}
              </Typography>
            </ListItem>

            {groups.map((g) => (
              <ListItemButton {...getButtonProps(g)}>
                <ListItemText primary={g} />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Grid>

      <PDFPreviewDialog file={openDocFile} onClose={() => setOpenDocFile(undefined)} />

      <Grid item {...dashboardContentGrid}>
        <EnhancedTable
          rows={rows}
          total={total}
          loading={loading}
          headCells={headCells}
          hideCheckbox={true}
          variant="endless"
          onSortRequest={onSortRequest}
          loadNewPage={handleLoadNew}
        />
      </Grid>
    </Grid>
  );
}

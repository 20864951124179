import { Box, BoxProps, Typography } from '@mui/material';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormDropdown, OptionItem } from '../../../../../component/form/FormDropdown';
import { FinancesDrawerFormValues } from '../RightDrawerSchema';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';

interface Props extends BoxProps {
  control: Control<FinancesDrawerFormValues>;
  onRelevantChange: (name: string, value: unknown) => void;
  loading: boolean;
  fuelServiceProducts: OptionItem[];
  readonly?: boolean;
}

export function FinancesServiceFuel({
  control,
  loading,
  fuelServiceProducts,
  onRelevantChange,
  readonly,
  ...others
}: Props) {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit =
    hasPermission('Services', 'Edit') && hasPermission('FuelService', 'Edit') && !readonly;

  const handleRelevantChangeEvent = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onRelevantChange(event.target.name, event.target.value);
  };

  return (
    <Box
      {...others}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Box sx={{ p: 2, pr: 6 }}>
        <h3>{t('deals.new.financesConfiguration.calculationDrawer.services.fuel')}</h3>
        {fuelServiceProducts.length > 0 || loading ? (
          <FormDropdown
            isRequired={false}
            control={control}
            name="selectedFuelServiceProductId"
            label={t('deals.new.financesConfiguration.calculationDrawer.services.product')}
            options={fuelServiceProducts}
            shrink
            sx={{ m: 1 }}
            disabled={loading || !canEdit}
            emptyText={t(
              'deals.new.financesConfiguration.calculationDrawer.services.noFuelProduct'
            )}
            emptyValue={undefined}
            onChange={handleRelevantChangeEvent}
          />
        ) : (
          <Typography>
            {t('deals.new.financesConfiguration.calculationDrawer.services.noFuelProductAvailable')}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

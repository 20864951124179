import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { CustomerOverview } from '../../../generated/ApiClient';

type CustomerState = {
  customers: CustomerOverview[];
  setCustomers: Dispatch<SetStateAction<CustomerOverview[]>>;

  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  total: number;
  setTotal: Dispatch<SetStateAction<number>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  orderSelectors: string[];
  setOrderSelectors: Dispatch<SetStateAction<string[]>>;
};

export const CustomerContext = createContext<CustomerState>({} as CustomerState);

export function CustomerProvider({ children }: { children: React.ReactNode }) {
  const [customers, setCustomers] = useState<CustomerOverview[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderSelectors, setOrderSelectors] = useState<string[]>(['id|desc']);

  return (
    <CustomerContext.Provider
      value={{
        customers,
        setCustomers,
        page,
        setPage,
        total,
        setTotal,
        loading,
        setLoading,
        orderSelectors,
        setOrderSelectors,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
}

import { Box, Breakpoint, Button, Dialog } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { Icons } from './icons/Icons';

interface EnhancedDialogProps {
  children: React.ReactNode;
  isDialogOpened: boolean;
  setIsDialogOpened: Dispatch<SetStateAction<boolean>>;
  handleCancel?: () => void;
  width?: string;
  maxWidth?: false | Breakpoint | undefined;
}

export function EnhancedDialog(props: EnhancedDialogProps) {
  const { children, isDialogOpened, setIsDialogOpened, handleCancel, maxWidth, width } = props;

  const handleClose = () => {
    if (handleCancel) {
      handleCancel();
    } else {
      setIsDialogOpened(false);
    }
  };

  return (
    <div>
      <Dialog
        open={isDialogOpened}
        onClose={handleClose}
        maxWidth={maxWidth !== undefined ? maxWidth : 'xl'}
        scroll="paper"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          '.MuiDialogTitle-root': {
            pt: 0,
          },
        }}
      >
        <Box sx={{ textAlign: 'right', width }}>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{ p: 0, height: '32px', width: '32px', minWidth: 'unset' }}
            aria-label="close"
          >
            {Icons.clear()}
          </Button>
        </Box>
        {/*<DialogContent dividers>*/}
        {children}
        {/*</DialogContent>*/}
        {/*{actions && <DialogActions>{actions}</DialogActions>}*/}
      </Dialog>
    </div>
  );
}

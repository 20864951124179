import { Document, Page } from 'react-pdf';
import { Box, Button, Dialog } from '@mui/material';
import { Icons } from './icons/Icons';
import { useState } from 'react';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useTranslation } from 'react-i18next';

export interface PdfPreview {
  base64: string;
  filename?: string;
}

interface Props {
  file?: PdfPreview;
  onClose: () => void;
}

const PDFPreviewDialog = ({ file, onClose }: Props) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState<number | undefined>(undefined);

  function onDocumentLoadSuccess({ numPages }: PDFDocumentProxy) {
    setNumPages(numPages);
  }

  return (
    <Dialog
      sx={{ '& .MuiPaper-root': { maxWidth: 'unset' } }}
      open={Boolean(file)}
      onClose={onClose}
    >
      <Box
        sx={{
          pt: 0,
          pl: 3,
          pr: 0,
          pb: 0,
          width: '100%',
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h4>{file?.filename || t('deals.edit.documents.preview')}</h4>

          <Button
            onClick={onClose}
            variant="contained"
            sx={{ p: 0, height: '32px', width: '32px', minWidth: 'unset' }}
            aria-label="close"
          >
            {Icons.clear()}
          </Button>
        </Box>
        <Box sx={{ overflow: 'scroll' }}>
          {file && (
            <Document
              onLoadError={console.error}
              onLoadSuccess={onDocumentLoadSuccess}
              file={file.base64}
              options={{ isEvalSupported: false }}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page width={1000} key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default PDFPreviewDialog;

import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { SalesAidValues } from '../../RightDrawerSchema';
import { SalesAidView } from '../../../../../../generated/ApiClient';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Icons } from '../../../../../../component/icons/Icons';
import PDFPreviewDialog, { PdfPreview } from '../../../../../../component/PDFPreviewDialog';
import RestHttpClient from '../../../../../../common/RestHttpClient';

interface Props {
  salesAid?: SalesAidValues | SalesAidView;
}

const SalesAidDetails = ({ salesAid }: Props) => {
  const { t } = useTranslation();

  const [openDocFile, setOpenDocFile] = useState<PdfPreview | undefined>();

  if (!salesAid) {
    return <Box />;
  }

  const handlePreviewClick = () => {
    if (!salesAid.descriptionFileId) return;
    RestHttpClient.getUploadedDocument(salesAid.descriptionFileId).then((res) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result as string;
        setOpenDocFile({ base64: base64data, filename: res.fileName });
      };
      reader.readAsDataURL(res.data);
    });
  };

  const handleDownloadClick = () => {
    if (!salesAid.descriptionFileId) return;
    RestHttpClient.getUploadedDocument(salesAid.descriptionFileId).then((res) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(res.data);
      link.download = res.fileName ?? 'download';
      link.click();
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      <PDFPreviewDialog file={openDocFile} onClose={() => setOpenDocFile(undefined)} />

      <Typography variant="subtitle1" whiteSpace="pre" aria-multiline sx={{ height: '100%' }}>
        {t('deals.new.financesConfiguration.calculationDrawer.salesAids.description')}
      </Typography>
      <Typography variant="body1">
        {salesAid.description?.split('\n').map(function (item) {
          return (
            <>
              {item}
              <br />
            </>
          );
        })}
      </Typography>
      {salesAid.descriptionFileId && (
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
          <Tooltip title={t('deals.edit.documents.view')}>
            <IconButton onClick={(e) => handlePreviewClick()}>
              <VisibilityIcon opacity={0.4} color="secondary" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('deals.edit.documents.download')}>
            <IconButton onClick={(e) => handleDownloadClick()}>{Icons.download()}</IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default SalesAidDetails;

import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormHelperText, Stack, Switch, SxProps } from '@mui/material';
import { FieldValues } from 'react-hook-form/dist/types';
import { FormComponentProps } from './FormComponentProps';

interface Props<TFieldValues> extends FormComponentProps<TFieldValues> {
  variant?: 'checkbox' | 'switch';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  inverse?: boolean;
  labelSx?: SxProps
}

export const FormCheckbox = <TFieldValues extends FieldValues>({
  name,
  onChange,
  control,
  label,
  className,
  disabled,
  inverse,
  variant = 'checkbox',
  labelSx
}: Props<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return variant === 'checkbox' ? (
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (inverse) {
                      event.target.checked = !event.target.checked;
                    }
                    field.onChange(event);
                    if (onChange) {
                      onChange(event);
                    }
                  }}
                  checked={inverse ? field.value === false : field.value === true}
                />
              }
              label={label}
              disabled={disabled}
              className={`${className} ${error ? 'Mui-error' : ''}`}
              sx={labelSx}
            />
            <FormHelperText error={!!error}>{error ? error.message : ''}</FormHelperText>
          </Stack>
        ) : (
          <Stack>
            <FormControlLabel
              // @ts-ignore
              onChange={onChange}
              control={
                <Switch
                  {...field}
                  onChange={(e) => {
                    if (inverse) {
                      e.target.checked = !e.target.checked;
                    }
                    field.onChange(e);
                  }}
                  checked={inverse ? !field.value : field.value}
                />
              }
              label={label}
              disabled={disabled}
              className={`${className} ${error ? 'Mui-error' : ''}`}
              sx={labelSx}
            />
            <FormHelperText error={!!error}>{error ? error.message : ''}</FormHelperText>
          </Stack>
        );
      }}
    />
  );
};

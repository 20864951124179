import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Box } from '@mui/material';
import { t } from 'i18next';
import { CustomerType } from '../../../generated/ApiClient';

interface CustomerTypeDropdownProps {
  onChange?: any;
  value: CustomerType;
  disabled?: boolean;
}

export function NewCustomerTypeDropdown(props: CustomerTypeDropdownProps) {
  return (
    <Box className="customer-type-selection">
      <FormControl variant="standard" sx={{ m: 1, minWidth: 528 }}>
        <InputLabel id="demo-simple-select-standard-label">
          {t('customers.edit.customerType.label')}
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={props.value}
          onChange={(ev) => props.onChange(ev.target.value)}
          disabled={props.disabled}
        >
          <MenuItem className="menu-item" key={CustomerType.Private} value={CustomerType.Private}>
            {t('customers.edit.customerType.private')}
          </MenuItem>
          <MenuItem
            className="menu-item"
            key={CustomerType.Commercial}
            value={CustomerType.Commercial}
          >
            {t('customers.edit.customerType.commercial')}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

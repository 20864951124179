import RestHttpClient from '../../../../common/RestHttpClient';
import { HeadCell } from '../../../../common/table/EnhancedTableHead';
import { useTranslation } from 'react-i18next';
import { pushNotificationHandler } from '../../../../common/PushNotification';
import React, { useEffect, useState } from 'react';
import { EnhancedTable } from '../../../../common/table/EnhancedTable';
import { Box, Button, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material';
import {
  CustomerType,
  DealInsuranceOverview,
  NotificationLocation,
} from '../../../../generated/ApiClient';
import { Icons } from '../../../../component/icons/Icons';
import { DealDetailInsuranceDrawer } from './DealDetailInsuranceDrawer';
import { InsurancePrintDialog } from './InsurancePrintDialog';
import { usePermissions } from '../../../../common/auth/PermissionsContext';
import { formatToEuro } from '../../new/finances/FinancesCalculationList';
import { SendToContractManagementSystemDate } from '../finances/SendToContractManagementSystemDate';
import { useNotifications } from '../../../../common/global/useNotifications';
import { useQuery } from 'react-query';
import { SubmitInsuranceDialog, SubmitInsuranceFormValues } from './SubmitInsuranceDialog';

interface Props {
  dealId: number;
  customerId: number;
  readonly?: boolean;
  reload?: () => void;
}

class InsuranceRow {
  icon: JSX.Element;
  id: string;
  insuranceCalculationNumber: string;
  sendToContractManagementSystemDate: JSX.Element | undefined;
  liabilityPrice: string;
  liabilityPremiumDiscount: string;
  kaskoPrice: string;
  kaskoPremiumDiscount: string;
  engineTax: string;
  totalPrice: string;
  kw: number | undefined;
  kaskoInsuranceVariableFranchise?: string;
  isUnlocked!: boolean;

  constructor(dio: DealInsuranceOverview, icon: JSX.Element) {
    this.id = dio.id;
    this.insuranceCalculationNumber = dio.insuranceCalculationNumber;
    this.sendToContractManagementSystemDate = (
      <SendToContractManagementSystemDate
        date={dio.sendToContractManagementSystemDate}
        isPrimaryCalculation={false}
      />
    );
    this.liabilityPrice = formatToEuro(dio.liabilityPrice, true);
    this.liabilityPremiumDiscount = formatToEuro(dio.liabilityPremiumDiscount, true);
    this.kaskoPrice = formatToEuro(dio.kaskoPrice, true);
    this.kaskoPremiumDiscount = formatToEuro(dio.kaskoPremiumDiscount, true);
    this.engineTax = formatToEuro(dio.engineTax, true);
    this.totalPrice = formatToEuro(dio.totalPrice, true);
    this.kaskoPremiumDiscount = formatToEuro(dio.kaskoPremiumDiscount, true);
    this.kaskoInsuranceVariableFranchise = formatToEuro(dio.kaskoInsuranceVariableFranchise, true);
    this.isUnlocked = dio.isUnlocked;
    this.kw = dio.kw;
    this.icon = icon;
  }
}

export function DealDetailInsurances({ dealId, customerId, readonly, reload }: Props) {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEditInsuranceProducts = hasPermission('InsuranceProducts', 'Edit') && !readonly;
  const [isInsuranceCalcReadonly, setIsInsuranceCalcReadonly] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<InsuranceRow[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [editInsuranceId, setEditInsuranceId] = useState<number>();
  const [selectedInsuranceCalculation, setSelectedInsuranceCalculation] = useState<string | null>(
    null
  );
  const [isValidCustomer, setIsValidCustomer] = useState<boolean>(false);
  const [errorMsgs, setErrorMsgs] = useState<string[]>([]);
  const [isPrivateCustomer, setIsPrivateCustomer] = useState<boolean>(false);

  const [printInsuranceId, setPrintInsuranceId] = useState<number>();
  const [isPrintDialogOpen, setPrintDialogOpen] = useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRowClick = (dio: DealInsuranceOverview) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setEditInsuranceId(Number(dio.id));
    setIsInsuranceCalcReadonly(dio.sendToContractManagementSystemDate !== undefined);
    setIsDrawerOpen(true);
  };

  const handlePrintClick = (dio: DealInsuranceOverview) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setPrintInsuranceId(Number(dio.id));
    setPrintDialogOpen(true);
  };

  const handleNewClick = () => {
    setEditInsuranceId(undefined);
    setIsInsuranceCalcReadonly(false);
    setIsDrawerOpen(true);
  };

  const fetchInsurances = async (p = page) => {
    try {
      setLoading(true);
      const { data } = await RestHttpClient.getInsuranceOverview(dealId, p, 10, [
        'CalculationNumber|desc',
      ]);
      setTotal(data.total);
      const insurances = data.items.map(
        (dio) =>
          new InsuranceRow(
            dio,
            (
              <>
                <IconButton onClick={handleRowClick(dio)}>{Icons.pencil()}</IconButton>
                {hasPermission('InsuranceProducts', 'Print') && (
                  <IconButton onClick={handlePrintClick(dio)}>{Icons.print()}</IconButton>
                )}
              </>
            )
          )
      );
      if (p === 0 && insurances.length) {
        setRows(insurances);
      } else if (insurances.length) {
        setRows(rows.concat(insurances));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const submitInsurance = async (values: SubmitInsuranceFormValues) => {
    if (!selectedInsuranceCalculation) return;
    try {
      setLoading(true);
      await RestHttpClient.submitInsurance(
        +selectedInsuranceCalculation,
        values.commissionDealerId ?? undefined
      );
      pushNotificationHandler.publish(t('deals.edit.insurance.insuranceSubmitted'), 'success');
      setPage(0);
      fetchInsurances(0);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setIsSubmitting(false);
    }
  };

  const handleSubmitInsuranceClicked = async () => {
    setIsSubmitting(true);
  };

  const onSelection = (ev: React.SyntheticEvent<unknown>, names: readonly string[]) => {
    if (names.length > 0) setSelectedInsuranceCalculation(names[0]);
  };

  useNotifications(NotificationLocation.Insurance);

  useEffect(() => {
    RestHttpClient.getCustomerType(customerId).then((res) => {
      setIsPrivateCustomer(res.data === CustomerType.Private);
    });
  });

  useEffect(() => {
    RestHttpClient.isValidInsuranceCustomer(dealId, customerId).then((res) => {
      setIsValidCustomer(res.data);

      setErrorMsgs(
        // @ts-ignore
        res.messages.local.map((e) => {
          // @ts-ignore
          return t(`errors.${e.message}`);
        })
      );
    });
    // eslint-disable-next-line
  }, [customerId]);

  useEffect(() => {
    if (page > 0) {
      fetchInsurances();
    }
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (!isDrawerOpen) {
      setPage(0);
      fetchInsurances(0);
    }
    // eslint-disable-next-line
  }, [isDrawerOpen]);

  const loadNewPage = () => {
    setPage(page + 1);
  };

  const headCells: readonly HeadCell<InsuranceRow>[] = [
    {
      id: 'insuranceCalculationNumber',
      disablePadding: false,
      label: t('deals.edit.insurance.list.insuranceCalculationNumber'),
    },
    {
      id: 'sendToContractManagementSystemDate',
      align: 'right',
      disablePadding: false,
      label: t('deals.edit.insurance.list.sendToContractManagementSystemDate'),
    },
    {
      id: 'liabilityPrice',
      align: 'right',
      disablePadding: false,
      label: t('deals.edit.insurance.list.liabilityPrice'),
    },
    {
      id: 'liabilityPremiumDiscount',
      align: 'right',
      disablePadding: false,
      label: t('deals.edit.insurance.list.liabilityPremiumDiscount'),
    },
    {
      id: 'kaskoPrice',
      align: 'right',
      disablePadding: false,
      label: t('deals.edit.insurance.list.kaskoPrice'),
    },
    {
      id: 'kaskoPremiumDiscount',
      align: 'right',
      disablePadding: false,
      label: t('deals.edit.insurance.list.kaskoPremiumDiscount'),
    },
    {
      id: 'engineTax',
      align: 'right',
      disablePadding: false,
      label: t('deals.edit.insurance.list.engineTax'),
    },
    {
      id: 'totalPrice',
      align: 'right',
      disablePadding: false,
      label: t('deals.edit.insurance.list.totalPrice'),
    },
    {
      id: 'kw',
      align: 'right',
      disablePadding: false,
      label: t('deals.edit.insurance.list.kw'),
    },
    {
      id: 'kaskoInsuranceVariableFranchise',
      align: 'right',
      disablePadding: false,
      label: t('deals.edit.insurance.list.kaskoInsuranceVariableFranchise'),
    },
    {
      id: 'icon',
      align: 'right',
      disablePadding: false,
      label: '',
    },
  ];

  return (
    <>
      {loading && <LinearProgress />}
      <Box sx={{ display: 'flex', height: '80px', gap: '2.5%', alignItems: 'center' }}>
        <Typography variant="h3">{t('deals.edit.insurance.title')}</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '2%',
            justifyContent: 'flex-end',
            width: '90%',
            height: '100%',
          }}
        >
          {!isValidCustomer && !loading && (
            <Tooltip
              disableFocusListener
              title={
                errorMsgs.length ? (
                  <ul>
                    {errorMsgs.map((e) => (
                      <li>{e}</li>
                    ))}
                  </ul>
                ) : (
                  t('customers.invalidCustomer')
                )
              }
            >
              {Icons.alert()}
            </Tooltip>
          )}
          <Button
            type="button"
            sx={{ height: '28px' }}
            color="secondary"
            variant="outlined"
            disabled={selectedInsuranceCalculation == null || loading || !isValidCustomer}
            onClick={handleSubmitInsuranceClicked}
          >
            {t('deals.edit.insurance.submitInsurance')}
          </Button>
          {canEditInsuranceProducts && (
            <Button
              variant="contained"
              type="button"
              sx={{ height: '28px' }}
              onClick={handleNewClick}
            >
              {t('deals.edit.insurance.new')}
            </Button>
          )}
        </Box>
      </Box>
      <EnhancedTable
        rows={rows}
        total={total}
        headCells={headCells}
        hideCheckbox={false}
        variant="endless"
        enableSelection={true}
        enableSingleSelection={true}
        onSelection={onSelection}
        loadNewPage={loadNewPage}
      />
      <DealDetailInsuranceDrawer
        isDrawerOpened={isDrawerOpen}
        setIsDrawerOpened={setIsDrawerOpen}
        dealId={Number(dealId)}
        insuranceId={editInsuranceId}
        isPrivateCustomer={isPrivateCustomer}
        onSubmit={() => (reload ? reload() : undefined)}
        readonly={isInsuranceCalcReadonly}
      />

      <InsurancePrintDialog
        isOpen={isPrintDialogOpen}
        setIsOpen={setPrintDialogOpen}
        insuranceId={printInsuranceId}
      />
      <SubmitInsuranceDialog
        dealId={dealId}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        submitInsurance={submitInsurance}
      />
    </>
  );
}

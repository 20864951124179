import { Control, useWatch } from 'react-hook-form';
import { InsuranceFormValues } from '../InsuranceSchema';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { alpha, Box, Button, Divider } from '@mui/material';
import { usePermissions } from '../../../../../common/auth/PermissionsContext';

interface Props {
  control: Control<InsuranceFormValues>;
  loading: boolean;
  width: string | object;
  onClose: () => void;
  onCalculate: () => void;
  readonly: boolean;
}

export const DealInsuranceDrawerFooter = ({
  control,
  width,
  onClose,
  onCalculate,
  loading,
  readonly,
}: Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canEdit = hasPermission('InsuranceProducts', 'Edit');

  const engineTax = useWatch({ control, name: 'engineTax' });
  const kaskoPrice = useWatch({ control, name: 'kaskoPrice' });
  const liabilityPrice = useWatch({ control, name: 'liabilityPrice' });
  const additionalProductsPremium = useWatch({ control, name: 'additionalProductsPremium' });
  const totalPrice = useWatch({ control, name: 'totalPrice' });

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  const renderField = (value: number | undefined, label: string) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {value ? (
        <div className="footer-value">{value.toFixed(2).replace('.', ',')} €</div>
      ) : (
        <div className="stepper-footer">-</div>
      )}
      <div className="footer-label">{label}</div>
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: `0px -2px 12px ${alpha('#000000', 0.2)}`,
        pr: 2,
        position: 'fixed',
        bottom: 0,
        background: 'white',
        width: width,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          gap: 1,
          flexShrink: 0,
          justifyContent: 'space-around',
          input: {
            fontFamily: 'MB Corpo A Title Cond WEB',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '24px',
            lineHeight: '32px',
          },
        }}
      >
        <Box>
          <Button
            onClick={handleClose}
            sx={{ height: '28px', width: '94px', border: 1, borderColor: 'black', color: 'black' }}
          >
            {t('deals.edit.insurance.cancel')}
          </Button>
        </Box>
        <Box>{renderField(engineTax ?? undefined, t('deals.edit.insurance.fields.engineTax'))}</Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          {renderField(kaskoPrice ?? undefined, t('deals.edit.insurance.fields.kaskoPrice'))}
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          {renderField(
            liabilityPrice ?? undefined,
            t('deals.edit.insurance.fields.liabilityPrice')
          )}
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          {renderField(
            additionalProductsPremium ?? undefined,
            t('deals.edit.insurance.fields.additionalProductsPremium')
          )}
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          {renderField(totalPrice ?? undefined, t('deals.edit.insurance.fields.totalPrice'))}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
          {(canEdit && !readonly) && (
            <>
              <Button
                disabled={loading}
                onClick={onCalculate}
                sx={{
                  height: '28px',
                  width: '94px',
                  border: 1,
                  borderColor: 'black',
                  color: 'black',
                  alignItems: 'space-between',
                  mb: '10px',
                }}
              >
                {t('deals.edit.insurance.calculate')}
              </Button>
              <Button
                type="submit"
                form="insurance-form"
                variant="contained"
                disabled={loading}
                sx={{ height: '28px', width: '94px' }}
              >
                {t('deals.edit.insurance.save')}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

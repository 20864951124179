import { LightTheme } from '@mercedes-benz/mui5-theme';

function addSansSerifRecursive(obj: any) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const newObj: any = Array.isArray(obj) ? [] : {};

  for (let key in obj) {
    if (
      key === 'fontFamily' &&
      typeof obj[key] === 'string' &&
      !obj[key].includes('inherit') &&
      !obj[key].includes('sans-serif')
    ) {
      newObj[key] = obj[key] + ', sans-serif';
    } else {
      newObj[key] = addSansSerifRecursive(obj[key]);
    }
  }

  return newObj;
}

export const CustomTheme = addSansSerifRecursive(LightTheme);

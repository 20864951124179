import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { visuallyHidden } from '@mui/utils';

import { Order } from './TableComparator';

export interface HeadCell<T> {
  id: keyof T;
  disablePadding: boolean;
  label: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
  allowSorting?: boolean;
  columnName?: string;
  allowFiltering?: boolean;
  width?: string;
  sortTransform?: (value: any) => any;
}

export interface EnhancedTableHeadProps<T> {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: readonly HeadCell<T>[];
  hideCheckbox: boolean;
}

export function EnhancedTableHead<T>(props: EnhancedTableHeadProps<T>) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    hideCheckbox,
  } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {hideCheckbox ? (
          <Box></Box>
        ) : (
          <TableCell padding="checkbox">
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell: HeadCell<T>) => (
          <TableCell
            key={headCell.id as string}
            align={headCell.align ?? 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.columnName ? order : false}
            sx={{ width: headCell.width }}
          >
            {headCell.allowSorting && headCell.columnName ? (
              <TableSortLabel
                active={orderBy === headCell.columnName}
                direction={orderBy === headCell.columnName ? order : 'asc'}
                onClick={createSortHandler(headCell.columnName!)}
              >
                {headCell.label}
                {orderBy === headCell.columnName ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <>{headCell.label}</>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

import { Controller } from 'react-hook-form';
import { FormComponentProps } from './FormComponentProps';
import { FieldValues } from 'react-hook-form/dist/types';
import { distinct } from '../../common/util/ArrayUtil';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { OptionItem } from './OptionItem';

export class ReadonlyOptionItem extends OptionItem {
  readonly?: boolean;
  initial?: boolean;
}

export class OptionWithChildren extends ReadonlyOptionItem {
  children?: ReadonlyOptionItem[];
}

interface Props<TFieldValues> extends FormComponentProps<TFieldValues> {
  variant?: 'checkbox' | 'switch';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: OptionWithChildren[];
  required?: boolean;
  selectChildsOnChange?: boolean;
}

export const FormCheckboxGroup = <TFieldValues extends FieldValues>({
  onChange,
  name,
  control,
  label,
  options,
  required,
  selectChildsOnChange = true,
  disabled,
}: Props<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControl
            required={required}
            error={!!error}
            component="fieldset"
            variant="standard"
            className="form-checkbox-group"
          >
            {label && (
              <FormLabel style={{ color: 'black' }} component="legend">
                {label}
              </FormLabel>
            )}
            <FormGroup>
              {options.map((o) => (
                <>
                  <FormControlLabel
                    key={o.value}
                    control={
                      <Checkbox
                        disabled={disabled || o.readonly}
                        name={o.value.toString()}
                        checked={(field.value && field.value.includes(o.value)) === true}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const childIds = o.children
                            ? o.children
                                .filter(
                                  (childReadOnlyOptionItem) => !childReadOnlyOptionItem.readonly
                                )
                                .map((c) => c.value)
                            : [];

                          const selectedChildIdsOnChange = selectChildsOnChange ? childIds : [];
                          const childIdsWithInitial = o.children
                            ? o.children.filter((x) => x.initial).map((c) => c.value)
                            : [];
                          const childIdToChange = selectedChildIdsOnChange
                            .concat(childIdsWithInitial)
                            .filter(distinct);

                          if (field.value && !field.value.includes(o.value)) {
                            field.onChange({
                              target: {
                                name,
                                value: [...field.value, ...childIdToChange, o.value],
                              },
                            });
                          } else {
                            const newValue = field.value.filter(
                              (v: any) =>
                                v !== o.value &&
                                !childIdToChange.includes(v) &&
                                !childIds.includes(v)
                            );
                            field.onChange({
                              target: {
                                name,
                                value: newValue,
                              },
                            });
                          }
                          if (onChange) {
                            onChange(event);
                          }
                        }}
                      />
                    }
                    label={o.label}
                  />
                  {o.children &&
                    (field.value && field.value.includes(o.value)) === true &&
                    o.children.map((oc) => (
                      <FormControlLabel
                        sx={{ ml: 1 }}
                        key={oc.value}
                        control={
                          <Checkbox
                            name={oc.value.toString()}
                            checked={field.value && field.value.includes(oc.value)}
                            disabled={disabled || oc.readonly}
                            onChange={(_) => {
                              if (field.value && !field.value.includes(oc.value)) {
                                field.onChange({
                                  target: {
                                    name,
                                    value: [...field.value, oc.value],
                                  },
                                });
                              } else {
                                field.onChange({
                                  target: {
                                    name,
                                    value: field.value.filter((v: any) => v !== oc.value),
                                  },
                                });
                              }
                            }}
                          />
                        }
                        label={oc.label}
                      />
                    ))}
                </>
              ))}
            </FormGroup>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

import { zodResolver } from '@hookform/resolvers/zod';
import { FormEvent, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  PrivateCustomerFormValues,
  privateCustomerRequestSchema,
} from '../data/PrivateCustomerFormValues';
import { useNavigate } from 'react-router-dom';
import { errorHandler, PrivateCustomerForm } from '../form/PrivateCustomerForm';
import RestHttpClient, { renderValidationErrors } from '../../../common/RestHttpClient';
import { ApiException, CreatePrivateCustomerRequest } from '../../../generated/ApiClient';
import { pushNotificationHandler } from '../../../common/PushNotification';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from '@mui/material';
import { PrivateCustomerKsvSearch } from '../ksv/PrivateCustomerKsvSearch';
import { austriaCountryId, noPepId } from '../data/CustomerConstants';

export function NewPrivateCustomerForm() {
  const { t } = useTranslation();
  const [tabErrors, setTabErrors] = useState<number[]>([]);
  const ksvSubmitRef = useRef<HTMLButtonElement>(null);

  const { control, handleSubmit, setError, clearErrors, getValues, setValue } =
    useForm<PrivateCustomerFormValues>({
      resolver: zodResolver(privateCustomerRequestSchema),
      mode: 'onChange',
      defaultValues: {
        addressCountryId: austriaCountryId,
        pepSpecificationId: noPepId,
        isPotentialBuyer: false,
      },
    });

  const navigate = useNavigate();

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    console.log('submit');
    setTabErrors([]);
    handleSubmit(
      (values) => {
        console.log('submitting');
        const req = new CreatePrivateCustomerRequest();
        req.init({
          ...values,
          jobTitleId: values.jobTitleId || undefined,
        });

        RestHttpClient.createPrivateCustomer(req)
          .then((_) => {
            pushNotificationHandler.publish(t('customers.saved'), 'success');
            navigate('/customers');
          })
          .catch((error: ApiException) => {
            renderValidationErrors(error, setError);
          });
      },
      (err) => {
        errorHandler(err, setTabErrors);
      }
    )(event);
  };

  return (
    <form id="new-customer-form" onSubmit={onSubmit} noValidate>
      <PrivateCustomerForm
        control={control}
        isNew
        readonly={false}
        tabErrors={tabErrors}
        setValue={setValue}
        actions={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              ksvSubmitRef.current?.click();
              ksvSubmitRef.current?.scrollIntoView();
            }}
          >
            {t('customers.ksv.submitSearch')}
          </Button>
        }
      />
      <Divider />
      <PrivateCustomerKsvSearch
        getFormValues={getValues}
        setError={setError}
        clearErrors={clearErrors}
        setValue={setValue}
        control={control}
        ref={ksvSubmitRef}
      />
    </form>
  );
}

import {Box, Button, CircularProgress, FormControlLabel, Grid, Link, Switch, Typography,} from '@mui/material';
import {useTranslation} from 'react-i18next';
import React, {Dispatch, SetStateAction, useState} from 'react';
import RestHttpClient from '../../../common/RestHttpClient';
import { GlobalTextIdentifiers, useGlobalTitles } from '../../../common/global/useGlobalTitles';

interface Props {
    loading: boolean;
    incentiveAccepted: boolean | undefined;
    documentId: string | undefined;
    handleApprove: (isAccepted: boolean) => Promise<void>;
    setActiveTab: Dispatch<SetStateAction<number>>;
}

const DashboardIncentives = ({
                                 loading,
                                 incentiveAccepted,
                                 documentId,
                                 handleApprove,
                                 setActiveTab
                             }: Props) => {
    const {t} = useTranslation();

    const [acceptedBox, setAcceptedBox] = useState(false);
    const getTitle = useGlobalTitles();

    const downloadDoc = () => {
        if (!documentId) return;
        RestHttpClient.downloadDocument(documentId).then((data) => {
            const tmpDownloadLink = document.createElement('a');
            tmpDownloadLink.href = window.URL.createObjectURL(data.data);
            tmpDownloadLink.download = data.fileName ?? 'Teilnahmebedingungen.pdf';
            tmpDownloadLink.click();
        });
    };

    if (incentiveAccepted === false && !loading) {
        return <></>;
    }

    return (
        <Grid item xs={6} lg={3} container spacing={4}>
            <Grid item xs={12}>
                <Box className="card incentives" display="flex" flexDirection="column" alignItems="center">
                    <h2>{getTitle(GlobalTextIdentifiers.DashboardIncentives)}</h2>
                    <span className="line"/>
                    {loading && (
                        <Box>
                            <CircularProgress/>
                        </Box>
                    )}

                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        justifyContent="space-around"
                        width="100%"
                        minHeight="250px"
                        px={2}
                        py={2}
                    >
                        {incentiveAccepted && !loading && (
                            <>
                                <Typography variant="subtitle1">
                                    {t('dashboard.incentives.acceptedText')}
                                </Typography>
                                {documentId && (
                                    <Link component="button" variant="body1" onClick={() => setActiveTab(6)}>
                                        {t('dashboard.incentives.rank')}
                                    </Link>
                                )}
                            </>
                        )}
                        {!incentiveAccepted && !loading && (
                            <>
                                {documentId && (
                                    <Link component="button" variant="body1" onClick={downloadDoc}>
                                        {t('dashboard.incentives.termsOfCondition')}
                                    </Link>
                                )}

                                <FormControlLabel
                                    onChange={() => setAcceptedBox(!acceptedBox)}
                                    control={<Switch checked={acceptedBox}/>}
                                    label={t('dashboard.incentives.checkbox')}
                                />

                                <Button
                                    variant="contained"
                                    disabled={!acceptedBox}
                                    onClick={() => handleApprove(true)}
                                >
                                    {t('dashboard.incentives.accept')}
                                </Button>
                                <Button variant="outlined" color="secondary" onClick={() => handleApprove(false)}>
                                    {t('dashboard.incentives.decline')}
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default DashboardIncentives;

import { Dispatch, SetStateAction } from 'react';
import { EnhancedDialog } from '../../../component/EnhancedDialog';
import { CustomerMergeProvider } from './CustomerMergeContext';
import { CustomerMergeForm } from './CustomerMergeForm';

interface Props {
  customerId: number;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isPrivate?: boolean;
  isPoseidon?: boolean;
  handleMerge: (selectedIds: string[]) => void;
  handleCancel: () => void;
}

export function CustomerMergeDialog({
  customerId,
  isOpen,
  setIsOpen,
  isPrivate,
  isPoseidon,
  handleMerge,
  handleCancel,
}: Props) {
  return (
    <EnhancedDialog
      isDialogOpened={isOpen}
      setIsDialogOpened={setIsOpen}
      handleCancel={handleCancel}
    >
      <CustomerMergeProvider>
        <CustomerMergeForm
          customerId={customerId}
          handleMerge={handleMerge}
          handleCancel={handleCancel}
          isPrivate={isPrivate}
          isPoseidon={isPoseidon}
        />
      </CustomerMergeProvider>
    </EnhancedDialog>
  );
}

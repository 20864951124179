import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { a11yProps, TabPanel } from '../../../../component/TabPanel';
import { EditDealUploadedDocuments } from './EditDealUploadedDocuments';
import { EditDealSignedDocuments } from './EditDealSignedDocuments';
import { EditDealArchivedDocuments } from './EditDealArchivedDocuments';
import { usePermissions } from '../../../../common/auth/PermissionsContext';
import { useNotifications } from '../../../../common/global/useNotifications';
import { NotificationLocation } from '../../../../generated/ApiClient';

interface Props {
  dealId: number;
  reload?: () => void;
}

export function EditDealDocuments({ dealId, reload }: Props) {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const canViewSignatureDocs = hasPermission('SignatureDocument', 'View');
  const canViewArchivedDocs = hasPermission('ArchivedDocument', 'View');

  const [value, setValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useNotifications(NotificationLocation.Documents);

  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', height: '80px', gap: '2.5%', alignItems: 'center' }}>
        <Typography variant="h3">{t('deals.edit.documents.title')}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tabs aria-label="basic tabs example" value={value} onChange={handleTabChange}>
            <Tab label={t('deals.edit.documents.uploaded')} {...a11yProps(0)} />
            {canViewSignatureDocs && (
              <Tab label={t('deals.edit.documents.signed')} {...a11yProps(1)} />
            )}
            {canViewArchivedDocs && (
              <Tab label={t('deals.edit.documents.archived')} {...a11yProps(2)} />
            )}
          </Tabs>
        </Box>
      </Box>
      <TabPanel index={0} value={value}>
        <EditDealUploadedDocuments dealId={dealId} reload={reload} />
      </TabPanel>
      <TabPanel index={1} value={value}>
        {canViewSignatureDocs && <EditDealSignedDocuments dealId={dealId} />}
      </TabPanel>
      <TabPanel index={2} value={value}>
        {canViewArchivedDocs && <EditDealArchivedDocuments dealId={dealId} />}
      </TabPanel>
    </Box>
  );
}

import { grey, blue, green, yellow } from '@mercedes-benz/mui5-theme/';

export function DashboardContractsIndicator({
  expireInLessThan90Days,
  expireInLessThan180Days,
  recently,
  settled,
}: {
  expireInLessThan90Days?: boolean;
  expireInLessThan180Days?: boolean;
  recently?: boolean;
  settled?: boolean;
}) {
  if (settled) {
    return <span className="dashboard-indicator" style={{ background: grey[65] }} />;
  }

  if (recently) {
    return <span className="dashboard-indicator" style={{ background: green[70] }} />;
  }

  if (expireInLessThan90Days) {
    return <span className="dashboard-indicator" style={{ background: yellow[60] }} />;
  }

  if (expireInLessThan180Days) {
    return <span className="dashboard-indicator" style={{ background: blue[70] }} />;
  }

  return <span className="dashboard-indicator" style={{ background: grey[25] }} />;
}

import { Control, useFieldArray, UseFormSetValue, useWatch } from 'react-hook-form';
import { VinConfigValues } from '../RightDrawerSchema';
import { VinStatusIcon } from '../../../new/model/VinStatusIcon';
import { FormTextField } from '../../../../../component/form/FormTextField';
import { MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { pushNotificationHandler } from '../../../../../common/PushNotification';
import RestHttpClient from '../../../../../common/RestHttpClient';
import { VinParameters } from '../../../../../generated/ApiClient';

interface Props {
  control: Control<{ vinConfigs: VinConfigValues[] }>;
  handleRelevantChange?: (name: string, value: unknown) => void;
  setValue: UseFormSetValue<{ vinConfigs: VinConfigValues[] }>;
  disabled?: boolean;
}

export function VinAssignmentField({ control, handleRelevantChange, setValue, disabled }: Props) {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'vinConfigs',
  });

  const onChange = (name: string, value: unknown) => {
    if (handleRelevantChange) {
      handleRelevantChange(name, value);
    }
  };

  const numberOfVehicleOptionItems: { label: string; value: number }[] = [
    { label: '1 ' + t('deals.new.modelConfiguration.body.numberOfVehicles.single'), value: 1 },
    ...Array.from({ length: 99 }, (_value, index) => index + 2).map((x) => ({
      label: `${x} ${t('deals.new.modelConfiguration.body.numberOfVehicles.multiple')}`,
      value: x,
    })),
  ];

  const handleVinCountChange = (count: number) => {
    const currentCount = fields.length;
    if (count > currentCount) {
      for (let i = currentCount; i < count; i++) {
        append({
          position: i + 1,
        });
      }
    } else if (count < currentCount) {
      for (let i = currentCount; i > count; i--) {
        const item = fields[i - 1];
        if (item.vin || item.commissionNumber) {
          pushNotificationHandler.publish("Can't remove VIN with data", 'warning');
          return;
        } else {
          remove(i - 1);
        }
      }
    }
    onChange('vinAssignment', count);
  };

  return (
    <Stack>
      <TextField
        label={t('deals.new.modelConfiguration.body.vehicleDetails.numberOfVehicles')}
        value={fields.length}
        variant="standard"
        select
        disabled={disabled}
        sx={{ m: 1 }}
        onChange={(e) => handleVinCountChange(Number(e.target.value))}
      >
        {numberOfVehicleOptionItems.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {fields.map((item, index) => (
        <VinField
          key={item.id}
          control={control}
          index={index}
          item={item}
          setValue={setValue}
          handleRelevantChange={onChange}
          disabled={disabled}
        />
      ))}
    </Stack>
  );
}

export function VinField({
  control,
  index,
  item,
  setValue,
  handleRelevantChange,
  disabled,
}: {
  control: Control<{ vinConfigs: VinConfigValues[] }>;
  index: number;
  item: VinConfigValues;
  handleRelevantChange: (name: string, value: unknown) => void;
  setValue: UseFormSetValue<{ vinConfigs: VinConfigValues[] }>;
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const chassisNumber = useWatch({ control, name: `vinConfigs.${index}.vin` });

  let vinStatus: VinParameters | undefined = undefined;
  const onVinBlur = async () => {
    if (chassisNumber) {
      const res = await RestHttpClient.getVinStatus(chassisNumber);
      vinStatus = res.data;
    }

    setValue(`vinConfigs.${index}.vinStatus`, vinStatus?.vinStatus);
    setValue(`vinConfigs.${index}.vinState`, vinStatus?.vinState);
    setValue(`vinConfigs.${index}.vinDate`, vinStatus?.vinDate);
    setValue(`vinConfigs.${index}.vinIssue`, vinStatus?.vinIssue);
  };

  return (
    <Stack flexDirection="row" justifyContent="center" alignItems="center" m={1} gap={1}>
      <VinStatusIcon
        vinState={item.vinState ?? undefined}
        vinDate={item.vinDate ?? undefined}
        vinIssue={item.vinIssue ?? undefined}
        vinStatus={item.vinStatus ?? undefined}
      />
      <FormTextField
        control={control}
        name={`vinConfigs.${index}.vin`}
        label={
          item.position + '. ' + t('deals.new.modelConfiguration.body.vehicleDetails.chassisNumber')
        }
        onBlur={onVinBlur}
        disabled={disabled}
      />
      <FormTextField
        control={control}
        name={`vinConfigs.${index}.commissionNumber`}
        label={t('deals.new.modelConfiguration.body.vehicleDetails.commissionNumber')}
        onBlur={() => handleRelevantChange(`vinAssignment`, null)}
        disabled={disabled}
      />
    </Stack>
  );
}

import { Control, UseFormClearErrors, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RestHttpClient from '../../../common/RestHttpClient';
import { HeadCell } from '../../../common/table/EnhancedTableHead';
import { CommercialCustomerKsvResponse } from '../../../generated/ApiClient';
import { CommercialCustomerFormValues } from '../data/CommercialCustomerFormValues';
import { KsvSearch } from './KsvSearch';
import { forwardRef, Ref } from 'react';
import { parseRomanHousenumber } from './KsvSearch.util';

interface CommercialCustomerKsvSearchProps {
  getFormValues: () => CommercialCustomerFormValues;
  setError: UseFormSetError<CommercialCustomerFormValues>;
  clearErrors: UseFormClearErrors<CommercialCustomerFormValues>;
  setValue: UseFormSetValue<CommercialCustomerFormValues>;
  control: Control<CommercialCustomerFormValues>;
}

export const CommercialCustomerKsvSearch = forwardRef(
  (props: CommercialCustomerKsvSearchProps, ref: Ref<HTMLButtonElement>) => {
    interface CommercialKsvResultRow {
      id: string;
      companyName: string;
      address: string;
    }

    const { getFormValues, setError, clearErrors, setValue, control } = props;

    const { t } = useTranslation();
    const headCells: readonly HeadCell<CommercialKsvResultRow>[] = [
      {
        id: 'id',
        disablePadding: false,
        label: t('customers.ksv.result.commercial.id'),
      },
      {
        id: 'companyName',
        disablePadding: false,
        label: t('customers.ksv.result.commercial.companyName'),
      },
      {
        id: 'address',
        disablePadding: false,
        label: t('customers.ksv.result.commercial.address'),
      },
    ];

    async function search(
      values: CommercialCustomerFormValues
    ): Promise<CommercialCustomerKsvResponse[]> {
      const result = await RestHttpClient.searchCommercialCustomerKSV(
        values.companyName,
        values.uidNumber ?? undefined,
        values.commercialRegisterNumber ?? undefined,
        values.addressStreet,
        values.addressPostalCode,
        values.addressCity,
        values.ksvId ?? undefined
      );
      setValue('ksvSearchDone', true);
      return result.data;
    }

    function resultSelected(result: CommercialCustomerKsvResponse) {
      setValue('ksvId', result.ksvId);
      setValue('companyName', result.companyName.trim());
      setValue('addressPostalCode', result.zip.trim());

      if (result.house) {
        setValue('addressStreet', result.street.trim());
        setValue('addressHouseNumber', result.house.trim());
      } else {
        const { street, housenumber } = parseRomanHousenumber(result.street);
        setValue('addressStreet', street.trim());
        setValue('addressHouseNumber', housenumber.trim());
      }

      setValue('addressCity', result.city.trim());
      setValue('selfDisclosureAgreed', false);
    }

    function onReset() {
      setValue('ksvId', undefined);
    }

    function map(resultEntry: CommercialCustomerKsvResponse) {
      return {
        id: resultEntry.ksvId,
        companyName: resultEntry.companyName,
        address: `${resultEntry.zip} ${resultEntry.city}, ${resultEntry.street} ${resultEntry.house}`,
      };
    }

    return KsvSearch<
      CommercialCustomerFormValues,
      CommercialCustomerKsvResponse,
      CommercialKsvResultRow
    >({
      getFormValues,
      setError,
      clearErrors,
      headCells,
      search,
      requiredFields: ['companyName'],
      resultSelected,
      map,
      control,
      onReset,
      ref,
    });
  }
);

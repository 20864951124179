import {
  CoOptIntermFile,
  FinancesCalculationContextValues,
  FinancesDrawerFormValues,
  RecalculateParams,
  SalesAidValues,
} from '../../common/financeOverlay/RightDrawerSchema';
import { Dispatch, SetStateAction, useContext } from 'react';
import { StateMachineContext } from '../state/StateMachineContextProvider';
import { useActor } from '@xstate/react';
import {
  AgentOption,
  ApiconServiceRequestBundle,
  CustomerType,
  GetCalculatedProductRequest,
  GetCalculationResponse,
  GetReconsideratedFundingProductRequest,
  ObjectConfigurationRequest,
  ServiceOption,
} from '../../../../generated/ApiClient';
import RestHttpClient from '../../../../common/RestHttpClient';
import {
  ADD_SALES_AID,
  CONFIRM_CALCULATION,
  CONFIRM_OVERTAKEN_CUSTOMER,
  EDIT_SALES_AID,
} from '../state/orderStateMachine';
import { FinancesRightDrawer } from '../../common/financeOverlay/FinancesRightDrawer';
import {
  createCommonServiceRequest,
  mapCalculationToDto,
  mapConvOptionValuesToView,
  mapModelConfigToObjectConfig,
  mapSalesAidValuesToView,
} from '../state/stateToDtoMapper';
import { PrivateCustomerFormValues } from '../../../customers/data/PrivateCustomerFormValues';
import { CommercialCustomerFormValues } from '../../../customers/data/CommercialCustomerFormValues';

interface RightDrawerProps {
  isDrawerOpened: boolean;
  editObject?: FinancesCalculationContextValues;
  setIsDrawerOpened: Dispatch<SetStateAction<boolean>>;
  isIncludingVat: boolean;
}

export const NewDealFinanceDrawer = ({
  isDrawerOpened,
  setIsDrawerOpened,
  editObject,
  isIncludingVat,
}: RightDrawerProps) => {
  const stateMachine = useContext(StateMachineContext);
  const [state, send] = useActor(stateMachine);

  const defaultValues = {
    objectConfig: state.context.model ? mapModelConfigToObjectConfig(state.context.model) : {},
    vinConfigs: state.context.model?.vinConfigs ?? [{ position: 1 }],
    credibilityCheck: {},
  };

  const { model } = state.context;

  const fetchDefault = async (): Promise<GetCalculationResponse> => {
    const { model, finances } = state.context;

    if (model && finances && isDrawerOpened && !editObject) {
      const latestFinance = finances.calculations.at(-1);
      if (latestFinance) {
        const { data } = await RestHttpClient.getReconsideratedFundigProduct(
          new GetReconsideratedFundingProductRequest({
            modelId: model.modelId,
            dealTypeId: finances.businessType,
            vehicleTypeConditionId: model.vehicleCondition,
            discountCategoryId: model.discountCategory,
            salesPersonId: model.sellerId,
            initialMileage: model.mileage ?? undefined,
            commissionNumber: model.commissionNumber,
            listPrice: model.listPrice,
            extrasPrice: model.specialEquipment,
            purchasePrice: model.totalPrice,
            discountPercent: model.discountInPercentages,
            discountInEuro: model.discountInEuro,
            numberOfVehicles: model.numberOfVehicles,
            vat: 20,
            isIncludeVat: model.pricesIncludingVAT,
            registrationDate: model.registrationDate,
            distributionChannel: model.distributionChannel,
            previousCalculation: mapCalculationToDto(latestFinance),
          })
        );

        const response = new GetCalculationResponse();
        response.init(data);
        return response;
      } else {
        const { data } = await RestHttpClient.getDefaultFundigProduct(
          model.modelId,
          finances.businessType,
          model.vehicleCondition,
          model.discountCategory,
          model.sellerId,
          model.mileage ?? undefined,
          model.commissionNumber,
          model.listPrice,
          model.specialEquipment,
          model.totalPrice,
          model.discountInPercentages,
          model.discountInEuro,
          model.numberOfVehicles,
          20,
          isIncludingVat,
          model.modelPrototypeId,
          model.registrationDate,
          model.distributionChannel
        );

        const response = new GetCalculationResponse();
        response.init(data);
        return response;
      }
    }
    // eslint-disable-next-line no-throw-literal
    throw 'Model/Finance not saved.';
  };

  const fetchCalculate = async (
    recalcParams: RecalculateParams
  ): Promise<GetCalculationResponse> => {
    const {
      fundingType,
      duration,
      mileageId,
      businessRelationshipId,
      interestCalculationMethodId,
      tariffGroup,
      downPayment,
      downPaymentPercent,
      deposit,
      depositPercent,
      residualValueId,
      sourceOfFoundsId,
      accountOwner,
      residualValueConversionOptions,
      residualValueVehicleAbsolute,
      residualValuePercentage,
      specialOfferId,
      purchaseContractDate,
      objectConfiguration_ListPrice,
      objectConfiguration_ExtrasPrice,
      objectConfiguration_ListPriceIncludingExtras,
      objectConfiguration_TotalPrice,
      objectConfiguration_CoFinancedExtras,
      objectConfiguration_PurchasePrice,
      objectConfiguration_CalculationBase,
      objectConfiguration_DiscountPercent,
      objectConfiguration_DiscountInEuro,
      objectConfiguration_RegistrationDate,
      objectConfiguration_InitialMileage,
      objectConfiguration_CommissionNumber,
      salesAid,
      agentOptions,
      conversionOptions,
      selectedFuelServiceProductId,
      referencesDealId,
      referencesCalculationId,
      service,
    } = recalcParams;
    const { model, finances } = state.context;

    if (model && finances) {
      const { data } = await RestHttpClient.calculate(
        new GetCalculatedProductRequest({
          modelId: model.modelId,
          dealTypeId: finances.businessType,
          vehicleTypeConditionId: model.vehicleCondition,
          discountCategoryId: model.discountCategory,
          salesPersonId: model.sellerId,
          initialMileage: model.mileage ?? undefined,
          commissionNumber: model.commissionNumber,
          listPrice: model.listPrice,
          extrasPrice: model.specialEquipment,
          purchasePrice: model.totalPrice,
          discountPercent: model.discountInPercentages,
          discountInEuro: model.discountInEuro,
          numberOfVehicles: model.numberOfVehicles,
          vat: 20,
          isIncludeVat: model.pricesIncludingVAT,
          registrationDate: model.registrationDate,
          distributionChannel: model.distributionChannel,
          financingTypeId: fundingType,
          duration: duration,
          mileageId: mileageId,
          businessRelationshipId: businessRelationshipId,
          interestRateCalculationMethodId: interestCalculationMethodId,
          rateCategoryId: tariffGroup,
          downpayment: downPayment,
          downPaymentPercent: downPaymentPercent,
          deposit: deposit,
          depositPercent: depositPercent,
          residualValueContractId: residualValueId,
          sourceOfFoundsId: sourceOfFoundsId,
          referencesCalculationId: referencesCalculationId,
          referencesDealId: referencesDealId,
          accountOwner: accountOwner,
          residualValueConversionOptions: residualValueConversionOptions,
          residualValueVehicleAbsolute: residualValueVehicleAbsolute,
          residualValuePercentage: residualValuePercentage,
          objectConfig: new ObjectConfigurationRequest({
            listPrice: objectConfiguration_ListPrice,
            extrasPrice: objectConfiguration_ExtrasPrice,
            listPriceIncludingExtras: objectConfiguration_ListPriceIncludingExtras,
            totalPrice: objectConfiguration_TotalPrice,
            coFinancedExtras: objectConfiguration_CoFinancedExtras,
            purchasePrice: objectConfiguration_PurchasePrice,
            calculationBase: objectConfiguration_CalculationBase,
            discountPercent: objectConfiguration_DiscountPercent,
            discountInEuro: objectConfiguration_DiscountInEuro,
            registrationDate: objectConfiguration_RegistrationDate,
            initialMileage: objectConfiguration_InitialMileage,
            commissionNumber: objectConfiguration_CommissionNumber,
          }),
          apiconServiceRequestBundle: new ApiconServiceRequestBundle(
            createCommonServiceRequest(service, undefined, undefined, fundingType)
          ),
          agentOptions: agentOptions
            .filter((ao) => ao.price > 0 && ao.title)
            .map(
              (ao) =>
                new AgentOption({
                  id: Number.isInteger(ao.id) ? ao.id : 0,
                  title: ao.title,
                  dealerId: ao.dealerId,
                  price: ao.price,
                })
            ),
          conversionOptions: conversionOptions
            .filter((co) => co.price > 0 && co.title)
            .map(mapConvOptionValuesToView),
          purchaseContractDate: purchaseContractDate,
          specialOfferId: specialOfferId,
          salesAid: salesAid ? mapSalesAidValuesToView(salesAid) : undefined,
          selectedFuelServiceProductId: selectedFuelServiceProductId,
        })
      );

      return data;
    }

    // eslint-disable-next-line no-throw-literal
    throw 'Model/Finance not saved';
  };

  const handleSubmit = (
    values: FinancesDrawerFormValues | FinancesCalculationContextValues,
    _: boolean,
    __: ServiceOption[],
    intermFiles?: CoOptIntermFile[]
  ) => {
    const finance = values as FinancesCalculationContextValues;
    finance.id = finance?.id ?? state.context.finances?.calculations.length ?? 0;
    send({
      type: CONFIRM_CALCULATION,
      info: {
        finance,
        intermFiles: intermFiles?.map((i) => ({
          ...i,
          financeId: finance.id,
        })),
      },
    });
    console.log('most up-to-date values: ' + JSON.stringify(stateMachine.getSnapshot().context));
    return Promise.resolve(undefined);
  };

  const handleAddSalesAid = (salesAid: SalesAidValues) => {
    send({
      type: ADD_SALES_AID,
      info: salesAid,
    });
  };

  const handleEditSalesAid = (salesAid: SalesAidValues, index: number) => {
    send({
      type: EDIT_SALES_AID,
      info: {
        index,
        salesAid,
      },
    });
  };

  const fetchSubmittedDeals = async (
    page?: number,
    id?: string,
    salesPersonName?: string,
    creationDate?: Date,
    customerName?: string,
    orderSelectors?: string[]
  ) => {
    const { model, finances } = state.context;

    if (model && finances) {
      const { data } = await RestHttpClient.getSubmittedDealsForNewDeal(
        model.modelId,
        finances.businessType,
        model.vehicleCondition,
        model.distributionChannel,
        model.sellerId,
        model.registrationDate,
        id,
        salesPersonName,
        creationDate,
        customerName,
        page ?? 0,
        10,
        orderSelectors
      );
      return data;
    }
    // eslint-disable-next-line no-throw-literal
    throw 'Model/Finance not saved';
  };

  const fetchReconsideredFundingProduct = async (
    referenceDealId: number,
    overtakeCustomer: boolean
  ) => {
    const { model, finances } = state.context;

    if (model && finances) {
      const { data } = await RestHttpClient.getReconsideratedFundigProduct(
        new GetReconsideratedFundingProductRequest({
          modelId: model.modelId,
          dealTypeId: finances.businessType,
          vehicleTypeConditionId: model.vehicleCondition,
          discountCategoryId: model.discountCategory,
          salesPersonId: model.sellerId,
          initialMileage: model.mileage ?? undefined,
          commissionNumber: model.commissionNumber,
          listPrice: model.listPrice,
          extrasPrice: model.specialEquipment,
          purchasePrice: model.totalPrice,
          discountPercent: model.discountInPercentages,
          discountInEuro: model.discountInEuro,
          numberOfVehicles: model.numberOfVehicles,
          vat: 20,
          isIncludeVat: model.pricesIncludingVAT,
          registrationDate: model.registrationDate,
          distributionChannel: model.distributionChannel,
          referencesDealId: referenceDealId,
          overtakeCustomer: overtakeCustomer,
        })
      );
      //data.overtakenCustomerId
      //  check businesss types
      // data.

      if (data.overtakenCustomerId) {
        let overtakenCustomer;
        if (finances.businessType === 1) {
          overtakenCustomer = (
            await RestHttpClient.getPrivateDealCustomer(referenceDealId, data.overtakenCustomerId)
          ).data;
        } else {
          overtakenCustomer = (
            await RestHttpClient.getCommercialDealCustomer(
              referenceDealId,
              data.overtakenCustomerId
            )
          ).data;
        }

        send({
          type: CONFIRM_OVERTAKEN_CUSTOMER,
          info: {
            customerId: data.overtakenCustomerId.toString(),
            type: finances.businessType === 1 ? CustomerType.Private : CustomerType.Commercial,
            applyChangesToMainCustomer: false,
            customer:
              finances.businessType === 1
                ? (overtakenCustomer as PrivateCustomerFormValues)
                : (overtakenCustomer as CommercialCustomerFormValues),
          },
        });
      }
      return data;
    }
    // eslint-disable-next-line no-throw-literal
    throw 'Model/Finance not saved';
  };

  return (
    <FinancesRightDrawer
      modelId={model?.modelId}
      distributionChannel={model?.distributionChannel}
      dealTypeId={model?.businessType}
      vehicleCondition={model?.vehicleCondition}
      isDrawerOpened={isDrawerOpened}
      setIsDrawerOpened={setIsDrawerOpened}
      isIncludingVat={isIncludingVat}
      defaultValues={defaultValues}
      editObject={editObject}
      fetchDefault={fetchDefault}
      fetchCalculate={fetchCalculate}
      onSubmit={handleSubmit}
      dealSalesAids={
        state.context?.finances?.salesAids.map((sa) => {
          const { isEditable, id, ...saWithoutEdit } = sa;
          return {
            ...sa,
            isEditable:
              state.context?.finances?.calculations
                .filter((f) => f.salesAid && f.id !== editObject?.id)
                .findIndex((f) => {
                  const { isEditable: _, id, ...aid } = f.salesAid || {};
                  const sAid: SalesAidValues = aid as SalesAidValues;
                  const mapSubsidy = (f: any) => ({
                    fixum: f.fixum,
                    isFixValue: f.isFixValue,
                    percentage: f.percentage,
                    subsidyGiverId: f.subsidyGiverId,
                    subsidyRecipientId: f.subsidyRecipientId,
                  });
                  const a = {
                    name: sAid.name || undefined,
                    description: sAid.description || undefined,
                    notice: sAid.notice || undefined,
                    specialOfferId: sAid.specialOfferId || undefined,
                    descriptionFileId: sAid.descriptionFileId || undefined,
                    subsidies: sAid.subsidies.map(mapSubsidy),
                  };

                  const b = {
                    name: saWithoutEdit.name || undefined,
                    description: saWithoutEdit.description || undefined,
                    notice: saWithoutEdit.notice || undefined,
                    specialOfferId: saWithoutEdit.specialOfferId || undefined,
                    descriptionFileId: saWithoutEdit.descriptionFileId || undefined,
                    subsidies: saWithoutEdit.subsidies.map(mapSubsidy),
                  };
                  return JSON.stringify(a) === JSON.stringify(b);
                }) === -1,
          };
        }) ?? []
      }
      onAddSalesAid={handleAddSalesAid}
      onEditSalesAid={handleEditSalesAid}
      fetchSubmittedDeals={fetchSubmittedDeals}
      fetchReconsideredFundingProduct={fetchReconsideredFundingProduct}
    />
  );
};

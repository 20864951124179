/* eslint-disable eqeqeq */
import { FieldValues } from 'react-hook-form/dist/types';
import { Controller } from 'react-hook-form';
import {
  Box,
  BoxProps,
  InputAdornment,
  InputBaseComponentProps,
  TextField,
  TextFieldProps,
} from '@mui/material';

import { FormComponentProps } from './FormComponentProps';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface Props<TFieldValues>
  extends FormComponentProps<TFieldValues>,
    Omit<NumericFormatProps, 'name'> {
  pricesIncludingVAT: boolean;
  rate: number;
  vatFree?: boolean;
  boxProps?: BoxProps;
}

const PriceNumberFormat = React.forwardRef<typeof NumericFormat, InputBaseComponentProps>(
  (props, ref) => {
    const { onChange, defaultValue, ...other } = props;

    return (
      <NumericFormat
        {...other}
        defaultValue={defaultValue as string}
        getInputRef={ref}
        onValueChange={(values) => {
          (onChange as any)({
            target: {
              name: props.name,
              value: values.floatValue != null ? values.floatValue : undefined,
            },
          });
        }}
        decimalSeparator=","
        thousandSeparator="."
        decimalScale={2}
        fixedDecimalScale
        onBlur={() => {
          if (props.onBlur) {
            (props.onBlur as any)({
              target: {
                name: props.name,
                value: props.value,
              },
            });
          }
        }}
        onFocus={(e) => e.target.select()}
        allowNegative={false}
      />
    );
  }
);

export const FormPriceInput = <TFieldValues extends FieldValues>({
  control,
  name,
  className,
  shrink,
  label,
  pricesIncludingVAT,
  rate,
  onBlur,
  readOnly,
  disableUnderline,
  vatFree,
  boxProps,
  ...others
}: Props<TFieldValues> & TextFieldProps) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState, fieldState: { error } }) => {
        const handleOnBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          if (onBlur && (field.name.includes('.') || formState.dirtyFields[field.name])) {
            onBlur(e);
          }
        };

        const renderGross = () => {
          return (
            <>
              <TextField
                error={!!error}
                {...field}
                value={field.value as number}
                label={`${label} ${t('price.gross')}`}
                variant="standard"
                InputLabelProps={{ shrink }}
                InputProps={{
                  inputComponent: PriceNumberFormat,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  readOnly,
                  disableUnderline,
                }}
                onBlur={(e) => {
                  handleOnBlur(e);
                  field.onBlur();
                }}
                helperText={error ? error.message : ''}
                {...others}
                sx={
                  others.required && !error && !others.disabled
                    ? {
                        '& .MuiInput-underline:not(.Mui-focused):before': {
                          borderBottom: '2px solid black',
                        },
                        '& .MuiInput-underline:not(.Mui-focused):after': {
                          borderBottom: '2px solid black',
                        },
                        ...others.sx,
                      }
                    : others.sx
                }
              />
              <TextField
                error={!!error}
                value={field.value != undefined ? (field.value as number) / (rate / 100 + 1) : 0}
                label={t('price.exclVat')}
                variant="standard"
                InputLabelProps={{ shrink }}
                InputProps={{
                  inputComponent: PriceNumberFormat,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  readOnly: true,
                  disabled: true,
                  disableUnderline,
                }}
                disabled
                {...others}
              />
            </>
          );
        };

        const renderNet = () => {
          return (
            <>
              <TextField
                error={!!error}
                value={
                  field.value != undefined ? (field.value as number) * ((100.0 + rate) / 100) : 0
                }
                label={t('price.incVat')}
                variant="standard"
                InputLabelProps={{ shrink }}
                InputProps={{
                  inputComponent: PriceNumberFormat,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  readOnly: true,
                  disabled: true,
                  disableUnderline,
                }}
                {...others}
                disabled
              />
              <TextField
                error={!!error}
                {...field}
                onBlur={(e) => {
                  handleOnBlur(e);
                  field.onBlur();
                }}
                value={field.value as number}
                label={`${label} ${t('price.net')}`}
                variant="standard"
                InputLabelProps={{ shrink }}
                InputProps={{
                  inputComponent: PriceNumberFormat,
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  readOnly,
                  disableUnderline,
                }}
                helperText={error ? error.message : ''}
                {...others}
                sx={
                  others.required && !error && !others.disabled
                    ? {
                        '& .MuiInput-underline:not(.Mui-focused):before': {
                          borderBottom: '2px solid black',
                        },
                        '& .MuiInput-underline:not(.Mui-focused):after': {
                          borderBottom: '2px solid black',
                        },
                        ...others.sx,
                      }
                    : others.sx
                }
              />
            </>
          );
        };

        if (!vatFree) {
          return (
            <Box
              {...boxProps}
              display="flex"
              className={className ? `${className} price-input` : 'price-input'}
            >
              {pricesIncludingVAT ? renderGross() : renderNet()}
            </Box>
          );
        }
        return (
          <Box
            display="flex"
            {...boxProps}
            className={className ? `${className} price-input` : 'price-input'}
          >
            <TextField
              error={!!error}
              {...field}
              onBlur={(e) => {
                handleOnBlur(e);
                field.onBlur();
              }}
              value={field.value}
              label={`${label}`}
              variant="standard"
              InputLabelProps={{ shrink }}
              InputProps={{
                inputComponent: PriceNumberFormat,
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                readOnly,
                disableUnderline,
              }}
              helperText={error ? error.message : ''}
              {...others}
              sx={
                others.required && !error && !others.disabled
                  ? {
                      '& .MuiInput-underline:not(.Mui-focused):before': {
                        borderBottom: '2px solid black',
                      },
                      '& .MuiInput-underline:not(.Mui-focused):after': {
                        borderBottom: '2px solid black',
                      },
                      ...others.sx,
                    }
                  : others.sx
              }
            />
          </Box>
        );
      }}
    />
  );
};

FormPriceInput.defaultProps = {
  pricesIncludingVAT: true,
  rate: 20,
};

import { Box, BoxProps } from '@mui/material';
import { FinancesObjectPricing } from './FinancesObjectPricing';
import { FinancesObjectPropetyDetails } from './FinancesObjectPropetyDetails';
import { Control } from 'react-hook-form';
import { FinancesDrawerFormValues } from '../RightDrawerSchema';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  control: Control<FinancesDrawerFormValues>;
  setValue: UseFormSetValue<FinancesDrawerFormValues>;
  getValues: UseFormGetValues<FinancesDrawerFormValues>;
  isIncludingVat: boolean;
  vatFree: boolean;
  onRelevantChange: (name: string, value: unknown) => void;
  loading: boolean;
  readonly?: boolean;
  dealId?: number;
  modelId?: number;
  dealTypeId?: number;
  vehicleCondition?: number;
  hasSubmittedCaclulation?: boolean;
} & BoxProps;
export const FinancesObject = ({
  control,
  isIncludingVat,
  vatFree,
  onRelevantChange,
  setValue,
  getValues,
  loading,
  readonly,
  dealId,
  modelId,
  dealTypeId,
  vehicleCondition,
  hasSubmittedCaclulation,
  ...others
}: Props) => {
  const { t } = useTranslation();
  return (
    <Box {...others}>
      <Box sx={{ m: 2 }}>
        <h2 style={{ marginBottom: 16 }}>
          {t('deals.new.financesConfiguration.calculationDrawer.parameter.addObject')}
        </h2>
      </Box>
      <FinancesObjectPricing
        setValue={setValue}
        getValues={getValues}
        control={control}
        isIncludingVat={isIncludingVat}
        vatFree={vatFree}
        onRelevantChange={onRelevantChange}
        loading={loading}
        readonly={readonly}
      />
      <FinancesObjectPropetyDetails
        control={control}
        setValue={setValue}
        onRelevantChange={onRelevantChange}
        loading={loading}
        readonly={readonly}
        dealId={dealId}
        modelId={modelId}
        dealTypeId={dealTypeId}
        vehicleCondition={vehicleCondition}
        hasSubmittedCaclulation={hasSubmittedCaclulation}
      />
    </Box>
  );
};

export function distinct(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}

// usage example:
//var a = ['a', 1, 'a', 2, '1'];
//var unique = a.filter(distinct);

type NestedValue = { [key: string]: any };

export function getNestedValue<T>(obj: T, path: string): any {
  return path.split('.').reduce((acc, part) => acc && (acc as NestedValue)[part], obj);
}